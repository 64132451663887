import { styled } from '@mui/system';
import Select from '@mui/material/Select';

export const StyledSelect = styled(Select)(({
    '& .MuiSelect-select':{
      padding: '12px',
      ':focus':{
        borderRadius: '12px',
      }
    }
}));