import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useUpdateWritingMutation,
} from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';
import { SubmitTaskContext } from '../submit-task-context';
import { DELETED_ASSIGNMENT_MESSAGE, SUBMISSION_DOES_NOT_EXIST_ERROR } from '../../../variables/constant';

const useWritingTask = () => {
  const history = useHistory();
  const {
    id,
    submission,
    setShowSuccess,
  } = useContext(SubmitTaskContext);
  const [writing, setWriting] = useState(submission?.writing);
  const [updateWritingForAssignment, { loading }] = useUpdateWritingMutation();
  const {
    updateStatus,
  } = useContext(SubmitTaskContext);
  const updateWriting = async () => {
    if (!id) {
      return;
    }
    try {
      const response = await updateWritingForAssignment({
        variables: {
          id,
          writing: writing?.trim(),
        },
      });
      if (response?.data?.updateWriting?.id) {
        updateStatus?.(response?.data?.updateWriting?.status);
        setShowSuccess?.(true);
      }
    } catch (error) {
      if (getErrorMessage(error) === SUBMISSION_DOES_NOT_EXIST_ERROR) {
        openSnackbar({
          message: DELETED_ASSIGNMENT_MESSAGE,
        }, NotifierType.Error);
        history.replace('/');
      } else {
        openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
      }
    }
  };

  return {
    writing,
    setWriting,
    loading,
    updateWriting,
  };
};

export default useWritingTask;
