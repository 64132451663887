import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import { ScoreTabs, SubmissionSort } from '../../../variables/types';
import { AssignmentSubmissionFragment, OrderBy, SubmissionStatus } from '../../../generated/graphql';
import { toReadableFormat } from '../../../utils/dateFormat';
import NoResults from '../../../components/NoResults';
import HumanDeskFrame from '../../../components/Icons/HumanDeskFrame';
import AssignmentStatus from '../../../components/AssignmentStatus';
import MarkCompleteIcon from '../../../components/Icons/MarkCompleteIcon';
import { StylesCss } from '../Assignments.styled';
import Loader from '../../../components/Loader/loader';


interface Props {
  stickyHeader?: boolean,
  loading?: boolean,
  submissions?: AssignmentSubmissionFragment[],
  onSortChange: (sortBy: SubmissionSort, orderBy: OrderBy) => void,
  sort: SubmissionSort,
  order: OrderBy,
  createQueue?: (id: string) => void,
}

const SubmissionTable = ({
  loading = false,
  stickyHeader = true,
  submissions,
  onSortChange,
  sort,
  order,
  createQueue,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCss(theme);
  const history = useHistory();
  const isTableEmpty = !loading && submissions?.length === 0;

  const navigateTo = (id: string) => () => {
    createQueue?.(id);
    history.push(`/tasks/${id}/score/${ScoreTabs.Speaking}`);
  };

  const handleSort = (column: SubmissionSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const orderBy = order === OrderBy.Desc ? 'asc' : 'desc';

  return (
    <Table
      /*
        *  Due to 'stickyHeader' prop, backgroundColor on TableHead or TableRow
        *  was not working. So added global styles under theme.ts file for
        *  MuiTableCell & MuiTableHead
        */
      stickyHeader={stickyHeader}
      aria-label="students submissions table"
      sx={loading || isTableEmpty ? styles.tableWithNoData : {}}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '15%' }}>
            <TableSortLabel
              direction={orderBy}
              onClick={handleSort(SubmissionSort.Name)}
              sx={styles.iconSortLabel1}
              active={sort === SubmissionSort.Name}
              IconComponent={ArrowDownIcon}
            >
              Student Name
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '20%' }}>
            <TableSortLabel
              direction={orderBy}
              onClick={handleSort(SubmissionSort.Assignment)}
              sx={styles.iconSortLabel1}
              active={sort === SubmissionSort.Assignment}
              IconComponent={ArrowDownIcon}
            >
              Assignment
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '15%' }}>
            <TableSortLabel
              direction={orderBy}
              onClick={handleSort(SubmissionSort.Date)}
              active={sort === SubmissionSort.Date}
              IconComponent={ArrowDownIcon}
            >
              Date Submitted
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '10%' }}>Class</TableCell>
          <TableCell sx={{ width: '20%' }}>School</TableCell>
          <TableCell sx={{ width: '15%' }}>
            <Box sx={styles.tableHeadContentContainer}>
              Status
            </Box>
          </TableCell>
          <TableCell sx={{ width: '5%' }} />
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading && submissions?.map((row) => (
          <TableRow key={row.id}>
            <TableCell
              sx={{ color: theme.palette.primary.main, fontSize: 18 }}
            >
              {`${row?.student?.first_name} ${row?.student?.last_name}`}
            </TableCell>
            <TableCell
              sx={{ color: theme.palette.customBlack.lightBlack, fontSize: 16 }}
            >
              {`${row?.assignment?.title}`}
            </TableCell>
            <TableCell
              sx={{ color: theme.palette.customBlack.lightBlack, fontSize: 16 }}
            >
              {row?.submitted_at ? toReadableFormat(row?.submitted_at) : ''}
            </TableCell>
            <TableCell
              sx={{ color: theme.palette.customBlack.lightBlack, fontSize: 16 }}
            >
              {`${row?.assignment?.section?.name}`}
            </TableCell>
            <TableCell
              sx={{ color: theme.palette.customBlack.lightBlack, fontSize: 16 }}
            >
              {`${row?.assignment?.section?.school?.name}`}
            </TableCell>
            <TableCell>
              <Box display="flex">
                <AssignmentStatus abbreviation="S" tileStatus={row?.gradingStatus?.IsSpeakingScored} name="Speaking" />
                <AssignmentStatus abbreviation="SG" tileStatus={row?.gradingStatus?.IsSpeakingGoalSet} name="Speaking Goal" />
                <AssignmentStatus abbreviation="W" tileStatus={row?.gradingStatus?.IsWritingScored} name="Writing" />
                <AssignmentStatus abbreviation="WG" tileStatus={row?.gradingStatus?.IsWritingGoalSet} name="Writing Goal" />
                <AssignmentStatus iconPresent abbreviation={<MarkCompleteIcon />} tileStatus={row?.gradingStatus?.IsReviewed && row?.gradingStatus?.IsSpeakingGoalSet && row?.gradingStatus?.IsWritingGoalSet} name="Reviewed" />
              </Box>
            </TableCell>
            <TableCell sx={{ color: theme.palette.customBlack.lightBlack, fontSize: 16 }} align="center">
              <Button
                variant="outlined"
                onClick={navigateTo(row.id)}
                sx={{
                  fontWeight: 800,
                  fontSize: 16,
                  height: 42,
                  width: 165,
                  border: `2px solid ${theme.palette.customBackground.disabled}`,
                  lineHeight: '24px',
                  textAlign: 'center',
                  textTransform: 'none',
                }}
              >
                {row.status === SubmissionStatus.Reviewed ? 'View' : 'Grade'}
              </Button>
            </TableCell>
          </TableRow>
        ))}
        {isTableEmpty && (
          <TableRow>
            <TableCell
              colSpan={6}
              align="center"
            >
              <NoResults
                label="No submissions yet"
                description="There have been no student submissions recorded at this time."
                svgComponent={<HumanDeskFrame />}
              />
            </TableCell>
          </TableRow>
        )}
        <Loader open={loading} />
      </TableBody>
    </Table>
  );
};
export default SubmissionTable;
