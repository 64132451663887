import React from 'react';
import { Box, Step, StepConnector, StepLabel, Stepper, Typography } from '@mui/material';
import { styled, withStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import { FormStep } from '../../variables/types';
import { palette  } from '../../theme/palette';

interface Props {
  steps: Array<FormStep>;
  progressValue: number;
}

interface ColoredStepIconProps {
  active: boolean;
  completed: boolean;
  icon: React.ReactNode;
}

const styles = {
  root: {
    backgroundColor: 'transparent !important',
    padding: '0px',
  },
  stepIcon: {
    backgroundColor: palette.background,
    zIndex: 1,
    color: palette.customGrey.darkGrey,
    width: '32px',
    height: '32px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inactive: {
    border: `3px solid ${palette.customGrey.main}`,
    boxSizing: 'border-box',
  },
  active: {
    border: `3px solid ${palette.customBlue.primaryPurple}`,
    borderWidth: '1%',
    backgroundColor: palette.customWhite.main,
    color: palette.customBlue.primaryPurple,
  },
  completed: {
    borderRadius: '50%',
    backgroundColor: palette.customBlue.primaryPurple,
    color: palette.customWhite.main,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  stepper: {
    backgroundColor: 'transparent !important',
    padding: '0px',
  },
};

const ColoredConnector = withStyles(() => ({
  alternativeLabel: {
    top: 14,
  },
  active: {
    '& $line': {
      backgroundColor: palette.customBlue.primaryPurple,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: palette.customBlue.primaryPurple,
    },
  },
  line: {
    marginLeft: 0.8,
    width: '100%',
    height: 3,
    border: 0,
    backgroundColor: palette.customGrey.main,
  },
}))(StepConnector);

const BoldTypography = styled(Typography)(() => ({
  fontWeight: 500,
  marginTop: 0,
}));

const ColoredStepIcon = (props: ColoredStepIconProps) => {
  const { active, completed, icon } = props;

  return (
    <Box
      sx={{
        ...styles.stepIcon, ...(active) && styles.active,
        ...(!active && completed) && styles.completed,
        ...(!active && !completed) && styles.inactive,
      }}
    >
      {(!active && completed) ? <CheckIcon sx={{ ...styles.completed }} fontSize='medium'/> : (
        <BoldTypography>
          {` ${icon} `}
        </BoldTypography>
      )}
    </Box>
  );
};

const FormProgressBar = ({ steps, progressValue }: Props) => {
  return (
    <Box sx={{ ...styles.root }}>
      <Stepper alternativeLabel activeStep={progressValue} connector={<ColoredConnector />} sx={{ ...styles.root }}>
        {steps.map((step, idx) => {
          const stepProps: { active?: boolean, completed?: boolean } = {};
          stepProps.active = (idx === progressValue);
          stepProps.completed = idx < progressValue;
          return (
            <Step sx={{ ...styles.root }} key={step.label} {...stepProps}>
              <StepLabel
                StepIconComponent={ColoredStepIcon}
                StepIconProps={{ ...stepProps }}
              >
                <BoldTypography  sx={{ ...styles.capitalize }}>{step.label}</BoldTypography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default FormProgressBar;
