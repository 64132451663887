import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  useGetAllAssignmentsForStudentQuery,
  useCreateSubmissionMutation,
  SubmissionStatus,
  useGetActiveGoalsQuery,
  useGetUnreadFeedbackOfStudentQuery,
  useMarkFeedbackViewedMutation,
  UnReadFeedbacksFragment,
  TeacherOfStudentFilterFragmentFragment,
  SectionFilterFragmentFragment,
  GoalType,
  useGetTeachersAssociatedWithSectionsQuery,
  useCreateBenchmarkSubmissionMutation,
} from '../../generated/graphql';
import { toUTC } from '../../utils/dateFormat';
import { openSnackbar } from '../../components/Notifier';
import { NavigationType, NotifierType } from '../../variables/types';
import getErrorMessage from '../../utils/getErrorMessage';
import getRandomElementFromArray from '../../utils/getRandomElementFromArray';
import { OVERLAY_COLORS } from '../../theme/palette';
import { useNavigationProvider } from '../../core/navigationContext';

const useAssignmentsForStudents = () => {
  const now = useRef(toUTC(new Date()));
  const history = useHistory();
  const [feedback, setFeedback] = useState<UnReadFeedbacksFragment[]>([]);
  const [markFeedbackViewedMutation, { loading: feedbackSubmitting }] = useMarkFeedbackViewedMutation();
  const [selectedTeacher, setSelectedTeacher] = useQueryParam('teacher', StringParam);
  const [selectedSection, setSelectedSection] = useQueryParam('section', StringParam);
  const [sectionIds, setSectionIds] = useState<(string)[]>([]);
  const { setNavigationType } = useNavigationProvider();

  const { data, loading } = useGetAllAssignmentsForStudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        start_at: { lte: now.current },
        close_at: { gte: now.current },
        include_not_started: true,
        status: [
          SubmissionStatus.Started,
          SubmissionStatus.AnnotationCompleted,
          SubmissionStatus.SpeakingCompleted,
        ],
      }
    },
  });

  const { data: activeGoals, loading: fetchingGoals } = useGetActiveGoalsQuery({
    fetchPolicy: 'network-only',
    variables:{
      goalType: GoalType.Active
    }
  });

  const { data: feedbackData, loading: fetchingFeedbacks, refetch: refetchUnread } = useGetUnreadFeedbackOfStudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(selectedSection ? { sectionID: selectedSection } : {}),
      ...(selectedTeacher ? { teacherID: selectedTeacher } : {}),
    },
  });

  const onTeacherChange = (teacher: string | TeacherOfStudentFilterFragmentFragment | null) => {
    setSelectedTeacher(teacher as string);
  };

  const onSectionChange = (section: string | SectionFilterFragmentFragment | null) => {
    setSelectedSection(section as string);
  };

  const [create, { loading: submissionLoading }] = useCreateSubmissionMutation();
  const [createBenchmarkSubmission, {loading: benchmarkLoading}] = useCreateBenchmarkSubmissionMutation()
  const assignments = data?.getAllAssignmentsAndBenchmarksForStudent.assignments ?? [];
  const benchmarks = data?.getAllAssignmentsAndBenchmarksForStudent.benchmarks ?? [];

  useEffect(() => {
    if (feedbackData?.unreadFeedbackOfStudent) {
      setFeedback(feedbackData.unreadFeedbackOfStudent);
    }
  }, [feedbackData]);

  const exitFeedbacks = () => {
    const submissionIds = feedback.map((item) => item.id);
    handleExit(submissionIds);
  };

  const handleRedirection = (id: string, submissionId?: string, isBenchmark?: boolean) => async () => {
    if (submissionId) {
      history.push(`/tasks/${submissionId}/submit`);
      openSnackbar({ message: 'Task Resumed.' }, NotifierType.Success);
    } else {
     isBenchmark ? await onCreateBenchmarkSubmission(id) : await createSubmission(id);
    }
  };

  const progressRedirect = async (submissionId: string) => {
    if (submissionId) history.push(`/progress/score?submission=${submissionId}`);
  };

  const createSubmission = async (id: string) => {
    try {
      const response = await create({
        variables: {
          assignment_id: id,
        },
      });
      if (response.data?.createSubmission) {
        history.push(`/tasks/${response.data.createSubmission}/submit`);
        openSnackbar({ message: 'Task Started.' }, NotifierType.Success);
      }
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  const onCreateBenchmarkSubmission = async (id: string) => {
    try {
      const response = await createBenchmarkSubmission({
        variables: {
          benchmark_id: id,
        },
      });
      if (response.data?.createBenchmarkSubmission) {
        history.push(`/tasks/${response.data.createBenchmarkSubmission}/submit`);
        openSnackbar({ message: 'Task Started.' }, NotifierType.Success);
      }
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  }

  const getMap = () => assignments?.reduce((result: Record<string, string>, assignment) => {
    // eslint-disable-next-line no-param-reassign
    result[assignment?.teacher_user_id || 'new'] = getRandomElementFromArray(OVERLAY_COLORS);
    return result;
  }, {});

  const handleExit = async (submissionIds: string[]) => {
    try {
      await markFeedbackViewedMutation({
        variables: {
          submissionIds,
        },
      });
      // remove the specific feedback from the list
      if (submissionIds?.length === 1) {
        setFeedback(feedback.filter((item) => item.id !== submissionIds?.[0]));
      } else {
        refetchUnread();
      }
    } catch (error) {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  const handleReset = () => {
    setSelectedSection('');
    setSelectedTeacher('');
  };

  useEffect(() => {
    setNavigationType(NavigationType.Both);
  }, [setNavigationType]);

  useEffect(() => {
    getSectionIds()
  }, [assignments]);

  const getSectionIds = () => {
    const sectionIds = assignments.map(assignment => assignment.section_id ?? '')
    setSectionIds(sectionIds!)
  }

  const {data: sectionTeachers, loading: teacherLoading} = useGetTeachersAssociatedWithSectionsQuery({
    fetchPolicy: 'network-only',
    variables: {
       sectionIDs: sectionIds ?? [],
    },
    skip: !sectionIds.length
  })

  const assignmentTeachers = (id?: string) => {
      const teachers = sectionTeachers?.teachersOfSection?.find(section => section?.id == id)?.teachers
      teachers?.sort((t1, t2) => Number(!t1?.primary_teacher) - Number(!t2?.primary_teacher));
    return teachers?.map(teacher => teacher?.primary_teacher ? ` ${teacher?.first_name} ${teacher?.last_name}*` : ` ${teacher?.first_name} ${teacher?.last_name}` )
  }

  return {
    loading: loading || submissionLoading || fetchingGoals || fetchingFeedbacks || teacherLoading || benchmarkLoading,
    assignments,
    benchmarks,
    handleRedirection,
    teacherIDColorMap: getMap(),
    activeGoals,
    handleExit,
    feedbackSubmitting,
    progressRedirect,
    feedback,
    selectedTeacher,
    selectedSection,
    onTeacherChange,
    onSectionChange,
    setSelectedSection,
    setSelectedTeacher,
    exitFeedbacks,
    handleReset,
    assignmentTeachers,
  };
};

export default useAssignmentsForStudents;
