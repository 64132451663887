import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import useTeacherSchoolsFilter from './teacherschools-filter-hook';

interface Props {
  error?: boolean;
  className?: string;
  value?: SchoolValue | null | string;
  onChange?: (data: SchoolValue | null | string, district_id?: string | null ) => void;
  placeholder?: string;
  disabled?:boolean;
  readOnly?:boolean;
  idSelectOnly?: boolean;
}

export type SchoolValue = {
  id: string,
  name: string
  district_id? : string
} | null | undefined;

const TeacherSchoolFilter = ({
  className,
  placeholder,
  onChange,
  value,
  disabled,
  readOnly = false,
  idSelectOnly = false,
}: Props) => {
  const {
    schools,
    loading,
  } = useTeacherSchoolsFilter();

  const onSelectChange = (_: any, school: SchoolValue | null) => {
    onChange?.(idSelectOnly ? (school?.id ?? '') : school, school?.district_id);
  };

  const selectedValue: SchoolValue = idSelectOnly ? schools?.find((item) => item?.id === value) ?? null : value as SchoolValue;

  return (
    <Autocomplete
      className={className}
      options={schools || []}
      getOptionLabel={(option: any) => option?.name}
      isOptionEqualToValue={(option: any, selected: any) => option?.name === selected?.name}
      onChange={onSelectChange}
      value={selectedValue}
      disabled={disabled || loading}
      readOnly={readOnly}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: { fontWeight: 800, fontSize: '16px' },
          }}
        />
      )}
    />
  );
};

export default TeacherSchoolFilter;
