import { createTheme } from '@mui/material/styles';
import { palette  } from './palette';

const defaultTheme = createTheme();

const typography = {
  fontFamily:
    '"Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  pageTitle: {
    fontSize: defaultTheme.typography.pxToRem(32),
    fontWeight: 800,
    lineHeight: '44px',
    color: palette.fontColors.fontBlack,
    letterSpacing: '0.1px',
    textTransform: 'capitalize',
  },
  tileTitle: {
    fontSize: defaultTheme.typography.pxToRem(24),
    lineHeight: '42px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  breadcrumbs: {
    color: palette.customBlack.lightBlack,
    fontWeight: 800,
    fontSize: defaultTheme.typography.pxToRem(18),
    textTransform: 'uppercase',
  },
  sTitle:{
    fontSize: defaultTheme.typography.pxToRem(20),
    lineHeight: '27.28px',
    fontWeight: 'bold',
    weight: '800',
    textTransform: 'capitalize',
    paddingLeft: 8,
  },
  sContentHeader:{
    color: palette.fontColors.fontBlack,
    fontSize: defaultTheme.typography.pxToRem(24),
    fontWeight: 800,
    lineHeight: '33px',
    letterSpacing: '0.1px',
  },
  sLabel:{
    color: palette.customBlack.lightBlack,
    fontWeight: 600,
    fontSize: defaultTheme.typography.pxToRem(18),
    lineHeight: '25px',
    letterSpacing: '0.1px',
  },
  sFormTitle:{
    color: palette.fontColors.fontBlack,
    fontSize: defaultTheme.typography.pxToRem(18),
    fontWeight: 800,
    margin: defaultTheme.spacing(2, 0, 1, 0),
  },
  sFormHelperText:{
    color: palette.fontColors.fontBlack,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 600,
    margin: defaultTheme.spacing(1, 0, 1, 0),
  },
  sFormHelper:{
    color: palette.customRed.main,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 600,
    marginTop: '8px',
  },
  sTaskTitle:{
    color: palette.fontColors.fontBlack,
    fontSize: defaultTheme.typography.pxToRem(18),
    fontWeight: 800,
    lineHeight: '25px',
    letterSpacing: '0.1px',
  },
  secondaryTitle: {
    fontSize: defaultTheme.typography.pxToRem(32),
    fontWeight: 800,
    lineHeight: '32x',
    color: palette.fontColors.fontBlack,
    letterSpacing: '0.1px',
    textTransform: 'capitalize',
  },
  secondaryLabel:{
    color: palette.customBlack.lightBlack,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: '22px',
  },
  font28:{
    fontSize: defaultTheme.typography.pxToRem(28),
    fontWeight: 'bold',
    lineHeight: '38px',
    color: palette.fontColors.fontBlack,
    letterSpacing: '0.1px',
  },
  font22:{
    fontSize: defaultTheme.typography.pxToRem(22),
    fontWeight: 800,
    lineHeight: '22px',
    color: palette.fontColors.fontBlack,
    letterSpacing: '0.1px',
  },
  optionsItalic:{
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
  selectPlaceholder: {
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 800,
    letterSpacing: 0.1,
    color: palette.fontColors.fontBlack,
  },
  redirectionLinks:{
    fontWeight: 700,
    color:palette.customBlue.primaryBlue,
    textDecoration: 'none',
    fontSize: defaultTheme.typography.pxToRem(16),
  },
  tableTitleData:{
    fontSize: defaultTheme.typography.pxToRem(18),
    textDecoration: 'none',
    fontWeight: 700,
    color: palette.fontColors.fontBlack,
  },
  filterErrors:{
    color: palette.customRed.main,
    fontSize: defaultTheme.typography.pxToRem(14),
    fontWeight: 600,
    marginLeft: 0,
    marginTop: 10,
    lineHeight: '15px',
  },
  rubricOrder:{
    color: palette.fontColors.fontBlack,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 800,
    lineHeight: '22px',
    letterSpacing: '0.1px',
    marginRight: '8px',
  },
  rubricOrderContent:{
    color: palette.customBlack.lightBlack,
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
  sliderTimer:{
    color: palette.fontColors.fontBlack,
    fontWeight: 800,
    lineHeight: '25px',
    fontSize: defaultTheme.typography.pxToRem(12),
  },
  title18:{
    fontWeight: 800,
    fontSize: defaultTheme.typography.pxToRem(18),
    lineHeight: '24px',
    letterSpacing: '1px',
  },
  font16: {
    fontWeight: 400,
    fontSize: defaultTheme.typography.pxToRem(16),
    lineHeight: '24px',
    letterSpacing: '1px',
  },
  modalTitle: {
    fontWeight: 700,
    fontSize: defaultTheme.typography.pxToRem(36),
    lineHeight: '24px',
  },
  filterChip: {
    fontWeight: 700,
    fontSize: defaultTheme.typography.pxToRem(14),
    lineHeight: '24px',
    letterSpacing: '1px',
  },
};

export default typography;
