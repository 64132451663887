import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StudentBackPackBoard from './StudentBackPackBoard';
import StudentBackPackView from './StudentBackPackView';

const StudentBackPack = () => (
  <Switch>
    <Route
      path="/backpack"
      component={StudentBackPackBoard}
      exact
    />
    <Route
      path="/backpack/:id/view"
      component={StudentBackPackView}
      exact
    />
  </Switch>
);

export default React.memo(StudentBackPack);
