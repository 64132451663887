import React from 'react';
import StyledCard from '../../../../components/StyledCard';
import NoResults from '../../../../components/NoResults';
import EmptyResults from '../../../../components/Icons/EmptyResults';
import SingleBarGraphChart from '../../../../components/Charts/Graph/SingleBarGraphChart';
import SingleBarGraphLoader from '../../../../components/Loader/SingleBarGraphLoader';
import { palette  } from '../../../../theme/palette';
import PerformanceTable from '../../../Analytics/Overview/PerformanceTable';
import AppTooltip from '../../../../components/AppTooltip';
import useGoalPerformanceWidget, { Props } from './goal-performance-widget-hook';
import { AdminDashboards } from '../../../../variables/constant';

const GoalPerformanceWidget = ({
  skillTypes,
  alignTitle,
  enableTooltip,
  tooltipTitle,
}: Props) => {
  const {
    totalCount, hasData,
    performanceSkillData,
    barGraphData,
    goalPerformanceWidgetDataLoading,
    title, handleTooltipClick,
  } = useGoalPerformanceWidget();

  return (
    <>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={tooltipTitle}/>}
        fullHeight
      >
        {!goalPerformanceWidgetDataLoading && hasData && (
          <>
            <SingleBarGraphChart
              boxWidth='100%'
              data={barGraphData}
              onLinkClick={(current, skillScore) => {
                handleTooltipClick(current?.payload ?? null, skillScore);
              }}
              disableLink
              disableDesc
            />
            <PerformanceTable
              data={performanceSkillData}
              skillType={skillTypes}
              skillCount={totalCount}
              customLabel
              fullWidth
            />
          </>
        )}
        {!goalPerformanceWidgetDataLoading && !hasData && (
          <NoResults
            description={AdminDashboards.learningGoals.GoalPerformanceWidget.insufficientData.description}
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {goalPerformanceWidgetDataLoading && <SingleBarGraphLoader />}
      </StyledCard>
    </>
  );
};

export default React.memo(GoalPerformanceWidget);
