/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { svgStyles } from '../Common/Common.styled';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { PredefinedGrades } from '../../generated/graphql';
import useGradesFilter from './grade-filter-hook';
import ClassesIcon from '../Icons/ClassesIcon';
import { palette  } from '../../theme/palette';

interface Props {
  error?: boolean;
  className?: string;
  value?: PredefinedGrades | null | string;
  onChange?: (data: PredefinedGrades | null | string, oldValue: PredefinedGrades | null | string) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
}

type GradeValue = PredefinedGrades | null | undefined;

const GradeFilter = ({
  className,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled = false,
}: Props) => {
  const {
    grades,
    loading,
  } = useGradesFilter();
  const onSelectChange = (_: any, grade: PredefinedGrades | null) => {
    onChange?.(
      idSelectOnly ? (grade?.id ?? '') : grade,
      value as string,
    );
  };
  const selectedValue: GradeValue = idSelectOnly ? grades?.find((item) => item.id === value) ?? null : value as GradeValue;

  return (
    <Autocomplete
      className={className}
      options={grades || []}
      getOptionLabel={(option: any) => option?.grade}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      onChange={onSelectChange}
      value={selectedValue}
      disabled={loading || disabled}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <ClassesIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(GradeFilter);
