import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PAGE_SIZE } from '../../../variables/constant';
import {
  BenchmarkSubmissionListInput,
  BenchmarkSubmissionListSortType,
  OrderBy,
  SubmissionStatus, useAdminBenchmarkGradingQueueQuery,
  useBenchmarkGradesListQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import useBenchmarksQueueHook from '../../ScoreTaskBenchmark/score-benchmark-queue-hook';

export interface BenchmarkGradesListParams {
  benchmarkId?: string
  sort?: BenchmarkSubmissionListSortType
  gradeStatuses?: SubmissionStatus[]
}

const useBenchmarkGradesList = (
  params?: BenchmarkGradesListParams
) => {
  const StatusInitial = [SubmissionStatus.Reviewed, SubmissionStatus.Submitted];
  const pagination = usePagination();
  const history = useHistory();
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, BenchmarkSubmissionListSortType.SubmittedAt));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [benchmarkTypes, setBenchmarkTypes] = useState<any []>([]);
  const [benchmarkId, setBenchmarkId] = useState<string | undefined>(params?.benchmarkId);
  const [selectedStatuses, setSelectedStatuses] = useState<SubmissionStatus[]>(params?.gradeStatuses ?? StatusInitial);
  const [selectedStates, setSelectedStates] = useState<string[] | undefined>(undefined);
  const [selectedDistricts, setSelectedDistricts] = useState<string[] | undefined>(undefined);
  const [selectedSchools, setSelectedSchools] = useState<string[] | undefined>(undefined);
  const initialInputVariables = {
    limit: PAGE_SIZE,
    page: pagination.page,
    sort: params?.sort ?? BenchmarkSubmissionListSortType.SubmittedAt,
    benchmark_id: benchmarkId,
    order_by: order as OrderBy,
    grade_statuses: selectedStatuses,
  };

  const [queryVariables, setQueryVariables] = useState<BenchmarkSubmissionListInput>({
    ...initialInputVariables,
  });

  const { initializeSubmissionQueue } = useBenchmarksQueueHook();

  const createBenchmarkQueue = (submissionQueueIDs: string[], submissionID: string) => {
    initializeSubmissionQueue(submissionQueueIDs, submissionID);
  };

  const { data, loading } = useBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });

  const { data:submissionQueueData, loading: submissionQueueLoading } = useAdminBenchmarkGradingQueueQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...queryVariables,
      limit: undefined,
      page: undefined,
    },
  });

  const handleClearFilters = async () => {
    pagination.setPage(1);
    setQueryVariables({
      ...initialInputVariables,
      state_ids: undefined,
      district_ids: undefined,
      school_ids: undefined,
    });
    setBenchmarkTypes([]);
    setBenchmarkId(undefined);
    setSelectedStatuses( StatusInitial);
    setSelectedStates(undefined);
    setSelectedDistricts(undefined);
    setSelectedSchools(undefined);
  };

  const handleApplyFilters = async () => {
    pagination.setPage(1);
    setQueryVariables({
      limit: PAGE_SIZE,
      page: 1,
      sort: sort as BenchmarkSubmissionListSortType,
      order_by: order as OrderBy,
      ...(benchmarkTypes.length ? { benchmark_types: benchmarkTypes } : {}),
      ...(benchmarkId ? { benchmark_id: benchmarkId } : {}),
      ...(selectedStatuses ? { grade_statuses: selectedStatuses } : {}),
      ...(selectedStates ? { state_ids: selectedStates } : {}),
      ...(selectedDistricts ? { district_ids: selectedDistricts } : {}),
      ...(selectedSchools ? { school_ids: selectedSchools } : {}),
    });
  };

  const onSortChange = (sortBy: BenchmarkSubmissionListSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
    setQueryVariables({
      ...queryVariables,
      sort: sortBy,
      order_by: orderBy,
    });
  };

  const handlePagination = (value: React.SetStateAction<number>) => {
    pagination.setPage(value as number);
    setQueryVariables({
      ...queryVariables,
      page: value as number,
    });
  };

  const handleRedirection = (submissionQueueIDs: string[], submissionID: string) => async () => {
    createBenchmarkQueue(submissionQueueIDs, submissionID);
    if (submissionID) history.push(`/tasks/${submissionID}/benchmark/score`);
  };

  return {
    loading: loading || submissionQueueLoading,
    handleRedirection,
    gradeBenchmarks: data?.adminBenchmarkSubmissionList?.nodes ?? [],
    pagination,
    totalCount: data?.adminBenchmarkSubmissionList?.node_count ?? 0,
    onSortChange,
    sort,
    order: order as OrderBy,
    benchmarkTypes, setBenchmarkTypes,
    benchmarkId, setBenchmarkId,
    selectedStatuses, setSelectedStatuses,
    selectedStates, setSelectedStates,
    selectedDistricts, setSelectedDistricts,
    selectedSchools, setSelectedSchools,
    handleApplyFilters, handleClearFilters, handlePagination,
    submissionQueueData: submissionQueueData?.adminBenchmarkGradingQueue ?? [],
    createBenchmarkQueue,
  };
};

export default useBenchmarkGradesList;
