import { createContext, useContext, useEffect, useState } from 'react';
import { getUnixTime, sub } from 'date-fns';
import { fromUTCSOD } from '../../utils/dateFormat';
import {
  AdminDashboardFilterInput,
  AdminDashboardSectionFragmentFragment,
  PredefinedGrades,
  SchoolFilterFragmentFragment,
  SubmissionType,
  TeacherFilterFragmentFragment,
  useGetAcademicSessionQuery,
  useMyDistrictQuery,
  useMySchoolQuery,
} from '../../generated/graphql';
import useRole from '../../hooks/useRole';

export interface AnalyticsFiltersProps {
  dateRange?: string,
  submissionType?: SubmissionType,
  schools?: SchoolFilterFragmentFragment[],
  grades?: PredefinedGrades[],
  teachers?: TeacherFilterFragmentFragment[],
  classes?: AdminDashboardSectionFragmentFragment[],
}

export interface DashboardWidgetsDataStatusProps {
  widget: string,
  loading: boolean,
  dataMissing: boolean,
}

export interface DashboardContextProps {
  loading?: boolean,
  setLoading?: Function,
  isDistrictAdmin?: boolean,
  myDistrictData?: any,
  isSchoolAdmin?: boolean,
  mySchoolData?: any,
  currentFilters?: AnalyticsFiltersProps,
  setCurrentFilters?: Function,
  analyticsFilters?: AdminDashboardFilterInput,
  setAnalyticsFilters?: Function,
  academicSession?: any,
  academicSessionLoading?: boolean,
  dashboardWidgetsDataStatus?: DashboardWidgetsDataStatusProps[],
  setDashboardWidgetsDataStatus?: Function,
  updateDashboardWidgetsDataStatus?: Function,
  checkDataMissingInAllWidgets?: Function,
  isDataMissingInAllWidgets?: boolean,
  setIsDataMissingInAllWidgets?: Function,
  areWidgetsLoading?: boolean,
  setAreWidgetsLoading?: Function,
}

export const DashboardContext = createContext<DashboardContextProps>({});

export const DashboardContextInit = (dashboardWidgets: any) => {
  const [loading, setLoading] = useState(false);
  const [dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus] = useState<DashboardWidgetsDataStatusProps[]>(
    Object.values(dashboardWidgets).map((widgetData: any) => ({ widget: widgetData.name, loading: true, dataMissing: false })),
  );
  const [isDataMissingInAllWidgets, setIsDataMissingInAllWidgets] = useState(false);
  const [areWidgetsLoading, setAreWidgetsLoading] = useState(true);
  // ** currentFilters Will save the current Analytics Filters state before user's changes are applied helps in reverting the filters state **
  const [currentFilters, setCurrentFilters] = useState<AnalyticsFiltersProps>();

  // Set Init Date Range to Last 3 months.
  const today = new Date();
  // Last 3 months
  var startDateInit = sub(today, {
    months: 3,
  });
  const [analyticsFilters, setAnalyticsFilters] = useState<AdminDashboardFilterInput>({
    submission_score_type: SubmissionType.Speaking,
    start_timestamp: getUnixTime(startDateInit),
    end_timestamp: getUnixTime(today),
  });

  const { isSchoolAdmin, isDistrictAdmin } = useRole();

  const { data: myDistrictData, loading: myDistrictDataLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: isSchoolAdmin,
  });

  const { data: mySchoolData, loading: mySchoolDataLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: isDistrictAdmin,
  });

  // YTD
  const { data: academicSession, loading: academicSessionLoading } = useGetAcademicSessionQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setLoading?.((isDistrictAdmin && myDistrictDataLoading) || (isSchoolAdmin && mySchoolDataLoading));
  }, [isDistrictAdmin, isSchoolAdmin, myDistrictDataLoading, mySchoolDataLoading]);

  return ({
    loading, setLoading,
    isSchoolAdmin, mySchoolDataLoading, mySchoolData: mySchoolData?.mySchool,
    isDistrictAdmin, myDistrictDataLoading, myDistrictData: myDistrictData?.myDistrict,
    currentFilters, setCurrentFilters,
    analyticsFilters, setAnalyticsFilters,
    academicSessionLoading, academicSession: academicSession?.getAcademicSession,
    dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
    areWidgetsLoading, setAreWidgetsLoading,
  });
};

export const useDashboardContext = () => {
  const {
    loading, setLoading,
    isSchoolAdmin, mySchoolData,
    isDistrictAdmin, myDistrictData,
    currentFilters, setCurrentFilters,
    analyticsFilters, setAnalyticsFilters,
    academicSessionLoading, academicSession,
    dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
    areWidgetsLoading, setAreWidgetsLoading,
  } = useContext(DashboardContext);

  const sessionStartDate = fromUTCSOD(academicSession?.session_start_date);
  const sessionEndDate = fromUTCSOD(academicSession?.session_end_date);

  const skipGraphQLRequest = () => (
    (!isSchoolAdmin && !isDistrictAdmin) ||
    (isSchoolAdmin && !mySchoolData?.id) ||
    (isDistrictAdmin && !myDistrictData?.id)
  );

  // Insufficient Data Code Block
  const checkDataMissingInAllWidgets = () => dashboardWidgetsDataStatus?.map(widgetData => widgetData.dataMissing).every(dataMissing => dataMissing);
  const checkIfAnyWidgetStillLoading = () => dashboardWidgetsDataStatus?.map(widgetData => widgetData.loading).some(flag => flag === true);

  const updateDashboardWidgetsDataStatus = ({ widget, loading: widgetLoading, dataMissing }: DashboardWidgetsDataStatusProps) => {
    setDashboardWidgetsDataStatus?.(dashboardWidgetsDataStatus?.map((dashboardWidget) => (
      dashboardWidget.widget !== widget ? dashboardWidget : { widget, loading: widgetLoading, dataMissing }
    )));
  };

  const resetDashboardWidgetsDataStatus = (widgetLoading: boolean, widgetDataMissing?: boolean) => {
    const newDashboardWidgetsDataStatus: DashboardWidgetsDataStatusProps[] = [];
    dashboardWidgetsDataStatus?.forEach(widgetDataStatus => {
      let newWidgetDataStatus: any = {};
      if (widgetLoading !== undefined) newWidgetDataStatus = { loading: widgetLoading, ...newWidgetDataStatus };
      if (widgetDataMissing !== undefined) newWidgetDataStatus = { dataMissing: widgetDataMissing, ...newWidgetDataStatus };
      newDashboardWidgetsDataStatus.push({
        ...widgetDataStatus,
        ...newWidgetDataStatus,
      });
    });
    setDashboardWidgetsDataStatus?.([...newDashboardWidgetsDataStatus ]);
  };
  // Insufficient Data Code Block

  // update isDataMissingInAllWidgets when there is change in the data-Status in each widget
  useEffect(() => {
    setIsDataMissingInAllWidgets?.(checkDataMissingInAllWidgets());
    setAreWidgetsLoading?.(checkIfAnyWidgetStillLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardWidgetsDataStatus]);

  return ({
    loading, setLoading,
    isSchoolAdmin, mySchoolData,
    isDistrictAdmin, myDistrictData,
    skipGraphQLRequest,
    currentFilters, setCurrentFilters,
    analyticsFilters, setAnalyticsFilters,
    academicSessionLoading, academicSession, sessionStartDate, sessionEndDate,
    dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
    updateDashboardWidgetsDataStatus, resetDashboardWidgetsDataStatus, checkDataMissingInAllWidgets,
    isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
    areWidgetsLoading, setAreWidgetsLoading,
  });
};