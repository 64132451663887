import React from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import StyledTableContainer from '../../Assignments/AssignmentsTable/List.styled';
import { PaginationProps } from '../../../variables/types';
import TablePagination from '../../../components/TablePagination';
import { PAGE_SIZE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import {
  ManageClassUserFragmentFragment,
} from '../../../generated/graphql';
import ListRow from './ListRow';
import NumberFormat from 'react-number-format';
import { StyledChipBox } from '../../../components/Chip/ChipsCount.styled';
import { StyledTableCell, tableWithNoDataStyles } from '../../../components/Common/Common.styled';
import { palette  } from '../../../theme/palette';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  students?: ManageClassUserFragmentFragment[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  title: string,
  onChange: (studentId: string) => void,
  isSelected: (studentId: string) => boolean,
  isSelectAll: boolean,
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void,
  disabled?: boolean,
}

const manageClassStyles = {
  tableContainerRoot: {
    '&.MuiTableContainer-root': {
      borderColor:palette.customBlue.primaryBlue,
    },
  },
  tableContainer: {
    height: 'calc(100vh - 350px)',
  },
  tableTitle: {
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: palette.fontColors.fontBlack,
  },
  whiteCheckbox: {
    color: palette.customWhite.main,
  },
};


const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  handlePageChange,
  students,
  title,
  onChange,
  isSelected,
  isSelectAll,
  handleSelectAll,
  disabled = false,
}: Props) => {

  return (
    <Box
      display="grid"
      width="100%"
      mt={3}
    >
      <Box
        display="flex"
        width="100%"
        mb={2}
        alignItems="center"
      >
        <Typography sx={{ ...manageClassStyles.tableTitle }} >
          {title}
        </Typography>
        <Box ml={2}>
          <StyledChipBox
            backgroundColor={palette.customBlue.chipDark}
            hascolor={palette.customWhite.main}
            label={(
              <NumberFormat
                displayType="text"
                value={totalCount}
                thousandSeparator
              />
            )}
          />
        </Box>
      </Box>
      <Box>
        <StyledTableContainer
          sx={{ ...manageClassStyles.tableContainerRoot, ...manageClassStyles.tableContainer }}
        >
          <Table
            stickyHeader
            aria-label="students table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: '25%' }}>
                  <Checkbox
                    checked={isSelectAll}
                    color="default"
                    onChange={handleSelectAll}
                    sx={{ ...manageClassStyles.whiteCheckbox }}
                    disabled={disabled}
                  />
                  Students
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && students?.map((student) => (
                <ListRow
                  key={student?.id}
                  student={student}
                  onChange={onChange}
                  isSelected={isSelected}
                  disabled={disabled}
                />
              ))}
              {isTableEmpty && !loading && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <NoResults
                      label="No Students"
                      description="There are no students in this section"
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      {totalCount > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
            disabled={disabled}
          />
        </Box>
      )}
    </Box>
  );
};
export default List;
