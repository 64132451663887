import { useTeachersFilterQuery } from '../../generated/graphql';

const teacherVariables = (stateId?: string, districtId?: string, schoolId?: string) => {
  let variables = {};
  if (stateId) {
    variables = { ...variables, stateID: stateId as string };
  }
  if (districtId) {
    variables = { ...variables, districtID: districtId as string };
  }
  if (schoolId) {
    variables = { ...variables, schoolID: schoolId as string };
  }
  return variables;
};

interface Props {
  stateId?: string
  districtId?: string
  schoolId?: string
}

const useTeachersFilter = ({ stateId, districtId, schoolId }: Props) => {
  const { data, loading } = useTeachersFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(teacherVariables(stateId, districtId, schoolId)),
    },
  });

  return {
    teachers: data?.teacherList?.nodes ?? [],
    loading,
  };
};

export default useTeachersFilter;
