import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCreateBenchmark } from '../create-benchmark-hook';
import FormProgressBar from '../../../../components/FormProgressBar';
import { CreateBenchmarkSteps } from '../../../../variables/constant';
import { StyledDangerButton, StyledNavigationButtonOne } from '../../../Assignments/Assignments.styled';
import { StepProp } from '../../../Assignments/CreateAssignment/create-assignment-hook';
import { SxProps } from '@mui/system';
import List from '../AssignedToList';
import { useUploader } from '../../../../utils/multipart-uploader';
import { getToken } from '../../../../utils/auth';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { openSnackbar } from '../../../../components/Notifier';
import { NotifierType } from '../../../../variables/types';
import { uploadPromptReponse } from '../../../Assignments/CreateAssignment/Steps/instructionsStep';
import Loader from '../../../../components/Loader/loader';
import { CreateAssignmentStylesCss } from '../../../Assignments/CreateAssignment/CreateAssignment.styled';
import { GradedByOptions } from './DetailsStep';
import useBenchmarkTypes from '../../../../components/BenchmarkTypes/benchmarkTypes-hook';


const SummaryStep = ({ title }: StepProp) => {
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);

  const { getBenchmarkTypeLabel } = useBenchmarkTypes();

  const {
    routeData,
    benchmarkInput,
    backToBenchmarks,
    handlePrev,
    speakingPromptFile,
    writingPromptFile,
    createBenchmark,
    staticResourceImgSelected,
    studentCountLoading,
  } = useCreateBenchmark();

  const {
    getValues,
  } = useFormContext();

  const { uploadAsync } = useUploader({
    // @ts-ignore: suppressing vscode meta.env tag lint error.
    uploadUrl: `${import.meta.env.REACT_APP_API_URL}/file/upload`,
    accessToken: getToken()?.idToken?.jwtToken,
  });

  const updatePromptRecording = async (file: File | null | undefined) => {
    const result: uploadPromptReponse = { response: { url: '', type: '' }, error: false, message: '' };
    try {
      if (file) {
        const resp = await uploadAsync(file);
        // eslint-disable-next-line no-param-reassign
        result.response = resp;
      }
    } catch (err) {
      result.error = true;
      result.message = getErrorMessage(err);
    }
    return result;
  };

  const submitBenchmark = async (data:any) => {
    const uploadErrors: string[] = [];
    let result: uploadPromptReponse;
    setIsFormSubmit(true);
    if (speakingPromptFile) {
      result = await updatePromptRecording(speakingPromptFile);
      if (!result.error) {
        // eslint-disable-next-line no-param-reassign
        data.speaking_prompt_url = result.response.url;
        // eslint-disable-next-line no-param-reassign
        data.speaking_prompt_file_type = result.response.type;
        // eslint-disable-next-line no-param-reassign
        data.speaking_prompt_file_name = result.response.url.split('/').pop();
      } else {
        uploadErrors.push(result.message);
      }
    }
    if (writingPromptFile) {
      result = await updatePromptRecording(writingPromptFile);
      if (!result.error) {
        // eslint-disable-next-line no-param-reassign
        data.writing_prompt_url = result.response.url;
        // eslint-disable-next-line no-param-reassign
        data.writing_prompt_file_type = result.response.type;
        // eslint-disable-next-line no-param-reassign
        data.writing_prompt_file_name = result.response.url.split('/').pop();
      } else {
        uploadErrors.push(result.message);
      }
    }
    if (uploadErrors.length === 0) {
      await createBenchmark?.({ ...benchmarkInput, ...data });
    } else {
      uploadErrors.forEach((error) => openSnackbar({ message: error }, NotifierType.Error));
    }
    setIsFormSubmit(false);
  };


  return (
    <>
      <Box sx={styles.formatTopNavBar}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant='secondaryTitle'>{title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormProgressBar
              steps={CreateBenchmarkSteps}
              progressValue={routeData?.currentStep! - 1}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Box mt={2}>
              <Typography variant='sFormTitle'>Title</Typography>
              <Typography variant='body1'>{benchmarkInput?.title}</Typography>
            </Box>
            {benchmarkInput?.is_common_prompt ? (
              <Box mt={2}>
                <Typography variant='sFormTitle'>Speaking And Writing Prompts</Typography>
                <Typography variant='body1'>{benchmarkInput?.speaking_prompt.length ? benchmarkInput?.speaking_prompt : '-' }</Typography>
              </Box>) : (<>
              <Box mt={2}>
                <Typography variant='sFormTitle'>Speaking Prompt</Typography>
                <Typography variant='body1'>{benchmarkInput?.speaking_prompt.length ? benchmarkInput?.speaking_prompt : '-' }</Typography>
              </Box>
              <Box mt={2}>
                <Typography variant='sFormTitle'>Writing Prompt</Typography>
                <Typography variant='body1'>{benchmarkInput?.writing_prompt.length ? benchmarkInput?.writing_prompt : '-' }</Typography>
              </Box>
            </>)}
            <Box mt={2}>
              <Typography variant='sFormTitle'>Benchmark Type</Typography>
              <Typography variant='body1'>{getBenchmarkTypeLabel(benchmarkInput?.benchmark_type ?? '')}</Typography>
            </Box>
            <Box mt={2}>
              <Typography variant='sFormTitle'>Date Range</Typography>
              <Typography variant='body1'>{benchmarkInput?.start_at.toDateString()} - {benchmarkInput?.close_at.toDateString()}</Typography>
            </Box>
            <Box mt={2}>
              <Typography variant='sFormTitle'>Allow Students to go back during benchmark?</Typography>
              <Typography variant='body1'>{benchmarkInput?.back_navigation_allowed ? 'Yes' : 'No'}</Typography>
            </Box>
            <Box mt={2}>
              <Typography variant='sFormTitle'>Graded By</Typography>
              <Typography variant='body1'>{ GradedByOptions.find(options => options.value === benchmarkInput?.graded_by)?.label }</Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box sx={styles.formImageContainer}>
              <CardMedia
                image={staticResourceImgSelected?.file?.url!}
                component="img"
                sx={{ borderRadius: 4 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt = {theme.spacing(4)}>
        <Typography variant='sFormTitle'>Assigned To</Typography>
        <List />
      </Box>
      <Box sx={styles.benchmarkFormBottomNavBar as SxProps}>
        <Box>
          <StyledNavigationButtonOne
            sx={styles.saveButton}
            variant="outlined"
            color="primary"
            onClick={() => { handlePrev(getValues()); }}
          >
            Back
          </StyledNavigationButtonOne>
        </Box>
        <Box>
          <StyledDangerButton
            variant="outlined"
            color="primary"
            onClick={() => { backToBenchmarks(); }}
            sx={{ mr: 2 }}
          >
            Cancel
          </StyledDangerButton>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={studentCountLoading}
            onClick={() => { submitBenchmark(benchmarkInput); }}
          >
            Create Benchmark
          </Button>
        </Box>
        <Loader open={isFormSubmit} />
      </Box>
    </>
  );
};

export default SummaryStep;
