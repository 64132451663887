import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const AverageIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="2"
    viewBox="0 0 16 2"
    fill="none"
  >
    <path d="M16 1H0" stroke={palette.customBlue.primaryBlue} strokeOpacity="0.5" strokeWidth="2" strokeDasharray="4 4"/>
  </SvgIcon>
);

export default AverageIcon;
