/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

export type FlyoverHeaderColor = "primary" | "success" | "warning" | "error";

interface Props {
  title: string;
  subtitle?: string;
  color?: FlyoverHeaderColor;
  onClose?: () => void;
}

const FlyoverHeader = ({ title, subtitle, onClose, color }: Props) => {
  const theme = useTheme();
  const themeColor = color ?? "primary";

  const getTextColor = (): string => {
    if (themeColor == "primary") {
      return theme.palette.common.white;
    }

    return theme.palette.customBlack.fontBlack!;
  };

  const getBackgroundColor = (): string => {
    switch (themeColor) {
      case "success":
        return theme.palette.customGreen.submitted!;
      case "warning":
        return theme.palette.customOrange.main!;
      case "error":
        return theme.palette.customRed.buttonFontRed!;
      default:
        return theme.palette.primary.main;
    }
  };

  return (
    <Box sx={{ width: "100%", backgroundColor: getBackgroundColor(), color: getTextColor() }}>
      <Container>
        <Grid container spacing={0} direction="row" alignItems="center" justifyContent="middle">
          <Grid item xs={11}>
            <Box sx={{ py: 2 }}>
              <Typography variant="sFormTitle" sx={{ color: getTextColor() }}>
                {title}
              </Typography>
              {subtitle && <Typography variant="body1">{subtitle}</Typography>}
            </Box>
          </Grid>
          {onClose && (
            <Grid item xs={1}>
              <IconButton aria-label="close" color="inherit" size="large" onClick={onClose}>
                <CloseIcon fontSize="inherit" style={{ color: "#fff" }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default FlyoverHeader;
