import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import zIndex from '../../theme/z-index';
import { palette  } from '../../theme/palette';

export const StyledImageOverlayBox = styled(Box)({
  position: 'absolute',
  width: '100%',
  borderRadius: '12px 12px 0 0',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledTaskButton = styled(Button)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, 0)',
  width: '200px',
});

export const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'block',
});

export const StyledFlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1.5),
  border: `2px solid ${theme.palette.customBackground.disabled}`,
  borderTopWidth: '1px',
  borderRadius: '0 0 12px 12px',
}));

export const StyledIconContainer = styled(Box)({
  borderRadius: '50%',
  height: '30px',
  width: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledSeeNext = styled(Button)(({ theme }) => ({
  fontWeight: 800,
  fontSize: 18,
  padding: theme.spacing(1.5, 5),
  height: 50,
  width: 180,
  margin: theme.spacing(5, 2.5),
  position: 'absolute',
  right: 35,
  bottom: 75,
  zIndex: zIndex.zIndex99,
}));

export const boxStyles = {
  goalBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '24px',
    borderRadius: '12px',
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customBackground.disabled}`,
    justifyContent: 'space-between',
  },
  goalContainer:{
    display:'flex',
    flexDirection:'column',
    width:'100%',
  },
  assignmentContainer:{
    display:'flex',
    width:'100%',
    height:'400px',
    flexDirection:'column',
  },
};

export const capIconStyles = {
  iconDimensions:{
    height: '30px',
    width: '30px',
    justifyItems: 'center',
    fill: palette.customBlue.primaryBlue,
  },
  iconWrapper: {
    background: palette.customBlue.primaryBlue,
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    position: 'absolute',
    top: '10px',
    left: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  iconPosition: {
    top: '10px',
    bottom: 'unset',
  },
};