/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from "react";

import Container from "@mui/material/Container";
import { styled } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
}

const StyledFlyoverContainer = styled(Container)(() => ({
  maxWidth: "100%",
  maxHeight: "100%",
  overflowY: "scroll",
  padding: "0 !important",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 5px rgb(255, 251, 251)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#999",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "rgb(255, 251, 251)",
  },
}));

const FlyoverContainer = ({ children }: Props) => {
  return <StyledFlyoverContainer>{children}</StyledFlyoverContainer>;
};

export default FlyoverContainer;
