import React from 'react';
import { CheckboxProps, InputAdornment, Typography, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import { DistrictFilterFragmentFragment, Maybe } from '../../generated/graphql';
import useDistrictsFilter from '../DistrictFilter/district-filter-hook';
import DistrictsIcon from '../Icons/DistrictsIcon';

interface Props {
  error?: boolean;
  source?: string[] | undefined,
  className?: string;
  value?: Maybe<string>[];
  onChange?: (districtList: Maybe<string>[] | undefined, data: DistrictFilterFragmentFragment) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  stateIds?: [string] | undefined;
  districtId?: string | undefined;
  disabled?: boolean;
  selectBoxIcon?: JSX.Element;
  limitTags?: number;
  textInputPlaceholder?: string;
  onSelectedDistrictChange?: (selectedDistricts: DistrictFilterFragmentFragment[]) => void;
  districtsToEnable?: string[];
  updateLoader?: Function;
}

const DistrictMultiSelectFilter = ({
  placeholder,
  onChange,
  value,
  stateIds,
  source,
  disabled,
  limitTags = 3,
  textInputPlaceholder,
  onSelectedDistrictChange,
  districtsToEnable,
  updateLoader,
}: Props) => {
  const {
    districts,
    loading,
  } = useDistrictsFilter({ stateIds, source });
  updateLoader?.(loading);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const onSelectChange = (_:any, districtList:DistrictFilterFragmentFragment[] | undefined = []) => {
    onChange?.(districtList?.map((district: DistrictFilterFragmentFragment) => district?.id),
      districtList[0]);
    onSelectedDistrictChange?.(districtList);
  };
  const districtOptions = districtsToEnable && districtsToEnable.length ? districts.filter(district => districtsToEnable?.includes(district?.id!)) : districts;

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={limitTags}
      options={districts ? districtOptions : []}
      getOptionLabel={(option: any) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={loading || disabled}
      onChange={onSelectChange}
      value={districts?.filter((district) => value?.includes(district?.id!))}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }} component='span'>{option?.name ?? ''}</Typography>}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option: any, { selected }) => (
        <Box component="li" {...props}>
          <Checkbox
            color={'primary' as  CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
          />
          {`${option?.name ?? ''}`}
        </Box>
      )}
      renderInput={(params) => (
        <>
          {placeholder && <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>}
          <TextField
            {...params}
            placeholder={params.InputProps.startAdornment ? undefined : (!textInputPlaceholder ? 'Select Districts' : textInputPlaceholder)}
            error={!!value}
            required={value?.length === 0}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <DistrictsIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default DistrictMultiSelectFilter;
