import { useParams } from 'react-router-dom';
import { useSchoolDetailsQuery, useMySchoolQuery, UserPermissions } from '../../../generated/graphql';
import { MY_SCHOOL } from '../../../variables/constant';
import usePermission from '../../../hooks/usePermission';
import useRole from '../../../hooks/useRole';

interface RouteProp {
  id: string
}

const useSchool = () => {
  const { id } = useParams<RouteProp>();
  const { data, loading } = useSchoolDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
    skip: id === MY_SCHOOL,
  });

  const { data: mySchoolData, loading: mySchoolLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: id !== MY_SCHOOL,
  });

  const { hasPermission: editSchoolPermission } = usePermission(UserPermissions.EditSchools);
  const { isSchoolAdmin, isDistrictAdmin } = useRole();

  return {
    loading: loading || mySchoolLoading,
    school: data?.schoolDetails || mySchoolData?.mySchool,
    editSchoolPermission,
    isSchoolAdmin,
    isDistrictAdmin,
  };
};

export default useSchool;
