import { Box } from '@mui/material';
import React from 'react';
import { PAGE_SIZE } from '../../variables/constant';

interface CountProps {
  pageNumber: number
  recordLength: number
  totalRecords: number
}

const RecordCount = ({ pageNumber, recordLength, totalRecords }: CountProps) => {
  const recordsCountFrom = totalRecords > 0 ? (pageNumber - 1) * PAGE_SIZE + 1 : 0;
  const recordsCountTo =  totalRecords > 0 ? recordsCountFrom + recordLength - 1 : 0;
  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
      fontSize="18px"
      fontWeight="600"
      lineHeight="25px"
      padding={1}
    >
      {`${recordsCountFrom} - ${recordsCountTo} of ${totalRecords}`}
    </Box>
  );
};

export default RecordCount;
