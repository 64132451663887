import React from 'react';
import { Box } from '@mui/material';
import { PredefinedGrades } from '../../../generated/graphql';
import ChipGrid from '../../../components/ChipGrid';

const styles = {
  grades: {
    flexGrow: 1,
  },
};

interface Props {
  onChange: (selectedItemsKey: string[] | string | null) => void,
  selected?: string,
  preDefinedGrades?: PredefinedGrades[] | null | any,
  isLoading?: boolean,
  disabled?: boolean,
}

const GradesGrid = ({ onChange, selected, preDefinedGrades, isLoading, disabled = false }: Props) => {

  const gradeRows = (
    <Box sx={{ ...styles.grades }}>
      <ChipGrid
        onChange={onChange}
        grades={preDefinedGrades}
        selected={selected ? [selected] : []}
        isLoading={isLoading}
        disabled={disabled}
      />
    </Box>
  );

  return (gradeRows);
};

export default React.memo(GradesGrid);
