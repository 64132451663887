import React from 'react';
import { Box, Button, Checkbox, Divider, Grid, MenuItem, styled, TextField, Typography } from '@mui/material';
import { StudentsIcon } from '../../../components/Icons';
import { palette } from '../../../theme/palette';
import useStudentForm from './student-form-hook';
import { Controller } from 'react-hook-form';
import { errorMessages, labels } from './constants';
import { SectionCategory } from '../../../generated/graphql';
import { formStyles, studentFormStyles, StyledFlexBox, StyledSectionsSelectMultiple, StyledSelectStudentGrades } from './form-styled';
import { NON_SPACE_REGEX, PROVIDER_TYPE } from '../../../variables/constant';
import { StyledMenuItem } from '../../AdminStudents/StudentForm/student-form-styled';
import { HERITAGE_LANGUAGE_OTHER_VALUE } from '../../Teachers/TeacherForm/constants';
import { StyledSaveButton } from '../../Districts/CreateDistrict/create-district.styled';
import SchoolFilterTeacher from '../../../components/SchoolFilterTeacher';
import Loader from '../../../components/Loader/loader';
import { CreateAssignmentStylesCss } from '../../Assignments/CreateAssignment/CreateAssignment.styled';
import { useTheme } from '@mui/material/styles';

const StyledSchoolFilter = styled(SchoolFilterTeacher)(({
  width: '300px',
}));

interface Props {
  closeRemoveStudentDialog: (type?: string | undefined, isForm?: boolean) => void;
  closeDialog: (type?: string | undefined) => void;
  refetch: () => void;
}

const StudentForm = ({ closeRemoveStudentDialog, closeDialog, refetch }: Props) => {
  const { 
    handleSubmit,
    getValues,
    createStudent,
    register,
    handleChange,
    checked,
    control,
    selectedSchoolId,
    selectedClassIds,
    preDefinedGrades,
    loading,
    errors,
    editPassword,
    editPasswordToggle,
    heritageLanguageOptions,
    heritageLanguage,
    onSchoolChange,
    source,
    initialSections,
    setScrollNode,
    teacherDetails,
    teacherPrimaryInSections,
    isPrimaryInSelectedSections,
  } = useStudentForm({ refetch, closeDialog });
  const studentID = getValues('id');
  const formTitle = studentID ? 'Edit student info' : 'Add a new student';
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);
  const checkReadOnly = () => {
    if (studentID && source !== PROVIDER_TYPE.FLASHLIGHT) {
      return studentID !== undefined && source !== PROVIDER_TYPE.FLASHLIGHT;
    } else if (studentID && source === PROVIDER_TYPE.FLASHLIGHT) {
      return !((studentID !== undefined) && (source === PROVIDER_TYPE.FLASHLIGHT));
    } else if (!studentID) {
      return studentID !== undefined;
    }
    return false;
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
        ref={(node: HTMLDivElement | undefined)=>  {setScrollNode(node);}}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems='baseline'
          >
            <StudentsIcon
              pathFill='primary'
              sx={{
                height: '30px',
                width: '30px',
                mr: '16px',
              }}
            />
            <Typography variant="modalTitle" sx={{ mb: 4 }} >{formTitle}</Typography>
          </Box>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(createStudent)}
            noValidate
          >
            <Grid
              xs={8}
              lg={5}
            >
              {!studentID && <Box>
                <Controller
                  name="school_id"
                  render={({ onChange, value }) => (
                    <StyledSchoolFilter
                      value={value}
                      onChange={(school, district_id) => {
                        onSchoolChange(district_id);
                        onChange(school);
                      }}
                      placeholder="Select a school"
                      idSelectOnly
                      readOnly={Boolean(studentID)}
                      sx={{ ...(Boolean(studentID) && styles.disabled) }}
                    />
                  )}
                  rules={{ required: errorMessages.schoolHelperText }}
                  control={control}
                  defaultValue={null}
                />
                <Typography variant='sFormHelper' component='p'>
                  {errors.school_id && errors.school_id.message}
                </Typography>
              </Box>}
              <Box sx={{ mt: !studentID ? 3 : 0 }} >
                <Controller
                  name="section_ids"
                  render={({ onChange }) => (
                    <StyledSectionsSelectMultiple
                      value={selectedClassIds}
                      onChange={onChange}
                      schoolId={selectedSchoolId}
                      classTypes={[SectionCategory.Class, SectionCategory.CustomClass]}
                      teacherId={teacherDetails?.teacherByUser.id}
                    />
                  )}
                  rules={
                    {
                      required: errorMessages.classesHelperText,
                      validate: (value) => value?.length > 0 || errorMessages.classesHelperText,
                    }
                  }
                  control={control}
                  defaultValue={null}
                />
                <Typography variant='sFormHelper' component='p'>
                  {errors.section_ids && errorMessages.classesHelperText}
                </Typography>
              </Box>
              <Box>
                <Typography variant='sFormTitle' component='p'>Grade</Typography>
                <Controller
                  name="predefined_grade_id"
                  render={(
                    { onChange, value },
                  ) => (
                    <StyledSelectStudentGrades
                      onChange={onChange}
                      value={value}
                      preDefinedGrades={preDefinedGrades}
                      isLoading={loading}
                      placeholder={'Select grade'}
                      idSelectOnly
                      readOnly={Boolean(checkReadOnly())}
                      sx={{ ...(Boolean(checkReadOnly()) && styles.disabled) }}
                      noOptionPlaceholder={selectedSchoolId ? errorMessages.gradeSchoolHelperText : errorMessages.gradeMissingSchoolHelperText}
                    />
                  )}
                  rules={{ required: errorMessages.gradeHelperText }}
                  control={control}
                  defaultValue={null}
                />
                <Typography variant='sFormHelper' component='p'>
                  {errors.predefined_grade_id && errorMessages.gradeHelperText}
                </Typography>
              </Box>
              <Box >
                <Typography variant='sFormTitle' component='p'> First Name </Typography>
                <TextField
                  id="first-name"
                  aria-describedby="first-name"
                  name="first_name"
                  sx={{ ...formStyles.titleInput, width: '300px', ...(Boolean(checkReadOnly()) && styles.disabled)  }}
                  error={!!errors.first_name}
                  helperText={errors.first_name && errorMessages.firstNameHelperText}
                  InputProps={{ readOnly: Boolean(checkReadOnly()) }}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
              <Box>
                <Typography variant='sFormTitle' component='p'> Last Name </Typography>
                <TextField
                  id="last-name"
                  aria-describedby="last-name"
                  name="last_name"
                  sx={{ ...formStyles.titleInput, width: '300px', ...(Boolean(checkReadOnly()) && styles.disabled)  }}
                  error={!!errors.last_name}
                  helperText={errors.last_name && errorMessages.lastNameHelperText}
                  InputProps={{ readOnly: Boolean(checkReadOnly()) }}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
              <Box>
                <Typography variant='sFormTitle' component='p'>Student ID</Typography>
                <TextField
                  id="student-id"
                  aria-describedby="student-id"
                  name="student_id"
                  sx={{ ...formStyles.textFieldAdmin, ...(Boolean(studentID) && styles.disabled)  }}
                  error={!!errors.student_id}
                  helperText={errors.student_id && errorMessages.studentIDHelperText}
                  InputProps={{ readOnly: Boolean(studentID) }}
                  inputRef={register({
                    required: true,
                    pattern: NON_SPACE_REGEX,
                    validate: (value) => !!value.trim() && value.trim().length >= 4,
                  })}
                />
              </Box>
              {
                !studentID && (
                  <StyledFlexBox sx={{ ...(errors.student_id && studentFormStyles.customMargin30), mt: 0 }}>
                    <Typography variant='sFormTitle' component='p'>Password</Typography>
                    <TextField
                      id="password"
                      aria-describedby="password"
                      name="password"
                      sx={{ ...formStyles.textFieldAdmin  }}
                      error={!!errors.password}
                      helperText={errors.password
                    && errorMessages.passwordHelperText}
                      inputRef={register({
                        required: true,
                        validate: (value) => !!value.trim()
                        && value.trim().length >= 6,
                      })}
                    />
                  </StyledFlexBox>
                )
              }
              <Box>
                <Typography variant='sFormTitle' component='p'>Email (Optional)</Typography>
                <TextField
                  id="email"
                  aria-describedby="email"
                  name="email"
                  sx={{ ...formStyles.textFieldAdmin, ...(Boolean(checkReadOnly()) && styles.disabled)  }}
                  InputProps={{ readOnly: Boolean(checkReadOnly()) }}
                  inputRef={register({
                    required: false,
                  })}
                />
              </Box>
              <StyledFlexBox sx={{ ...(errors.student_id && studentFormStyles.customMargin30), mt: 0 }}>
                <Typography variant='sFormTitle' component='p'>Language Level</Typography>
                <TextField
                  id="language-level"
                  aria-describedby="language-level"
                  name="language_level"
                  sx={{ ...formStyles.textFieldAdmin, ...(Boolean(checkReadOnly()) && styles.disabled)  }}
                  InputProps={{ readOnly: Boolean(checkReadOnly()) }}
                  inputRef={register({
                    required: false,
                  })}
                />
              </StyledFlexBox>
              <Box mt={2}>
                <Typography variant='sFormTitle' component='p'>
              Heritage Language (Optional)
                </Typography>
                <Controller
                  name="heritage_language"
                  render={(
                    { onChange, value },
                  ) => (
                    <TextField
                      id="heritage-language"
                      select
                      aria-describedby="heritage-language"
                      name="heritage_language"
                      value={value || 'select'}
                      InputProps={{ readOnly: Boolean(checkReadOnly()) }}
                      onChange={(event) => {
                        onChange(event?.target?.value);
                      }}
                      sx={{
                        width: '300px',
                        '& .MuiInputBase-input': {
                          background: 'none',
                        },
                        '& .MuiSelect-icon': {
                          height: '1em',
                          width: '1em',
                          top: 'auto',
                        },
                        ...(Boolean(checkReadOnly()) && styles.disabled),
                      }}
                      defaultValue="select"
                    >
                      <MenuItem key="select" value="select" disabled>
                    Select a Language
                      </MenuItem>
                      {
                        heritageLanguageOptions.map((item) => (
                          [
                            <StyledMenuItem
                              key={item.key}
                              value={item.value}
                            >
                              {item.value}
                            </StyledMenuItem>,
                            item.divider ?  <Divider
                              variant="middle"
                              sx={{ background: palette.customGrey.main }}
                            /> : null,
                          ]
                        ),
                        )
                      }
                    </TextField>
                  )}
                  rules={{ required: false }}
                  control={control}
                  defaultValue=""
                />
              </Box>
              {
                heritageLanguage === HERITAGE_LANGUAGE_OTHER_VALUE && (
                  <Box mt={2}>
                    <Typography variant='sFormTitle' component='p'>
                    Other Language
                    </Typography>
                    <Controller
                      name="heritage_other_language"
                      render={(
                        { onChange, value },
                      ) => (
                        <TextField
                          id="heritage-other-language"
                          aria-describedby="other language"
                          name="heritage_other_language"
                          sx={{ ...formStyles.textFieldAdmin, ...(Boolean(checkReadOnly()) && styles.disabled)  }}
                          error={!!errors.heritage_other_language}
                          helperText={errors.heritage_other_language
                        && errorMessages.otherHeritageLanguageHelperText}
                          InputProps={{ readOnly: Boolean(checkReadOnly()) }}
                          inputRef={register({
                            required: true,
                            validate: (val) => !!val.trim(),
                          })}
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                        />
                      )}
                      control={control}
                    />
                  </Box>
                )
              }
              <StyledFlexBox sx={{ ...(errors.student_id && studentFormStyles.customMargin30), mt: 0 }}>
                <Typography variant='sFormTitle' component='p'>Demographic Information</Typography>
                <TextField
                  id="demographic-info"
                  aria-describedby="demographic-info"
                  name="demographic_info"
                  sx={{ ...formStyles.textFieldAdmin, ...(Boolean(checkReadOnly()) && styles.disabled)  }}
                  InputProps={{ readOnly: Boolean(checkReadOnly()) }}
                  inputRef={register({
                    required: false,
                  })}
                />
              </StyledFlexBox>
              {
                studentID && editPassword && (
                  <Box>
                    <Typography variant='sFormTitle' component='p'>Edit Password</Typography>
                    <Box display="flex">
                      <Box sx={{ marginRight: '24px' }}>
                        <TextField
                          id="password"
                          aria-describedby="password"
                          name="password"
                          sx={{ ...formStyles.textFieldAdmin }}
                          error={!!errors.password}
                          helperText={errors.password
                          && errorMessages.passwordHelperText}
                          inputRef={register({
                            required: true,
                            validate: (value) => !!value.trim()
                            && value.trim().length >= 6,
                          })}
                        />
                      </Box>
                      <Box>
                        <StyledSaveButton
                          sx={{ margin: 0 }}
                          variant="contained"
                          color="primary"
                          type="button"
                          onClick={editPasswordToggle}
                        >
                        Cancel
                        </StyledSaveButton>
                      </Box>
                    </Box>
                  </Box>
                )
              }
              {
                !studentID && (
                  <Box display="flex" justifyContent="flex-start" sx={{ mt: 4, ml: -1.5 }}>
                    <Checkbox
                      id="reopen-create-class-form"
                      aria-describedby="reopen-create-class-form"
                      name="reopen_create_checkbox"
                      checked={checked}
                      onChange={handleChange}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      inputRef={register({
                        required: false,
                      })}
                    />
                    <Typography variant='sLabel' sx={{ pt: 1 }}>
                      {labels.checkBoxLabel}
                    </Typography>
                  </Box>
                )
              }
              <Box
                display="grid"
                justifyContent="flex-start"
              >
                {source !== PROVIDER_TYPE.CLEVER && studentID && !editPassword && (
                  <Button
                    sx={{ ...studentFormStyles.saveButton }}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={editPasswordToggle}
                  >
                  Edit Password
                  </Button>
                )}
                <Box sx={{  display: 'inline-flex' }}>
                  {studentID && <StyledSaveButton
                    sx={{ ...studentFormStyles.removeButton, ...{ width: '250px' } }}
                    variant="contained"
                    color="error"
                    disabled={Boolean(studentID) && (!teacherPrimaryInSections || !initialSections?.length)}
                    onClick={() => closeRemoveStudentDialog('remove', true)}
                  >
                    Remove Student
                  </StyledSaveButton>}
                  <StyledSaveButton
                    sx={{ ...studentFormStyles.saveButton }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={Boolean(studentID) && !isPrimaryInSelectedSections}
                  >
                    { studentID ? 'Update' : 'Add Student' }
                  </StyledSaveButton>
                </Box>
              </Box>
            </Grid>
          </form>
        </Box>
      </Box>
      <Loader open={loading} />
    </>
  );
};

export default React.memo(StudentForm);