import React, { useMemo, useState } from 'react';
import VocabularyAndGrammarIcon from '../../../components/Icons/VocabularyAndGrammarIcon';
import DescriptionAndExplanation from '../../../components/Icons/DescriptionAndExplanation';
import PointsAndReason from '../../../components/Icons/PointsAndReason';
import { Skills, SpeakingSkills, WritingSkills } from '../../../variables/types';
import InterpretationIcon from '../../../components/Icons/InterpretationIcon';
import VocabularyIcon from '../../../components/Icons/VocabularyIcon';
import GrammarIcon from '../../../components/Icons/GrammarIcon';
import FluencyIcon from '../../../components/Icons/FluencyIcon';
import PronunciationIcon from '../../../components/Icons/PronunciationIcon';
import {
  ANCHOR_GRADE_1_2,
  ANCHOR_GRADE_3_5,
  ANCHOR_GRADE_6_8,
  ANCHOR_GRADE_9_12,
  ANCHOR_GRADE_PK_K, DEFAULT_SCHEME, SCHEME_1,
} from '../../../variables/constant';
import { useAuthProvider } from '../../../core/authContext';
import { palette  } from '../../../theme/palette';

interface Props {
  section: string;
}

export interface SkillLevel {
  description?: string,
  url?: string,
  fileType?: string,
  scoreType: string,
  disable?: boolean,
  scoreValue?: number,
}

export interface Datum {
  name: string,
  Icon: React.ComponentType<any>,
  subText?: string
  scoreTypes?: SkillLevel[]
}

export interface Skill {
  pageCaption: string,
  pageSubCaption: string,
  data?: Datum[],
  ANCHOR_GRADE_PK_K?: SkillLevel[],
  ANCHOR_GRADE_1_2?: SkillLevel[],
  ANCHOR_GRADE_3_5?: SkillLevel[],
  ANCHOR_GRADE_6_8?: SkillLevel[],
  ANCHOR_GRADE_9_12?: SkillLevel[],
}
export interface AnchorDataByCategory {
  interpretation?: SkillLevel[],
  vocabulary?: SkillLevel[],
  grammar?: SkillLevel[],
  fluency?: SkillLevel[],
  pronunciation?: SkillLevel[],
  vocabularyAndGrammar?: SkillLevel[],
  descriptionAndExplanation?: SkillLevel[],
  pointsOfReason?: SkillLevel[],
}

export interface AnchorTableData {
  ANCHOR_GRADE_PK_K: AnchorDataByCategory,
  ANCHOR_GRADE_1_2: AnchorDataByCategory,
  ANCHOR_GRADE_3_5: AnchorDataByCategory,
  ANCHOR_GRADE_6_8: AnchorDataByCategory,
  ANCHOR_GRADE_9_12?: AnchorDataByCategory,
}

const bucketAudiosBaseURL = `https://${
  import.meta.env.REACT_APP_DOCUMENT_BUCKET_NAME
}`
  + '.s3-accelerate'
  + '.amazonaws.com/static_resource/audios/speaking_anchor_audios';

const SpeakingTableData: AnchorTableData = {
  ANCHOR_GRADE_PK_K: {
    interpretation: [
      {
        url: `${bucketAudiosBaseURL}/PK_K/(1)-Interpretation-Vocabulary-Grammar.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(2)-Interpretation-Vocabulary-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(3)-Interpretation-Vocabulary-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    vocabulary: [
      {
        url: `${bucketAudiosBaseURL}/PK_K/(1)-Interpretation-Vocabulary-Grammar.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(2)-Interpretation-Vocabulary-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(3)-Interpretation-Vocabulary-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    grammar: [
      {
        url: `${bucketAudiosBaseURL}/PK_K/(1)-Interpretation-Vocabulary-Grammar.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(2)-Interpretation-Vocabulary-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(3)-Interpretation-Vocabulary-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    fluency: [
      {
        url: `${bucketAudiosBaseURL}/PK_K/Level-1-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(2)-Interpretation-Vocabulary-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(3)-Interpretation-Vocabulary-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pronunciation: [
      {
        url: `${bucketAudiosBaseURL}/PK_K/Level-1-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(2)-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/Level-3-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/PK_K/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
  ANCHOR_GRADE_1_2: {
    interpretation: [
      {
        url: `${bucketAudiosBaseURL}/1_2/(1)-All-indicators_01.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(2)-Interpretation-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/3)-Interpretation-vocabulary-.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    vocabulary: [
      {
        url: `${bucketAudiosBaseURL}/1_2/(1)-All-indicators_01.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(2)-Vocabulary-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/3)-Interpretation-vocabulary-.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    grammar: [
      {
        url: `${bucketAudiosBaseURL}/1_2/(1)-All-indicators_01.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(2)-Interpretation-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/1-2-Level-3-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    fluency: [
      {
        url: `${bucketAudiosBaseURL}/1_2/(1)-All-indicators_01.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(2)-Interpretation-Grammar-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/1-2-Level-3-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pronunciation: [
      {
        url: `${bucketAudiosBaseURL}/1_2/(1)-All-indicators_01.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(2)-Vocabulary-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/1-2-Level-3-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/1_2/(5)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
  ANCHOR_GRADE_3_5: {
    interpretation: [
      {
        url: `${bucketAudiosBaseURL}/3_5/3-5-Level.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(2)-Interpretation-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/3)-Interpretation-Fluency-Vocabulary.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(5)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    vocabulary: [
      {
        url: `${bucketAudiosBaseURL}/3_5/3-5-Level.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(2)-Vocabulary-Grammar-Pronunciation..mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/3)-Interpretation-Fluency-Vocabulary.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(5)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    grammar: [
      {
        url: `${bucketAudiosBaseURL}/3_5/3-5-Level.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(2)-Vocabulary-Grammar-Pronunciation..mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/Level-3-Grammar.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(5)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    fluency: [
      {
        url: `${bucketAudiosBaseURL}/3_5/3-5-Level.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(2)-Interpretation-Fluency.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/3)-Interpretation-Fluency-Vocabulary.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(5)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pronunciation: [
      {
        url: `${bucketAudiosBaseURL}/3_5/3-5-Level.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(2)-Vocabulary-Grammar-Pronunciation..mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/Level-3-Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/3_5/(5)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
  ANCHOR_GRADE_6_8: {
    interpretation: [
      {
        url: `${bucketAudiosBaseURL}/6_8/6-8-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(2)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(3)-Interpretation_Vocabulary.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    vocabulary: [
      {
        url: `${bucketAudiosBaseURL}/6_8/6-8-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(2)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(3)-Interpretation_Vocabulary.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    grammar: [
      {
        url: `${bucketAudiosBaseURL}/6_8/6-8-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(2)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(3)-Grammar_Fluency_Pronunciation_ReMastered.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    fluency: [
      {
        url: `${bucketAudiosBaseURL}/6_8/6-8-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(2)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(3)-Grammar_Fluency_Pronunciation_ReMastered.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pronunciation: [
      {
        url: `${bucketAudiosBaseURL}/6_8/6-8-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(2)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(3)-Grammar_Fluency_Pronunciation_ReMastered.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/6_8/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
  ANCHOR_GRADE_9_12: {
    interpretation: [
      {
        url: `${bucketAudiosBaseURL}/9_12/9-12-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(2)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(3)-Interpreatation_Vocabulary.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    vocabulary: [
      {
        url: `${bucketAudiosBaseURL}/9_12/9-12-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(2)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(3)-Interpreatation_Vocabulary.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    grammar: [
      {
        url: `${bucketAudiosBaseURL}/9_12/9-12-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(2)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(3)-Grammar_Fluency_Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    fluency: [
      {
        url: `${bucketAudiosBaseURL}/9_12/9-12-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(2)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(3)-Grammar_Fluency_Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    pronunciation: [
      {
        url: `${bucketAudiosBaseURL}/9_12/9-12-Level-1.mp3`,
        fileType: 'audio/wav',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(2)-All+indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(3)-Grammar_Fluency_Pronunciation.mp3`,
        fileType: 'audio/wav',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(4)-All-indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        url: `${bucketAudiosBaseURL}/9_12/(5)-All-Indicators.mp3`,
        fileType: 'audio/wav',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
};

const AnchorList: Record<'speaking' | 'writing', Skill> = {
  speaking: {
    pageCaption: 'Speaking Anchors',
    pageSubCaption: 'Samples of students’ responses at each level',
    data: [
      {
        name: 'Interpretation',
        Icon: InterpretationIcon,
        subText: 'How well did the student comprehend, interpret, and analyze the narrative or content-related image to describe, explain, or justify claims/opinions with reasons?',
      },
      {
        name: 'Vocabulary',
        Icon: VocabularyIcon,
        subText: 'How well did the student use precise vocabulary to '
          + 'describe the scene, including academic vocabulary and '
          + 'content-specific vocabulary?',
      },
      {
        name: 'Grammar',
        Icon: GrammarIcon,
        subText: 'How well did the student produce grammatically correct '
          + 'speech, including use of word order to talk about the '
          + 'picture in a cohesive and connected way?',
      },
      {
        name: 'Fluency',
        Icon: FluencyIcon,
        subText: 'How well did the student produce speech that is smooth '
          + 'and sustained?',
      },
      {
        name: 'Pronunciation',
        Icon: PronunciationIcon,
        subText: 'How well did the student produce speech with accurate '
          + 'pronunciation and intonation that does not impede '
          + 'meaning?',
      },
    ],
  },
  writing: {
    pageCaption: 'Writing Anchors',
    pageSubCaption: 'Here are examples of student writing at each level.',
    data: [
      {
        name: 'Vocabulary & Grammar',
        Icon: VocabularyAndGrammarIcon,
        subText: 'How well did the student use '
          + 'vocabulary, grammar, and mechanics in their writing?',
      },
      {
        Icon: DescriptionAndExplanation,
        name: 'Description/Explanation',
        subText: 'How well did the student describe a picture with details '
          + 'and connect ideas in a meaningful and cohesive way?',
      },
      {
        Icon: PointsAndReason,
        name: 'Points & Reasons',
        subText: 'How well did the student support a claim '
          + 'or opinion with evidence and/or reasons?',
      },
    ],
    ANCHOR_GRADE_PK_K: [
      {
        description: 'No written response or response in heritage language or letter written does not represent the first sound of the specified word.',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'Student writes an initial letter in an initial position for a word (“b” for book)',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'Student writes two letters of a word including an initial letter in an initial position for a word and another letter in any position in the word (“grl” for girl)',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'Student writes entire words appropriate to image phonetically (“boy”)',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'Student writes whole words and/or attempts to construct phrases or short sentences (“a girl”; “the book”)',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    ANCHOR_GRADE_1_2: [
      {
        description: 'I see a school. I see un policia asiendo que el bus se pare. ',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'I se i schol the studens or walking to the schol a men using a sain that seds stop and a rout.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'I can tell this is a school because there are kids , a dad waking her kid and a man stooping a bus.\n \nOne day the peoples are taking the children to school. Then the man is stopping the school bus with a sign of stop. Finally the school has a beautiful flag, grass, doors, children, and a clock.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'I see that it is a nice morning people are walking to school. There is a trafic helper helping people get across. well it looks like its time for school. and I see a flag that school is good.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I believe this is outside of a school because I see a big red school bilding and a man walking a kid to school. In addicion, there is a group of kids walking to school. Finally there is a man telling a bus to stop driving because there are people crossing the road.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    ANCHOR_GRADE_3_5: [
      {
        description: 'wi going to do art and also wi have art pictures up in the wall.',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'In this story is a art clas and what the techer is doing is pating and the gril and the techer and 2 students pating.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'The teacher is passing out crayons to the students. And the students are making a project after they are done making the project.They paint it or draw on it. There is a clock on the wall and drawings.',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'There were two teachers who liked art so they started a art club and a lot of students joined that day. They did a lot of activities but one person didnt like the art club because he thought it was a waist of time. When he started he didnt like it but when he went everyday he liked it more and more.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'I can tell that this is a picture of an art classroom because students are working on art projects. The teacher is picking up unwanted markers, while the two boys at the table paint foam cubes with the colors red and green. The boy in the orange shirt looks focused. Another student is not painting, but drawing with markers. I think she is drawing the outside, because I can slightly see a sun and grass. I see a student in the back, who is painting a striped sphere with the colors orange and yellow. In conclusion, this image shows students actively working on art projects.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    ANCHOR_GRADE_6_8: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
    ANCHOR_GRADE_9_12: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
        scoreValue: 1,
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
        scoreValue: 2,
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
        scoreValue: 3,
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
        scoreValue: 4,
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
        scoreValue: 5,
      },
    ],
  },
};

const WritingTableData: AnchorTableData = {
  ANCHOR_GRADE_PK_K: {
    vocabularyAndGrammar: [
      {
        description: 'No written response or response in heritage language or letter written does not represent the first sound of the specified word.',
        scoreType: 'limited',
      },
      {
        description: 'Student writes an initial letter in an initial position for a word (“b” for book)',
        scoreType: 'emerging',
      },
      {
        description: 'Student writes two letters of a word including an initial letter in an initial position for a word and another letter in any position in the word (“grl” for girl)',
        scoreType: 'expanding',
      },
      {
        description: 'Student writes entire words appropriate to image phonetically (“boy”)',
        scoreType: 'bridging',
      },
      {
        description: 'Student writes whole words and/or attempts to construct phrases or short sentences (“a girl”; “the book”)',
        scoreType: 'advanced',
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'No written response or response in heritage language or letter written does not represent the first sound of the specified word.',
        scoreType: 'limited',
      },
      {
        description: 'Student writes an initial letter in an initial position for a word (“b” for book)',
        scoreType: 'emerging',
      },
      {
        description: 'Student writes two letters of a word including an initial letter in an initial position for a word and another letter in any position in the word (“grl” for girl)',
        scoreType: 'expanding',
      },
      {
        description: 'Student writes entire words appropriate to image phonetically (“boy”)',
        scoreType: 'bridging',
      },
      {
        description: 'Student writes whole words and/or attempts to construct phrases or short sentences (“a girl”; “the book”)',
        scoreType: 'advanced',
      },
    ],
    pointsOfReason: [
      {
        description: 'No written response or response in heritage language or letter written does not represent the first sound of the specified word.',
        scoreType: 'limited',
      },
      {
        description: 'Student writes an initial letter in an initial position for a word (“b” for book)',
        scoreType: 'emerging',
      },
      {
        description: 'Student writes two letters of a word including an initial letter in an initial position for a word and another letter in any position in the word (“grl” for girl)',
        scoreType: 'expanding',
      },
      {
        description: 'Student writes entire words appropriate to image phonetically (“boy”)',
        scoreType: 'bridging',
      },
      {
        description: 'Student writes whole words and/or attempts to construct phrases or short sentences (“a girl”; “the book”)',
        scoreType: 'advanced',
      },
    ],
  },
  ANCHOR_GRADE_1_2: {
    vocabularyAndGrammar: [
      {
        description: 'I see a school. I see un policia asiendo que el bus se pare. ',
        scoreType: 'limited',
      },
      {
        description: 'I se i schol the studens or walking to the schol a men using a sain that seds stop and a rout.',
        scoreType: 'emerging',
      },
      {
        description: 'I can tell this is a school because there are kids , a dad waking her kid and a man stooping a bus.\n \nOne day the peoples are taking the children to school. Then the man is stopping the school bus with a sign of stop. Finally the school has a beautiful flag, grass, doors, children, and a clock.',
        scoreType: 'expanding',
      },
      {
        description: 'I see that it is a nice morning people are walking to school. There is a trafic helper helping people get across. well it looks like its time for school. and I see a flag that school is good.',
        scoreType: 'bridging',
      },
      {
        description: 'I believe this is outside of a school because I see a big red school bilding and a man walking a kid to school. In addicion, there is a group of kids walking to school. Finally there is a man telling a bus to stop driving because there are people crossing the road.',
        scoreType: 'advanced',
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'I see a school. I see un policia asiendo que el bus se pare. ',
        scoreType: 'limited',
      },
      {
        description: 'I se i schol the studens or walking to the schol a men using a sain that seds stop and a rout.',
        scoreType: 'emerging',
      },
      {
        description: 'I can tell this is a school because there are kids , a dad waking her kid and a man stooping a bus.\n \nOne day the peoples are taking the children to school. Then the man is stopping the school bus with a sign of stop. Finally the school has a beautiful flag, grass, doors, children, and a clock.',
        scoreType: 'expanding',
      },
      {
        description: 'I see that it is a nice morning people are walking to school. There is a trafic helper helping people get across. well it looks like its time for school. and I see a flag that school is good.',
        scoreType: 'bridging',
      },
      {
        description: 'I believe this is outside of a school because I see a big red school bilding and a man walking a kid to school. In addicion, there is a group of kids walking to school. Finally there is a man telling a bus to stop driving because there are people crossing the road.',
        scoreType: 'advanced',
      },
    ],
    pointsOfReason: [
      {
        description: 'I see a school. I see un policia asiendo que el bus se pare. ',
        scoreType: 'limited',
      },
      {
        description: 'I se i schol the studens or walking to the schol a men using a sain that seds stop and a rout.',
        scoreType: 'emerging',
      },
      {
        description: 'I can tell this is a school because there are kids , a dad waking her kid and a man stooping a bus.\n \nOne day the peoples are taking the children to school. Then the man is stopping the school bus with a sign of stop. Finally the school has a beautiful flag, grass, doors, children, and a clock.',
        scoreType: 'expanding',
      },
      {
        description: 'I see that it is a nice morning people are walking to school. There is a trafic helper helping people get across. well it looks like its time for school. and I see a flag that school is good.',
        scoreType: 'bridging',
      },
      {
        description: 'I believe this is outside of a school because I see a big red school bilding and a man walking a kid to school. In addicion, there is a group of kids walking to school. Finally there is a man telling a bus to stop driving because there are people crossing the road.',
        scoreType: 'advanced',
      },
    ],
  },
  ANCHOR_GRADE_3_5: {
    vocabularyAndGrammar: [
      {
        description: 'wi going to do art and also wi have art pictures up in the wall.',
        scoreType: 'limited',
      },
      {
        description: 'In this story is a art clas and what the techer is doing is pating and the gril and the techer and 2 students pating.',
        scoreType: 'emerging',
      },
      {
        description: 'The teacher is passing out crayons to the students. And the students are making a project after they are done making the project.They paint it or draw on it. There is a clock on the wall and drawings.',
        scoreType: 'expanding',
      },
      {
        description: 'There were two teachers who liked art so they started a art club and a lot of students joined that day. They did a lot of activities but one person didnt like the art club because he thought it was a waist of time. When he started he didnt like it but when he went everyday he liked it more and more.',
        scoreType: 'bridging',
      },
      {
        description: 'I can tell that this is a picture of an art classroom because students are working on art projects. The teacher is picking up unwanted markers, while the two boys at the table paint foam cubes with the colors red and green. The boy in the orange shirt looks focused. Another student is not painting, but drawing with markers. I think she is drawing the outside, because I can slightly see a sun and grass. I see a student in the back, who is painting a striped sphere with the colors orange and yellow. In conclusion, this image shows students actively working on art projects.',
        scoreType: 'advanced',
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'wi going to do art and also wi have art pictures up in the wall.',
        scoreType: 'limited',
      },
      {
        description: 'In this story is a art clas and what the techer is doing is pating and the gril and the techer and 2 students pating.',
        scoreType: 'emerging',
      },
      {
        description: 'The teacher is passing out crayons to the students. And the students are making a project after they are done making the project.They paint it or draw on it. There is a clock on the wall and drawings.',
        scoreType: 'expanding',
      },
      {
        description: 'There were two teachers who liked art so they started a art club and a lot of students joined that day. They did a lot of activities but one person didnt like the art club because he thought it was a waist of time. When he started he didnt like it but when he went everyday he liked it more and more.',
        scoreType: 'bridging',
      },
      {
        description: 'I can tell that this is a picture of an art classroom because students are working on art projects. The teacher is picking up unwanted markers, while the two boys at the table paint foam cubes with the colors red and green. The boy in the orange shirt looks focused. Another student is not painting, but drawing with markers. I think she is drawing the outside, because I can slightly see a sun and grass. I see a student in the back, who is painting a striped sphere with the colors orange and yellow. In conclusion, this image shows students actively working on art projects.',
        scoreType: 'advanced',
      },
    ],
    pointsOfReason: [
      {
        description: 'wi going to do art and also wi have art pictures up in the wall.',
        scoreType: 'limited',
      },
      {
        description: 'In this story is a art clas and what the techer is doing is pating and the gril and the techer and 2 students pating.',
        scoreType: 'emerging',
      },
      {
        description: 'The teacher is passing out crayons to the students. And the students are making a project after they are done making the project.They paint it or draw on it. There is a clock on the wall and drawings.',
        scoreType: 'expanding',
      },
      {
        description: 'There were two teachers who liked art so they started a art club and a lot of students joined that day. They did a lot of activities but one person didnt like the art club because he thought it was a waist of time. When he started he didnt like it but when he went everyday he liked it more and more.',
        scoreType: 'bridging',
      },
      {
        description: 'I can tell that this is a picture of an art classroom because students are working on art projects. The teacher is picking up unwanted markers, while the two boys at the table paint foam cubes with the colors red and green. The boy in the orange shirt looks focused. Another student is not painting, but drawing with markers. I think she is drawing the outside, because I can slightly see a sun and grass. I see a student in the back, who is painting a striped sphere with the colors orange and yellow. In conclusion, this image shows students actively working on art projects.',
        scoreType: 'advanced',
      },
    ],
  },
  ANCHOR_GRADE_6_8: {
    vocabularyAndGrammar: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
      },
    ],
    pointsOfReason: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
      },
    ],
  },
  ANCHOR_GRADE_9_12: {
    vocabularyAndGrammar: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
      },
    ],
    descriptionAndExplanation: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
      },
    ],
    pointsOfReason: [
      {
        description: 'the boy en girl es the conputer the pichurs en peiper es the teibol en boy y girl is camarise',
        scoreType: 'limited',
      },
      {
        description: 'In the photo I look at six people Iook at a couple of photos that are hanging and other photos are also a computer two peoples ,a boy and girl .two other characters that are two teenagers are taking a picture the man has the camera and the woman She is having a certificate and are hangingn the photos on a clothesline.',
        scoreType: 'emerging',
      },
      {
        description: 'What I notice in this classroom are students and a teacher working on what seems to be a picture project. I know this because the kids are hanging pictures on a string next to the wall. The students are also taking pictures, adding on the teacher is printing out pictures from her computer. ',
        scoreType: 'expanding',
      },
      {
        description: 'What I see in this image is a desk with papers, a camera, and to the left of the desk there are two girls hanging up pictures. Next, there is a girl on the left which appears to be proudly holding an award and in front of her there is a boy who is taking a picture of her. Lastly, on the left corner of this image, there is a boy and a girl who are busy looking at pictures on the computer.',
        scoreType: 'bridging',
      },
      {
        description: 'This picture depicts a photography class. I know this because there are photographs and cameras. Two girls are hanging up photos on a wire. It appears that one is of an angry boy. I hypothesize this because he is crossing his arms. I also notice that the pictures on the wire are equivalent to the ones on the teacher\'s computer. She is approving the photos that the students took, while another student is peering over her shoulder checking what she is doing. There is a student getting her picture taken while holding a certificate. Her friend is taking the picture. In this image, everyone is actively working on photography projects.',
        scoreType: 'advanced',
      },
    ],
  },
};

export const getOverlayAnchorInfo = (grade: string | undefined | null, writingSkill: WritingSkills | null, speakingSkill: SpeakingSkills | null, scoreValue: number) => {
  switch (grade) {
    case 'PK':
    case 'TK':
    case 'K':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_PK_K?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : AnchorList.writing.ANCHOR_GRADE_PK_K?.find((data) => data.scoreValue === scoreValue)?.description;
    case '1':
    case '2':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_1_2?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : AnchorList.writing.ANCHOR_GRADE_1_2?.find((data) => data.scoreValue === scoreValue)?.description;
    case '3':
    case '4':
    case '5':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_3_5?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : AnchorList.writing.ANCHOR_GRADE_3_5?.find((data) => data.scoreValue === scoreValue)?.description;
    case '6':
    case '7':
    case '8':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_6_8?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : AnchorList.writing.ANCHOR_GRADE_6_8?.find((data) => data.scoreValue === scoreValue)?.description;
    case '9':
    case '10':
    case '11':
    case '12':
      return speakingSkill !== null
        ? SpeakingTableData.ANCHOR_GRADE_9_12?.[speakingSkill]?.find((data) => data.scoreValue === scoreValue)?.url
        : AnchorList.writing.ANCHOR_GRADE_9_12?.find((data) => data.scoreValue === scoreValue)?.description;
    default:
      return '';
  }
};

export interface TableEntity {
  fill: string | undefined,
  textColor: string | undefined,
  label: string,
  description: string,
}

export interface ScoreScheme {
  default: TableEntity[],
  texas: TableEntity[]
}

const TableChip: ScoreScheme = {
  default: [
    {
      fill: palette.customRed.buttonFontRed,
      textColor: palette.customWhite.main,
      label: 'Level 1: Limited',
      description: 'Limited/no response in English, may contain student’s first language',
    },
    {
      fill: palette.customYellow.main,
      textColor: palette.fontColors.fontBlack,
      label: 'Level 2: Emerging',
      description: 'Emerging English with significant errors',
    },
    {
      fill: palette.fontColors.buttonFontGreen,
      textColor: palette.customWhite.main,
      label: 'Level 3: Expanding',
      description: 'Responses are more precise and contain moderate errors',
    },
    {
      fill:palette.customBlue.primaryBlue,
      textColor: palette.customWhite.main,
      label: 'Level 4: Bridging',
      description: 'Correct and relevant responses with minimal errors',
    },
    {
      fill: palette.customBlue.cornflowerBlue,
      textColor: palette.customWhite.main,
      label: 'Level 5: Advanced',
      description: 'Meets or exceeds grade-level ELA/ELD and CCSS/CCR standards',
    },
  ],
  texas: [
    {
      fill: palette.customRed.buttonFontRed,
      textColor: palette.customWhite.main,
      label: 'Level 1: Beginner',
      description: ' Limited/no response in English. May contain student’s home language.',
    },
    {
      fill: palette.customYellow.main,
      textColor: palette.fontColors.fontBlack,
      label: 'Level 2: Intermediate',
      description: 'Has simple language structure, utilizes high frequency vocabulary, verbalizes with routine and social vocabulary.',
    },
    {
      fill: palette.fontColors.buttonFontGreen,
      textColor: palette.customWhite.main,
      label: 'Level 3: Advanced',
      description: 'Uses grade level appropriate English language response with minimal errors, with second language support.',
    },
    {
      fill:palette.customBlue.primaryBlue,
      textColor: palette.customWhite.main,
      label: 'Level 4: Advanced High',
      description: 'Uses grade level appropriate English language response, comparable to English speaking peer, with minimum second language support.',
    },
    {
      fill: palette.customBlue.cornflowerBlue,
      textColor: palette.customWhite.main,
      label: 'Proficient',
      description: 'Meets or exceeds ELPs criteria and grade level TEKS/CCR standards.',
    },
  ],
};

export interface GradeEntity {
  label: string,
  pt_image?: string,
}

const GradeChip: GradeEntity[] = [
  {
    label: ANCHOR_GRADE_PK_K,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/PK-K-Library-Time.png',
  },
  {
    label: ANCHOR_GRADE_1_2,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/1-2-School-Community-Gr.PK-K.jpg',
  },
  {
    label: ANCHOR_GRADE_3_5,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/Art-class-Gr.3-5.jpg',
  },
  {
    label: ANCHOR_GRADE_6_8,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/Photography-Class-Gr.6-8.jpg',
  },
  {
    label: ANCHOR_GRADE_9_12,
    pt_image: '/images/teacher-toolbox-anchor-pt-images/Photography-Class-Gr.6-8.jpg',
  },
];

const useAnchorData = ({ section }: Props) => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const [grade, setGrade] = useState(ANCHOR_GRADE_PK_K);
  const anchorData = useMemo(() => (section === Skills.Speaking
    ? AnchorList.speaking : AnchorList.writing), [section]);

  const getTableChipData = (scoreScheme: string) => {
    switch (scoreScheme) {
      case DEFAULT_SCHEME:
        return TableChip.default;
      case SCHEME_1:
        return TableChip.texas;
      default:
        return TableChip.default;
    }
  };

  const tableChipData = useMemo(() => getTableChipData(user.score_scheme?.title_key), [user.score_scheme]);
  const gradeChipData = useMemo(() => GradeChip, []);

  const getGradeData = (gradeType: string) => {
    switch (gradeType) {
      case ANCHOR_GRADE_PK_K:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_PK_K
          : WritingTableData.ANCHOR_GRADE_PK_K;
      case ANCHOR_GRADE_1_2:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_1_2
          : WritingTableData.ANCHOR_GRADE_1_2;
      case ANCHOR_GRADE_3_5:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_3_5
          : WritingTableData.ANCHOR_GRADE_3_5;
      case ANCHOR_GRADE_6_8:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_6_8
          : WritingTableData.ANCHOR_GRADE_6_8;
      case ANCHOR_GRADE_9_12:
        return section === Skills.Speaking
          ? SpeakingTableData.ANCHOR_GRADE_9_12
          : WritingTableData.ANCHOR_GRADE_9_12;
      default:
        return null;
    }
  };
  const anchorContent = useMemo(() => getGradeData(grade), [getGradeData, grade]);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>();
  const [showPreview, setShowPreview] = useState(false);

  return ({
    anchorData,
    tableChipData,
    gradeChipData,
    grade,
    setGrade,
    anchorContent,
    selectedPlayerId,
    setSelectedPlayerId,
    showPreview,
    setShowPreview,
  });
};

export default useAnchorData;
