import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import React, { useEffect } from 'react';
import { MY_DISTRICT, PAGE_SIZE } from '../../../variables/constant';
import {
  ClassFilterFragmentFragment,
  DistrictFilterFragmentFragment,
  OrderBy,
  SchoolFilterFragmentFragment,
  StateFilterFragmentFragment,
  UserPermissions,
  useMyDistrictQuery,
  useStudentsListQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { AdminStudentsSort } from '../../../variables/types';
import usePermission from '../../../hooks/usePermission';
import { SearchContext } from '../../../core/searchContext';
import useDistrict from '../../Districts/District/district-hook';

const useStudents = () => {
  const pagination = usePagination();
  const { searchTerm } = React.useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [order, setOrder] = useQueryParam('order', StringParam);
  const [selectedState, setStateValue] = useQueryParam('state', StringParam);
  const [selectedDistrict, setSelectedDistrict] = useQueryParam('district', StringParam);
  const [selectedClass, setSelectedClass] = useQueryParam('class', StringParam);
  const [selectedSchool, setSelectedSchool] = useQueryParam('school', StringParam);
  const { hasPermission: districtPermission } = usePermission([
    UserPermissions.ListDistricts,
    UserPermissions.ViewDistricts,
  ]);
  const { hasPermission: schoolPermission } = usePermission([UserPermissions.ListSchools, UserPermissions.ViewSchools]);
  const { hasPermission: myDistrictPermission } = usePermission([
    UserPermissions.MyDistrict,
  ]);
  const { data: district, loading: myDistrictDataLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: !myDistrictPermission,
  });

  useEffect(() => {
    if (district) {
      setSelectedDistrict(district?.myDistrict?.id);
    }
  }
    , [district])

  const { data, loading } = useStudentsListQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort,
      searchByName: searchTerm,
      orderBy: order as OrderBy,
      ...(selectedState ? { stateId: selectedState } : {}),
      ...(selectedDistrict ? { districtId: selectedDistrict } : {}),
      ...(selectedSchool ? { schoolId: selectedSchool } : {}),
      ...(selectedClass ? { sectionId: selectedClass } : {}),
    },
  });

  const onSortChange = (sortBy: AdminStudentsSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const students = data?.allStudentsList.nodes ?? [];
  const totalCount = data?.allStudentsList.totalCount ?? 0;

  const isTableEmpty = !loading && students?.length === 0;

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: React.SetStateAction<number>) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: AdminStudentsSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onStateChange = (state: string | StateFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setStateValue(state as string);
    setSelectedDistrict(null);
    setSelectedClass(null);
    setSelectedSchool(null);
  };

  const onDistrictChange = (district: string | DistrictFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedDistrict(district as string);
    setSelectedClass(null);
    setSelectedSchool(null);
  };

  const onSchoolChange = (school: string | SchoolFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedSchool(school as string);
    setSelectedClass(null);
  };

  const onClassChange = (section: string | ClassFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedClass(section as string);
  };

  return {
    loading,
    totalCount,
    students,
    pagination,
    onSortChange,
    sort: sort as AdminStudentsSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    selectedClass,
    onClassChange,
    selectedSchool,
    onSchoolChange,
    districtPermission,
    schoolPermission,
  };
};

export default useStudents;
