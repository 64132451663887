/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import {
  Box,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import StyledTableContainer from '../../Assignments/AssignmentsTable/List.styled';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { SKILL_TYPE_TEXT } from '../../../variables/constant';
import useRedirect from '../../../hooks/useRedirect';
import { toSingleReadableFormat } from '../../../utils/dateFormat';
import { GoalGraphArray } from '../Overview/overview-hook';
import { GoalSetGraphData, GoalsSet, Skills, SpeakingSkills, WritingSkills } from '../../../variables/types';

interface Props {
  data?: GoalSetGraphData,
  loading: boolean
  section?: Skills
}

interface StyleProps {
  color?: string;
  skillKey?: string;
}

interface AllGoals {
  color: string
  goals: GoalGraphArray
  skill_key: string
  skill_label: string
}

const StylesCSS = ( theme: Theme, { color, skillKey }: StyleProps) => ({
  assignmentNameCell: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(2.25),
  },
  goal: {
    color: theme.palette.fontColors.fontBlack,
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.75),
  },
  width20: {
    width: '20%',
  },
  width60: {
    width: '60%',
  },
  tableWithNoData: {
    '& tbody tr:hover': {
      backgroundColor: theme.palette.transparent,
      cursor: 'initial',
    },
  },
  tableHeadRoot: {
    borderRadius: 0,
  },
  tableHeadCell: {
    '&.MuiTableCell-root': {
      backgroundColor: color ? color : 'inherit',
      color: ([SpeakingSkills.Pronunciation, SpeakingSkills.Grammar, WritingSkills.VocabAndGrammar, WritingSkills.PointsAndReason].includes(skillKey as SpeakingSkills | WritingSkills | undefined ?? SpeakingSkills.Fluency)) ? theme.palette.fontColors.fontBlack : theme.palette.customWhite.main,
      borderBottom: 0,
    },
  },
  tableContainerRoot: {
    '&.MuiTableContainer-root': {
      borderTopLeftRadius: 0,
      height: 'auto',
      borderColor: color ? color : theme.palette.customRed.progressPrimary,
    },
  },
});

const GoalsTable = ({
  loading,
  data,
  section,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme,  { color: data?.color ?? '', skillKey: data?.skill_key });
  const GetIconFromBase = (iconString: string) => (
    <img alt="performanceIcon" src={`data:image/svg+xml;base64,${iconString}`} />
  );
  const GoalTableBody = (goalItem: GoalsSet | any) => (
    <TableRow key={`${goalItem?.assigned_date}_${goalItem?.current_goal_skill}_${goalItem?.student_id}`}>
      <TableCell
        sx={{
          ...styles.assignmentNameCell,
        }}
      >
        <Link
          component={RouterLink}
          to={{
            pathname: `/students/${goalItem?.student_id}/view/task`,
            search,
          }}
          underline='none'
        >
          {goalItem?.student_name}
        </Link>
      </TableCell>
      <TableCell>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          {goalItem.current_goal !== null ? GetIconFromBase(goalItem?.icon_base64 || '') : null}
          {' '}
          <Typography component="span" sx={{
            marginLeft: theme.spacing(2),
            minHeight: theme.spacing(2.5),
            maxHeight: theme.spacing(18.75),
            overflow: 'scroll',
          }}>
            {goalItem?.current_goal}
          </Typography>
        </Typography>
      </TableCell>
      <TableCell
        sx={styles.goal}
      >
        <Typography variant='secondaryLabel' sx={{  color: theme.palette.fontColors.fontBlack, fontWeight: 'normal' }} >{goalItem?.assigned_date ? toSingleReadableFormat(goalItem.assigned_date) : '-'}</Typography>
      </TableCell>
    </TableRow>
  );
  const isTableEmpty = !data || data?.numberOfGoals === 0;
  const { search } = useRedirect();
  return (
    <Box width="100%">
      <Box>
        <StyledTableContainer
          sx={{
            ...styles.tableContainerRoot,
          }}
        >
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={ loading || isTableEmpty ? styles.tableWithNoData : {} }
          >
            <TableHead
              sx={{ ...styles.tableHeadRoot }}
            >
              <TableRow>
                <TableCell
                  sx={{ ...styles.width20, ...styles.tableHeadCell }}
                >
                  Student
                </TableCell>
                <TableCell
                  sx={{ ...styles.width60, ...styles.tableHeadCell }}
                >
                  {`Active ${SKILL_TYPE_TEXT[section ?? Skills.Speaking]} Goal`}
                </TableCell>
                <TableCell
                  sx={{ ...styles.width20, ...styles.tableHeadCell }}
                >
                  Assigned
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && data?.skill_key === 'all'
                ? data?.goals?.map((item: AllGoals | any) => item?.students?.map((goal: GoalsSet | any) => (
                  <GoalTableBody {...goal} key={goal?.id} />
                )))
                : data?.students?.map((goal: GoalsSet | any) => (
                  <GoalTableBody {...goal} key={goal?.id} />
                ))}
              {!loading && isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="center"
                  >
                    <NoResults
                      label="No students in this section"
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </Box>
  );
};

export default React.memo(GoalsTable);
