import React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { svgStyles } from '../Common/Common.styled';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Checkbox, CheckboxProps, InputAdornment, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import useGroupedClasses from './class-grouped-hook';
import ClassesIcon from '../Icons/ClassesIcon';
import { SectionCategory } from '../../generated/graphql';
import Loader from '../Loader/loader';

export interface SectionDetails {
  id: string;
  name: string;
  school_id: string;
  school: string;
}

interface Props {
  error?: boolean;
  className?: string;
  value?: SectionDetails[] | undefined;
  onChange?: (data: SectionDetails[] | undefined) => void;
  stateId?: string | undefined;
  districtId?: string | undefined;
  schoolIds?: string[] | undefined | null;
  disabled?: boolean;
  placeholder?: string | undefined;
  isPrimary? : boolean | undefined
  classTypes?: SectionCategory[];
}

const styles = {
  border: '0px',
  minHeight: '48px',
  height: 'auto',
  '& input.MuiInputBase-input.Mui-disabled' : {
    backgroundColor: palette.customWhite.inputDisabled,
  },
};

const ClassesGrouped = ({
  className,
  value,
  onChange,
  stateId,
  districtId,
  schoolIds,
  disabled,
  placeholder = 'Classes',
  isPrimary = false,
  classTypes,
}: Props) => {
  const { classes, loading } = useGroupedClasses({ stateId, districtId, schoolIds, classTypes });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const onSelectChange = (_:any, classList:SectionDetails[]) => {
    onChange?.(classList);
  };

  /* Handle enable/disable check on group(schools) as per selected optios */
  const checkGroup = (group: string) => {
    const groupLength = classes.filter((classItem) => classItem.school_id === group).length;
    const selectedGroupLength = value?.filter((classItem) => classItem.school_id === group).length;
    return groupLength === selectedGroupLength;
  };

  /*Conditionally handle enable/disable for groups and pass values to onChange*/
  const selectGroup = (group: string) => {
    const groupedClasses = classes.filter((section) => section.school_id === group);
    const selectedIndependentGroupClasses = value?.filter((section) => section?.school_id !== group);
    const selectedGroupClasses = value?.filter((section) => section?.school_id === group);
    const checkedGroup = checkGroup(group);

    if (selectedGroupClasses?.length === 0 && selectedIndependentGroupClasses?.length) {
      onChange?.([...groupedClasses, ...selectedIndependentGroupClasses]);
    }  else if (selectedGroupClasses?.length && selectedIndependentGroupClasses?.length === 0) {
      if (checkedGroup) {
        onChange?.([]);
      } else {
        onChange?.([...groupedClasses]);
      }
    }  else if (selectedGroupClasses?.length && selectedIndependentGroupClasses?.length){
      if (checkedGroup) {
        onChange?.([...selectedIndependentGroupClasses]);
      } else {
        onChange?.([...groupedClasses, ...selectedIndependentGroupClasses ]);
      }
    } else {
      onChange?.(groupedClasses ?? []);
    }
  };


  return (
    <Autocomplete
      multiple
      className={className}
      limitTags={3}
      disableCloseOnSelect
      options={ classes }
      getOptionLabel={(option: any) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={loading || disabled}
      onChange={onSelectChange}
      value={value ?? []}
      selectOnFocus
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return (
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }}
                component='span'>{option?.name ?? ''}</Typography>}
              {...getTagProps({ index })}
              disabled={isPrimary}
              key={index}
            />
          );
        })
      }
      renderOption={(props, option: any, { selected }) => (
        <Box component="li" {...props} key={option.id}>
          <Checkbox
            color={'primary' as  CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            disabled={isPrimary}
            sx={{ mr: '8px' }}
          />
          {`${option?.name ?? ''}`}
        </Box>
      )}
      groupBy={(option) => option.school_id}
      renderGroup={(params) => (
        <>
          <Checkbox
            key={params.group}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={checkGroup(params.group)}
            onChange={() => selectGroup(params.group)}
            disabled={isPrimary}
          />
          <Typography component='span' sx={{ fontWeight: 800 }}>{classes.find((section) => section.school_id === params.group)?.school_name || ''}</Typography>
          {params.children}
        </>
      )}
      renderInput={(params) => (
        <>
          <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>
          <TextField
            {...params}
            sx={{ ...styles }}
            placeholder={params.InputProps.startAdornment ? undefined : 'None'}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px', overflowY: 'scroll' },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <ClassesIcon
                      pathfill={palette.customBlue.primaryBlue}
                      sx={{ ...svgStyles.icon }} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
          <Loader open={loading}/>
        </>
      )}
    />
  );
};

export default ClassesGrouped;
