import { useEffect } from 'react';
import {
  AdminDashboardFilterInput,
  useTasksAssignedAndCompletedWidgetQuery,
} from '../../../../generated/graphql';
import { AdminDashboards } from '../../../../variables/constant';
import { useDashboardContext } from '../../admin-analytics-hook';

interface QueryInputProps {
  district_id?: string,
  school_id?: string,
  input?: AdminDashboardFilterInput,
}

const useTasksAssignedAndCompleted = () => {

  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    analyticsFilters :queryFilters,
    updateDashboardWidgetsDataStatus,
  } = useDashboardContext();

  const queryInput: QueryInputProps = {
    input: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.school_id = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData) queryInput.district_id = myDistrictData?.id;

  const { data, loading: tasksAssignedAndCompletedLoading } = useTasksAssignedAndCompletedWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryInput },
  });

  useEffect(() => {
    if (!tasksAssignedAndCompletedLoading && !!data?.performanceWideTaskAssignedAndCompletedData) {
      const dataMissing = [
        data?.performanceWideTaskAssignedAndCompletedData?.average_tasks_assigned_per_teacher as number,
        data?.performanceWideTaskAssignedAndCompletedData?.count_of_students as number,
        data?.performanceWideTaskAssignedAndCompletedData?.count_of_tasks_assigned as number,
        data?.performanceWideTaskAssignedAndCompletedData?.tasks_completed_per_student as number,
      ].reduce((previousValue, currentValue) => (previousValue! + currentValue!), 0);
      updateDashboardWidgetsDataStatus?.({
        loading: tasksAssignedAndCompletedLoading,
        widget: AdminDashboards.performance.TasksAssignedNCompletedWidget.name,
        dataMissing: !dataMissing,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksAssignedAndCompletedLoading, data]);

  return ({
    tasksAssignedAndCompletedLoading, tasksAssignedAndCompletedData: data?.performanceWideTaskAssignedAndCompletedData,
    title: 'Tasks Assigned & Completed',
  });
};

export default useTasksAssignedAndCompleted;