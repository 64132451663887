import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Admin from './Admin';
import AdminsTable from './AdminsTable';
import Layout from '../../components/Layout';
import { AdminType } from '../../variables/types';

const Admins = () => (
  <Layout>
    <Switch>
      <Route
        path="/admins/:type"
        component={AdminsTable}
        exact
      />
      <Route
        path="/admins/:type/:id"
        component={Admin}
        exact
      />
      <Redirect from="/admins" to={`/admins/${AdminType.School}`} exact />
    </Switch>
  </Layout>
);

export default React.memo(Admins);
