import {useEffect, useState} from 'react';
import { PAGE_SIZE } from '../../../variables/constant';
import {
  BenchmarkSubmissionListInput,
  BenchmarkSubmissionListNode,
  BenchmarkSubmissionListSortType,
  OrderBy,
  SubmissionStatus,
  useBenchmarkGradesListQuery,
} from '../../../generated/graphql';
import { BenchmarkGradesListParams } from './grades-list-hook';

const useBenchmarkGradesInfiniteList = (
  params?: BenchmarkGradesListParams
) => {
  const [sort, setSort] = useState<BenchmarkSubmissionListSortType>(params?.sort ?? BenchmarkSubmissionListSortType.SubmittedAt);
  const [order, setOrder] = useState<string>(OrderBy.Asc);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<BenchmarkSubmissionListNode[]>([]);
  const [benchmarkTypes, setBenchmarkTypes] = useState<any []>([]);
  const [benchmarkId, setBenchmarkId] = useState<string | undefined>(params?.benchmarkId);
  const [selectedStatuses, setSelectedStatuses] = useState<SubmissionStatus[] | undefined>(params?.gradeStatuses);
  const [selectedStates, setSelectedStates] = useState<string[] | undefined>(undefined);
  const [selectedDistricts, setSelectedDistricts] = useState<string[] | undefined>(undefined);
  const [selectedSchools, setSelectedSchools] = useState<string[] | undefined>(undefined);
  const initialInputVariables = {
    limit: PAGE_SIZE,
    page: 1,
    sort,
    benchmark_id: benchmarkId,
    order_by: order as OrderBy,
    grade_statuses: selectedStatuses,
  };

  const [variables, setVariables] = useState<BenchmarkSubmissionListInput>({
    ...initialInputVariables,
  });

  const { data, loading } = useBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  useEffect(() => {
    if (data && data.adminBenchmarkSubmissionList?.nodes) {
      setRows(rows => [...rows, ...data?.adminBenchmarkSubmissionList.nodes!])
    }
  }, [data]);

  const handleClearFilters = async () => {
    setPage(1)
    setVariables({
      ...initialInputVariables,
      state_ids: undefined,
      district_ids: undefined,
      school_ids: undefined,
    });
    setBenchmarkTypes([]);
    setBenchmarkId(undefined);
    setSelectedStatuses( undefined);
    setSelectedStates(undefined);
    setSelectedDistricts(undefined);
    setSelectedSchools(undefined);
  };

  const handleApplyFilters = async () => {
    setPage(1)
    setVariables({
      limit: PAGE_SIZE,
      page: 1,
      sort: sort as BenchmarkSubmissionListSortType,
      order_by: order as OrderBy,
      ...(benchmarkTypes.length ? { benchmark_types: benchmarkTypes } : {}),
      ...(benchmarkId ? { benchmark_id: benchmarkId } : {}),
      ...(selectedStatuses ? { grade_statuses: selectedStatuses } : {}),
      ...(selectedStates ? { state_ids: selectedStates } : {}),
      ...(selectedDistricts ? { district_ids: selectedDistricts } : {}),
      ...(selectedSchools ? { school_ids: selectedSchools } : {}),
    });
  };

  const onSortChange = (sortBy: BenchmarkSubmissionListSortType, orderBy: OrderBy) => {
    setPage(1)
    setSort(sortBy);
    setOrder(orderBy);
    setVariables({
      ...variables,
      sort: sortBy,
      order_by: orderBy,
    });
  };

  const handlePagination = (value: React.SetStateAction<number>) => {
    setPage(value)
    setVariables({
      ...variables,
      page: value as number,
    });
  };

  return {
    loading: loading,
    gradeBenchmarks: rows ?? [],
    totalCount: data?.adminBenchmarkSubmissionList?.node_count ?? 0,
    page,
    onSortChange,
    sort,
    order: order as OrderBy,
    benchmarkTypes, setBenchmarkTypes,
    benchmarkId, setBenchmarkId,
    selectedStatuses, setSelectedStatuses,
    selectedStates, setSelectedStates,
    selectedDistricts, setSelectedDistricts,
    selectedSchools, setSelectedSchools,
    handleApplyFilters,
    handleClearFilters,
    handlePagination,
  };
};

export default useBenchmarkGradesInfiniteList;
