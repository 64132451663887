import React, { useEffect, useState } from 'react';
import { sub, getUnixTime } from 'date-fns';
import {
  SubmissionType,
  AdminDashboardFilterInput,
  AdminDashboardSectionFragmentFragment,
  PredefinedGrades,
  SchoolFilterFragmentFragment,
  TeacherFilterFragmentFragment,
} from '../../../generated/graphql';
import { AdminAnalyticsDateFilterOptions } from '../../../variables/types';
import { AnalyticsFiltersProps, useDashboardContext } from '../admin-analytics-hook';

const useAnalyticsFilters = () => {

  const {
    loading, setLoading,
    currentFilters, setCurrentFilters,
    analyticsFilters, setAnalyticsFilters,
    academicSessionLoading, academicSession,
    resetDashboardWidgetsDataStatus,
  } = useDashboardContext();

  const updateLoader = (loadingStatus: boolean) => setLoading?.(loading && loadingStatus);

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const toggleFiltersDisplay = () => {
    setOpenFilters(!openFilters);
  };

  const [revertFilters, setRevertFilters] = useState<boolean>(false);
  const toggleRevertFilters = () => {
    setRevertFilters(!revertFilters);
  };

  const handleDialogOnClose = (
    _: any,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason && ['backdropClick', 'escapeKeyDown'].includes(reason)) setOpenFilters(openFilters);
    else toggleFiltersDisplay();
  };

  const today = new Date();
  // Last 3 months
  var startDateInit = sub(today, {
    months: 3,
  });
  const [dateRangeSelected, setDateRangeSelected] = useState<string>(AdminAnalyticsDateFilterOptions.Last3Months);
  const handleDateRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateRangeSelected?.((event.target as HTMLInputElement).value);
  };

  const [submissionTypeSelected, setSubmissionTypeSelected] = useState<SubmissionType>(SubmissionType.Speaking);
  const [schoolsSelected, setSchoolsSelected] = useState<SchoolFilterFragmentFragment[]>([]);
  const [gradesSelected, setGradesSelected] = useState<PredefinedGrades[]>([]);
  const [teachersSelected, setTeachersSelected] = useState<TeacherFilterFragmentFragment[]>([]);
  const [classesSelected, setClassesSelected] = useState<AdminDashboardSectionFragmentFragment[]>([]);
  const [startDateSelected, setStartDateSelected] = useState<Date>(startDateInit);
  const [endDateSelected, setEndDateSelected] = useState<Date>(today);
  const applyFilterArgs: AnalyticsFiltersProps = {};

  const applyAnalyticsFilters = ({
    dateRange = dateRangeSelected,
    submissionType = submissionTypeSelected,
    schools = schoolsSelected,
    grades = gradesSelected,
    teachers = teachersSelected,
    classes = classesSelected,
  }: AnalyticsFiltersProps) => {
    const newAnalyticsFilters: AdminDashboardFilterInput = {};
    newAnalyticsFilters.submission_score_type = submissionType;
    if (schools && schools.length)
      newAnalyticsFilters.school_ids = schools?.map((school: SchoolFilterFragmentFragment) => school?.id);
    if (grades && grades.length)
      newAnalyticsFilters.grade_ids = grades?.map((grade: PredefinedGrades) => grade.id);
    if (teachers && teachers.length)
      newAnalyticsFilters.teacher_ids = teachers?.map((teacher: TeacherFilterFragmentFragment) => teacher.id);
    if (classes && classes.length)
      newAnalyticsFilters.section_ids = classes?.map((tclass: AdminDashboardSectionFragmentFragment) => tclass.id);
    updateDateFilterRange(newAnalyticsFilters, dateRange);
    resetDashboardWidgetsDataStatus(true);
    setAnalyticsFilters?.({
      ...newAnalyticsFilters,
    });
  };

  const getIndexByTabKey = (tabKey: string) => {
    var tabIndex = 0;
    switch (tabKey) {
      case SubmissionType.Writing:
        tabIndex = 1;
        break;
      case SubmissionType.Speaking:
      default:
        tabIndex = 0;
    }
    return tabIndex;
  };
  const [tab, setTab] = useState<number>(getIndexByTabKey(SubmissionType.Speaking));
  const handleSubmissionTypeSelectedChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    switch (newValue) {
      case 1:
        setSubmissionTypeSelected(SubmissionType.Writing);
        return;
      case 0:
      default:
        setSubmissionTypeSelected(SubmissionType.Speaking);
        return;
    }
  };

  // Schools
  const updateSchoolsSelected = (selectedSchools: SchoolFilterFragmentFragment[]) => {
    setSchoolsSelected(selectedSchools);
  };
  // Grades
  const updateGradesSelected = (selectedGrades: PredefinedGrades[]) => {
    setGradesSelected(selectedGrades);
  };
  const gradesToEnable = (selectedSchools: SchoolFilterFragmentFragment[]) => {
    if (!selectedSchools) return [];
    const schoolGrades = new Set<string>();
    selectedSchools?.forEach((school: SchoolFilterFragmentFragment) => {
      school?.school_predefined_grade_mappings?.forEach((schoolGradeItem: any) => {
        schoolGrades.add(schoolGradeItem?.predefined_grade_id);
      });
    });
    return [...schoolGrades];
  };
  // Teachers
  const updateTeachersSelected = (selectedTeachers: TeacherFilterFragmentFragment[]) => {
    setTeachersSelected(selectedTeachers);
  };
  // Classes
  const updateClassesSelected = (selectedClasses: AdminDashboardSectionFragmentFragment[]) => {
    setClassesSelected(selectedClasses);
  };

  // Filter Deleters
  const delSchoolSelected = (schoolId?: string) => {
    const newSchoolsSelected = !schoolId ? [] : schoolsSelected.filter((school) => school?.id === schoolId);
    setSchoolsSelected(newSchoolsSelected);
    applyAnalyticsFilters({
      ...applyFilterArgs,
      ...{ schools: newSchoolsSelected },
    });
  };
  const delGradeSelected = (gradeId?: string) => {
    const newGradesSelected = !gradeId ? [] : gradesSelected.filter((grade) => grade?.id === gradeId);
    setGradesSelected(newGradesSelected);
    applyAnalyticsFilters({
      ...applyFilterArgs,
      ...{ grades: newGradesSelected },
    });
  };
  const delTeacherSelected = (teacherId?: string) => {
    const newTeachersSelected = !teacherId ? [] : teachersSelected.filter((teacher) => teacher?.id === teacherId);
    setTeachersSelected(newTeachersSelected);
    applyAnalyticsFilters({
      ...applyFilterArgs,
      ...{ teachers: newTeachersSelected },
    });
  };
  const delClassSelected = (classId?: string) => {
    const newClassesSelected = !classId ? [] : classesSelected.filter((c) => c.id === classId);
    setClassesSelected(newClassesSelected);
    applyAnalyticsFilters({
      ...applyFilterArgs,
      ...{ classes: newClassesSelected },
    });
  };
  const resetDateRangeSelected = () => {
    const newDateRangeSelected = AdminAnalyticsDateFilterOptions.Last3Months;
    setDateRangeSelected(newDateRangeSelected);
    if (startDateSelected === startDateInit) return;
    applyAnalyticsFilters({
      ...applyFilterArgs,
      ...{ dateRange: newDateRangeSelected },
    });
  };

  // Date Range
  const updateDateFilterRange = (newAnalyticsFilters: AdminDashboardFilterInput, dateRange: string = dateRangeSelected) => {
    switch (dateRange) {
      case AdminAnalyticsDateFilterOptions.CustomDateRange:
        newAnalyticsFilters.start_timestamp = getUnixTime(startDateSelected);
        newAnalyticsFilters.end_timestamp = getUnixTime(endDateSelected);
        if (analyticsFilters?.start_timestamp === newAnalyticsFilters.start_timestamp) newAnalyticsFilters.start_timestamp += 1;
        break;
      // case AdminAnalyticsDateFilterOptions.LastYear:
      //   break;
      case AdminAnalyticsDateFilterOptions.ThisMonth:
        var startDate = sub(today, {
          days: today.getDate() - 1,
        });
        newAnalyticsFilters.start_timestamp = getUnixTime(startDate);
        newAnalyticsFilters.end_timestamp = getUnixTime(today);
        break;
      case AdminAnalyticsDateFilterOptions.Last30Days:
        startDate = sub(today, {
          days: 30,
        });
        newAnalyticsFilters.start_timestamp = getUnixTime(startDate);
        newAnalyticsFilters.end_timestamp = getUnixTime(today);
        break;
      default:
        // Last 3 months
        startDate = sub(today, {
          months: 3,
        });
        newAnalyticsFilters.start_timestamp = getUnixTime(startDate);
        newAnalyticsFilters.end_timestamp = getUnixTime(today);
    }
  };

  const resetAnalyticsFilters = async () => {
    setDateRangeSelected(AdminAnalyticsDateFilterOptions.Last3Months);
    setSchoolsSelected([]);
    setGradesSelected([]);
    setTeachersSelected([]);
    setClassesSelected([]);
    applyAnalyticsFilters({
      dateRange: AdminAnalyticsDateFilterOptions.Last3Months,
      submissionType: submissionTypeSelected,
      schools: [],
      grades: [],
      teachers: [],
      classes: [],
    });
  };

  const revertAnalyticsFilters = async ({
    dateRange = dateRangeSelected,
    schools = schoolsSelected,
    grades = gradesSelected,
    teachers = teachersSelected,
    classes = classesSelected,
  }: AnalyticsFiltersProps) => {
    if (dateRange) setDateRangeSelected(dateRange);
    if (schools) setSchoolsSelected(schools);
    if (grades) setGradesSelected(grades);
    if (teachers) setTeachersSelected(teachers);
    if (classes) setClassesSelected(classes);
  };

  // save the current filters state in context before allowing user to modify filters
  useEffect(() => {
    if (openFilters) {
      setCurrentFilters?.({
        dateRange: dateRangeSelected,
        submissionType: submissionTypeSelected,
        schools: schoolsSelected,
        grades: gradesSelected,
        teachers: teachersSelected,
        classes: classesSelected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openFilters,
  ]);

  // revert filters to the previous state by referring to context when user has not applied modified filters
  useEffect(() => {
    if (revertFilters) {
      revertAnalyticsFilters({
        ...currentFilters,
      });
      toggleRevertFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    revertFilters,
  ]);

  useEffect(() => {
    applyFilterArgs.dateRange = dateRangeSelected;
    applyFilterArgs.submissionType = submissionTypeSelected;
    applyFilterArgs.schools = schoolsSelected;
    applyFilterArgs.grades = gradesSelected;
    applyFilterArgs.teachers = teachersSelected;
    applyFilterArgs.classes = classesSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRangeSelected,
    submissionTypeSelected,
    schoolsSelected,
    gradesSelected,
    teachersSelected,
    classesSelected,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    applyFilterArgs.submissionType = submissionTypeSelected;
    applyAnalyticsFilters({
      ...applyFilterArgs,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionTypeSelected]);

  return ({
    tab,
    openFilters, setOpenFilters, toggleFiltersDisplay, handleDialogOnClose,
    submissionTypeSelected, handleSubmissionTypeSelectedChange,
    schoolsSelected, updateSchoolsSelected, delSchoolSelected,
    gradesSelected, updateGradesSelected, gradesToEnable, delGradeSelected,
    teachersSelected, updateTeachersSelected, delTeacherSelected,
    classesSelected, updateClassesSelected, delClassSelected,
    startDateSelected, setStartDateSelected,
    endDateSelected, setEndDateSelected, updateDateFilterRange,
    dateRangeSelected, handleDateRangeChange, resetDateRangeSelected,
    applyAnalyticsFilters, resetAnalyticsFilters,
    revertFilters, toggleRevertFilters, revertAnalyticsFilters,
    academicSessionLoading, academicSession,
    updateLoader,
  });
};

export default useAnalyticsFilters;
