import React, { useContext } from 'react';
import {
  Grid,
  Box,
  Typography,
  SxProps,
} from '@mui/material';
import { PTSubmissionSteps } from '../../../variables/types';
import { PromptFile } from '../../../generated/graphql';
import AudioPlayer from '../../../components/Player';
import { SubmitTaskContext } from '../submit-task-context';
import { SPEAKING_DEFAULT_PROMPT_TEXT, WRITING_DEFAULT_PROMPT_TEXT } from '../../../variables/constant';
import { palette  } from '../../../theme/palette';

interface SpeakingWritingPromptProps {
  taskType: string,
  setIsPlaying?: Function,
}

interface SpeakingWritingAudioPromptProps {
  content: Pick<PromptFile, 'id' | 'url' | 'file_type'> | undefined,
  active: boolean,
  setIsPlaying?: Function,
}

export const styles = {
  playerContainer: {
    alignItems: 'center',
  },
  promptContentContainer: {
    display: 'flex',
    alignItems: 'center',
    overflowY: 'scroll',
    padding: '2px 6px',
    border: '1px solid',
    borderColor:palette.customBlue.primaryBlue,
  },
  promptContentText: {
    fontSize: '15px',
    letterSpacing: '1px',
    color: palette.fontColors.fontBlack,
    fontWeight: 700,
    paddingTop: '2px',
  },
};

const SpeakingWritingAudioPrompt = ({
  content,
  active = true,
  setIsPlaying,
}: SpeakingWritingAudioPromptProps) => {
  return (
    <>
      {
        content !== undefined ? (
          <AudioPlayer
            url={content?.url}
            fileType={content?.file_type}
            active={active}
            disablePlay={!active}
            ptSubmissionPlayer
            setIsPlaying={setIsPlaying}
          />
        ) : ('')
      }
    </>
  );
};

const SpeakingWritingPrompt = ({
  taskType,
  setIsPlaying,
}: SpeakingWritingPromptProps) => {

  const {
    submission,
    playing,
    recording,
  } = useContext(SubmitTaskContext);

  const benchmarkOrAssignment = submission?.assignment ?? submission?.getBenchmark
  let promptContent: string | undefined = '';
  let promptRecording: Pick<PromptFile, 'id' | 'url' | 'file_type'> | undefined;
  if (taskType === PTSubmissionSteps.Speaking || benchmarkOrAssignment?.is_common_prompt) {
    promptContent = benchmarkOrAssignment?.speaking_prompt;
    if (benchmarkOrAssignment?.speaking_prompt_file) promptRecording = benchmarkOrAssignment?.speaking_prompt_file;
  } else {
    promptContent = benchmarkOrAssignment?.writing_prompt ?? '';
    if (benchmarkOrAssignment?.writing_prompt_file) promptRecording = benchmarkOrAssignment?.writing_prompt_file;
  }
  if (!promptContent) {
    if (taskType === PTSubmissionSteps.Speaking) promptContent = SPEAKING_DEFAULT_PROMPT_TEXT;
    if (taskType === PTSubmissionSteps.Writing) promptContent = WRITING_DEFAULT_PROMPT_TEXT;
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {taskType !== PTSubmissionSteps.Labeling && (
        <Grid item xs={1}>
          <SpeakingWritingAudioPrompt
            content={promptRecording}
            active={!(playing || recording)}
            setIsPlaying={setIsPlaying}
          />
        </Grid>
      )}
      <Grid item xs={9}>
        <Box sx={{ ...styles.promptContentContainer } as SxProps}>
          <Typography variant="body1" gutterBottom sx={{ ...styles.promptContentText }}>
            {promptContent}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SpeakingWritingPrompt;
