import React from 'react';
import { Route, Switch } from 'react-router-dom';
import District from './District';
import DistrictsTable from './DistrictsTable';
import Layout from '../../components/Layout';

const Districts = () => (
  <Layout>
    <Switch>
      <Route
        path="/districts"
        component={DistrictsTable}
        exact
      />
      <Route
        path="/districts/:id"
        component={District}
        exact
      />
    </Switch>
  </Layout>
);

export default React.memo(Districts);
