import React from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { ClassesSort, PaginationProps } from '../../../variables/types';
import TablePagination from '../../../components/TablePagination';
import { PAGE_SIZE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { OrderBy, SectionCategory, SectionDetailsFragmentFragment, TeacherInfo } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import SuccessIcon from '../../../components/Icons/SuccessIcon';
import {
  StyledTableCell,
  StyledTableCellOverFlowContent,
  StyledTableDataCell,
  StyledTableDataCellWidth10,
  StyledTableDataColumn,
} from '../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../components/Common/Common.styled';
import { palette  } from '../../../theme/palette';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: ClassesSort,
  order: OrderBy,
  classes?: SectionDetailsFragmentFragment[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: ClassesSort) => void) | Function,
}

const successIconStyles = {
  customClassIcon: {
    color: palette.customBlue.cornflowerBlue,
    '& circle': {
      fill: palette.customBlue.cornflowerBlue,
    },
  },
};

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  classes: classesData,
  handleSort,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const getTeacher = (teachers?: Array<TeacherInfo | undefined | null>) => (teachers && teachers?.length > 0 ? `${teachers?.[0]?.teacher_name} ${teachers?.length > 1 ? `+${teachers?.length - 1}` : ''}` : '-');

  const sortColumn = (title:string, sortEnum: ClassesSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
        { title }
    </StyledTableSort>
  );

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box>
        <StyledContainerRoot>
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ textAlign: 'left', width: '25%' }}>
                { sortColumn('Class', ClassesSort.Name) }
                </StyledTableCell>
                <StyledTableDataCellWidth10>
                  Teacher
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                { sortColumn('Period', ClassesSort.Periods) }
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                { sortColumn('Grades', ClassesSort.Grade) }
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                  Custom
                </StyledTableDataCellWidth10>
                <StyledTableDataCellWidth10>
                  Active PT
                </StyledTableDataCellWidth10>
                <StyledTableDataCell>
                { sortColumn('School', ClassesSort.School) }
                </StyledTableDataCell>
                <StyledTableDataCellWidth10>
                { sortColumn('District', ClassesSort.District) }
                </StyledTableDataCellWidth10>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && classesData?.map((row) => (
                <TableRow key={row.id}>
                  <StyledTableCellOverFlowContent>
                    <Typography
                      component={Link}
                      variant='tableTitleData'
                      to={`/classes/${row.id}`}
                    >
                      {row.name}
                    </Typography>
                  </StyledTableCellOverFlowContent>
                  {row.teachers && row?.teachers?.length > 1 ?
                    <Tooltip title={row?.teachers?.map((teacherName, i) => <Typography component='p' key={i}  variant='tableTitleData' sx={{ mb: '6px' }}>{teacherName?.teacher_name}</Typography>)} placement="top-start">
                      <StyledTableDataColumn>
                        {getTeacher(row?.teachers)}
                      </StyledTableDataColumn>
                    </Tooltip>  :
                    <StyledTableDataColumn>{getTeacher(row?.teachers)}</StyledTableDataColumn>
                  }
                  <StyledTableDataColumn>
                    {row?.period || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.grade || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.category === SectionCategory.CustomClass
                      ? <SuccessIcon sx={{ ...successIconStyles.customClassIcon }} /> : '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.active_pt ? <SuccessIcon /> : '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.school || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn sx={{ fontWeight: 800 }}>
                    {row?.district || '-'}
                  </StyledTableDataColumn>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <NoResults
                      label="No Classes"
                      description="There has yet to be a class created."
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      {totalCount > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
    </Box>
  );
};
export default List;
