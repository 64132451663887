import React from 'react';
import { Typography } from '@mui/material';
import { FixedHeader } from '../StudentBackPackView/studentbackpackview.styled';
import { palette } from '../../../theme/palette';

interface Props {
  pageTitle?: string
  containIcon : boolean
  Icon?: any
}

const filterSectionStyles = {
  header: {
    borderBottom: `2px solid ${palette.customBackground.loginBackground}`,
    display: 'flex',
    justifyContent: 'space-between',
    background: palette.customWhite.main
  },
};

const StudentFixedHeader = ({
  pageTitle,
  containIcon,
  Icon,
}:Props) => {
  return (
    <FixedHeader sx={{ ...filterSectionStyles.header }}>
      <Typography variant="pageTitle">
        {containIcon && <Icon />}
        {' '}
        {pageTitle}
      </Typography>
    </FixedHeader>
  );
};

export default StudentFixedHeader;
