import React from 'react';
import { Box, Button, Grid, TextField, Checkbox, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import useClassForm from './class-form-hook';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Loader from '../../../components/Loader/loader';
import ClassesIcon from '../../../components/Icons/ClassesIcon';
import GradesGrid from '../GradesGrid';
import { NON_SPACE_REGEX, PROVIDER_TYPE } from '../../../variables/constant';
import {
  StyledDistrictFilter, StyledGrid,
  StyledSchoolFilter,
  StyledSecondaryTeacherFilter,
  StyledStatesFilter,
  StyledTeacherFilter,
} from './class-form-styled';
import { palette  } from '../../../theme/palette';
import { SectionCategory } from '../../../generated/graphql';

const ClassForm = () => {
  const {
    loading,
    errors,
    handleSubmit,
    control,
    register,
    createSchool,
    checked,
    handleChange,
    getValues,
    preDefinedGrades,
    onStateChange,
    onDistrictChange,
    onSchoolChange,
    selectedStateId,
    selectedDistrictId,
    selectedSchoolId,
    source,
    classCategory,
  } = useClassForm();

  const classID = getValues('id');
  const formSchoolID = getValues('school_id');

  const breadcrumb = [{
    label: 'Classes',
    route: '/classes',
  }, {
    label: classID ? 'Update' : 'Create Class',
  }];

  const checkBoxLabel = "I'll be adding another class after this one";
  const classNameHelperText = 'Please enter the class name.';
  const classIDHelperText = 'Please enter a valid class ID.';
  const districtHelperText = 'Please select a district.';
  const schoolHelperText = 'Please select a school.';
  const teacherHelperText = 'Please select a teacher';
  const gradeHelperText = 'Please select a grade.';
  const gradeSchoolHelperText = 'Please add grades in selected school.';
  const gradeMissingSchoolHelperText = 'Please select a school.';
  const periodHelperText = 'Please select a period.';
  const formTitle = classID ? 'Update a class' : 'Add a new class';
  const secondaryHelperText = 'Please select secondary teacher different from primary';

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
      >
        <Box>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems='baseline'
            sx={{ mt : -1 }}
          >
            <ClassesIcon
              pathfill={palette.customBlue.primaryBlue}
              sx={{
                height: '30px',
                width: '30px',
                mr: 2,
              }}
            />
            <Typography variant="pageTitle"  sx={{ mb: 1 }}>{formTitle}</Typography>
          </Box>
        </Box>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createSchool)}
          noValidate
        >
          <StyledGrid
            xs={8}
            lg={5}
            container
            direction='column'
          >
            <Grid item>
              <Controller
                name="state_id"
                render={({ onChange, value }) => (
                  <StyledStatesFilter
                    value={value}
                    onChange={(state) => {
                      onChange(state);
                      onStateChange();
                    }}
                    placeholder="Select a state"
                    idSelectOnly
                    disabled={Boolean(classID)}
                  />
                )}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.state_id && errors.state_id.message}
              </Typography>
            </Grid>
            <Grid item>
              <Controller
                name="district_id"
                render={({ onChange, value }) => (
                  <StyledDistrictFilter
                    value={value}
                    onChange={(districtId, district) => {
                      onChange(districtId);
                      onDistrictChange(district);
                    }}
                    placeholder="Select a district"
                    idSelectOnly
                    stateIds={selectedStateId as string}
                    disabled={Boolean(classID)}
                    source={[PROVIDER_TYPE.FLASHLIGHT, PROVIDER_TYPE.CLEVER, PROVIDER_TYPE.ROSTERSTREAM]}
                  />
                )}
                rules={{ required: districtHelperText }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.district_id && errors.district_id.message}
              </Typography>
            </Grid>
            <Grid item sx={{ mb: 2 }}>
              <Controller
                name="school_id"
                render={({ onChange, value }) => (
                  <StyledSchoolFilter
                    value={value}
                    onChange={(schoolID, school) => {
                      onChange(schoolID);
                      onSchoolChange(school);
                    }}
                    placeholder="Select a school"
                    idSelectOnly
                    stateId={selectedStateId as string}
                    districtId={selectedDistrictId as string}
                    disabled={Boolean(classID)}
                  />
                )}
                rules={{ required: schoolHelperText }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.school_id && errors.school_id.message}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='sFormTitle'>Primary Teacher</Typography>
              <Controller
                name="teacher_id"
                render={({ onChange, value }) => (
                  <StyledTeacherFilter
                    value={value}
                    onChange={onChange}
                    placeholder="Select a teacher"
                    idSelectOnly
                    stateId={selectedStateId as string}
                    districtId={selectedDistrictId as string}
                    schoolId={selectedSchoolId as string}
                    disabled={(Boolean(classID) && (source !== PROVIDER_TYPE.FLASHLIGHT && classCategory !== SectionCategory.CustomClass)) || !Boolean(formSchoolID)} // Primary teacher can be edited for flashlight & custom Classes.
                  />
                )}
                rules={{ required: teacherHelperText }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.teacher_id && errors.teacher_id.message}
              </Typography>
            </Grid>
            <StyledGrid item>
              <Controller
                name="secondary_teacher_ids"
                render={({ onChange, value }) => (
                  <StyledSecondaryTeacherFilter
                    value={value}
                    onChange={onChange}
                    stateId={selectedStateId as string}
                    districtId={selectedDistrictId as string}
                    schoolId={selectedSchoolId as string}
                    disabled={!Boolean(formSchoolID)}
                  />
                )}
                rules={{
                  validate: () => !getValues('secondary_teacher_ids').includes(getValues('teacher_id')),
                }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.secondary_teacher_ids && errors.secondary_teacher_ids.type === 'validate' && secondaryHelperText }
              </Typography>
            </StyledGrid>
            <StyledGrid item>
              <Typography variant='sFormTitle' component='p'>Class Name</Typography>
              <TextField
                id="class-name"
                aria-describedby="class-name"
                name="name"
                sx={{
                  '& .MuiInputBase-input': {
                    background: 'none',
                  },
                }}
                error={!!errors.name}
                fullWidth
                helperText={errors.name && classNameHelperText}
                disabled={Boolean(classID) && source !== PROVIDER_TYPE.FLASHLIGHT && classCategory !== SectionCategory.CustomClass }
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
              />
            </StyledGrid>
            <StyledGrid item>
              <Typography variant='sFormTitle' component='p'>Class ID</Typography>
              <TextField
                id="class-id"
                aria-describedby="class-id"
                name="section_number"
                sx={{
                  '& .MuiInputBase-input': {
                    background: 'none',
                  },
                }}
                error={!!errors.section_number}
                fullWidth
                helperText={errors.section_number && classIDHelperText}
                disabled={Boolean(classID) && source !== PROVIDER_TYPE.FLASHLIGHT && classCategory !== SectionCategory.CustomClass}
                inputRef={register({
                  required: false,
                  pattern: NON_SPACE_REGEX,
                })}
              />
            </StyledGrid>
            <StyledGrid item>
              <Typography variant='sFormTitle' component='p'>Period</Typography>
              <TextField
                id="period"
                aria-describedby="period"
                name="period"
                sx={{
                  '& .MuiInputBase-input': {
                    background: 'none',
                  },
                }}
                fullWidth
                helperText={errors.period && periodHelperText}
                disabled={Boolean(classID) && source !== PROVIDER_TYPE.FLASHLIGHT && classCategory !== SectionCategory.CustomClass}
                inputRef={register({
                  required: false,
                })}
                type="number"
              />
            </StyledGrid>
            <StyledGrid item>
              <Typography variant='sFormTitle' component='p'>Grade</Typography>
              {preDefinedGrades && preDefinedGrades?.length > 0 ? (
                <Controller
                  name="grade"
                  render={(
                    { onChange, value },
                  ) => (
                    <GradesGrid
                      onChange={onChange}
                      selected={value}
                      preDefinedGrades={preDefinedGrades}
                      isLoading={loading}
                      disabled={Boolean(classID) && source !== PROVIDER_TYPE.FLASHLIGHT && classCategory !== SectionCategory.CustomClass}
                    />
                  )}
                  rules={{ required: gradeHelperText }}
                  control={control}
                  defaultValue=""
                />
              ) : (
                <Typography variant='sLabel' component='p'>
                  {selectedSchoolId ? gradeSchoolHelperText : gradeMissingSchoolHelperText}
                </Typography>
              )}
              <Typography variant='sFormHelper' component='p'>
                { errors.grade && errors.grade.message}
              </Typography>
            </StyledGrid>

          </StyledGrid>
          {
            !classID && (
              <Box display="flex" justifyContent="flex-start" sx={{ mt: 4, ml: -1.5 }}>
                <Checkbox
                  id="reopen-create-class-form"
                  aria-describedby="reopen-create-class-form"
                  name="reopen_create_checkbox"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  inputRef={register({
                    required: false,
                  })}
                />
                <Typography variant='sLabel' sx={{ pt: 1 }}>{checkBoxLabel}</Typography>
              </Box>
            )
          }
          <Box
            display="grid"
            justifyContent="flex-start"
          >
            <Button
              sx={{
                width: 200,
                fontSize: 18,
                lineHeight: '24px',
                fontWeight: 800,
                marginTop: '32px',
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              { classID ? 'Update' : 'Add Class' }
            </Button>
          </Box>
        </form>
      </Box>
      <Loader open={loading} />
    </>
  );
};

export default React.memo(ClassForm);
