import { useMemo } from 'react';
import { usePredefinedGradesListQuery } from '../../generated/graphql';
import { Direction, sortData } from '../../utils/sort';

const useGradesFilter = () => {
  const { data, loading } = usePredefinedGradesListQuery({
    fetchPolicy: 'network-only',
  });

  const grades = useMemo(() => data?.allPredefinedGrades?.sort(sortData({ direction: Direction.Up, name: 'position' })), [data]);

  return {
    loading,
    grades,
  };
};

export default useGradesFilter;
