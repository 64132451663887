import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Student from './Student';
import StudentsTable from './StudentsTable';
import Layout from '../../components/Layout';

const Students = () => (
  <Layout>
    <Switch>
      <Route
        path="/students"
        component={StudentsTable}
        exact
      />
      <Route
        path="/students/:id/view/:section"
        component={Student}
        exact
      />
    </Switch>
  </Layout>
);

export default React.memo(Students);
