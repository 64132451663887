import { useState } from 'react';
import {
  useGetDistrictsToSyncQuery,
  useSyncImportedDistrictsMutation,
} from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import getErrorMessage from '../../../utils/getErrorMessage';
import useSyncJobList from '../DistrictSyncTable/synclog-list-hook';
import { StringParam, useQueryParam } from 'use-query-params';

export type DistrictDetails = {
  id: string,
  name: string,
};

const useSyncDistricts = () => {
  const [districtId, setDistrict] = useQueryParam('districtId', StringParam);
  const { loading: listLoading, fetchSyncList } = useSyncJobList();
  const [selectedDistricts, setSelectedDistricts] = useState<DistrictDetails[]>([]);
  const { data, loading } = useGetDistrictsToSyncQuery({
    fetchPolicy: 'network-only',
  });

  const [syncImportedDistricts, { loading: syncLoading }] = useSyncImportedDistrictsMutation();

  const runSyncForSelectedDistricts = async () => {
    const districtsList = getSelectedDistricts()
    const districtIds = districtsList.map((district:DistrictDetails) => district?.id);
    try {
      await syncImportedDistricts({
        fetchPolicy: 'network-only',
        variables: {
          districts: districtIds,
        },
      });
      selectDistricts([]);
      await fetchSyncList();
      openSnackbar({ message: 'Sync started. Please refresh page till status shows Success/Failed' }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const selectDistricts = (districtList: DistrictDetails[]) => {
    if(districtList) {
      setDistrict(undefined)
    }
    setSelectedDistricts(districtList);
  }

  const getSelectedDistricts = () => {
    const districts = districtId ? data?.syncDistrictsList?.filter((district) => district.id === districtId) as DistrictDetails[] : [];
    const districtsList = districts ? [...districts, ...selectedDistricts] : [...selectedDistricts]
    return districtsList;
  }

  return {
    runSyncForSelectedDistricts,
    syncDistrictList: data?.syncDistrictsList,
    loading: syncLoading || loading || listLoading,
    selectedDistricts: getSelectedDistricts(),
    selectDistricts
  };
};

export default useSyncDistricts;
