import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from 'recharts';
import CustomTooltip from './CustomTooltip';
import AppTooltip from '../../AppTooltip';
import StyledCard from '../../StyledCard';
import { palette } from '../../../theme/palette';
import GoalAchievementLoader from '../../Loader/GoalAchievementLoader';
import { dateFormatter, getTicks, getColor, formatSessionDate } from '../CommonFunctions';
import { AxisDomain } from '../../../variables/types';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import NoResults from '../../NoResults';
import { AdminDashboards } from '../../../variables/constant';
import EmptyResults from '../../Icons/EmptyResults';


interface Props {
  loading: boolean,
  title?: string,
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  enableTooltip?: boolean, // to enable tooltip for this component.
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  data?: any,
  sessionStartDate: Date,
  sessionEndDate: Date,
  maxScore: number | null | undefined,
  hasData?: boolean,
}

const GoalAchievementGraphWidget = ({ loading, title, tooltipTitle, enableTooltip, alignTitle, data, sessionEndDate, sessionStartDate, maxScore, hasData }: Props) => {

  const ticks = getTicks(sessionStartDate, sessionEndDate);
  const dataLoaded = !loading && data?.length > 0;
  const getDateData = (dataKeys: { timestamp: number }) =>{
    let keys = dataKeys.timestamp * 1000;
    return keys;
  };

  return (
    <StyledCard
      title={title}
      isToolTip={enableTooltip}
      columnSetting
      titleAlignment={alignTitle ? 'left' : 'center'}
      toolTipComponent={<AppTooltip toolTipText={tooltipTitle}/>}
    >
      <Box display='flex' width='100%' mb={4}>
        <Typography
          variant='secondaryLabel'
          sx={{ lineHeight: '31px', color: palette.customBlack.dimmedBlack } as SxProps}
        >
          {sessionStartDate && !loading && formatSessionDate(sessionStartDate)} - {sessionEndDate && !loading && formatSessionDate(sessionEndDate)}
        </Typography>
      </Box>
      {dataLoaded && (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            margin={{
              top: 5,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false}/>
            <XAxis
              dataKey={getDateData}
              tickFormatter={dateFormatter}
              type="number"
              scale='time'
              interval={0}
              domain={[(dataMin: AxisDomain) => dataMin, () => sessionEndDate.getTime()]}
              ticks={ticks}
              axisLine={false}
              tickLine={false}
              allowDuplicatedCategory={false}
              dy={5}
              dx={30}
              style={{ fontSize: '14px', fontWeight: 600, color: palette.customBlack.fontBlack, textTransform: 'uppercase' }}
            />
            <YAxis
              tickCount={6}
              axisLine={false}
              tickLine={false}
              domain={[0, maxScore]}

            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ strokeDasharray: '3 3', stroke: 'blue' }}
              wrapperStyle={{ outline: 'none' }}
              position={{ y:-50 }}
            />
            {data?.map((item:any)=>{
              return (
                <Line
                  dataKey='value'
                  data={item.data}
                  stroke={getColor(item?.label)}
                  key={item?.label}
                  name={item?.label}
                  strokeWidth={2}
                  activeDot={{ r: 6 }}
                />
              );
            })}
            <Legend
              iconSize={25}
              wrapperStyle={{
                paddingTop: '30px',
              }}
              formatter={(value) => <span style={{ color: palette.customBlack.main, paddingRight: '10px' }}>{value}</span>}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
      {!loading && !hasData && (
        <NoResults
          description={AdminDashboards.learningGoals.GoalAchievementWidget.insufficientData.description}
          svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
        />
      )}
      {loading && (<GoalAchievementLoader />)}
    </StyledCard>
  );
};

export default GoalAchievementGraphWidget;
