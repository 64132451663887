import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import Grade from './Grade';

const TeacherBenchmarks = () => (
  <Switch>
    <Route
      path="/teacher/benchmarks"
      component={List}
      exact
    />
    <Route
      path="/teacher/benchmarks/grade"
      component={Grade}
      exact
    />
  </Switch>
);

export default TeacherBenchmarks;
