import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import useSchools from './schools-hook';
import List from './List';
import StateFilter from '../../../components/StateFilter';
import DistrictFilter from '../../../components/DistrictFilter';
import RecordCount from '../../../components/RecordCount';
import { SchoolDetails } from '../../../generated/graphql';
import useRole from '../../../hooks/useRole';
import { Link } from 'react-router-dom';

const Schools = () => {
  const {
    schools,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    districtPermission,
  } = useSchools();
  const { isSuperAdmin } = useRole();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="pageTitle">
            Schools
          </Typography>
        </Box>
        <Box>
          {isSuperAdmin && (
            <Button
              variant="outlined"
              color="primary"
              disableRipple={true}
              component={Link}
              to="/admin/create/school"
            >
            Add School
            </Button>
          )}
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={districtPermission ? 'repeat(2, 15%) 1fr 200px' : '1fr 200px'}
        sx={{
          gridGap: 24,
        }}
        mt={2}
      >
        {districtPermission && (
          <>
            <StateFilter
              value={selectedState}
              onChange={onStateChange}
              idSelectOnly
              placeholder="All states"
            />
            <DistrictFilter
              value={selectedDistrict}
              onChange={onDistrictChange}
              stateIds={selectedState ?? undefined}
              idSelectOnly
              placeholder="All districts"
            />
          </>
        )}
        <Box />
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {schools && (
            <RecordCount
              pageNumber={pagination.page}
              recordLength={schools?.length}
              totalRecords={totalCount}
            />
          )}
        </Box>
      </Box>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading}
          totalCount={totalCount}
          order={order}
          pagination={pagination}
          sort={sort}
          schools={schools as SchoolDetails[]}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
        />
      </Box>
    </Box>
  );
};

export default React.memo(Schools);
