import React from 'react';
import { format } from 'date-fns';
import { palette } from '../../../theme/palette';
import { Box, Typography } from '@mui/material';

interface Props {
  active?: string,
  payload?: any
}

const styles = {
  backgroundColor: palette.customWhite.main,
  marginLeft: '-30px',
};

const CustomTooltip = ({ active, payload }:Props) => {
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <Box className="custom-tooltip" style={styles}>
        <Typography component='p' sx={{ textAlign: 'center', fontSize:'14px', fontWeight: 700, color: palette.customBlack.fontBlack }}>
          {currData ? currData.avgScore : ' -- '}
        </Typography>
        <Typography component='p' sx={{ textAlign: 'center', fontSize:'12px', color: palette.customBlack.fontBlack }}>
          {currData ? format(new Date(currData.date), 'M/d/yy') : ' -- '}
        </Typography>
      </Box>
    );
  }
  return null;
};

export default CustomTooltip;
