import { createContext } from 'react';
import {
  BenchmarkSubmissionScoreFragmentFragment,
  GetGoalsQuery, GetPredefinedGoalsQuery,
  GoalStatus, NewSubmissionScoreFragmentFragment,
  Rubric,
} from '../../generated/graphql';
import { RadioGroupProps } from '../../components/Radio';
import { SelectiveButtonGroupProps } from '../../components/SelectiveButtons';

export interface ScoreContextValue {
  rubrics?: Rubric[],
  submission?: NewSubmissionScoreFragmentFragment | BenchmarkSubmissionScoreFragmentFragment | null,
  expanded?: boolean,
  setExpanded?: Function,
  handleScoreUpdate?: (gradeId: string, scoreDetailId: string) => void,
  handleGoalStatus?: (goalId: string, status: GoalStatus) => void,
  updating?: boolean,
  activeGoals?: GetGoalsQuery,
  fetchingGoals?: boolean,
  updatingStatus?: boolean,
  addStudentGoal?: Function,
  adding?: boolean,
  refetch?: Function,
  submissionsRefetch?: Function,
  predefinedGoalsData?: GetPredefinedGoalsQuery,
  predefinedLoading?: boolean,
  predefinedRefetch?: Function,
  scoreSummaryOptions: RadioGroupProps[]
  submissionGoalInfoRefetch?: Function,
  speakingGoalCount: number,
  writingGoalCount: number,
  submissionGoalInfoLoading?: boolean,
  selectiveScoreSummaryOptions?: SelectiveButtonGroupProps[] | undefined,
  handleAnchorToggleUpdate?: Function,
  anchorEnabled?: boolean,
  updateToggle?: boolean,
  anchorRefetch?: Function,
  isAnchorPlaying?: boolean,
  setIsAnchorPlaying?: Function,
}

export const ScoreContext = createContext<ScoreContextValue>({
  rubrics: [],
  submission: null,
  updating: false,
  updatingStatus: false,
  scoreSummaryOptions: [],
  speakingGoalCount: 0,
  writingGoalCount: 0,
  selectiveScoreSummaryOptions: [],
  isAnchorPlaying: false,
});
