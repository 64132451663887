import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import LandingPage from './LandingPage';
import UploadImages from './UploadImages/UploadImages';
import ManageImages from './ManageImages/ManageImages';

const Settings = () => (
  <Layout>
    <Switch>
      <Route
        path="/settings"
        component={LandingPage}
        exact
      />
      <Route
        path="/settings/upload-images"
        component={UploadImages}
        exact
      />
      <Route
        path="/settings/manage-images/:id"
        component={ManageImages}
        exact
      />
    </Switch>
  </Layout>
);

export default Settings;
