import React from 'react';
import { Route, Switch } from 'react-router-dom';
import School from './School';
import SchoolsTable from './SchoolsTable';
import Layout from '../../components/Layout';

const Schools = () => (
  <Layout>
    <Switch>
      <Route
        path="/schools"
        component={SchoolsTable}
        exact
      />
      <Route
        path="/schools/:id"
        component={School}
        exact
      />
    </Switch>
  </Layout>
);

export default React.memo(Schools);
