import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import {
  useMarkHelpVideoSeenMutation,
} from '../../generated/graphql';
import { useAuthProvider } from '../../core/authContext';
import { deleteToken } from '../../utils/auth';
import { openSnackbar } from '../Notifier';
import { NotifierType } from '../../variables/types';
import useLocalStorage from '../../hooks/useLocalStorage';

const useNavbar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showVideo, setShowVideo] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const history = useHistory();
  const { getUser, setUser } = useAuthProvider();
  const user = getUser();
  const showHelpVideo = user?.showHelpVideo;

  const [markSeen, { loading }] = useMarkHelpVideoSeenMutation();

  const [district, setDistrict] = useLocalStorage('school-district');

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    try {
      deleteToken();
      await Auth.signOut();
      setDistrict(district);
      history.push('/login');
    } catch (error: any) {
      openSnackbar({ message: error.message }, NotifierType.Error);
    }
  };

  const showVideoModal = () => {
    setShowVideo(true);
  };

  const hideVideoModal = async () => {
    if (user.showHelpVideo) {
      await markVideoSeen();
    }
    setShowVideo(false);
  };

  const markVideoSeen = async () => {
    try {
      const response = await markSeen();
      if (response?.data?.markHelpVideoSeen) {
        setUser(response?.data?.markHelpVideoSeen);
      }
    } catch (error) {
      // As this is a non-blocking, non-cognizant event for the user, we are not showing any toast here
      console.log('Error in updating video seen status');
    }
  };

  useEffect(() => {
    if (showHelpVideo) {
      setShowVideo(true);
    }
  }, [showHelpVideo]);

  return {
    anchorEl,
    user,
    loading,
    isMenuOpen,
    handleProfileMenuOpen,
    handleMenuClose,
    signOut,
    showVideoModal,
    hideVideoModal,
    showVideo,
  };
};

export default useNavbar;
