import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { palette } from '../../../theme/palette';

interface Props {
  title: string,
  primaryBtnLabel?: string,
  PrimaryBtnIcon?: any,
  primaryBtnRedirectionLink?: string
  secondaryBtnLabel?: string,
  SecondaryBtnIcon?: any,
  secondaryBtnRedirectionLink?: string,
  secondaryBtnOnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  addNewEntity?: React.ReactNode,
  syncNowRedirectionLink?: string,
  syncEnabled?: Boolean | null
}

const styles = {
  icon: {
    marginRight: '8px',
    fill: palette.customBlue.primaryBlue,
    '& path': {
      fill: palette.customBlue.primaryBlue,
    },
  },
  button: {
    fontWeight: 800,
    width: '100%',
  },
};


const ResourceDetailHeader = ({
  title,
  primaryBtnLabel,
  PrimaryBtnIcon,
  primaryBtnRedirectionLink,
  secondaryBtnLabel,
  SecondaryBtnIcon,
  secondaryBtnRedirectionLink,
  secondaryBtnOnClick,
  addNewEntity,
  syncNowRedirectionLink,
  syncEnabled = false
}: Props) => {

  const getActionElementsCount = () => {
    const actionElements = [primaryBtnLabel, secondaryBtnLabel, addNewEntity, syncNowRedirectionLink];
    return actionElements.filter(Boolean).length;
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={`1fr repeat(${getActionElementsCount() || 0}, 250px)`}
      sx={{
        gridGap: 32,
      }}
      alignItems="center"
    >
      <Box sx={{ display: "flex", width: "600px", maxWidth: "100%" }}>
        <Typography variant="pageTitle" sx={{ wordBreak: 'break-word', }}>
        {title}
      </Typography>
      </Box>
      {syncNowRedirectionLink && (
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          sx={{ ...styles.button, py: 1.75 }}
          to={syncNowRedirectionLink}
          disabled={Boolean(syncEnabled)}
        >
         Sync Now
        </Button>
      )}
      {secondaryBtnLabel && (secondaryBtnOnClick ? (
        <Button
          variant="outlined"
          color="primary"
          sx={{ ...styles.button }}
          onClick={secondaryBtnOnClick || (() => {})}
          startIcon={SecondaryBtnIcon ? <SecondaryBtnIcon  sx={{ ...styles.icon }} /> : undefined}
        >
          {secondaryBtnLabel}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          sx={{ ...styles.button }}
          to={secondaryBtnRedirectionLink ?? ''}
          startIcon={SecondaryBtnIcon ? <SecondaryBtnIcon sx={{ ...styles.icon }} /> : undefined}
        >
          {secondaryBtnLabel}
        </Button>
      ))}
      {addNewEntity && <>{addNewEntity}</> }
      {primaryBtnLabel && PrimaryBtnIcon && primaryBtnRedirectionLink && (
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          sx={{ ...styles.button, py: 1.75 }}
          to={primaryBtnRedirectionLink}
          startIcon={<PrimaryBtnIcon sx={{ ...styles.icon }} />}
        >
          {primaryBtnLabel}
        </Button>
      )}
    </Box>
  );
};

export default React.memo(ResourceDetailHeader);
