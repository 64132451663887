import { useSectionsFilterQuery } from '../../generated/graphql';

interface Props {
  studentId?: string
  hideGroups?: boolean;
  schoolId?: string | null;
}

const useSectionsFilter = ({ studentId, hideGroups, schoolId }: Props) => {
  const { data, loading } = useSectionsFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(studentId ? { studentID: studentId as string } : {}),
      ...(hideGroups ? { hideGroups: true } : {}),
      ...(schoolId ? { schoolID: schoolId } : {}),
    },
  });

  return {
    sections: data?.sections ?? [],
    loading,
  };
};

export default useSectionsFilter;
