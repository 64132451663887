import { StringParam, useQueryParam } from 'use-query-params';
import React, { useContext } from 'react';
import { PAGE_SIZE } from '../../../variables/constant';
import {
  DistrictFilterFragmentFragment,
  OrderBy,
  SchoolFilterFragmentFragment, SectionCategory,
  StateFilterFragmentFragment,
  TeacherFilterFragmentFragment,
  UserPermissions,
  useSectionsListQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { ClassesSort } from '../../../variables/types';
import usePermission from '../../../hooks/usePermission';
import { SearchContext } from '../../../core/searchContext';

interface Props {
  classTypes?: SectionCategory[],
}

const useClasses = ({ classTypes }: Props) => {
  const pagination = usePagination();
  const { searchTerm } = useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [order, setOrder] = useQueryParam('order', StringParam);
  const [selectedState, setStateValue] = useQueryParam('state', StringParam);
  const [selectedDistrict, setSelectedDistrict] = useQueryParam('district', StringParam);
  const [selectedTeacher, setSelectedTeacher] = useQueryParam('teacher', StringParam);
  const [selectedSchool, setSelectedSchool] = useQueryParam('school', StringParam);
  const { hasPermission: districtPermission } = usePermission([
    UserPermissions.ListDistricts,
    UserPermissions.ViewDistricts,
  ]);
  const { hasPermission: schoolPermission } = usePermission([
    UserPermissions.ListSchools,
    UserPermissions.ViewSchools,
  ]);
  const { data, loading } = useSectionsListQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort,
      searchByName: searchTerm,
      orderBy: order as OrderBy,
      ...(selectedState ? { stateId: selectedState } : {}),
      ...(selectedDistrict ? { districtId: selectedDistrict } : {}),
      ...(selectedSchool ? { schoolId: selectedSchool } : {}),
      ...(selectedTeacher ? { teacherId: selectedTeacher } : {}),
      ...(classTypes ? { classTypes } : {}),
    },
  });

  const onSortChange = (sortBy: ClassesSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const classes = data?.sectionsList.nodes ?? [];
  const totalCount = data?.sectionsList.totalCount ?? 0;

  const isTableEmpty = !loading && classes?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: ClassesSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onStateChange = (state: string | StateFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setStateValue(state as string);
    setSelectedDistrict(null);
    setSelectedTeacher(null);
    setSelectedSchool(null);
  };

  const onDistrictChange = (district: string | DistrictFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedDistrict(district as string);
    setSelectedTeacher(null);
    setSelectedSchool(null);
  };

  const onSchoolChange = (school: string | SchoolFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedSchool(school as string);
    setSelectedTeacher(null);
  };

  const onTeacherChange = (teacher: string | TeacherFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedTeacher(teacher as string);
  };

  return {
    loading,
    totalCount,
    classes,
    pagination,
    onSortChange,
    sort: sort as ClassesSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    selectedTeacher,
    onTeacherChange,
    selectedSchool,
    onSchoolChange,
    districtPermission,
    schoolPermission,
  };
};

export default useClasses;
