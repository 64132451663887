import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  TableCell,
  CircularProgress, Typography, TableHead
} from '@mui/material';
import {
  StyledTableCellFlyover,
  StyledTableContainerFlyover, tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import useBenchmarkGradesInfiniteList from '../../Grade/grades-list-infinite-hook';
import {SubmissionStatus, TeacherBenchmarkSubmissionListSortType} from '../../../../generated/graphql';
import NoResults from "../../../../components/NoResults";
import WavingLadyFrame from "../../../../components/Icons/WavingLadyFrame";
import ProgressBar from "../../../../components/ProgressBar";

interface Props {
  benchmarkId: string
}

const InProgressList = (props: Props) => {
  const {
    page,
    totalCount,
    benchmarkSubmissionList,
    loading,
    handlePagination,
  } = useBenchmarkGradesInfiniteList({
    benchmarkId: props.benchmarkId,
    sort: TeacherBenchmarkSubmissionListSortType.StudentTitle,
    gradeStatuses: [
      SubmissionStatus.Started,
      SubmissionStatus.AnnotationCompleted,
      SubmissionStatus.SpeakingCompleted,
    ]
  });

  const isTableEmpty = !loading && benchmarkSubmissionList?.length === 0;

  const progressMilestoneList = [
    'Labeling',
    'Speaking',
    'Writing',
  ];

  const getProgress = (status: SubmissionStatus) => {
    switch (status) {
      case SubmissionStatus.Started:
        return 0;
      case SubmissionStatus.AnnotationCompleted:
        return 1;
      case SubmissionStatus.SpeakingCompleted:
        return 2;
      case SubmissionStatus.Submitted:
        return 2;
      default:
        return 0;
    }
  };

  return (
      <Box>
        <StyledTableContainerFlyover>
          <InfiniteScroll
            height={'calc(100vh - 83px)'}
            next={() => handlePagination(page + 1)}
            hasMore={totalCount > benchmarkSubmissionList?.length}
            dataLength={totalCount}
            loader={null}
          >
          <Table
            stickyHeader
            aria-label="students submissions table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            {(!loading && !isTableEmpty) && (
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align='right'>
                    <Box display="grid"
                         gridTemplateColumns="repeat(3, 1fr)"
                         gap='10px'
                         alignItems="center">
                      {progressMilestoneList.map((label) => (
                        <Typography key={label} variant='secondaryLabel' fontWeight='bold'>
                          {label}
                        </Typography>
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {benchmarkSubmissionList && benchmarkSubmissionList?.map((student) => (
                <TableRow key={student.id}>
                  <StyledTableCellFlyover>
                    <Typography variant='tableTitleData'>
                      {student?.user_first_name!} {student?.user_last_name!}
                    </Typography>
                  </StyledTableCellFlyover>
                  <StyledTableCellFlyover sx={{textAlign: 'right'}}>
                    <ProgressBar steps={progressMilestoneList} hiddenStepLabel progressValue={getProgress(student.status as SubmissionStatus)} />
                  </StyledTableCellFlyover>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="No Students"
                      description="No students at this step yet"
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </InfiniteScroll>
        </StyledTableContainerFlyover>
      </Box>
  );
};

export default InProgressList;
