import React from 'react';
import { Box, Typography } from '@mui/material';
import useDistrict from './district-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import StateIcon from '../../../components/Icons/StateIcon';
import ResourceDetailHeader from './resource-detail-header';
import ResourceDetail from './resource-detail';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import ClassesIcon from '../../../components/Icons/ClassesIcon';
import TeachersIcon from '../../../components/Icons/TeachersIcon';
import { StudentsIcon } from '../../../components/Icons';
import { MY_SCHOOL, PROVIDER_TYPE } from '../../../variables/constant';
import { Entity } from '../../../variables/types';
import { getMountainTimeZone } from '../../../utils/timezone';
import AddNewEntity from '../../../components/AddNewEntity';
import useSyncJobList from '../../DistrictSync/DistrictSyncTable/synclog-list-hook';

const District = () => {
  const {
    district,
    loading,
    editDistrictPermission,
    listSchoolsPermission,
    mySchoolPermission } = useDistrict();
  const { syncEnabled } = useSyncJobList();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <ResourceDetailHeader
        title={district?.name ?? ''}
        addNewEntity={editDistrictPermission ? (
          <AddNewEntity
            entity={district?.source !== PROVIDER_TYPE.FLASHLIGHT ? Entity.CleverDistrict : Entity.District}
            districtId={district?.id}
            stateId={district?.state?.id}
          />
        ) : undefined
        }
        primaryBtnRedirectionLink={`/admin/create/district/${district?.id}`}
        syncNowRedirectionLink={district?.source !== PROVIDER_TYPE.FLASHLIGHT ? `/syncDistricts?districtId=${district?.id}` : undefined}
        syncEnabled={district?.is_disabled || syncEnabled}
        PrimaryBtnIcon={PencilIcon}
        primaryBtnLabel={editDistrictPermission ? 'Edit' : undefined}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          <ResourceDetail label={district?.state?.name || '-'} Icon={StateIcon} />
          <ResourceDetail label={`District ID: ${district?.district_id || '-'}`} Icon={DistrictsIcon} />
          {listSchoolsPermission && (
            <ResourceDetail
              label="View Schools"
              link={`/schools?district=${district?.id ?? ''}`}
              Icon={SchoolsIcon}
            />
          )}
          <ResourceDetail
            label="View Classes"
            link={`/classes?district=${district?.id ?? ''}`}
            Icon={ClassesIcon}
          />
          <ResourceDetail
            label="View Teachers"
            link={`/teachers?district=${district?.id ?? ''}`}
            Icon={TeachersIcon}
          />
          <ResourceDetail
            label="View Students"
            link={
              !mySchoolPermission
                ? `/admin-students?district=${district?.id ?? ''}`
                : `/admin-students?school=${MY_SCHOOL}`
            }
            Icon={StudentsIcon}
          />
          {district?.source !== PROVIDER_TYPE.FLASHLIGHT && (
            <Typography>
              Updated At:
              {' '}
              {getMountainTimeZone(district?.last_synced_at, '-')}
            </Typography>
          )}
        </StyledBoxWrapper>
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(District);
