import React from 'react';
import { Box, Button, Grid, TextField, Checkbox, MenuItem, Typography, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Loader from '../../../components/Loader/loader';
import TeacherIcon from '../../../components/Icons/TeachersIcon';
import { errorMessages, labels, titles } from './constants';
import useTeacherForm from './teacher-form-hook';
import { EMAIL_REGEX, NON_SPACE_REGEX, PROVIDER_TYPE } from '../../../variables/constant';
import { StyledFlexBox } from '../../../components/Common/Common.styled';
import { StyledDistrictFilter, StyledStatesFilter } from '../../Admin/admin-tables-styled';
import { palette } from '../../../theme/palette';
import SchoolFilterWithMultiSelect from '../../../components/SchoolFilterWithMultiSelect';
import ClassesGrouped from '../../../components/ClassesGrouped';
import GroupedClassesPrimary from '../../../components/ClassesGrouped/GroupedClassesPrimary';
import { SectionCategory } from '../../../generated/graphql';
import { CreateAssignmentStylesCss } from '../../Assignments/CreateAssignment/CreateAssignment.styled';

const formStyles = {
  titleInput: {
    '& .MuiInputBase-input': {
      background: 'none',
    },
  },
  title: {
    '& .MuiSelect-icon': {
      height: '1em',
      width: '1em',
      top: 'auto',
    },
  },
  width120: {
    width: 120,
  },
  width200: {
    width: 200,
  },
  width298: {
    width: 298,
  },
  addUpdateButton: {
    width: 200,
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 800,
    marginTop: '32px',
  },
};

const TeacherForm = () => {
  const {
    loading,
    errors,
    handleSubmit,
    control,
    register,
    createTeacher,
    checked,
    handleChange,
    getValues,
    onStateChange,
    onDistrictChange,
    selectedStateId,
    selectedDistrictId,
    selectedSchoolIds,
    primarySchools,
    validateSecondaryOnEdit,
    onSchoolChange,
  } = useTeacherForm();
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);
  const teacherID = getValues('id');
  const breadcrumb = [{
    label: 'Teachers',
    route: '/teachers',
  }, {
    label: teacherID ? 'Update' : 'Create Teacher',
  }];

  const formTitle = teacherID ? 'Update a teacher' : 'Add a new teacher';
  const secondaryHelperText = 'Please select class as secondary teacher different from primary';

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
      >
        <Box>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems='baseline'
          >
            <TeacherIcon
              pathfill={palette.customBlue.primaryBlue}
              sx={{
                height: '30px',
                width: '30px',
                mr: '16px',
              }}
            />
            <Typography variant="pageTitle" mb={1}>{formTitle}</Typography>
          </Box>
        </Box>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createTeacher)}
          noValidate
        >
          <Grid xs={8} lg={5} item>
            <Controller
              name="state_id"
              render={({ onChange, value }) => (
                <StyledStatesFilter
                  value={value}
                  onChange={(state) => {
                    onChange(state);
                    onStateChange();
                  }}
                  placeholder="Select a state"
                  idSelectOnly
                  disabled={(Boolean(primarySchools.length) && Boolean(teacherID))}
                />
              )}
              control={control}
              defaultValue={null}
            />
            <Typography variant='sFormHelper' component='p'>
              {errors.state_id && errors.state_id.message}
            </Typography>
            <Controller
              name="district_id"
              render={({ onChange, value }) => (
                <StyledDistrictFilter
                  value={value}
                  onChange={(data, district) => {
                    onChange(data);
                    onDistrictChange(district?.state_id ?? undefined);
                  }}
                  placeholder="Select a district"
                  idSelectOnly
                  stateIds={selectedStateId as string}
                  disabled={(Boolean(primarySchools.length) && Boolean(teacherID))}
                  source={[PROVIDER_TYPE.FLASHLIGHT, PROVIDER_TYPE.CLEVER, PROVIDER_TYPE.ROSTERSTREAM]}
                />
              )}
              rules={{ required: errorMessages.districtHelperText }}
              control={control}
              defaultValue={null}
            />
            <Typography variant='sFormHelper' component='p'>
              {errors.district_id && errors.district_id.message}
            </Typography>
            <Box width='50%'>
              <Controller
                name="schools"
                render={({ onChange, value }) => (
                  <SchoolFilterWithMultiSelect
                    value={value}
                    onChange={(schoolList, data) => {
                      onChange(schoolList);
                      onSchoolChange(data);
                    }}
                    stateIds={[selectedStateId] as string[]}
                    districts={[selectedDistrictId] as string[]}
                    disabled={!selectedDistrictId}
                    placeholder='School'
                    primarySchools={primarySchools}
                    errorMessage={errorMessages}
                  />
                )}
                rules={{
                  required: errorMessages.schoolHelperText,
                }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.schools && errors.schools.message}
              </Typography>
            </Box>
            {
              teacherID && (
                <Box width='50%'>
                  <Controller
                    name="classes_primary"
                    render={({ onChange, value }) => (
                      <GroupedClassesPrimary
                        value={value}
                        onChange={onChange}
                        stateId={selectedStateId as string}
                        districtId={selectedDistrictId as string}
                        schoolIds={primarySchools}
                        classTypes={[SectionCategory.Class, SectionCategory.CustomClass]}
                        placeholder={'Classes(as Primary Teacher)'}
                        isPrimary={true}
                        disabled={!primarySchools.length}
                        sx={{ ...(!primarySchools.length && styles.disabled) }}
                      />
                    )}
                    control={control}
                    defaultValue={null}
                  />
                </Box>
              )
            }
            <Box width='50%'>
              <Controller
                name="classes"
                render={({ onChange, value }) => (
                  <ClassesGrouped
                    value={value}
                    onChange={onChange}
                    stateId={selectedStateId as string}
                    districtId={selectedDistrictId as string}
                    schoolIds={selectedSchoolIds as string[] | undefined | null}
                    classTypes={[SectionCategory.Class, SectionCategory.CustomClass]}
                    disabled = {!selectedSchoolIds?.length}
                  />
                )}
                rules={{
                  validate: () => validateSecondaryOnEdit(),
                }}
                control={control}
                defaultValue={null}
              />
            </Box>
            <Typography variant='sFormHelper' component='p'>
              {errors.classes && errors.classes.type === 'validate' && secondaryHelperText }
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              flexGrow={1}
            >
              <Box  mr={3}>
                <Typography variant='sFormTitle' component='p'> Title </Typography>
                <Controller
                  name="title"
                  render={({ onChange, value }) => (
                    <TextField
                      id="title"
                      select
                      aria-describedby="title"
                      name="title"
                      sx={{ ...formStyles.title, ...formStyles.titleInput, ...formStyles.width120 }}
                      value={value || titles?.[0]?.value}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                      defaultValue={titles?.[0]?.value}
                    >
                      {titles.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  control={control}
                  defaultValue={null}
                />
              </Box>
              <Box mr={3}>
                <Typography variant='sFormTitle' component='p' > First Name </Typography>
                <TextField
                  id="first-name"
                  aria-describedby="first-name"
                  name="first_name"
                  sx={{ ...formStyles.titleInput, ...formStyles.width200 }}
                  error={!!errors.first_name}
                  helperText={errors.first_name && errorMessages.firstNameHelperText}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
              <Box>
                <Typography variant='sFormTitle' component='p'> Last Name </Typography>
                <TextField
                  id="last-name"
                  aria-describedby="last-name"
                  name="last_name"
                  sx={{ ...formStyles.titleInput, ...formStyles.width200 }}
                  error={!!errors.last_name}
                  helperText={errors.last_name && errorMessages.lastNameHelperText}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
            </Box>
            <StyledFlexBox>
              <Typography variant='sFormTitle' component='p'>Teacher ID</Typography>
              <TextField
                id="teacher-id"
                aria-describedby="teacher-id"
                name="teacher_id"
                sx={{ ...formStyles.titleInput, ...formStyles.width298 }}
                error={!!errors.teacher_id}
                helperText={errors.teacher_id && errorMessages.teacherIDHelperText}
                inputRef={register({
                  required: true,
                  pattern: NON_SPACE_REGEX,
                  validate: (value) => !!value.trim(),
                })}
              />
            </StyledFlexBox>
            <StyledFlexBox>
              <Typography variant='sFormTitle' component='p'>Job Title</Typography>
              <TextField
                id="job-title"
                aria-describedby="job-title"
                name="job_title"
                sx={{ ...formStyles.titleInput, ...formStyles.width298 }}
                error={!!errors.job_title}
                helperText={errors.job_title && errorMessages.jobTitleHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
              />
            </StyledFlexBox>
            <StyledFlexBox>
              <Typography variant='sFormTitle' component='p'>Email</Typography>
              <TextField
                id="email"
                aria-describedby="email"
                name="email"
                sx={{ ...formStyles.titleInput, ...formStyles.width298 }}
                inputRef={register({
                  required: { value: true, message: errorMessages.emailHelperText },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: errorMessages.validEmailText,
                  },
                })}
              />
              {errors.email && (
                <Typography variant='sFormHelper'>
                  {errors?.email?.message}
                </Typography>
              )}
            </StyledFlexBox>
            <StyledFlexBox>
              <Typography variant='sFormTitle' component='p'>Phone (Optional)</Typography>
              <TextField
                id="phone-number"
                aria-describedby="phone-number"
                name="phone_number"
                sx={{ ...formStyles.titleInput, ...formStyles.width298 }}
                inputRef={register()}
              />
            </StyledFlexBox>
            {
              !teacherID && (
                <StyledFlexBox>
                  <Typography variant='sFormTitle' component='p'>Password</Typography>
                  <TextField
                    id="password"
                    aria-describedby="password"
                    name="password"
                    sx={{ ...formStyles.titleInput, ...formStyles.width298 }}
                    error={!!errors.password}
                    helperText={errors.password && errorMessages.passwordHelperText}
                    inputRef={register({
                      required: true,
                      validate: (value) => !!value.trim(),
                    })}
                  />
                </StyledFlexBox>
              )
            }
          </Grid>
          {
            !teacherID && (
              <Box display="flex" justifyContent="flex-start" sx={{ mt: 4, ml: -1.5 }}>
                <Checkbox
                  id="reopen-create-class-form"
                  aria-describedby="reopen-create-class-form"
                  name="reopen_create_checkbox"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  inputRef={register({
                    required: false,
                  })}
                />
                <Typography variant='sLabel' sx={{ pt: 1 }} component='p'>{labels.checkBoxLabel}</Typography>
              </Box>
            )
          }
          <Box
            display="grid"
            justifyContent="flex-start"
          >
            <Button
              sx={{ ...formStyles.addUpdateButton }}
              variant="contained"
              color="primary"
              type="submit"
            >
              { teacherID ? 'Update' : 'Add Teacher' }
            </Button>
          </Box>
        </form>
      </Box>
      <Loader open={loading} />
    </>
  );
};

export default TeacherForm;
