import React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Checkbox, CheckboxProps, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import Loader from '../Loader/loader';
import useTaskTypesFilter from './taskTypes-hook';
import { Maybe } from '../../generated/graphql';


interface Props {
  error?: boolean;
  className?: string;
  value?: Maybe<string>[] | undefined;
  onChange?: (data: Maybe<string>[] | undefined) => void;
  disabled?: boolean;
  placeholder?: string | undefined;
}

const styles = {
  border: '0px',
  minHeight: '48px',
  height: 'auto',
  maxHeight: '500px',
  '& input.MuiInputBase-input.Mui-disabled' : {
    backgroundColor: palette.customWhite.inputDisabled,
  },
};

const TaskTypes = ({
  className,
  value,
  onChange,
  disabled,
  placeholder = 'Task Type(s)',
}: Props) => {
  const { taskTypes, loading } = useTaskTypesFilter();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const onSelectChange = (_:any, taskList:any[]) => {
    onChange?.(taskList.map((task:any) => task?.id));
  };

  return (
    <Autocomplete
      multiple
      className={className}
      limitTags={3}
      disableCloseOnSelect
      options={ taskTypes }
      getOptionLabel={(option: any) => `${option?.title ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={ disabled || loading}
      onChange={onSelectChange}
      value={taskTypes?.filter((task) => value?.includes(task?.id as string))}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          return (
            <Chip
              label={<Typography sx={{ fontWeight: 800, fontSize: '13px' }}
                component='span'>{option?.title ?? ''}</Typography>}
              {...getTagProps({ index })}
              key={index}
            />
          );
        })
      }
      renderOption={(props, option: any, { selected }) => (
        <Box component="li" {...props} key={option.id}>
          <Checkbox
            color={'primary' as  CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ mr: '8px' }}
          />
          {`${option?.title ?? ''}`}
        </Box>
      )}
      style={{ width: 600, maxHeight: 500, overflowY: 'scroll' }}
      groupBy={(option:any) => option.category}
      renderGroup={(params) => (
        <Box key={params.group}>
          <Typography component='span' sx={{ fontWeight: 800, margin:1.5, paddingBottom: 1 }}>{params.group}</Typography>
          {params.children}
        </Box>
      )}
      renderInput={(params) => (
        <>
          <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>
          <TextField
            {...params}
            variant="standard"
            placeholder={params.InputProps.startAdornment ? '' : 'Select a Task Type'}
            sx={{ ...styles }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { minHeight:'50px', maxHeight: '500px', height:'auto', fontSize: '16px' },
            }}
          />
          <Loader open={loading}/>
        </>
      )}
    />
  );
};

export default TaskTypes;
