import React, { useEffect } from 'react';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Maybe, SchoolFilterFragmentFragment } from '../../generated/graphql';
import { svgStyles } from '../Common/Common.styled';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Checkbox, CheckboxProps, Chip, Divider, InputAdornment } from '@mui/material';
import { palette } from '../../theme/palette';
import useSchoolsFilters from '../SchoolFilter/school-filter-hook';
import { SxProps } from '@mui/system';
import SchoolsIcon from '../Icons/SchoolsIcon';
import Loader from '../Loader/loader';

interface Props {
  error?: boolean;
  className?: string;
  value?: string[];
  onChange?: (data: string[]) => void;
  disabled?: boolean;
  teacherIcon?: boolean;
  districts?: string[] | undefined;
  grades?: string[] | undefined;
  limitTag?: number;
}

const styles = {
  textField:{
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    '& input.MuiInputBase-input.Mui-disabled' : {
      backgroundColor: palette.customWhite.inputDisabled,
    },
  },
  box: {
    backgroundColor: palette.customWhite.main,
    height: '45px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

type SchoolValue = SchoolFilterFragmentFragment | null | undefined;

const SchoolMultiSelect = ({
  className,
  onChange,
  value,
  disabled,
  districts,
  grades = [],
  limitTag = 10,
}: Props) => {
  const {
    schoolsOnly: schools,
    loading,
  } = useSchoolsFilters({ districts, grades });

  const allSchoolsOption: SchoolFilterFragmentFragment = {
    id: 'all',
    name: ' All Schools',
    district_id: '',
  };
  const schoolOptions = schools?.length ? [allSchoolsOption].concat(schools!) : [];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [checkAll, setCheckAll] = React.useState(false);

  const onSelectChange = (schoolList:(any)[], reason: AutocompleteChangeReason) => {
    if (reason === 'clear' || reason === 'removeOption') {
      setCheckAll(false);
    } else if (reason === 'selectOption' && schoolList.length === schools?.length) {
      setCheckAll(true);
    }
    onChange?.(schoolList?.map((school:any) => school?.id));
  };

  const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      onChange?.(schools?.map((School: any) => School?.id) as string[]);
    } else {
      onChange?.([]);
    }
  };
  useEffect(() => {
    filterIdsWithSchools(value);
  }, [districts, grades, schools]);

  const filterIdsWithSchools = (selectedValue: Maybe<string>[] | undefined ) => {
    const schoolIds = schools?.map((school: any) => school.id) ?? [];
    const filteredIds = selectedValue?.filter((id) => schoolIds.includes(id)) ?? [];
    const schoolList = schools?.filter((school) => filteredIds.includes(school.id));
    if (selectedValue?.length !== filteredIds.length && schools) onChange?.(schoolList?.map((school:any) => school?.id) ?? []);
    if (selectedValue?.length === 0 || selectedValue?.length !== schools?.length) {
      setCheckAll(false);
    } else if (selectedValue?.length === schools?.length) {
      setCheckAll(true);
    }
  };

  return (
    <>
      <Loader open={loading} />
      <Autocomplete
        multiple
        limitTags={limitTag}
        className={className}
        disableCloseOnSelect
        options={schoolOptions ?? []}
        getOptionLabel={(option: SchoolValue) => `${`${option?.name ?? ''}`}`}
        isOptionEqualToValue={(option: SchoolValue, selected: any) => option?.id === selected?.id}
        disabled={loading || disabled}
        onChange={(_, schoolList, reason) => onSelectChange(schoolList, reason)}
        value={schools?.filter((school) => value?.includes(school.id)) ?? []}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={`${ option?.name && option?.name?.length > 40 ? option?.name.substring(0, 40) + '...' : option?.name  ?? ''}`} />
          ));
        }}
        renderOption={(props, option: SchoolValue, { selected }) => (
          option?.id === 'all' ? (
            <>
              <Box sx={{ ...styles.box } as SxProps}>
                <Checkbox
                  checked={checkAll}
                  onChange={checkAllChange}
                  id="check-all"
                  onMouseDown={(e) => e.preventDefault()}
                />
                All Schools
              </Box>
              <Divider />
            </>
          ) : (
            <Box component="li" {...props}>
              <Checkbox
                color={'primary' as  CheckboxProps['color']}
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
                sx={{ mr: '8px' }}
              />
              {`${option?.name ?? ''}`}
            </Box>
          )
        )}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              sx={{ ...styles.textField }}
              placeholder={params.InputProps.startAdornment ? undefined : 'Select school/s'}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style: { height: 'auto', overflow:'auto', maxHeight: '500px', fontSize: '16px' },
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SchoolsIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          </>
        )}
      />
    </>
  );
};

export default SchoolMultiSelect;
