import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { OrderBy, useSearchStudentQuery } from "../../../../generated/graphql";
import { StudentSort } from "../../../../variables/types";

const SEARCH_LIMIT = 5;

const useSearchStudents = () => {
  const [search, setSearchValue] = useQueryParam('search-query', StringParam);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, StudentSort.FirstName));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Asc));
  const { data, loading } = useSearchStudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: SEARCH_LIMIT,
      orderBy: order as OrderBy,
      sort,
      ...(search?.length ? { search } : {}),
    },
    skip: !search
  });

  const onSearch = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target.value) {
      setSearchValue(event.target.value)
    } else {
      setSearchValue(undefined)
    }
  }

  return {
    students: data?.getAllStudentsOfTeacher ?? [],
    loading,
    onSearch
  };
};

export default useSearchStudents;
