import * as React from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import {
  OrderBy,
  Section,
  SectionFilterFragmentFragment,
  useGetTeacherByUserQuery,
  useSectionTeachersFilterQuery,
  useStudentsQuery
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { StudentSort } from '../../../variables/types';
import { PAGE_SIZE } from '../../../variables/constant';
import {SchoolValue} from "../../../components/SchoolFilterTeacher";
import { useAuthProvider } from '../../../core/authContext';


const useStudents = () => {
  const pagination = usePagination();
  const [search, setSearchValue] = useQueryParam('search', StringParam);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, StudentSort.FirstName));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Asc));
  const [section, setSection] = useQueryParam('section', StringParam);
  const [school, setSchoolValue] = useQueryParam('school', StringParam);
  const [selectedStudents, setSelectedStudents] = React.useState<string[]>([]);
  const [type, setType] = useQueryParam<string>("type");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openRemoveStudentDialog, setRemoveStudentOpenDialog] = React.useState(false);
  const [student, setStudentId] = useQueryParam<string | undefined>("student");
  const { getUser } = useAuthProvider();
  const user = getUser();
  const closeDialog = (type?: string, id?: string) => {
    setType(type!)
    setRemoveStudentOpenDialog(false);
    if(typeof type === 'object') { setOpenDialog(false); } else setOpenDialog(type !== undefined)
    if (id) { setStudentId(id) } else setStudentId(undefined)
  }
  const closeRemoveStudentDialog = (type?: string, isForm?: boolean) => {
    setRemoveStudentOpenDialog(!openRemoveStudentDialog);
    setOpenDialog(false);
    setType(type!)
    if (type === 'remove' && student && isForm) setSelectedStudents([student])
    if (!type) setSelectedStudents([])
  }
  const { data: teacherDetails } = useGetTeacherByUserQuery({
    fetchPolicy: 'network-only',
    variables: {
      userID: user.id
    },
    skip: !user.id,
  })

  const { data, loading, refetch } = useStudentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort,
      orderBy: order as OrderBy,
      ...(section ? { sectionID: section } : {}),
      ...(search?.length ? { search } : {}),
      ...(school ? { schoolID: school } : {}),
    },
  });

  const { data: teacherList } = useSectionTeachersFilterQuery({
    fetchPolicy: 'network-only',
    variables: { id: section! },
    skip: !section,
  });

  const onSortChange = (sortByColumn: StudentSort, orderByColumn: OrderBy) => {
    pagination.setPage(1);
    setSort(sortByColumn);
    setOrder(orderByColumn);
  };

  const onSectionChange = (sectionData: string | SectionFilterFragmentFragment | null) => {
    pagination.setPage(1);
    const sectionId = typeof sectionData === "string" ?  sectionData : sectionData?.id
    setSection(sectionId);
    setSelectedStudents([])
  };

  const onSchoolChange = (schoolData: SchoolValue | string) => {
    pagination.setPage(1);
    setSection(undefined);
    setSelectedStudents([])
    setSchoolValue(schoolData as string);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination.setPage(1);
    if (event.target.value === '') {
      setSearchValue(undefined);
    } else {
      setSearchValue(event.target.value);
    }
  };

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const students = data?.students?.nodes ?? [];
  const totalCount = data?.students?.totalCount ?? 0;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const filteredStudents = students.filter((std) => isTeacherPrimary(std.section))
      const newSelected = filteredStudents.map((n) => n.id);
      setSelectedStudents(newSelected);
      return;
    }
    setSelectedStudents([]);
  }

  const handleClick = (id: string) => {
    const selectedIndex = selectedStudents.indexOf(id);
    let newSelected:  string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStudents, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStudents.slice(1));
    } else if (selectedIndex === selectedStudents.length - 1) {
      newSelected = newSelected.concat(selectedStudents.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStudents.slice(0, selectedIndex),
        selectedStudents.slice(selectedIndex + 1),
      );
    }
    setSelectedStudents(newSelected);
  }

  const isTeacherPrimary = (sections?: Section[]) => sections?.some((section) => {
    return section?.teachers?.find((teacherInfo) => {
      return teacherInfo?.id === teacherDetails?.teacherByUser?.id && teacherInfo?.primary_teacher === true;
    });
  });

  const isStudentSelected = (id: string) => selectedStudents.indexOf(id) !== -1;
  return {
    loading,
    students,
    totalCount,
    pagination,
    search,
    onSearch,
    onSortChange,
    sort: sort as StudentSort,
    order: order as OrderBy,
    section,
    sectionTeacher: teacherList?.sectionTeachers,
    handlePageChange,
    school,
    setSchoolValue,
    onSectionChange,
    onSchoolChange,
    onSelectAllClick,
    handleClick,
    rowCount: students.filter((std) => isTeacherPrimary(std.section)).length,
    isStudentSelected,
    numSelectedStudents: selectedStudents.length,
    selectedStudents,
    openDialog,
    closeDialog,
    componentType: type,
    openRemoveStudentDialog,
    closeRemoveStudentDialog,
    teacherDetails,
    isTeacherPrimary,
    refetch
  };
};

export default useStudents;
