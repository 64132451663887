import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import useManageClass from './manage-class-hook';
import TeacherFilter from '../../../components/TeacherFilter';
import ClassFilter from '../../../components/ClassFilter';
import GradeFilter from '../../../components/GradeFilter';
import List from './List';
import WarningWindow from '../../../components/WarningWindow';
import Loader from '../../../components/Loader/loader';
import DebounceSearchBar from '../../../components/DebounceSearchBar';

const ManageClass = () => {
  const {
    section,
    loading,
    pagination,
    allocatedStudents,
    availableStudents,
    availableTotalCount,
    allocatedTotalCount,
    handlePageChange,
    selectedClass,
    onClassChange,
    selectedTeacher,
    onTeacherChange,
    selectedGrade,
    onGradeChange,
    search,
    onSearch,
    paginationAssignedStudents,
    handleAssignedStudentsPageChange,
    onChangeAllocated,
    onChangeAvailable,
    isAllocatedSelected,
    isAvailableSelected,
    handleAddSelected,
    selectedAllocatedStudents,
    selectedAvailableStudents,
    handleRemoveSelected,
    selectAllAvailable,
    selectAllAllocated,
    handleSelectAllAvailable,
    handleSelectAllAllocated,
    handleRemoveSelectedConfirm,
    showRemoveSelectedConfirmation,
    cancelRemoveSelected,
    confirmationMessage,
    pageLoading,
  } = useManageClass();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box>
        <Typography variant="pageTitle">
          {`Assign Students to ${section?.name ?? ''}`}
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="30% repeat(3, 15%)"
        sx={{
          gridGap: 24,
        }}
        mt={2}
        width="100%"
      >
        <DebounceSearchBar
          handleChange={onSearch}
          searchTerm={search}
          mt={0}
          mb={0}
        />
        <GradeFilter
          value={selectedGrade}
          onChange={onGradeChange}
          idSelectOnly
          placeholder="All Grades"
        />
        <ClassFilter
          value={selectedClass}
          onChange={onClassChange}
          schoolId={section?.school_id}
          idSelectOnly
          placeholder="All classes"
        />
        <TeacherFilter
          value={selectedTeacher}
          onChange={onTeacherChange}
          schoolId={section?.school_id}
          idSelectOnly
          placeholder="All teachers"
        />
      </Box>
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns="2fr 1fr 2fr"
        sx={{
          gridGap: 48,
        }}
      >
        <Box>
          <List
            isTableEmpty={availableTotalCount === 0}
            pagination={pagination}
            loading={loading}
            totalCount={availableTotalCount ?? 0}
            handlePageChange={handlePageChange}
            students={availableStudents}
            title="Assignable Students"
            onChange={onChangeAvailable}
            isSelected={isAvailableSelected}
            isSelectAll={selectAllAvailable}
            handleSelectAll={handleSelectAllAvailable}
          />
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="100%"
            display="grid"
            gridTemplateColumns="1fr"
            sx={{
              gridGap: 12,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIos />}
              onClick={handleAddSelected}
              disabled={selectedAvailableStudents?.length === 0}
            >
              Add Selected
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIos />}
              onClick={handleRemoveSelected}
              disabled={selectedAllocatedStudents?.length === 0}
            >
              Remove Selected
            </Button>
          </Box>
        </Box>
        <Box>
          <List
            isTableEmpty={allocatedTotalCount === 0}
            pagination={paginationAssignedStudents}
            loading={loading}
            totalCount={allocatedTotalCount ?? 0}
            handlePageChange={handleAssignedStudentsPageChange}
            students={allocatedStudents}
            title={section?.name || 'Allocated Students'}
            onChange={onChangeAllocated}
            isSelected={isAllocatedSelected}
            isSelectAll={selectAllAllocated}
            handleSelectAll={handleSelectAllAllocated}
          />
        </Box>
      </Box>
      <WarningWindow
        openDialog={showRemoveSelectedConfirmation && !pageLoading}
        closeDialog={cancelRemoveSelected}
        header="Are you sure?"
        subheader={confirmationMessage}
        actions={[{
          title: 'CANCEL',
          event: cancelRemoveSelected,
          classname: 'keepButton',
        }, {
          title: 'PROCEED',
          event: handleRemoveSelectedConfirm,
          classname: 'deleteButton',
        }]}
      />
      <Loader open={pageLoading} />
    </Box>
  );
};

export default React.memo(ManageClass);
