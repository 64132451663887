import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  StyledBodyBox,
  StyledBoxWrapper,
  StyledHeaderBox, StyledPaddedBox,
} from './SubmissionTile.styled';
import { BackPackSubmissionFragment } from '../../generated/graphql';
import ClickAndLabelTask from '../../pages/SubmitTask/ClickAndLabel/ClickAndLabelTask';
import BackdropModal from '../BackdropModal/BackdropModal';
import useAudioPlayer from '../../utils/useAudioPlayer';
import AudioControls from '../AudioControls';
import AudioSlider from '../AudioSlider';
import Loader from '../Loader/loader';
import { styledTypo } from '../Player/Player.styled';
import { palette  } from '../../theme/palette';

interface Props {
  submission?: BackPackSubmissionFragment | null,
  title: string,
  togglePreview: (event: React.MouseEvent<SVGElement | HTMLButtonElement | HTMLDivElement, MouseEvent>) => void,
  show: boolean,
  Icon: React.ComponentType<any>
  setIsPlaying?: Function
  selectedPlayerId?: string
  setSelectedPlayerId?: (id: string) => void;
  url?: string;
  onPlaying?: (id: string) => void;
  playerId?: string;
  active?: boolean;
}

export const submissionStyles = {
  contentBox: {
    maxHeight: '150px',
    height: '150px',
    overflowY: 'scroll',
  },
  defaultHeight: {
    height: 'auto',
  },
};

const SubmissionTile = ({
  submission,
  title,
  togglePreview,
  show,
  Icon,
  onPlaying,
  playerId,
  active,
}: Props) => {
  const [selectedUrl, setSelectedUrl] = useState<string>(submission?.speakings?.[0]?.url!);
  const [forcePlay, setForcePlay] = useState<boolean>(false);
  const onEnd = useCallback(() => {
    setForcePlay(false);
  }, [setForcePlay]);
  const { playing, togglePlaying, duration, currentTime, setTime, isLoading } = useAudioPlayer({ url: selectedUrl, onPlaying, active, playerId, forcePlay, onEnd });
  const handleAudioSliderChange = (event: React.SyntheticEvent | Event, time: number | Array<number>) => {
    setTime(time as number);
  };
  const changePlayer = (url : string) => async () => {
    if (url !== selectedUrl) {
      setSelectedUrl(url);
      setForcePlay(true);
    } else {
      setForcePlay(false);
      await togglePlaying();
    }
  };
  return (
    <StyledBoxWrapper>
      <Loader open={isLoading} />
      <StyledHeaderBox
        height="75px"
      >
        <Icon
          fillColor={palette.customBlue.primaryBlue}
        />
        <Typography variant='sContentHeader' sx={{ lineHeight: '24px' }}>
          {title}
        </Typography>
      </StyledHeaderBox>
      <StyledBodyBox>
        <Box>
          <ClickAndLabelTask
            togglePreview={togglePreview}
            submission={submission}
            annotations={submission?.annotations || []}
            disableEdit
            containerHeight="30vh"
          />
        </Box>
        <StyledPaddedBox>
          <Typography variant='title18'> My Writing </Typography>
          <Box sx={{ ...submissionStyles.contentBox, ...(submission?.writing) && submissionStyles.defaultHeight }}>
            <Typography variant='sLabel' component='p' sx={{ mt: '12px' }}>
              {submission?.writing}
            </Typography>
          </Box>
        </StyledPaddedBox>
        <Box
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent={submission && submission?.speakings?.length < 2 ? 'center' : submission && submission?.speakings?.length < 3 ? 'space-around' : 'space-between'}
          >
            {submission?.speakings?.map((recording, index: number) => {
              const id = `${recording.url}`;
              return (
                <Box
                  key={id}
                >
                  <Typography variant ='sTaskTitle' sx={{ ...styledTypo }}>
                    {`Recording ${index + 1}`}
                  </Typography>
                  <Box
                    sx={{
                      display : 'flex',
                      justifyContent : 'center',
                      marginTop : '20px',
                    }}
                  >
                    <AudioControls onClick={changePlayer(recording?.url)} playing={(playing && recording?.url) === selectedUrl} />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box marginTop="20px">
            <AudioSlider currentTime={currentTime} onChangeCommitted={handleAudioSliderChange} max={duration} customStyle />
          </Box>
        </Box>
        <BackdropModal open={show} onClose={togglePreview}>
          <ClickAndLabelTask
            submission={submission}
            annotations={submission?.annotations || []}
            disableEdit
            containerHeight="80vh"
          />
        </BackdropModal>
      </StyledBodyBox>
    </StyledBoxWrapper>
  );
};

export default React.memo(SubmissionTile);
