import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  TableCell,
  CircularProgress,
  Typography
} from '@mui/material';
import {
  StyledTableCellFlyover,
  StyledTableContainerFlyover,
  tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import useBenchmarkGradesInfiniteList from '../../Grade/grades-list-infinite-hook';
import {BenchmarkSubmissionListSortType, SubmissionStatus} from '../../../../generated/graphql';
import NoResults from "../../../../components/NoResults";
import WavingLadyFrame from "../../../../components/Icons/WavingLadyFrame";

interface Props {
  benchmarkId: string
}

const NotStartedList = (props: Props) => {
  const {
    page,
    totalCount,
    gradeBenchmarks,
    loading,
    handlePagination,
  } = useBenchmarkGradesInfiniteList({
    benchmarkId: props.benchmarkId,
    sort: BenchmarkSubmissionListSortType.StudentName,
    gradeStatuses: [
      SubmissionStatus.NotStarted,
    ]
  });

  const isTableEmpty = !loading && gradeBenchmarks?.length === 0;

  return (
    <Box>
      <StyledTableContainerFlyover>
        <InfiniteScroll
          height={'calc(100vh - 83px)'}
          next={() => handlePagination(page + 1)}
          hasMore={totalCount > gradeBenchmarks?.length}
          dataLength={totalCount}
          loader={null}
        >
          <Table
            stickyHeader
            aria-label="students submissions table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableBody>
              {gradeBenchmarks && gradeBenchmarks?.map((student) => (
                <TableRow key={student.id}>
                  <StyledTableCellFlyover>
                    <Typography variant='tableTitleData'>
                      {student?.user_first_name!} {student?.user_last_name!}
                    </Typography>
                  </StyledTableCellFlyover>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="No Students"
                      description="No students at this step yet"
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </StyledTableContainerFlyover>
    </Box>
  );
};

export default NotStartedList;
