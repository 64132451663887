import { Box, Grid, Button, Dialog, DialogActions, DialogContent, Slide, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import { CleverSyncDetails } from '../../../generated/graphql';
import Loader from '../../../components/Loader/loader';
import { StatusIcon } from './List';
import { getMountainTimeZone } from '../../../utils/timezone';
import { palette  } from '../../../theme/palette';

interface DetailsProps {
  openDialog: boolean,
  syncDetails: CleverSyncDetails | null | undefined,
  loading: boolean,
  handleClose: () => void | Function
}

const styles = {
  detailsModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  boldFont: {
    fontWeight: 'bold',
    color: palette.fontColors.darkFont,
  },

  districts: {
    textDecorationLine: 'none',
  },
};

const StyledSpan = styled('span')(() => ({
  fontWeight: 'bold',
  color: palette.fontColors.darkFont,
}));

const SyncDetails = ({ openDialog, syncDetails, loading, handleClose }:DetailsProps) => {
  const districtCount = syncDetails?.districts?.length || 0;
  if (loading) {
    return <Loader open={loading} />;
  }
  return (
    <Dialog open={openDialog} onClose={handleClose} sx={{ ...styles.detailsModal }} TransitionComponent={Slide}>
      <DialogContent>
        <Grid color="text.secondary" container direction='column' spacing={2.5}>
          <Grid item>
            <Typography variant='secondaryTitle'>
              {getMountainTimeZone(syncDetails?.created_at, '')}
              {' sync by '}
              {
                syncDetails?.owner_type === 'System'
                  ? syncDetails?.owner_type : `${syncDetails?.admin?.user?.first_name} ${syncDetails?.admin?.user?.last_name}`
              }
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {'Started: '}
              <StyledSpan>{getMountainTimeZone(syncDetails?.created_at, '')}</StyledSpan>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {'Duration: '}
              {
                syncDetails && (
                  <StyledSpan>
                    {
                      syncDetails?.status === 'InProgress' ? '-' : (syncDetails?.run_duration && syncDetails?.run_duration > 60
                        ? `${Math.floor(syncDetails?.run_duration / 60)}m ${syncDetails?.run_duration % 60}s`
                        : `${syncDetails?.run_duration} s`)
                    }
                  </StyledSpan>
                )
              }
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Box display="flex">
                <Box>{'Status: '}</Box>
                {
                  syncDetails?.status && (
                    <Box sx={{ ...styles.boldFont }} ml="1"><StatusIcon status={syncDetails?.status} noMargin={false} /></Box>
                  )
                }
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {'Message: '}
              <StyledSpan>{syncDetails?.message || '-'}</StyledSpan>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {'Districts: '}
              {
                syncDetails?.districts && syncDetails?.districts?.map((sync, idx:number) => (
                  <span key={sync?.id}>
                    <Typography component={Link} to={`/districts/${sync?.id}`} sx={{ ...styles.districts }}>
                      {sync?.name}
                    </Typography>
                    {idx !== districtCount - 1 ? ', ' : ''}
                  </span>
                ))
              }
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyncDetails;
