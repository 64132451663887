import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import { Box } from '@mui/material';
import useSearchBar from './searchBar-hook';
import SearchIcon from '../Icons/SearchIcon';
import zIndex from '../../theme/z-index';
import { palette  } from '../../theme/palette';

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  searchTerm?: string | number | null
  mt?: number
  mb?: number
  disabled?: boolean
}

const styles = {
  search: {
    width: '100%',
    background: palette.customWhite.whiteSmoke,
    position: 'relative',
    borderRadius: 12,
  },
  searchIcon: {
    padding: '0 16px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: zIndex.zIndex1,
  },
};

const searchStyled = {
  searchInput: {
    '&.MuiInputBase-root .MuiInputBase-input': {
      padding: '8px 8px 8px 0',
      // vertical padding + font size from searchIcon
      paddingLeft: 'calc(1em + 32px) !important',
      width: '100%',
      fontSize: '18px',
      letterSpacing: '1px',
      color: palette.customBlack.lightBlack,
    },
  },
};

const DebouncedSearchBar = ({ handleChange, searchTerm, mt = 2, mb = 2, disabled = false }: Props) => {
  const { searchInput, handleChangeEvent } = useSearchBar({ handleChange, searchTerm });
  return (
    <Box
      sx={{ ...styles.search }}
      mt={mt}
      mb={mb}
    >
      <Box sx={{ ...styles.searchIcon }}>
        <SearchIcon />
      </Box>
      <InputBase
        placeholder={'Search'}
        inputProps={{ 'aria-label': 'search' }}
        value={searchInput || ''}
        onChange={handleChangeEvent}
        disabled={disabled}
        sx={{ ...searchStyled.searchInput }}
      />
    </Box>
  );
};

export default DebouncedSearchBar;