import React, { useState } from 'react';
import {
  BenchmarkAssignToSortType,
  OrderBy,
  useGetBenchmarkStudentsQuery,
} from '../../../generated/graphql';
import { PAGE_SIZE } from '../../../variables/constant';
import usePagination from '../../../utils/usePagination';

interface Props {
  districts : string | string[],
  grades? : string | string[] | undefined,
  schools? : string | string[] | undefined,
}

const useStudentsCountFilter = ({ districts, grades, schools }:Props) => {
  const pagination = usePagination();
  const [queryInput, setQueryInput] = useState({
    limit: PAGE_SIZE,
    page: pagination.page,
    sort: BenchmarkAssignToSortType.DistrictName,
    orderBy: OrderBy.Asc,
  });

  const { data: benchmarkStudents, loading: studentCountLoading } = useGetBenchmarkStudentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      districtIds: districts || [],
      ...(grades ? { gradeIds: grades } : {}),
      ...(schools ? { schoolIds: schools } : {}),
      ...queryInput,
    },
    skip: !districts?.length,
  });

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
    setQueryInput({
      limit: queryInput.limit,
      page: value as number,
      sort: queryInput.sort,
      orderBy: queryInput.orderBy,
    });
  };

  const onSortChange = (sortBy: BenchmarkAssignToSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setQueryInput({
      limit: PAGE_SIZE,
      page: 1,
      sort: sortBy,
      orderBy: orderBy,
    });
  };

  const handleSort = (column: BenchmarkAssignToSortType) => () => {
    if (column === queryInput?.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const studentsCount = benchmarkStudents?.benchmarkStudentCountByFilters?.nodes ?
    benchmarkStudents?.benchmarkStudentCountByFilters?.nodes.reduce(
      (totalCount, item:any) => totalCount + item?.student_count ?? 0, 0) : 0;

  return {
    studentCountLoading,
    benchmarkStudents: benchmarkStudents?.benchmarkStudentCountByFilters?.nodes,
    studentsCount,
    totalCount: benchmarkStudents?.benchmarkStudentCountByFilters?.node_count ?? 0,
    pagination,
    handlePageChange,
    sort: queryInput?.sort,
    handleSort,
    order: queryInput?.orderBy,
  };
};

export default useStudentsCountFilter;
