export const errorMessages = {
  adminIDHelperText: 'Please enter a valid admin ID.',
  stateHelperText: 'Please select a state.',
  districtHelperText: 'Please select a district.',
  schoolHelperText: 'Please select a school.',
  titleHelperText: 'Please select title.',
  lastNameHelperText: 'Please enter last name.',
  firstNameHelperText: 'Please enter first name.',
  jobTitleHelperText: 'Please enter job title.',
  emailHelperText: 'Please enter a email ID.',
  passwordHelperText: 'Please set a password.',
};

export const labels = {
  checkBoxLabel: "I'll be adding another admin after this one",
};

export const titles = [
  {
    value: 'none',
    label: 'No Title',
  },
  {
    value: 'Dr',
    label: 'Dr.',
  },
  {
    value: 'Mrs',
    label: 'Mrs.',
  },
  {
    value: 'Ms',
    label: 'Ms.',
  },
  {
    value: 'Mr',
    label: 'Mr.',
  },
];

export const adminLevel = {
  SCHOOL: 'school',
  DISTRICT: 'district',
};
