import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import useClasses from './classes-hook';
import List from './List';
import StateFilter from '../../../components/StateFilter';
import DistrictFilter from '../../../components/DistrictFilter';
import SchoolFilter from '../../../components/SchoolFilter';
import TeacherFilter from '../../../components/TeacherFilter';
import RecordCount from '../../../components/RecordCount';
import { SectionCategory } from '../../../generated/graphql';
import useRole from '../../../hooks/useRole';
import { Link } from 'react-router-dom';

const Classes = () => {
  const {
    classes,
    pagination,
    loading,
    totalCount,
    sort,
    order,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    selectedTeacher,
    onTeacherChange,
    selectedSchool,
    onSchoolChange,
    districtPermission,
    schoolPermission,
  } = useClasses({ classTypes: [SectionCategory.Class, SectionCategory.CustomClass] });
  const { isSuperAdmin } = useRole();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="pageTitle">
            Classes
          </Typography>
        </Box>
        <Box>
          {isSuperAdmin && (
            <Button
              variant="outlined"
              color="primary"
              disableRipple={true}
              component={Link}
              to="/admin/create/class"
            >
              Add Class
            </Button>
          )}
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${districtPermission ? 4 : (schoolPermission ? 2 : 1)}, 15%) 1fr 200px`}
        sx={{
          gridGap: 24,
        }}
        mt={2}
      >
        {districtPermission && (
          <>
            <StateFilter
              value={selectedState}
              onChange={onStateChange}
              idSelectOnly
              placeholder="All states"
            />
            <DistrictFilter
              value={selectedDistrict}
              onChange={onDistrictChange}
              stateIds={selectedState ?? undefined}
              idSelectOnly
              placeholder="All districts"
            />
          </>
        )}
        {schoolPermission
        && (
          <SchoolFilter
            value={selectedSchool}
            onChange={onSchoolChange}
            stateId={selectedState ?? undefined}
            districtId={selectedDistrict ?? undefined}
            idSelectOnly
            placeholder="All schools"
          />
        )}
        <TeacherFilter
          value={selectedTeacher}
          onChange={onTeacherChange}
          stateId={selectedState ?? undefined}
          districtId={selectedDistrict ?? undefined}
          schoolId={selectedSchool ?? undefined}
          idSelectOnly
          placeholder="All teachers"
        />
        <Box />
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {classes && (
            <RecordCount
              pageNumber={pagination.page}
              recordLength={classes?.length}
              totalRecords={totalCount}
            />
          )}
        </Box>
      </Box>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading}
          totalCount={totalCount}
          order={order}
          pagination={pagination}
          sort={sort}
          classes={classes}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
        />
      </Box>
    </Box>
  );
};

export default React.memo(Classes);
