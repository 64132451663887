import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { InputAdornment, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import GradeSelectionIcon from '../Icons/GradeSelectionIcon';
import { SubmissionStatus } from '../../generated/graphql';

type Option = {
  label: string;
  value: SubmissionStatus;
};

type GradeStatusFilterProps = {
  onChange: (newStatus: SubmissionStatus | undefined) => void;
  status: SubmissionStatus;
  options?: Option[];
  placeholder?: string;
  textInputPlaceholder?: string;
};

const GradeStatusFilter = ({ onChange, status, options, placeholder, textInputPlaceholder }: GradeStatusFilterProps) => {

  const handleOptionChange = (event: React.SyntheticEvent, newStatus:Option | null) => {
    onChange(newStatus?.value);
  };

  return (
    <Autocomplete
      options={options ?? []}
      getOptionLabel={(option) => option.label}
      value={options?.find(option => option.value === status) ?? null}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <>
          {placeholder && <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>}
          <TextField
            {...params}
            placeholder={params.InputProps.startAdornment ? undefined : (!textInputPlaceholder ? 'Select Benchmarks' : textInputPlaceholder)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <GradeSelectionIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default GradeStatusFilter;
