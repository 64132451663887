import React from 'react';
import { ComposedChart, Bar, XAxis, Line, ResponsiveContainer, Tooltip } from 'recharts';
import VerticalBarLoaders from '../../Loader/VerticalBarLoaders';
import { palette } from '../../../theme/palette';
import { Box, Typography } from '@mui/material';
import {
  LearningGoalsCompletedPTsMostRecentGoalsPerMonth,
  PerformanceWideStudentCompletionRateBar,
} from '../../../generated/graphql';
import AverageIcon from '../../Icons/AverageIcon';
import SubmissionsIcon from '../../Icons/SubmissionsIcon';
import CompletedIcon from '../../Icons/CompletedIcon';
import { getMonth } from '../CommonFunctions';
import NoResults from '../../NoResults';
import { AdminDashboards } from '../../../variables/constant';
import EmptyResults from '../../Icons/EmptyResults';

interface Props {
  loading: boolean,
  primaryLegend?: string,
  secondaryLegend?: string,
  tertiaryLegend?: string,
  barData: PerformanceWideStudentCompletionRateBar[] | LearningGoalsCompletedPTsMostRecentGoalsPerMonth[] | undefined,
  showData?: boolean,
  greyBar:string,
  blueBar:string,
  averageLine?:string
  toolTipComponent?: React.ReactElement
}

const StackedBarChart = ({
  loading,
  primaryLegend,
  secondaryLegend,
  tertiaryLegend,
  barData,
  showData,
  greyBar,
  blueBar,
  averageLine,
  toolTipComponent,
}: Props) => {

  return (
    <>
      <Box height={!showData ? '400px' : 'auto'}>
        {loading && (<VerticalBarLoaders />)}
        {!showData && (
          <NoResults
            description={AdminDashboards.performance.overallPerformance.insufficientData.description}
            svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
          />
        )}
        {/*After loading the react cycle checks for availability of data from hasData var*/}
        {showData && (<ResponsiveContainer width="100%" height={400}><ComposedChart
          data={barData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <XAxis dataKey={getMonth} axisLine={false} tickLine={false} style={{ fontWeight: 600, fontSize: '14px', color: palette.customBlack.fontBlack }}/>
          <Tooltip
            content={toolTipComponent}
            cursor={{ stroke: '0' }}
            wrapperStyle={{ outline: 'none' }}
            position={{ y:-50 }}
          />
          <Bar
            dataKey={blueBar}
            stackId="units"
            fill={palette.customBlue.primaryBlue}
            radius={[0, 0, 5, 5]}
            isAnimationActive={false}
          />
          <Bar
            dataKey={greyBar}
            stackId="units"
            fill={palette.customBackground.emptyBarBackground}
            radius={[5, 5, 0, 0]}
          >
          </Bar>
          <Line
            type="monotone"
            dataKey={averageLine}
            stroke={palette.customBlue.primaryBlue}
            strokeWidth={4}
            strokeLinecap='round'
            dot={false}
            strokeDasharray="2 15"
          />
        </ComposedChart>
        </ResponsiveContainer>
        )}
        {
          showData && !loading && (
            <Box display='flex' mt='20px' justifyContent='center'>
              <Box display='flex' mr='30px'>
                <SubmissionsIcon/>
                <Typography variant='sFormHelperText' sx={{ ml: '10px', mt: 0 }}>{primaryLegend}</Typography>
              </Box>
              <Box display='flex' mr='30px'>
                <CompletedIcon/>
                <Typography variant='sFormHelperText' sx={{ ml: '10px', mt: 0 }}>{secondaryLegend}</Typography>
              </Box>
              <Box display='flex' mr='30px'>
                <AverageIcon/>
                <Typography variant='sFormHelperText' sx={{ ml: '10px', mt: 0 }}>{tertiaryLegend}</Typography>
              </Box>
            </Box>
          )}
      </Box>
    </>
  );
};

export default StackedBarChart;
