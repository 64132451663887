import { useContext, useEffect } from 'react';
import {
  OrderBy,
  DistrictWideGradeSortType,
  useDistrictWideGradeDataQuery,
  useDistrictWideSchoolDataQuery,
  DistrictWideSchoolDetailsSortType,
} from '../../../../generated/graphql';
import {
  DistrictWideQueryInput,
  DistrictWideComparisonsContext,
} from '../district-wide-comparisons-hook';

interface Props {
  districtId?: string,
  schoolId?: string,
}

const useGrades = ({ districtId, schoolId }: Props) => {
  const {
    isSchoolAdmin, isDistrictAdmin, setWidgetLoading,
    gradesDataCount, setGradesDataCount, setSchoolsDataCount,
    gradesDataQueryInput, setGradesDataQueryInput,
  } = useContext(DistrictWideComparisonsContext);

  const updateGradesDataQueryInput = (data: Object) => {
    setGradesDataQueryInput?.({
      ...gradesDataQueryInput,
      ...data,
    });
  };

  var queryInput: DistrictWideQueryInput = gradesDataQueryInput ? gradesDataQueryInput : {
    sort: DistrictWideGradeSortType.GradePosition,
    orderBy: OrderBy.Asc,
  };
  if (isSchoolAdmin && schoolId) queryInput.school_id = schoolId;
  if (isDistrictAdmin && districtId) queryInput.district_id = districtId;

  const updateQueryInput = (queryInputUpdate: DistrictWideQueryInput) => {
    queryInput = {
      ...queryInput,
      ...queryInputUpdate,
    };
    updateGradesDataQueryInput(queryInput);
  };

  const onSortChange = (sortBy: DistrictWideGradeSortType, orderBy: OrderBy) => {
    updateQueryInput({
      sort: sortBy,
      orderBy: orderBy,
    });
  };

  const handleSort = (column: DistrictWideGradeSortType) => () => {
    if (column === queryInput.sort) {
      onSortChange(column, queryInput.orderBy === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const { data, loading: districtWideGradesDataLoading } = useDistrictWideGradeDataQuery({
    fetchPolicy: 'network-only',
    variables: { 'input': queryInput },
    skip: !districtId && !schoolId,
  });

  const districtWideGradesData = data?.districtWideGradeInfo ?? [];
  const isTableEmpty = !districtWideGradesDataLoading && gradesDataCount === 0;

  const { data: districtWideSchoolsData, loading: districtWideSchoolsDataLoading } = useDistrictWideSchoolDataQuery({
    fetchPolicy: 'network-only',
    variables: { ...queryInput, sort: DistrictWideSchoolDetailsSortType.SchoolName },
    skip: !districtId && !schoolId,
  });

  useEffect(() => {
    if (!districtWideGradesDataLoading) setGradesDataCount?.(districtWideGradesData?.length);
    if (!districtWideSchoolsDataLoading) setSchoolsDataCount?.(districtWideSchoolsData?.districtWideSchoolData?.districtWideSchoolDetailsCount as number);
    setWidgetLoading?.(districtWideSchoolsDataLoading || districtWideGradesDataLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtWideGradesDataLoading, districtWideSchoolsDataLoading]);

  return ({
    queryInput, handleSort,
    gradesDataCount, districtWideGradesData, isTableEmpty,
  });
};

export default useGrades;
