import { useEffect } from 'react';
import {
  OverallPerformanceWidgetInput,
  useOverallPerformanceWidgetQuery,
} from '../../../../generated/graphql';
import { AdminDashboards } from '../../../../variables/constant';
import { useDashboardContext } from '../../admin-analytics-hook';


const useOverallPerformanceInfo = () => {

  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
    analyticsFilters: queryFilters,
    sessionStartDate, sessionEndDate,
  } = useDashboardContext();

  const queryInput: OverallPerformanceWidgetInput = {
    filters: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.schoolID = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData?.id) queryInput.districtID = myDistrictData?.id;

  const { data: overallPerformanceData, loading: overallPerformanceDataLoading } = useOverallPerformanceWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });

  useEffect(() => {
    if (!overallPerformanceDataLoading && !!overallPerformanceData?.overallPerformanceInfo) {
      updateDashboardWidgetsDataStatus?.({
        widget: AdminDashboards.performance.overallPerformance.name,
        loading: overallPerformanceDataLoading,
        dataMissing: !overallPerformanceData?.overallPerformanceInfo?.historical_score?.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallPerformanceDataLoading, overallPerformanceData]);

  return ({
    overallPerformanceDataLoading, overallPerformanceData: overallPerformanceData?.overallPerformanceInfo,
    title: `Overall ${queryInput.filters?.submission_score_type} Performance`,
    sessionStartDate, sessionEndDate,
  });
};

export default useOverallPerformanceInfo;