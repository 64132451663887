import {useEffect, useState} from 'react';
import { PAGE_SIZE } from '../../../variables/constant';
import {
  TeacherSubmissionListInput,
  TeacherBenchmarkSubmissionListNode,
  TeacherBenchmarkSubmissionListSortType,
  OrderBy,
  SubmissionStatus,
  useTeacherBenchmarkGradesListQuery,
} from '../../../generated/graphql';
import { TeacherBenchmarkGradesListParams } from './grades-list-hook';

const useTeacherBenchmarkGradesInfiniteList = (
  params?: TeacherBenchmarkGradesListParams
) => {
  const [sort, setSort] = useState<TeacherBenchmarkSubmissionListSortType>(params?.sort ?? TeacherBenchmarkSubmissionListSortType.SubmittedAt);
  const [order, setOrder] = useState<string>(OrderBy.Asc);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<TeacherBenchmarkSubmissionListNode[]>([]);
  const [benchmarkTypes, setBenchmarkTypes] = useState<any []>([]);
  const [benchmarkId, setBenchmarkId] = useState<string | undefined>(params?.benchmarkId);
  const [selectedStatuses, setSelectedStatuses] = useState<SubmissionStatus[] | undefined>(params?.gradeStatuses);
  const initialInputVariables = {
    limit: PAGE_SIZE,
    page: 1,
    sort,
    benchmark_id: benchmarkId,
    order_by: order as OrderBy,
    grade_statuses: selectedStatuses,
  };

  const [variables, setVariables] = useState<TeacherSubmissionListInput>({
    ...initialInputVariables,
  });

  const { data, loading } = useTeacherBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    variables,
  });

  useEffect(() => {
    if (data && data.teacherBenchmarkSubmissionList?.nodes) {
      // @ts-ignore
      setRows(rows => [...rows, ...data?.teacherBenchmarkSubmissionList?.nodes!])
    }
  }, [data]);

  const handleClearFilters = async () => {
    setPage(1)
    setVariables({
      ...initialInputVariables
    });
    setBenchmarkTypes([]);
    setBenchmarkId(undefined);
    setSelectedStatuses( undefined);
  };

  const handleApplyFilters = async () => {
    setPage(1)
    setVariables({
      limit: PAGE_SIZE,
      page: 1,
      sort: sort as TeacherBenchmarkSubmissionListSortType,
      order_by: order as OrderBy,
      ...(benchmarkTypes.length ? { benchmark_types: benchmarkTypes } : {}),
      ...(benchmarkId ? { benchmark_id: benchmarkId } : {}),
      ...(selectedStatuses ? { grade_statuses: selectedStatuses } : {}),
    });
  };

  const onSortChange = (sortBy: TeacherBenchmarkSubmissionListSortType, orderBy: OrderBy) => {
    setPage(1)
    setSort(sortBy);
    setOrder(orderBy);
    setVariables({
      ...variables,
      sort: sortBy,
      order_by: orderBy,
    });
  };

  const handlePagination = (value: React.SetStateAction<number>) => {
    setPage(value)
    setVariables({
      ...variables,
      page: value as number,
    });
  };

  return {
    loading: loading,
    benchmarkSubmissionList: rows ?? [],
    totalCount: data?.teacherBenchmarkSubmissionList?.node_count ?? 0,
    page,
    onSortChange,
    sort,
    order: order as OrderBy,
    benchmarkTypes, setBenchmarkTypes,
    benchmarkId, setBenchmarkId,
    selectedStatuses, setSelectedStatuses,
    handleApplyFilters,
    handleClearFilters,
    handlePagination,
  };
};

export default useTeacherBenchmarkGradesInfiniteList;
