import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OrderBy, DistrictWideSchoolDetailsSortType, DistrictWideSchoolDetails } from '../../../../generated/graphql';
import TablePagination from '../../../../components/TablePagination';
import NoResults from '../../../../components/NoResults';
import { AnalyticsIcon } from '../../../../components/Icons';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';
import { StyledTableContainer, StyledTableSort, tableWithNoDataStyles } from '../../../../components/Common/Common.styled';
import { DistrictWideComparisonsStylesCSS } from '../DistrictWideComparisons.styled';
import { StyledTableCell2 } from '../../../Admin/admin-tables-styled';
import SchoolRow from './SchoolRow';
import Flyover from '../Flyover';
import SchoolSpecificDetails from '../SchoolSpecificDetails';
import { PaginationProps } from '../../../../variables/types';
import { DistrictWideComparisonsContext } from '../district-wide-comparisons-hook';

interface Props {
  isTableEmpty: boolean,
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  limit: number,
  sort: DistrictWideSchoolDetailsSortType,
  order: OrderBy,
  schools: DistrictWideSchoolDetails[],
  handlePageChange: (_: React.ChangeEvent, value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: DistrictWideSchoolDetailsSortType) => void) | Function,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  limit,
  sort,
  order,
  schools,
  handlePageChange,
  handleSort,
}: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const {
    setSchoolSpecificDataQueryInput,
  } = useContext(DistrictWideComparisonsContext);

  const [showFlyover, setShowFlyover] = useState(false);
  const toggleFlyover = () => {
    if (showFlyover) setSchoolSpecificDataQueryInput?.(undefined);
    setShowFlyover(!showFlyover);
  };
  const [flyoverSchool, setFlyoverSchool] = useState<DistrictWideSchoolDetails>();
  const openFlyOver = (school: DistrictWideSchoolDetails) => {
    setFlyoverSchool({
      ...school,
    });
  };

  useEffect(() => {
    if (!!flyoverSchool) toggleFlyover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyoverSchool]);

  return (
    <Box
      display="grid"
      width="100%"
    >
      <Box>
        <StyledTableContainer sx={{ ...styles.tableContainer }}>
          <Table
            stickyHeader
            aria-label="District-wide Comparisons Schools Table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData), maxHeight: '60px' }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell2 sx={{ textAlign: 'left', ...styles.width20 }}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolDetailsSortType.SchoolName)}
                    active={sort === DistrictWideSchoolDetailsSortType.SchoolName}
                    IconComponent={ArrowDownIcon}
                  >
                    Name
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width10}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolDetailsSortType.Teachers)}
                    active={sort === DistrictWideSchoolDetailsSortType.Teachers}
                    IconComponent={ArrowDownIcon}
                  >
                    Teachers
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width10}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolDetailsSortType.Students)}
                    active={sort === DistrictWideSchoolDetailsSortType.Students}
                    IconComponent={ArrowDownIcon}
                  >
                    Students
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width20}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolDetailsSortType.GoalsAssigned)}
                    active={sort === DistrictWideSchoolDetailsSortType.GoalsAssigned}
                    IconComponent={ArrowDownIcon}
                  >
                    Goals Assigned
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width20}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolDetailsSortType.PtsCompleted)}
                    active={sort === DistrictWideSchoolDetailsSortType.PtsCompleted}
                    IconComponent={ArrowDownIcon}
                  >
                    PTs Completed
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width10}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolDetailsSortType.AvgSpeakingScore)}
                    active={sort === DistrictWideSchoolDetailsSortType.AvgSpeakingScore}
                    IconComponent={ArrowDownIcon}
                  >
                    Speaking
                  </StyledTableSort>
                </StyledTableCell2>
                <StyledTableCell2 sx={styles.width10}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictWideSchoolDetailsSortType.AvgWritingScore)}
                    active={sort === DistrictWideSchoolDetailsSortType.AvgWritingScore}
                    IconComponent={ArrowDownIcon}
                  >
                    Writing
                  </StyledTableSort>
                </StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && schools?.map((school, index) => (
                <SchoolRow rowIndex={index} school={school} key={school?.school_name} openFlyover={openFlyOver} />
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="We Need More Data."
                      description=""
                      svgComponent={<AnalyticsIcon />}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Flyover
          open={showFlyover}
          onClose={toggleFlyover}
        >
          <SchoolSpecificDetails school={flyoverSchool!} toggleDrawer={toggleFlyover} />
        </Flyover>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        paddingTop="20px"
      >
        {totalCount > limit && (<TablePagination
          count={Math.ceil(totalCount / limit) || 0}
          page={pagination.page}
          onChange={handlePageChange}
          defaultPage={pagination.page}
          disabled={totalCount < limit}
        />)}
      </Box>
    </Box>
  );
};
export default List;
