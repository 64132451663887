import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import Performance from './Performance';
import LearningGoals from './LearningGoals';
import DistrictWideComparisons from './DistrictWideComparisons';

const AdminAnalytics = () => (
  <Layout>
    <Switch>
      <Route
        path="/admin/analytics/performance"
        component={Performance}
        exact
      />
      <Route
        path="/admin/analytics/learning-goals"
        component={LearningGoals}
        exact
      />
      <Route
        path={[
          '/admin/analytics/district-wide-comparisons',
          '/admin/analytics/district-wide-comparisons/:tabKey',
        ]}
        component={DistrictWideComparisons}
        exact
      />
      <Redirect
        from="/admin/analytics"
        to="/admin/analytics/performance"
      />
    </Switch>
  </Layout>
);

export default AdminAnalytics;
