import React from 'react';
import { Box } from '@mui/material';
import useSchool from './school-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import ResourceDetailHeader from '../../Districts/District/resource-detail-header';
import ResourceDetail from '../../Districts/District/resource-detail';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import ClassesIcon from '../../../components/Icons/ClassesIcon';
import TeachersIcon from '../../../components/Icons/TeachersIcon';
import { StudentsIcon } from '../../../components/Icons';
import { MY_DISTRICT, PROVIDER_TYPE } from '../../../variables/constant';
import { Entity } from '../../../variables/types';
import AddNewEntity from '../../../components/AddNewEntity';

const School = () => {
  const {
    school,
    loading,
    editSchoolPermission,
    isSchoolAdmin,
    isDistrictAdmin,
  } = useSchool();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <ResourceDetailHeader
        title={school?.name ?? ''}
        addNewEntity={editSchoolPermission ? (
          <AddNewEntity
            entity={school?.district?.source !== PROVIDER_TYPE.FLASHLIGHT ? Entity.CleverDistrict : Entity.School}
            districtId={school?.district?.id}
            stateId={school?.district?.state?.id}
            schoolId={school?.id}
          />
        ) : undefined }
        primaryBtnRedirectionLink={`/admin/create/school/${school?.id}`}
        PrimaryBtnIcon={PencilIcon}
        primaryBtnLabel={editSchoolPermission ? 'Edit' : undefined}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          <ResourceDetail
            label={school?.district?.name ?? '-'}
            link={`/districts/${isSchoolAdmin || isDistrictAdmin ? MY_DISTRICT : school?.district?.id}`}
            Icon={DistrictsIcon}
          />
          <ResourceDetail label={`School ID: ${school?.school_id ?? '-'}`} Icon={SchoolsIcon} />
          <ResourceDetail
            label="View Classes"
            link={`/classes?school=${school?.id ?? ''}`}
            Icon={ClassesIcon}
          />
          <ResourceDetail
            label="View Teachers"
            link={`/teachers?school=${school?.id ?? ''}`}
            Icon={TeachersIcon}
          />
          <ResourceDetail
            label="View Students"
            link={`/admin-students?school=${school?.id ?? ''}`}
            Icon={StudentsIcon}
          />
        </StyledBoxWrapper>
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(School);
