import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Typography, Card, CardContent } from '@mui/material';
import DistrictMultiSelect from '../../../../components/DistrictMultiSelect';
import GradeMultiSelect from '../../../../components/GradeMultiSelect';
import SchoolMultiSelect from '../../../../components/SchoolMultiSelect';
import { useTheme } from '@mui/material/styles';
import { palette } from '../../../../theme/palette';
import { BENCHMARK_COUNT_LIMIT } from '../../../../variables/constant';

interface Props {
  districts: string[]
  setDistrictIDs: React.SetStateAction<any>
  grades:string[] | undefined
  setGradeIDs: React.SetStateAction<any>
  schools: string[] | undefined
  setSchoolIDs: React.SetStateAction<any>
  studentsCount: number,
  benchmarkCountExceeded?: boolean
}

const AssignToFilterCard = ({
  districts, setDistrictIDs,
  grades, setGradeIDs,
  schools, setSchoolIDs,
  studentsCount, benchmarkCountExceeded
}: Props) => {

  const theme = useTheme;
  const {
    control,
    errors,
    clearErrors,
  } = useFormContext();

  return (
    <Box>
      <Card variant='outlined'>
        <CardContent sx={{ backgroundColor: palette.customGrey.main }}>
          <Box mt={1}>
            <Controller
              name='district_ids'
              defaultValue={districts!}
              value={districts}
              render={({ onChange }) => (
                <DistrictMultiSelect
                  onChange={(data) => {
                    if (!data?.length) {
                      setSchoolIDs([]);
                    }
                    setGradeIDs([]);
                    setDistrictIDs(data!);
                    onChange(data);
                    clearErrors('district_ids');
                  }}
                  selectedValue={districts ?? []}
                />
              )}
              control={control}
              rules={{
                validate: (value) => {
                  if (value.length === 0){
                    return 'You must select a district';
                  }
                  return true;
                },
              }}
            />
            <Typography variant="sFormHelper">
              {errors?.district_ids && errors?.district_ids?.message}
            </Typography>
          </Box>
          <Box mt={1}>
            <Controller
              name='grade_ids'
              defaultValue={grades!}
              value={grades}
              render={({ onChange }) => (
                <GradeMultiSelect
                  onChange={(data) => {
                    setGradeIDs(data!);
                    onChange(data);
                  }}
                  value={grades}
                  districts={districts!}
                  disabled={!districts.length}
                />
              )}
              control={control}
            />
          </Box>
          <Box mt={1}>
            <Controller
              name='school_ids'
              defaultValue={schools!}
              value={schools}
              render={({ onChange }) => (
                <SchoolMultiSelect
                  value={schools}
                  districts={districts}
                  grades={grades}
                  disabled={!districts.length}
                  onChange={(data) => {
                    setSchoolIDs(data);
                    onChange(data);
                  }}
                />
              )}
              control={control}
            />
          </Box>
          <Box mt={theme}>
            {
              !!districts.length && !studentsCount && <Typography variant="sFormHelper">
              Please change Assign To fields for positive student count for Benchmark
              </Typography>
            }
            {
              !!districts.length && benchmarkCountExceeded && <Typography variant="sFormHelper">
                Please change Assign To fields. Student count for benchmark exceeds 5000 students
              </Typography>
            }
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AssignToFilterCard;
