import { createContext } from 'react';

export interface SearchContextValues {
  searchTerm?: string | null,
  isSearchEnabled: boolean,
  searchSegment?: string | null,
  placeholder: string,
  setSearchEnabled?: () => void,
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}
export const SearchContext = createContext<SearchContextValues>({
  isSearchEnabled: false,
  searchSegment: '',
  placeholder: 'Search',
});
