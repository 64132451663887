import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import useAudioPlayer from '../../utils/useAudioPlayer';
import { StyledMicIcon, styledTypo } from './Player.styled';
import PauseIcon from '../Icons/PauseIcon';
import PlayIcon from '../Icons/PlayIcon';
import { SpeakIcon } from '../Icons';
import Loader from '../Loader/loader';
import AudioControls from '../AudioControls';
import AudioSlider from '../AudioSlider';
import { palette  } from '../../theme/palette';

const styles = {
  pauseButton: {
    color: palette.customPink.main,
  },
  buttonSpeak: {
    '&.MuiButtonBase-root.MuiButton-root': {
      padding: 1.5,
      /*
       * Removed default minHeight & minWidth set on material-ui button.
       * https://stackoverflow.com/a/47191093/1682304
      */
      minHeight: 0,
      minWidth: 0,
      borderRadius: '50%',
      '&::after': {
        borderRadius: '50%',
      },
    },
  },
  buttonSpeakDisabled: {
    backgroundColor: palette.customBackground.disabled,
    color: palette.customBackground.disabled,
    '&::after': {
      borderColor: palette.customBackground.disabled,
    },
  },
};

interface Props {
  url?: string;
  fileType?: string;
  showLabel?: boolean,
  label?: string;
  setIsPlaying?: Function;
  disablePlay?: boolean,
  buttonOnly?: boolean,
  flexStructure?: boolean,
  onPlaying?: (id: string) => void;
  playerId?: string;
  active?: boolean;
  singlePlayer?: boolean;
  backpackPlayer?: boolean;
  ptSubmissionPlayer?: boolean;
  customStyle?: boolean;
}

const AudioPlayer = ({
  url,
  showLabel = false,
  label,
  setIsPlaying,
  disablePlay = false,
  buttonOnly = false,
  flexStructure = false,
  onPlaying,
  playerId,
  active,
  singlePlayer,
  backpackPlayer,
  ptSubmissionPlayer,
  customStyle,
}: Props) => {
  const { playing, togglePlaying, duration, currentTime, setTime, isLoading } = useAudioPlayer({ url, onPlaying, active, playerId });

  // TODO - check with the slider debounce effect
  const handleAudioSliderChange = (event: React.SyntheticEvent | Event, time: number | Array<number>) => {
    setTime(time as number);
  };

  useEffect(() => {
    setIsPlaying?.(playing);
  }, [playing, setIsPlaying]);

  return (
    <Box sx={{ width:'100%' }}>
      <Loader open={isLoading} />
      {!buttonOnly && !flexStructure && !singlePlayer && !backpackPlayer && !ptSubmissionPlayer && (
        <Box
          sx={{
            gridRowGap: 10,
            width:'100%',
            display:'flex',
            justifyContent:'space-between',
            flexDirection:'column',
          }}
        >
          {showLabel && (
            <Box sx={{ paddingLeft: '16px' }}>
              <Typography variant ='sTaskTitle' sx={{ ...styledTypo }}>
                {`${label ?? 'Recording'}`}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display : 'grid',
              gridTemplateColumns :'19px 1fr 7fr',
              alignItems : 'center',
              alignContent : 'center',
              width : '100%',
            }}
          >
            <Box sx={{ gridColumn : 2 }}>
              <AudioControls disabled={disablePlay} onClick={togglePlaying} playing={playing} />
            </Box>
            <Box>
              <AudioSlider currentTime={currentTime} customStyle={customStyle} onChangeCommitted={handleAudioSliderChange} max={duration} />
            </Box>
          </Box>
        </Box>
      )}
      {buttonOnly && (
        <Button
          sx={{ ...styles.buttonSpeak, ...(playing && styles.buttonSpeakDisabled) }}
          variant="outlined"
          color="secondary"
          disabled={playing}
          onClick={togglePlaying}
        >
          <SpeakIcon
            pathFill={
              playing
                ? palette.customBackground.disabled
                : palette.customYellow.secondaryDark
            }
          />
        </Button>
      )}
      {
        flexStructure && (
          <>
            <Box>
              {showLabel && (
                <Typography variant ='sTaskTitle' sx={{ ...styledTypo }}>
                  {`${label ?? 'Recording'}`}
                </Typography>
              )}
              <Box
                sx={{
                  width:'100%',
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                  flexDirection:'column',
                }}
              >
                <AudioControls disabled={disablePlay} onClick={togglePlaying} playing={playing} />
                {
                  playing
                  && (
                    <AudioSlider currentTime={currentTime} onChangeCommitted={handleAudioSliderChange} max={duration} />
                  )
                }
              </Box>
            </Box>
          </>
        )
      }
      {
        singlePlayer && (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              display:'flex',
              flexDirection:'column',
            }}
          >
            <Box
              sx={{
                width:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column',
              }}
            >
              {showLabel && (
                <Typography variant ='sTaskTitle' sx={{ ...styledTypo }}>
                  {`${label ?? 'Recording'}`}
                </Typography>
              )}

              <StyledMicIcon
                variant="contained"
                color="primary"
                disabled={disablePlay}
                onClick={togglePlaying}
              >
                {playing ? (<PauseIcon />) : <PlayIcon />}
              </StyledMicIcon>
            </Box>

          </Box>
        )
      }
      {singlePlayer && (
        <AudioSlider currentTime={currentTime} onChangeCommitted={handleAudioSliderChange} max={duration} />
      )}
      {
        backpackPlayer && (
          <Box
            sx={{
              width:'100%',
              display:'flex',
              justifyContent:'space-between',
              flexDirection:'column',
            }}
          >
            {showLabel && (
              <Typography variant ='sTaskTitle' sx={{ ...styledTypo }}>
                {`${label ?? 'Recording'}`}
              </Typography>
            )}
            <Box
              sx={{
                display:'grid',
                gridGap: 8,
                alignItems:'center',
                alignContent:'center',
                width:'100%',
                gridTemplateColumns:(customStyle ? '0.1fr 6fr' : '1fr 6fr'),
                marginTop: (customStyle ? 2 : 3),
              }}
            >
              <AudioControls disabled={disablePlay} onClick={togglePlaying} playing={playing} />
              <AudioSlider customStyle={customStyle} currentTime={currentTime} onChangeCommitted={handleAudioSliderChange} max={duration} />
            </Box>
          </Box>
        )
      }
      {
        ptSubmissionPlayer && (
          <Box 
            sx={{
              width: '66px',
              height: '66px',
              alignItems: 'center',
              backgroundColor: palette.customGrey.main,
              borderRadius: '10%',
              pt: 1.5,
              pr: 1,
              pb: 1,
              pl: 1.5,
            }}
          >
            <AudioControls disabled={disablePlay} onClick={togglePlaying} playing={playing} ptSubmissionPlayer />
          </Box>
        )
      }
    </Box>
  );
};
export default React.memo(AudioPlayer);
