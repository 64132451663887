import React, { useMemo } from 'react';
import {
  TableRow,
  TableCell,
  Box,
  Typography,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { PROVIDER_TYPE } from '../../../variables/constant';
import { SchoolDetails } from '../../../generated/graphql';
import SuccessIcon from '../../../components/Icons/SuccessIcon';
import sortGradeMapping from '../../../utils/sortGradeMapping';
import { StyledTableDataColumn } from '../../Admin/admin-tables-styled';

interface Props {
  school: SchoolDetails,
}

const SchoolRow = ({
  school,
}: Props) => {
  const grades = useMemo(() => {
    const predefineGradeMapping = [...(school?.school_predefined_grade_mappings || [])];
    return sortGradeMapping(predefineGradeMapping);
  }, [school]);

  return (
    <TableRow key={school.id}>
      <TableCell>
        <Typography
          component={Link}
          variant='tableTitleData'
          to={`/schools/${school.id}`}
        >
          {school.name}
        </Typography>
      </TableCell>
      <StyledTableDataColumn>
        {school?.school_id}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <Box
          component="span"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {grades ? grades?.map((grade, index) => (
            <Typography key={grade?.predefined_grades?.id} component="span" sx={{ marginRight: '4px' }}>
              {grade?.predefined_grades?.grade ?? ''}
              {school?.school_predefined_grade_mappings && index !== school?.school_predefined_grade_mappings?.length - 1 ? ', ' : ''}
            </Typography>
          )) : '-'}
        </Box>
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {school?.district_name ?? '-'}
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        <NumberFormat
          displayType="text"
          value={school?.student_count ?? '-'}
          thousandSeparator
        />
      </StyledTableDataColumn>
      <StyledTableDataColumn>
        {school?.district_source !== PROVIDER_TYPE.FLASHLIGHT ? <SuccessIcon /> : ''}
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default SchoolRow;
