import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import React, { useContext } from 'react';
import { PAGE_SIZE } from '../../../variables/constant';
import {
  DistrictFilterFragmentFragment,
  OrderBy, SchoolsListSortType,
  StateFilterFragmentFragment,
  UserPermissions,
  useSchoolsQuery,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { SchoolSort } from '../../../variables/types';
import usePermission from '../../../hooks/usePermission';
import { SearchContext } from '../../../core/searchContext';

const useSchools = () => {
  const pagination = usePagination();
  const { searchTerm } = useContext(SearchContext);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, SchoolSort.Name));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Asc));
  const [selectedState, setStateValue] = useQueryParam('state', StringParam);
  const [selectedDistrict, setSelectedDistrict] = useQueryParam('district', StringParam);
  const { hasPermission: districtPermission } = usePermission([
    UserPermissions.ListDistricts,
    UserPermissions.ViewDistricts,
  ]);
  const { data, loading } = useSchoolsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort: sort as SchoolsListSortType,
      searchByName: searchTerm,
      orderBy: order as OrderBy,
      ...(selectedState ? { stateId: selectedState } : {}),
      ...(selectedDistrict ? { districtId: selectedDistrict } : {}),
    },
  });

  const onSortChange = (sortBy: SchoolSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const schools =  data?.schoolsList.nodes ?? [];
  const totalCount = data?.schoolsList.totalCount ?? 0;

  const isTableEmpty = !loading && schools?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    pagination.setPage(value as number);
  };

  const handleSort = (column: SchoolSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const onStateChange = (state: string | StateFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setStateValue(state as string);
    setSelectedDistrict(null);
  };

  const onDistrictChange = (district: string | DistrictFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSelectedDistrict(district as string);
  };

  return {
    loading,
    totalCount,
    schools,
    pagination,
    onSortChange,
    sort: sort as SchoolSort,
    order: order as OrderBy,
    isTableEmpty,
    handlePageChange,
    handleSort,
    selectedState,
    onStateChange,
    selectedDistrict,
    onDistrictChange,
    districtPermission,
  };
};

export default useSchools;
