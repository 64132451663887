import { useParams } from 'react-router-dom';
import { useDistrictDetailsQuery, useMyDistrictQuery, UserPermissions } from '../../../generated/graphql';
import { MY_DISTRICT } from '../../../variables/constant';
import usePermission from '../../../hooks/usePermission';

interface RouteProp {
  id: string;
}

const useDistrict = () => {
  const { id } = useParams<RouteProp>();
  const { data, loading } = useDistrictDetailsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
    skip: id === MY_DISTRICT,
  });

  const { data: myDistrictData, loading: myDistrictLoading } = useMyDistrictQuery({
    fetchPolicy: 'network-only',
    skip: id !== MY_DISTRICT,
  });
  const { hasPermission: editDistrictPermission } = usePermission(UserPermissions.EditDistricts);
  const { hasPermission: listSchoolsPermission } = usePermission(UserPermissions.ListSchools);
  const { hasPermission: mySchoolPermission } = usePermission([UserPermissions.MySchool]);

  return {
    loading: loading || myDistrictLoading,
    district: data?.districtDetails || myDistrictData?.myDistrict,
    editDistrictPermission,
    listSchoolsPermission,
    mySchoolPermission,
  };
};

export default useDistrict;
