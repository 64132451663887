import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { isIpad } from '../../variables/constant';

export const styledTypo = {
  fontSize: isIpad ? '14px' : '18px',
};


export const StyledMicIcon = styled(Button)({
  minWidth: 50,
  width: 50,
  height: 50,
  borderRadius: '50%',
  '&::after': {
    borderRadius: '50%',
  },
});

export const StyledMicIcon2 = styled(Button)({
  minWidth: 40,
  width: 40,
  height: 40,
  borderRadius: '50%',
  '&::after': {
    borderRadius: '50%',
  },
});
