import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DistrictWideComparisonsTabs } from '../../../variables/types';
import Loader from '../../../components/Loader/loader';
import {
  StyledDistrictWideComparisonsTabs,
  StyledTabs,
  DistrictWideComparisonsStylesCSS,
} from './DistrictWideComparisons.styled';
import useDistrictWideComparisons, {
  DistrictWideComparisonsContext,
} from './district-wide-comparisons-hook';
import Schools from './Schools';
import Grades from './Grades';

const DistrictWideComparisons = () => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);
  const {
    getTagStyleClass,
    isSchoolAdmin, isDistrictAdmin,
    loading, setLoading,
    widgetLoading, setWidgetLoading,
    schoolsDataCount, setSchoolsDataCount,
    schoolsDataQueryInput, setSchoolsDataQueryInput,
    schoolSpecificDataQueryInput, setSchoolSpecificDataQueryInput,
    gradesDataCount, setGradesDataCount,
    gradesDataQueryInput, setGradesDataQueryInput,
    gradeSpecificDataQueryInput, setGradeSpecificDataQueryInput,
    tab, handleTabChange,
    myDistrictData, mySchoolData,
  } = useDistrictWideComparisons();

  return (
    <>
      <DistrictWideComparisonsContext.Provider value={{
        getTagStyleClass,
        isSchoolAdmin, mySchoolData,
        isDistrictAdmin, myDistrictData,
        loading, setLoading,
        widgetLoading, setWidgetLoading,
        schoolsDataCount, setSchoolsDataCount,
        schoolsDataQueryInput, setSchoolsDataQueryInput,
        schoolSpecificDataQueryInput, setSchoolSpecificDataQueryInput,
        gradesDataCount, setGradesDataCount,
        gradesDataQueryInput, setGradesDataQueryInput,
        gradeSpecificDataQueryInput, setGradeSpecificDataQueryInput,
      }}>
        <Loader open={loading || widgetLoading} />
        <Switch>
          <Redirect
            from="/admin/analytics/district-wide-comparisons"
            exact
            to={`/admin/analytics/district-wide-comparisons/${DistrictWideComparisonsTabs.Schools}`}
          />
        </Switch>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          sx={styles.dashBoardContainer}
        >
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="pageTitle">
              {isSchoolAdmin ? 'School' : 'District'}-wide Comparisons
            </Typography>
          </Box>
          <StyledTabs
            value={tab}
            onChange={handleTabChange}
            textColor="primary"
            scrollButtons={false}
          >
            {
              Object.entries(DistrictWideComparisonsTabs).map(([tabLabel, tabKey]) => {
                return (
                  <StyledDistrictWideComparisonsTabs
                    key={tabKey as string}
                    classes={{
                      root: `${tabKey as string}`,
                    }}
                    label={(
                      <Typography>
                        <Typography
                          component="span"
                          className="tag"
                        >
                          {tabKey === DistrictWideComparisonsTabs.Schools && (schoolsDataCount || '-')}
                          {tabKey === DistrictWideComparisonsTabs.Grades && (gradesDataCount || '-')}
                        </Typography>
                        <Typography component="span">
                          {tabLabel as string}
                        </Typography>
                      </Typography>
                    )}
                    disableRipple
                  />
                );
              })
            }
          </StyledTabs>
          {
            tab === 0 ? (
              <Schools
                districtId={myDistrictData?.id}
                schoolId={mySchoolData?.id}
              />
            ) : (
              <Grades
                districtId={myDistrictData?.id}
                schoolId={mySchoolData?.id}
              />
            )
          }
        </Box>
      </DistrictWideComparisonsContext.Provider>
    </>
  );
};

export default DistrictWideComparisons;
