import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { GroupsFragment } from '../../../generated/graphql';
import NoResults from '../../../components/NoResults';
import HumanDeskFrame from '../../../components/Icons/HumanDeskFrame';
import { StyledStudentViewButton } from '../Groups.styled';
import { StyledClassNameTableCell, StyledGroupNameTableCell, StyledStudentCountTableCell } from '../Groups.styled';
import { palette  } from '../../../theme/palette';

interface Props {
  stickyHeader?: boolean,
  loading?: boolean,
  groups?: GroupsFragment[],
}

const tableWithNoDataStyles = {
  height: '100%',
  '& tbody tr:hover': {
    backgroundColor: palette.transparent.main,
    cursor: 'initial',
  },
};

const GroupsTable = ({
  loading = false,
  stickyHeader = true,
  groups,
}: Props) => {
  const history = useHistory();
  const isTableEmpty = !loading && groups?.length === 0;

  const navigateTo = (id: string) => {
    history.push(`/groups/${id}/manage`);
  };

  return (
    <Table
      stickyHeader={stickyHeader}
      aria-label="student directory table"
      sx={loading || isTableEmpty ? tableWithNoDataStyles : {}}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            Group
          </TableCell>
          <TableCell>
            Students
          </TableCell>
          <TableCell>
            Class
          </TableCell>
          <TableCell>
            School
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {
          !loading && groups?.map((group) => (
            <TableRow key={group.id}>
              <StyledGroupNameTableCell onClick={() => navigateTo(group.id)}>
                {group?.name ?? ''}
              </StyledGroupNameTableCell>
              <StyledStudentCountTableCell>
                {group?.student_count ?? ''}
              </StyledStudentCountTableCell>
              <StyledClassNameTableCell>
                {group?.parent_section_name ?? ''}
              </StyledClassNameTableCell>
              <StyledClassNameTableCell>
                {group?.school?.name ?? ''}
              </StyledClassNameTableCell>
              <StyledStudentCountTableCell align="right">
                <StyledStudentViewButton
                  size="large"
                  variant="outlined"
                  onClick={() => navigateTo(group.id)}
                >
                  MANAGE
                </StyledStudentViewButton>
              </StyledStudentCountTableCell>
            </TableRow>
          ))
        }
        {isTableEmpty && (
          <TableRow>
            <TableCell
              colSpan={4}
              align="center"
            >
              <NoResults
                label="No groups yet"
                description="You have not created any groups at this time."
                svgComponent={<HumanDeskFrame />}
              />
            </TableCell>
          </TableRow>
        )}
        {loading && (
          <TableRow>
            <TableCell
              colSpan={4}
              align="center"
            >
              <CircularProgress size={28} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default GroupsTable;
