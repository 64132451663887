import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  GoalStatus,
  SubmissionStatus,
  useCreateUpdateStudentGoalMutation,
  useGetPredefinedGoalsQuery,
  useNewSubmissionQuery,
  useUpdateScoreMutation,
  useMarkReviewCompleteMutation,
  useSubmissionGoalInfoQuery,
  useUpdateFeedbackNotesMutation,
  useUpdateGoalStatusMutation,
  useAnchorToggleStatusQuery,
  useAnchorToggleUpdateMutation,
  useGetGoalsQuery,
  GoalType,
} from '../../generated/graphql';
import {
  SUBMISSION_ALREADY_REVIEWED_MESSAGE,
  SUBMISSION_ALREADY_REVIEWED_ERROR,
} from '../../variables/constant';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType, ScoreTabs } from '../../variables/types';
import useSubmissionQueueHook from './submission-queue-hook';
import useSidebarHook from '../../components/Sidebar/sidebar-hook';

export interface RoutePropSection {
  id: string;
  section?: string;
}

export const getIndexBySection = (section?: string) => {
  if (!section || section === ScoreTabs.Speaking) {
    return 0;
  }
  if (section === ScoreTabs.Writing) {
    return 1;
  }
  return 2;
};

const useScore = () => {
  const [notes, setNotes] = useState('');
  const { id, section } = useParams<RoutePropSection>();
  const [tab, setTab] = useState(getIndexBySection(section));
  const [expanded, setExpanded] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isAnchorPlaying, setIsAnchorPlaying] = useState(false);
  const { setQueueCounter } = useSubmissionQueueHook();
  const { refetch: refetchNavbarSubmissionCount } = useSidebarHook();
  const history = useHistory();
  const { data: submissionData, loading: submissionLoading, refetch: submissionsRefetch } = useNewSubmissionQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: id!,
    },
  });
  const { data: predefinedGoalsData, loading: predefinedLoading, refetch: predefinedRefetch } = useGetPredefinedGoalsQuery({
    fetchPolicy: 'no-cache',
  });
  const submission = submissionData?.newSubmission;
  const { data: anchor, refetch: anchorRefetch } = useAnchorToggleStatusQuery({
    fetchPolicy: 'no-cache',
  });

  const anchorEnabled = anchor?.anchorToggle;

  const scoreSummaryOptions = (submission?.scoring_details.map((scoreDetail) => ({
    id: scoreDetail.id,
    value: scoreDetail.id,
    label: scoreDetail.score_value.toString(),
  }))) ?? [];

  const [updateFeedbackNotes] = useUpdateFeedbackNotesMutation();
  const [updateScore, { loading: updating }] = useUpdateScoreMutation();
  const [markReviewCompleteMutation, { loading: submitting }] = useMarkReviewCompleteMutation();
  const [anchorToggle, { loading: updateToggle }] = useAnchorToggleUpdateMutation();

  const handleAnchorToggleUpdate = async (value: boolean) => {
    try {
      await anchorToggle({
        fetchPolicy: 'network-only',
        variables: {
          showToggle: value,
        },
      });
      anchorRefetch();
    } catch (err) {
      openSnackbar({ message: 'Show Anchor value was not updated' }, NotifierType.Error);
    }
  };

  const saveNotes = async () => {
    try {
      const response = await updateFeedbackNotes({
        variables: {
          id,
          notes,
        },
      });
      if (!response.data?.updateFeedbackNotes?.id) {
        openSnackbar({ message: 'Error in updating notes.' }, NotifierType.Error);
      }
    } catch (error) {
      showError(error);
    }
  };

  const handleScoreUpdate = async (gradeId: string, scoreDetailId: string) => {
    try {
      await updateScore({
        variables: {
          gradeId,
          scoreDetailID: scoreDetailId,
        },
      });

      submissionsRefetch();
    } catch (error) {
      showError(error);
    }
  };

  const handleSubmit = async (submissionId: string) => {
    try {
      const response = await markReviewCompleteMutation({
        variables: {
          submissionId,
        },
      });
      if (!response.data?.markReviewComplete?.status || response.data.markReviewComplete.status !== SubmissionStatus.Reviewed) {
        openSnackbar({ message: 'Error in submitting review.' }, NotifierType.Error);
      } else setQueueCounter({ graded: 1, goalsSet: 0 });
    } catch (error) {
      showError(error);
    }
  };

  const { data: activeGoals, loading: fetchingGoals, refetch } = useGetGoalsQuery({
    fetchPolicy: 'network-only',
    variables: {
      goalType: GoalType.Active,
      submissionID: submission?.id,
    },
    skip: !submission?.id,
  });

  const showError = (error: any) => {
    if (getErrorMessage(error) === SUBMISSION_ALREADY_REVIEWED_ERROR) {
      openSnackbar({
        message: SUBMISSION_ALREADY_REVIEWED_MESSAGE,
      }, NotifierType.Error);
      history.push('/');
      refetchNavbarSubmissionCount();
    } else {
      openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
    }
  };

  const [updateGoalStatus, { loading: updatingStatus }] = useUpdateGoalStatusMutation();
  const [createUpdateGoal, { loading: adding }] = useCreateUpdateStudentGoalMutation();
  const submissionID = submission?.id ?? '';

  const {
    data: submissionGoalInfo,
    loading: submissionGoalInfoLoading,
    refetch: submissionGoalInfoRefetch,
  } = useSubmissionGoalInfoQuery({
    fetchPolicy: 'network-only',
    variables: {
      submissionID,
    },
    skip: !submissionID,
  });

  const speakingGoalCount = submissionGoalInfo
    ?.getSubmissionGoalInfo?.speaking?.inactive_goal_count ?? 0;

  const writingGoalCount = submissionGoalInfo
    ?.getSubmissionGoalInfo?.writing?.inactive_goal_count ?? 0;

  const handleGoalStatus = async (goalId: string, status: GoalStatus) => {
    try {
      const response = await updateGoalStatus({
        variables: {
          goalId,
          status,
        },
      });
      if (!response.data?.updateGoalStatus?.id) {
        openSnackbar({ message: 'Error in updating status.' }, NotifierType.Error);
      } else {
        submissionsRefetch();
      }
    } catch (error) {
      showError(error);
    }
  };

  const addStudentGoal = async (goalId: string, existingGoalId?: string) => {
    try {
      const response = await createUpdateGoal({
        variables: {
          id: existingGoalId,
          submissionId: id,
          goalId,
        },
      });
      if (!response.data?.createUpdateStudentGoal?.id) {
        openSnackbar({ message: 'Error in creating goal.' }, NotifierType.Error);
      } else {
        setQueueCounter({ graded: 0, goalsSet: 1 });
        openSnackbar({ message: 'Goal created successfully' }, NotifierType.Success);
      }
    } catch (error) {
      showError(error);
    }
  };

  const togglePreview = () => setShowPreview(!showPreview);

  useEffect(() => {
    setTab(getIndexBySection(section));

    // TODO - update at the time of initializing state,
    // See - https://technine.atlassian.net/browse/ABL-1679?focusedCommentId=61749
    setNotes(submission?.feedback_notes ?? '');
  }, [section, submission]);

  return {
    id,
    section,
    tab,
    setTab,
    expanded,
    setExpanded,
    loading: submissionLoading,
    submission,
    handleScoreUpdate,
    updating,
    handleSubmit,
    submitting,
    showPreview,
    togglePreview,
    activeGoals,
    fetchingGoals,
    handleGoalStatus,
    updatingStatus,
    addStudentGoal,
    adding,
    refetch,
    submissionsRefetch,
    predefinedGoalsData,
    predefinedLoading,
    predefinedRefetch,
    scoreSummaryOptions,
    submissionGoalInfoRefetch,
    speakingGoalCount,
    writingGoalCount,
    submissionGoalInfoLoading,
    handleAnchorToggleUpdate,
    anchorEnabled,
    updateToggle,
    anchorRefetch,
    notes,
    setNotes,
    saveNotes,
    isAnchorPlaying,
    setIsAnchorPlaying,
  };
};

export default useScore;
