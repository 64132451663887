import { StringParam, useQueryParam } from 'use-query-params';
import {
  GoalType,
  SectionFilterFragmentFragment, TeacherOfStudentFilterFragmentFragment,
  useGetActiveGoalsQuery,
  useStudentAssignmentsQuery,
} from '../../../generated/graphql';

const useStudentsProgress = () => {
  const [selectedTeacher, setSelectedTeacher] = useQueryParam('teacher', StringParam);
  const [selectedSection, setSelectedSection] = useQueryParam('section', StringParam);

  const onTeacherChange = (teacher: string | TeacherOfStudentFilterFragmentFragment | null) => {
    setSelectedTeacher(teacher as string);
  };

  const onSectionChange = (section: string | SectionFilterFragmentFragment | null) => {
    setSelectedSection(section as string);
  };

  const { data: goalsDetail, loading: goalsLoading } = useGetActiveGoalsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      goalType: GoalType.Active
    },
  });

  const { data: previousGoals, loading: perviousLoading } = useGetActiveGoalsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      goalType: GoalType.Attached
    },
  });

  const { data, loading } = useStudentAssignmentsQuery({
    fetchPolicy: 'no-cache'
  });

  const studentTasks = data?.getAllAssignmentsAndBenchmarksForStudent;
  const scoreScheme = data?.getAllAssignmentsAndBenchmarksForStudent?.score_details ?? [];

  const handleReset = () => {
    setSelectedSection('');
    setSelectedTeacher('');
  };

  return {
    loading: loading || goalsLoading || perviousLoading,
    studentTasks,
    previousGoals,
    goalsDetail,
    selectedTeacher,
    setSelectedTeacher,
    onTeacherChange,
    selectedSection,
    setSelectedSection,
    onSectionChange,
    handleReset,
    scoreScheme,
  };
};

export default useStudentsProgress;
