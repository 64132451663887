import React from 'react';
import { palette } from '../../../theme/palette';
import { Box, Typography } from '@mui/material';
import LegendsIcon from '../../Icons/LegendsIcon';
import { getMonthName } from '../CommonFunctions';

interface Props {
  active?: string,
  payload?: any
}

const style = {
  padding: 10,
  backgroundColor: palette.customWhite.main,
  border: `2px solid ${palette.customRed.progressPrimary}`,
  width:'200px',
  height: 'auto',
  borderRadius: '8px',
  boxShadow: `0 3px 5px 1px ${palette.customBackground.disabled}`,
};


const CustomTooltip = ({ active, payload }:Props) => {
  let calendarDate = new Date(payload[0]?.payload?.timestamp * 1000);
  let monthName =  getMonthName(calendarDate.getMonth() + 1);
  let monthDate =  calendarDate.getDate();
  let fullYear = calendarDate.getFullYear();
  if (active) {
    return (
      <Box className="line-chart-tooltip" style={style}>
        <Typography
          component='p'
          sx={{
            fontSize:'16px',
            fontWeight:600,
            color: palette.customGrey.calendarGrey,
            mb:'5px',
          }}
        >
          {monthName} {monthDate}, {fullYear}
        </Typography>
        {payload?.map((item:any) => {
          return (
            <Box key={item?.color}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box
                  display='flex'
                  alignItems='center'
                >
                  <LegendsIcon pathFill={item?.color}/>
                  <Typography
                    component='span'
                    sx={{
                      ml: '10px',
                      color: palette.customBlack.fontBlack,
                      fontSize:'14px',
                      fontWeight: 600,
                    }}
                  >{item?.value ? item?.name : null} : </Typography>
                </Box>
                <Typography
                  component='span'
                  sx={{
                    ml: '10px',
                    fontSize:'14px',
                    fontWeight: 800,
                    color: palette.customBlack.fontBlack,
                    textAlign: 'right',
                  }}
                >
                  {item.value ? item.value : '-'}
                </Typography>
              </Box>
            </Box>
          );
        },

        )}
      </Box>
    );
  }

  return null;
};

export default CustomTooltip;
