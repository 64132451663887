import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { DistrictSort, PaginationProps } from '../../../variables/types';
import TablePagination from '../../../components/TablePagination';
import { PAGE_SIZE, PROVIDER_TYPE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { DistrictDetails, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import SuccessIcon from '../../../components/Icons/SuccessIcon';
import { StyledTableCell, StyledTableDataCell, StyledTableDataColumn } from '../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../components/Common/Common.styled';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: DistrictSort,
  order: OrderBy,
  districts: DistrictDetails[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: DistrictSort) => void) | Function,
}


const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  districts,
  handleSort,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  const sortColumn = (title: string, sortEnum: DistrictSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );
  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box>
        <StyledContainerRoot>
          <Table
            stickyHeader
            aria-label="districts table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ textAlign: 'left' }}>
                  { sortColumn('District', DistrictSort.Name) }
                </StyledTableCell>
                <StyledTableCell>
                  { sortColumn('District ID', DistrictSort.DistrictId) }
                </StyledTableCell>
                <StyledTableDataCell>
                  { sortColumn('State', DistrictSort.State) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('Schools', DistrictSort.SchoolCount) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('Students', DistrictSort.StudentCount) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  Clever
                </StyledTableDataCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && districts?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography
                      component={Link}
                      variant='tableTitleData'
                      to={`/districts/${row.id}`}
                    >
                      {row.name}
                    </Typography>
                  </TableCell>
                  <StyledTableDataColumn>
                    {row?.district_id}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.state?.name ?? '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    <NumberFormat
                      displayType="text"
                      value={row?.school_count ?? '-'}
                      thousandSeparator
                    />
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    <NumberFormat
                      displayType="text"
                      value={row?.student_count ?? '-'}
                      thousandSeparator
                    />
                  </StyledTableDataColumn>
                  <TableCell align="center">
                    {row?.source !== PROVIDER_TYPE.FLASHLIGHT ? <SuccessIcon /> : ''}
                  </TableCell>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="No Districts"
                      description="There has yet to be a district created."
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      {totalCount > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
    </Box>
  );
};
export default List;
