/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { svgStyles } from '../Common/Common.styled';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { StateFilterFragmentFragment } from '../../generated/graphql';
import useStatesFilter from './states-filter-hook';
import StateIcon from '../Icons/StateIcon';
import { palette  } from '../../theme/palette';
import { SxProps, Theme } from '@mui/system';

interface Props {
  error?: boolean;
  className?: string;
  value?: StateFilterFragmentFragment | null | string;
  onChange?: (data: StateFilterFragmentFragment | null | string) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?:boolean;
  readOnly?:boolean;
  isVariableHeight?: boolean
  sx?: SxProps<Theme> | undefined;
}

type StateValue = StateFilterFragmentFragment | null | undefined;

const StateFilter = ({
  className,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  readOnly = false,
  disabled,
  isVariableHeight,
  sx,
}: Props) => {
  const {
    states,
    loading,
  } = useStatesFilter();
  const onSelectChange = (_: any, state: StateFilterFragmentFragment | null) => {
    onChange?.(idSelectOnly ? (state?.id ?? '') : state);
  };


  const selectedValue: StateValue = idSelectOnly ? states?.find((item) => item.id === value) ?? null : value as StateValue;
  return (
    <Autocomplete
      className={className}
      options={states || []}
      getOptionLabel={(option: any) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      onChange={onSelectChange}
      value={selectedValue}
      readOnly={readOnly}
      disabled={disabled || loading}
      sx={sx}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: {
              ...(sx ? {  backgroundColor: palette.customWhite.whiteSmoke } : { backgroundColor: 'inherit' }),
              fontWeight: 800,
              ...(isVariableHeight) && { height: '48px' },
              fontSize: '16px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <StateIcon pathfill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(StateFilter);
