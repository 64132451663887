import * as React from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { PAGE_SIZE } from '../../variables/constant';
import {
  OrderBy, SectionFilterFragmentFragment,
  SubmissionStatus,
  useSubmissionsIdQueueQuery,
  useSubmissionsQuery,
} from '../../generated/graphql';
import usePagination from '../../utils/usePagination';
import { SubmissionFilter, SubmissionSort } from '../../variables/types';
import { SelectChangeEvent } from '@mui/material/Select';
import { SchoolValue } from '../../components/SchoolFilterTeacher';

export const getFilterList = (filter?: string | null) => {
  if (filter === SubmissionFilter.Scored) {
    return [SubmissionStatus.Reviewed];
  }
  if (filter === SubmissionFilter.NotScored) {
    return [
      SubmissionStatus.Submitted,
    ];
  }
  return [
    SubmissionStatus.Reviewed,
    SubmissionStatus.Submitted,
  ];
};

export interface TeacherSubmissionsParams {
  assigmentId?: string
  sort?: SubmissionSort
  statusList?: SubmissionStatus[]
}

const useSubmissions = (
  params?: TeacherSubmissionsParams
) => {
  const pagination = usePagination();
  const [search, setSearchValue] = useQueryParam('search', StringParam);
  const [filter, setFilter] = useQueryParam('filter', withDefault(StringParam, SubmissionFilter.NotScored));
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, params?.sort ?? SubmissionSort.Date));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [assigmentId, setAssigmentId] = React.useState<string | undefined>(params?.assigmentId);
  const [section, setSectionValue] = useQueryParam('section', StringParam);
  const [school, setSchoolValue] = useQueryParam('school', StringParam);

  const { data, loading } = useSubmissionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      search,
      assignment_id: assigmentId,
      statusList: params?.statusList ?? getFilterList(filter),
      sort: sort || 'submitted_at',
      orderBy: order as OrderBy,
      ...(section ? { section_id: section } : {}),
      ...(school ? { school_id: school } : {}),
    },
  });

  const { data: submissionQueueData, loading: submissionQueueLoading } = useSubmissionsIdQueueQuery({
    fetchPolicy: 'network-only',
    variables: {
      search,
      status: getFilterList(filter),
      sort: sort || 'submitted_at',
      orderBy: order as OrderBy,
      ...(section ? { section_id: section } : {}),
      ...(school ? { school_id: school } : {}),
    },
  });

  const onSortChange = (sortBy: SubmissionSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const onFilter = (event: SelectChangeEvent<string[]>) => {
    pagination.setPage(1);
    setFilter(event.target.value as string);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination.setPage(1);
    if (event.target.value === '') {
      setSearchValue(undefined);
    } else {
      setSearchValue(event.target.value);
    }
  };

  const onSectionChange = (sectionData: string | SectionFilterFragmentFragment | null) => {
    pagination.setPage(1);
    setSectionValue(sectionData as string);
  };

  const onSchoolChange = (schoolData: SchoolValue | string) => {
    pagination.setPage(1);
    setSectionValue(undefined);
    setSchoolValue(schoolData as string);
  };

  const submissions = data?.submissions?.nodes ?? [];
  const totalCount = data?.submissions?.totalCount ?? 0;

  return {
    loading: loading || submissionQueueLoading,
    submissions,
    totalCount,
    pagination,
    search,
    onSearch,
    filter,
    onFilter,
    onSortChange,
    sort: sort as SubmissionSort,
    order: order as OrderBy,
    submissionQueueData: submissionQueueData?.submissionQueue?.IDs ?? [],
    assigmentId, setAssigmentId,
    section,
    onSectionChange,
    school,
    onSchoolChange,
  };
};

export default useSubmissions;
