import { PAGE_SIZE } from '../../../variables/constant';
import { OrderBy, BenchmarkTeacherDtoSortType, useTeacherBenchmarksListQuery } from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { useRef } from 'react';
import { toUTC } from '../../../utils/dateFormat';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import useBenchmarkTypes from '../../../components/BenchmarkTypes/benchmarkTypes-hook';

const useTeacherBenchmarksList = () => {
  const pagination = usePagination();
  const now = useRef(toUTC(new Date()));
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, BenchmarkTeacherDtoSortType.StartDate));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));

  const onSortChange = (sortBy: BenchmarkTeacherDtoSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const {
    loading: benchmarkTypesLoading,
    benchmarkTypes,
    getBenchmarkTypeLabel,
  } = useBenchmarkTypes();

  const { data, loading } = useTeacherBenchmarksListQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort: BenchmarkTeacherDtoSortType.StartDate,
      orderBy: order as OrderBy,
    },
  });

  const benchmarks = data?.teacherBenchmarks?.nodes;
  const totalCount = data?.teacherBenchmarks?.total_count ?? 0;

  return {
    loading: loading || benchmarkTypesLoading,
    benchmarkTypes,
    getBenchmarkTypeLabel,
    benchmarks,
    pagination,
    totalCount,
    onSortChange,
    sort,
    order: order as OrderBy,
  };
};

export default useTeacherBenchmarksList;
