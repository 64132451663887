import { useGetBenchmarkTypesQuery } from '../../generated/graphql';

const useBenchmarkTypes = ()=>{
  const { data, loading } = useGetBenchmarkTypesQuery({
    fetchPolicy: 'network-only',
  });
  const benchmarkTypes = data?.benchmarkTypes ?? [];

  const getBenchmarkTypeLabel = (benchmarkTypeKey: string) => {
    const bTypeFiltered = benchmarkTypes.filter((bType: any) => bType.key === benchmarkTypeKey);
    return bTypeFiltered.length ? bTypeFiltered[0]?.value : '';
  };

  return {
    loading,
    benchmarkTypes,
    getBenchmarkTypeLabel,
  };
};

export default useBenchmarkTypes;
