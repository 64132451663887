import * as React from 'react';
import { PAGE_SIZE } from '../../variables/constant';
import {
  Submission,
  OrderBy,
  useSubmissionsQuery,
} from '../../generated/graphql';
import { SubmissionSort } from '../../variables/types';
import { TeacherSubmissionsParams } from "./submissions-hook";
import { useEffect, useState } from "react";

const useSubmissionsInfinite = (
  params?: TeacherSubmissionsParams
) => {
  const [sort, setSort] = useState<SubmissionSort>(params?.sort ?? SubmissionSort.Date);
  const [order, setOrder] = useState<string>(OrderBy.Asc);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<Submission[]>([]);
  const [assigmentId, setAssigmentId] = React.useState<string | undefined>(params?.assigmentId);

  const { data, loading } = useSubmissionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      page,
      sort,
      limit: PAGE_SIZE,
      assignment_id: assigmentId,
      statusList: params?.statusList,
      orderBy: order as OrderBy
    },
  });

  useEffect(() => {
    if (data && data.submissions?.nodes) {
      // @ts-ignore
      setRows(rows => [...rows, ...data?.submissions.nodes!])
    }
  }, [data]);

  const onSortChange = (sortBy: SubmissionSort, orderBy: OrderBy) => {
    setPage(1)
    setSort(sortBy);
    setOrder(orderBy);
  };

  const handlePagination = (value: React.SetStateAction<number>) => {
    setPage(value)
  };

  return {
    loading: loading,
    submissions: rows ?? [],
    totalCount: data?.submissions?.totalCount ?? 0,
    page,
    onSortChange,
    sort: sort as SubmissionSort,
    order: order as OrderBy,
    assigmentId, setAssigmentId,
    handlePagination,
  };
};

export default useSubmissionsInfinite;
