import React from 'react';
import { AdminDashboards } from '../../../../variables/constant';
import useStudentCompletionRate from './student-completion-rate-hook';
import StackedBarChart from '../../../../components/AdminGraphs/StackedBarChart';
import AppTooltip from '../../../../components/AppTooltip';
import StyledCard from '../../../../components/StyledCard';
import StudentCompletionDonutChart from '../../../../components/AdminGraphs/StudentCompletionDonutChart';
import { Box, SxProps, Typography } from '@mui/material';
import { palette } from '../../../../theme/palette';
import { PerformanceWideStudentCompletionRateBar } from '../../../../generated/graphql';
import CustomTooltip from './CustomTooltip';
import { formatSessionDate } from '../../../../components/AdminGraphs/CommonFunctions';

interface Props{
  enableTooltip?: boolean
  alignTitle?: boolean
}

const StudentCompletionRateWidget = ({ enableTooltip = true, alignTitle = true }:Props) => {

  const {
    studentCompletionRateLoading, studentCompletionRateData, title, pieValue,
    hasData,
    sessionEndDate,
    sessionStartDate,
  } = useStudentCompletionRate();

  const processData = (data: PerformanceWideStudentCompletionRateBar[]) => {
    return data?.map((eventData:PerformanceWideStudentCompletionRateBar) => ({
      ...eventData,
      remainingUnits: Math.abs(eventData?.pts_assigned! - eventData?.pts_submitted_and_graded!),
    }));
  };

  const processedData = studentCompletionRateData && processData(studentCompletionRateData);

  return (
    <>
      <StyledCard
        title={title}
        columnSetting
        isToolTip={enableTooltip}
        titleAlignment={alignTitle ? 'left' : 'center'}
        toolTipComponent={<AppTooltip toolTipText={AdminDashboards.performance.studentCompletionRatesWidget.tooltipText}/>}
        contentAlignment
      >
        {
          !studentCompletionRateLoading && <Typography component='p' variant='secondaryLabel' sx={{ lineHeight: '31px', color: palette.customBlack.dimmedBlack } as SxProps}>
            {formatSessionDate(sessionStartDate)} - {formatSessionDate(sessionEndDate)}
          </Typography>
        }
        <Box display='flex'>
          <Box width='70%'>
            <StackedBarChart
              barData={processedData}
              loading={studentCompletionRateLoading}
              primaryLegend='Assigned'
              secondaryLegend='Completed'
              tertiaryLegend='Average'
              showData={hasData! > 0}
              averageLine='average_no_of_pts_submitted_and_graded'
              blueBar='pts_submitted_and_graded'
              greyBar='remainingUnits'
              toolTipComponent={<CustomTooltip />}
            />
          </Box>
          <Box>
            <StudentCompletionDonutChart loading={studentCompletionRateLoading} pieChartValue={pieValue}/>
          </Box>
        </Box>
      </StyledCard>
    </>
  );
};

export default StudentCompletionRateWidget;
