import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const PrimaryOutlinedButtonStyled = styled(Button)(({ theme }) => ({
  fontWeight: 800,
  fontSize: 18,
  padding: '13px 30px',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: 12,
  color: theme.palette.primary.main,
  '&:active': {
    borderWidth: '4px 2px 2px 2px',
  },
}));

export default PrimaryOutlinedButtonStyled;
