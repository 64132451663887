import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layout';
import LandingPage from './LandingPage';
import CreateDistrict from '../Districts/CreateDistrict';
import CreateDemo from '../Demo';
import SchoolForm from '../Schools/SchoolForm';
import TeacherForm from '../Teachers/TeacherForm';
import ClassForm from '../Classes/ClassForm';
import StudentForm from '../AdminStudents/StudentForm';
import AdminForm from '../Admin';
import useFeature from '../../hooks/useFeature';

const CreateResource = () => {
  const { isEnabled: isDemoFeatureEnabled } = useFeature('DEMO_FEATURES');
  return (
    <Layout>
      <Switch>
        <Route
          path="/admin/create"
          component={LandingPage}
          exact
        />
        <Route
          path={['/admin/create/district', '/admin/create/district/:id']}
          component={CreateDistrict}
          exact
        />
        <Route
          path={['/admin/create/school', '/admin/create/school/:id']}
          component={SchoolForm}
          exact
        />
        <Route
          path={['/admin/create/teacher', '/admin/create/teacher/:id']}
          component={TeacherForm}
          exact
        />
        <Route
          path={['/admin/create/class', '/admin/create/class/:id']}
          component={ClassForm}
          exact
        />
        <Route
          path={['/admin/create/student', '/admin/create/student/:id']}
          component={StudentForm}
          exact
        />
        <Route
          path={['/admin/create/admin', '/admin/create/admin/:admin', '/admin/create/admin/:admin/:id']}
          component={AdminForm}
          exact
        />
        {isDemoFeatureEnabled && (
          <Route
            path={['/admin/create/demo']}
            component={CreateDemo}
            exact
          />
        )}
      </Switch>
    </Layout>
  );
};

export default React.memo(CreateResource);
