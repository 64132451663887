import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper, Typography,
  Theme, styled,
} from '@mui/material';
import { GraphSetData } from '../../../../variables/types';

interface Props {
  data: GraphSetData[] | null,
  skillType?: string,
  skillCount?: number,
  customLabel?: boolean,
  fullWidth?: boolean,
}

const StylesCSS = (theme: Theme) => ({
  tableContainer: {
    border: 0,
    boxShadow: 'none',
    width: theme.spacing(75),
  },
  colorBand: {
    width: theme.spacing(1.875),
    height: theme.spacing(1.875),
    borderRadius: '50%',
    '&.MuiTypography-root' :{
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  skillLabel: {
    width: theme.spacing(14.375),
    '&.MuiTypography-root' : {
      fontSize: theme.spacing(2),
      fontWeight: 600,
      lineHeight: '31px',
    },
  },
  percentageLabel: {
    display: 'inline-block',
    '&.MuiTypography-root' : {
      fontSize: theme.spacing(1.75),
      fontWeight: 400,
      lineHeight: '31px',
      width: theme.spacing(6.25),
      marginLeft: theme.spacing(1),
    },
  },
  rowHover: {
    '& tbody tr:nth-of-type(even):hover': {
      backgroundColor: theme.palette.transparent.main,
      cursor: 'arrow',
    },
  },
  skillStudents: {
    width: theme.spacing(1.25),
    display: 'inline',
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.customBlack.fontBlack,
  fontSize: theme.spacing(2),
  lineHeight: '31px',
  fontWeight: 700,
  textAlign: 'center',
  borderBottom: 'none',
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 600,
  lineHeight: '31px',
  alignItems: 'center',
  borderBottom: 'none',
  textAlign: 'center',
}));

const percentage = (partialValue:number | undefined, totalValue:number | undefined) => (100 * partialValue!) / totalValue!;

const PerformanceTable = ({ data, skillType, skillCount, customLabel, fullWidth = false }:Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  return (
    <TableContainer component={Paper} sx={{
      border: 0,
      boxShadow: 'none',
      width: fullWidth ? '100%' : 600,
    }}>
      <Table sx={styles.rowHover}>
        <TableHead
          sx={{
            '&.MuiTableHead-root': {
              backgroundColor: theme.palette.transparent.main,
            },
          }}
        >
          <TableRow>
            <StyledTableCell >
              {customLabel ? 'Category' : `${skillType} Level`}
            </StyledTableCell>
            <StyledTableCell># of Students</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <StyledTableRow key={row?.id}>
              <TableCell align="left" style={{ display: 'flex', justifyContent: 'center', borderBottom: 'none' }}>
                <Typography sx={styles.colorBand} style={{ backgroundColor: row?.color }}/>
                <Typography sx={styles.skillLabel}>
                  {row?.skill_label}
                </Typography>
              </TableCell>
              <StyledTableCell2>
                <Typography sx={styles.skillStudents}>{row?.numberOfStudents}</Typography>
                <Typography sx={styles.percentageLabel}>
                  {`(${percentage(row.numberOfStudents, skillCount).toFixed(2)}%)`}
                </Typography>
              </StyledTableCell2>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PerformanceTable;
