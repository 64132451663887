/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { AutoCompleteTextField } from '../Autocomplete/Autocomplete.styled';
import { SectionFilterFragmentFragment } from '../../generated/graphql';
import useSectionsFilter from './sections-filter-hook';

interface Props {
  error?: boolean;
  className?: string;
  value?: SectionFilterFragmentFragment | null | string;
  onChange?: (data: SectionFilterFragmentFragment | null | string) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  studentId?: string;
  hideGroups?: boolean;
  schoolId?: string | null;
}

type SectionValue = SectionFilterFragmentFragment | null | undefined;

const SectionFilter = ({
  className,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  studentId,
  hideGroups = false,
  schoolId,
}: Props) => {
  const {
    sections,
    loading,
  } = useSectionsFilter({ studentId, hideGroups, schoolId });

  const onSelectChange = (_: any, section: SectionFilterFragmentFragment | null) => {
    onChange?.(idSelectOnly ? (section?.id ?? '') : section);
  };

  const selectedValue: SectionValue = idSelectOnly ? sections?.find((item) => item.id === value) ?? null : value as SectionValue;
  const propValue:SectionValue = sections.filter((section) => section.id === value)[0];
  return (
    <Autocomplete
      className={className}
      options={sections || []}
      getOptionLabel={(option: any) => option?.name}
      isOptionEqualToValue={(option: any, selected: any) => (option?.name === selected?.name)}
      onChange={onSelectChange}
      value={propValue || selectedValue}
      disabled={loading}
      renderInput={(params) => (
        <AutoCompleteTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style:{ fontWeight: 800 },
          }}
        />
      )}
    />
  );
};

export default SectionFilter;
