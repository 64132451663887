import { useForm } from 'react-hook-form';
import { useState } from 'react';
import {
  useCreateDemoDataMutation,
  CreateDemoUsersOutput,
} from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType } from '../../variables/types';
import DemoAccountType from './constants';

type DemoFormData = {
  name: string,
  state_id: string,
  score_scheme_id: string
};

const useDemo = () => {
  const [demoData, setDemoData] = useState<CreateDemoUsersOutput | undefined>();
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
  } = useForm<DemoFormData>();

  const [createDemoData, { loading }] = useCreateDemoDataMutation();

  const create = async (district: DemoFormData) => {
    try {
      const response = await createDemoData({
        variables: {
          input: {
            name: district.name,
            state_id: district.state_id,
            score_scheme_id: district.score_scheme_id,
          },
        },
      });
      if (response?.data?.createDemoDataForDistrict?.district?.id) {
        setDemoData(response.data.createDemoDataForDistrict);
        openSnackbar({
          message: `${district.name} district was added with demo data!`,
        }, NotifierType.Success);
      }
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const getCSVData = () => {
    const header = [
      ['District', 'Type', 'First Name', 'Middle Name', 'Last Name', 'Email', 'Password', 'Teacher ID', 'Student ID'],
    ];
    const teachers = demoData?.teachers.map((teacher) => [
      demoData?.district?.name || '-',
      DemoAccountType.TEACHER,
      teacher.first_name || '-',
      teacher.middle_name || '-',
      teacher.last_name || '-',
      teacher.email || '-',
      teacher.password || '-',
      teacher.teacher_id || '-',
      '-',
    ]) || [];

    const students = demoData?.students.map((student) => [
      demoData?.district?.name || '-',
      DemoAccountType.STUDENT || '-',
      student.first_name || '-',
      student.middle_name || '-',
      student.last_name || '-',
      student.email || '-',
      student.password || '-',
      '-',
      student.student_id || '-',
    ]) || [];
    return [...header, ...teachers, ...students];
  };

  return {
    loading,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    createDemoData: create,
    demoData,
    csvData: getCSVData(),
    setValue,
  };
};

export default useDemo;
