import React from 'react';
import { Box, Grid, TextField, Checkbox, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import StateFilter from '../../../components/StateFilter';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Loader from '../../../components/Loader/loader';
import useCreateDistrict from './create-district-hook';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';
import { NON_SPACE_REGEX, PROVIDER_TYPE } from '../../../variables/constant';
import useScoreSchemeRadioGroup from '../../../components/ScoreSchemeRadioGroup/score-scheme-radio-group-hook';
import { StyledRadioButton, StyledSaveButton } from './create-district.styled';
import { palette  } from '../../../theme/palette';

const CreateDistrict = () => {
  const {
    loading,
    errors,
    handleSubmit,
    control,
    register,
    createDistrict,
    updateDistrict,
    checked,
    handleChange,
    getValues,
    setValue,
    districtDetails,
    updateDistrictStatus,
    updateFlagLoading,
  } = useCreateDistrict();
  const districtID = districtDetails?.id;
  const setScoreSchemeValue = (value: string) => setValue('score_scheme_id', value);
  const { scoreSchemesDetailsLoading, scoreSchemeOptions } = useScoreSchemeRadioGroup({
    shouldDefault: true,
    setValue: setScoreSchemeValue,
    value: getValues('score_scheme_id') ?? '',
  });

  const breadcrumb = [{
    label: 'Districts',
    route: '/districts',
  }, {
    label: districtID ? 'Edit District' : 'Create District',
  }];

  const checkBoxLabel = "I'll be adding another district after this one";
  const districtNameHelperText = 'Please enter the district name.';
  const stateHelperText = 'Please select a state.';
  const districtIDHelperText = 'Please enter a valid district ID.';
  const formTitle = districtID ? 'Update a district' : 'Add a new district';

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
      >
        <Box>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
          <Box
            display="flex"
            justifyContent="flex-start"
            sx={{ alignItems: 'baseline' }}
          >
            <DistrictsIcon
              pathfill={palette.customBlue.primaryBlue}
              sx={{
                height: '30px',
                width: '30px',
                marginRight: '16px',
              }}
            />
            <Typography variant="pageTitle"  component='p' sx={{ mb: 1 }}>{formTitle}</Typography>
          </Box>
        </Box>
        <form
          autoComplete="off"
          onSubmit={districtID ? handleSubmit(updateDistrict) : handleSubmit(createDistrict)}
          noValidate
        >
          <Grid
            xs={8}
            lg={6}
            container
            spacing={2}
          >
            <Grid item lg={10}>
              <Controller
                name="state_id"
                render={({ onChange }) => (
                  <StateFilter
                    value={getValues('state_id') as string}
                    onChange={onChange}
                    placeholder="Select a state"
                    idSelectOnly
                    isVariableHeight
                  />
                )}
                rules={{ required: stateHelperText }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.state_id && errors.state_id.message}
              </Typography>
            </Grid>
            <Grid item lg={10}>
              <Typography variant='sFormTitle' component='p' sx={{  mt: 1 }}>District Name</Typography>
              <TextField
                id="district-name"
                aria-describedby="district-name"
                name="name"
                error={!!errors.name}
                disabled={!!districtID && districtDetails?.source !== PROVIDER_TYPE.FLASHLIGHT}
                helperText={errors.name && districtNameHelperText}
                inputRef={register({
                  required: true,
                  validate: (value) => !!value.trim(),
                })}
                fullWidth
              />
            </Grid>
            <Grid item lg={10}>
              <Typography variant='sFormTitle' component='p' sx={{  mt: 1 }}>District ID</Typography>
              <TextField
                id="district-id"
                aria-describedby="district-id"
                name="district_id"
                error={districtDetails?.source === PROVIDER_TYPE.FLASHLIGHT && !!errors.district_id}
                fullWidth
                disabled={Boolean(districtID)}
                helperText={districtDetails?.source === PROVIDER_TYPE.FLASHLIGHT && errors.district_id && districtIDHelperText}
                inputRef={register({
                  required: districtDetails?.source === PROVIDER_TYPE.FLASHLIGHT,
                  pattern: NON_SPACE_REGEX,
                  validate: (value) => districtDetails?.source !== PROVIDER_TYPE.FLASHLIGHT || !!value.trim(),
                })}
              />
            </Grid>
            <Grid item lg={10} sx={{ mt: 1 }}>
              <Controller
                name="score_scheme_id"
                render={({ onChange, value }) => (
                  <StyledRadioButton
                    value={value}
                    onChange={onChange}
                    scoreSchemeOptions={scoreSchemeOptions}
                    disabled={Boolean(districtDetails?.has_active_assignments)}
                  />
                )}
                rules={{ required: 'Please select score scheme.' }}
                control={control}
                defaultValue={null}
              />
            </Grid>
          </Grid>
          { !districtID && (
            <Box
              display="flex"
              justifyContent="flex-start"
              sx={{ mt: 4, ml: -1.5 }}
            >
              <Checkbox
                id="reopen-create-district-form"
                aria-describedby="reopen-create-district-form"
                name="reopen_create_checkbox"
                checked={checked}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'checkbox to reload create district form' }}
                inputRef={register({
                  required: false,
                })}
              />
              <Typography variant='sLabel' component='p' sx={{ pt: 1 }}>{checkBoxLabel}</Typography>
            </Box>
          ) }
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            maxWidth="50%"
          >
            <StyledSaveButton
              variant="contained"
              color="primary"
              type="submit"
            >
              { districtID ? 'Update' : 'Add District' }
            </StyledSaveButton>
            {!!districtID && districtDetails?.source !== PROVIDER_TYPE.FLASHLIGHT && (
              <StyledSaveButton
                variant="outlined"
                color="primary"
                onClick={() => updateDistrictStatus(districtID, !districtDetails?.is_disabled)}
                disabled={updateFlagLoading}
              >
                {districtDetails?.is_disabled ? 'ENABLE' : 'DISABLE'}
              </StyledSaveButton>
            )}
          </Box>
        </form>
      </Box>
      <Loader open={loading || scoreSchemesDetailsLoading} />
    </>
  );
};

export default React.memo(CreateDistrict);
