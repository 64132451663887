import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  PerformanceStylesCSS,
} from './Performance.styled';
import { AdminDashboards } from '../../../variables/constant';
import { DashboardContext, DashboardContextInit } from '../admin-analytics-hook';
import InsufficientDataBackdrop from '../InsufficientDataBackdrop';
import AnalyticsFilters from '../AnalyticsFilters';
import OverallPerformanceWidget from './OverallPerformanceWidget';
import PerformanceWidget from './PerformanceWidget';
import TasksAssignedAndCompletedWidget from './TasksAssignedAndCompletedWidget';
import StudentCompletionRateWidget from './StudentCompletionRateWidget';
import Loader from '../../../components/Loader/loader';

const Performance = () => {
  const theme = useTheme();
  const styles = PerformanceStylesCSS(theme);
  const {
    loading, setLoading,
    isSchoolAdmin, mySchoolData,
    isDistrictAdmin, myDistrictData,
    currentFilters, setCurrentFilters,
    analyticsFilters, setAnalyticsFilters,
    academicSessionLoading, academicSession,
    dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
    areWidgetsLoading, setAreWidgetsLoading,
  } = DashboardContextInit(AdminDashboards.performance);

  return (
    <DashboardContext.Provider value={{
      loading, setLoading,
      isSchoolAdmin, mySchoolData,
      isDistrictAdmin, myDistrictData,
      currentFilters, setCurrentFilters,
      analyticsFilters, setAnalyticsFilters,
      academicSessionLoading, academicSession,
      dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
      isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
      areWidgetsLoading, setAreWidgetsLoading,
    }}>
      <Loader open={loading} />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={styles.dashBoardContainer}
      >
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="pageTitle">
            Performance Data Dashboard
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2} item xs={12} sm={12} md={12} lg={12} p={2}>
            <AnalyticsFilters />
          </Grid>
          <Grid container spacing={2} sx={{ position: 'relative' }}>
            <InsufficientDataBackdrop dataMissing={!loading! && isDataMissingInAllWidgets!} />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <OverallPerformanceWidget />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StudentCompletionRateWidget />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <PerformanceWidget
                enableTooltip
                alignTitle
                tooltipTitle={AdminDashboards.performance.PerformanceSliderWidget.tooltipText}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TasksAssignedAndCompletedWidget />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DashboardContext.Provider>
  );
};

export default Performance;
