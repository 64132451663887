import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import StyledBoxWrapper from './FeedbackTile.styled';
import { BackPackSubmissionFragment } from '../../generated/graphql';
import {
  StyledBodyBox,
  StyledHeaderBox,
} from '../SubmissionTile/SubmissionTile.styled';
import AudioPlayer from '../Player';
import ScoreIcon from '../Icons/ScoreIcon';
import useFeedbackTiles from './feedback-tiles-graphql';
import { submissionStyles } from '../SubmissionTile';
import { palette } from '../../theme/palette';

interface Props {
  submission?: BackPackSubmissionFragment | null,
  title: string,
  Icon: React.ComponentType<any>,
  setIsPlaying?: Function
}

const feedbackTiles = {
  feedback:{
    width: '220px',
    height: '50px',
    color: palette.customWhite.main,
    padding: '20px 12px',
    marginTop: '20px',
    border: `1px solid ${palette.customBlue.primaryBlue}`,
    '&::after': {
      backgroundColor:palette.customBlue.primaryBlue,
      border: `1px solid ${palette.customBlue.primaryBlue}`,
    },
    '&:hover': {
      border: 'none',
    },
  },
};
const FeedbackTile = ({ submission, title, Icon, setIsPlaying }: Props) => {
  const feedbackNotes = submission?.getAdditionalFeedback?.feedback_notes;
  const audio = submission?.getAdditionalFeedback?.audio;
  const { handleRedirection } = useFeedbackTiles();
  const audioAvailability = audio && audio?.length > 0;
  return (
    <StyledBoxWrapper>
      <StyledHeaderBox>
        <Icon
          fillColor={palette.customBlue.primaryBlue}
        />
        <Typography variant='sContentHeader' sx={{ lineHeight: '24px' }}>
          {title}
        </Typography>
      </StyledHeaderBox>
      <StyledBodyBox>
        <Box sx={{ ...submissionStyles.contentBox, ...(submission?.writing) && submissionStyles.defaultHeight }}>
          <Typography variant='sLabel' sx={{ mt: '12px' }} component='p'>
            {feedbackNotes || '' }
          </Typography>
        </Box>
        {audioAvailability
        && (
          <Box mt={3}>
            <Typography variant='title18'> A message from your teacher </Typography>
            {audio?.map((recording) => (
              <Box
                key={recording?.url}
                mt={3}
              >
                <AudioPlayer
                  url={recording?.url}
                  setIsPlaying={setIsPlaying}
                  customStyle
                />
              </Box>
            ))}
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            sx={{ ...feedbackTiles.feedback }}
            onClick={handleRedirection(submission?.id || '')}
          >
            <ScoreIcon />
            <Typography component='p' variant='sTaskTitle' sx={{ ml: '12px', color: palette.customWhite.main }}>See my Scores</Typography>
          </Button>
        </Box>
      </StyledBodyBox>
    </StyledBoxWrapper>
  );
};

export default FeedbackTile;
