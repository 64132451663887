import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  useDistrictQuery,
} from '../../../generated/graphql';
import { PUBLIC } from '../../../variables/constant';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useUserInfo from '../../../utils/useUserInfo';

const useDistrictsList = () => {
  const [stateLoading, setLoading] = useState(false);
  const [isLoadingUserInfo] = useUserInfo();
  const history = useHistory();
  const { handleSubmit } = useForm();
  const [district, setDistrict] = useLocalStorage('school-district', {});
  const { data, loading } = useDistrictQuery({
    context: { clientName: PUBLIC },
  });

  const districtList = data?.district ?? [];

  const handleRedirection = (id: string) => {
    if (id) {
      history.push(`/login/${id}`);
    } else {
      history.push('/login');
      setDistrict({});
    }
  };

  return {
    loading: loading || isLoadingUserInfo || stateLoading,
    districtList,
    handleRedirection,
    handleSubmit,
    district,
    setDistrict,
    setLoading,
  };
};

export default useDistrictsList;
