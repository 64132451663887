import React from 'react';
import { Box, Chip } from '@mui/material';
import Backdrop from '../Backdrop';
import { PredefinedGrades } from '../../generated/graphql';
import { palette } from '../../theme/palette';

const chipStyles = {
  gradeChip: {
    alignItems: 'center',
    border: `2px solid ${palette.customGrey.silver}`,
    borderRadius: '12px',
    width: '100px',
    height: '48px',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '1px',
  },
  active: {
    backgroundColor:palette.customBlue.primaryBlue,
    borderColor:palette.customBlue.primaryBlue,
    color: palette.customWhite.main,
    '&.MuiChip-outlined:hover, &.MuiChip-outlined:focus': {
      backgroundColor:palette.customBlue.primaryBlue,
      borderColor:palette.customBlue.primaryBlue,
      color: palette.customWhite.main,
    },
  },
};

type Props = {
  onChange: (selectedItemsKey: string[] | string | null) => void,
  selected?: string[],
  grades?: PredefinedGrades[] | null | any,
  isLoading?: boolean;
  disabled?: boolean;
};

const ChipGrid = ({
  onChange,
  selected,
  grades,
  isLoading = false,
  disabled = false
}: Props) => {

  const handleChipClick = (key: string) => () => {
    onChange(key);
  };

  return (
    <>
      <Backdrop open={isLoading} />
      <Box display="grid" gridTemplateColumns="repeat(6, 110px)" sx={{
        gridGap: 24,
      }}>
        {
          grades?.map((grade: PredefinedGrades) => {
            const active = selected?.includes(grade.id);
            return (
              <Chip
                key={grade.id}
                variant="outlined"
                label={grade.grade}
                onClick={handleChipClick(grade.id)}
                color={active ? 'primary' : 'default'}
                disabled={disabled}
                sx={{
                  ...chipStyles.gradeChip,
                  ...(active && {
                    ...chipStyles.active,
                  }),
                }}
              />
            );
          })
        }
      </Box>
    </>
  );
};

ChipGrid.defaultProps = {
  onChange: () => null,
  isLoading: false,
};

export default ChipGrid;
