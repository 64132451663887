import { useCallback, useState } from 'react';

interface SearchParams {
  searchSegment?: string
  placeholder: string
}

const searchData: SearchParams[] = [
  { searchSegment: '/schools', placeholder: 'Search all schools' },
  { searchSegment: '/districts', placeholder: 'Search all districts' },
  { searchSegment: '/teachers', placeholder: 'Search all teachers' },
  { searchSegment: '/classes', placeholder: 'Search all classes' },
  { searchSegment: '/admins/district', placeholder: 'Search all district admins' },
  { searchSegment: '/admin-students', placeholder: 'Search all students' },
  { searchSegment: '/admins/school', placeholder: 'Search all school admins' },
];

const useSearch = () => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [placeholder, setPlaceholder] = useState<string>('Search');
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchSegment, setSegment] = useState<string>();

  const setSearchEnabled = useCallback(() => {
    const { location } = window;
    const pathName = location.pathname;
    if (searchSegment !== pathName) {
      setSearchTerm(undefined);
    }
    setSegment(location.pathname);
    const searchSegmentD = searchData.find((segment) => segment.searchSegment === location.pathname);
    const isEnabled = searchSegmentD?.searchSegment === location.pathname;
    setIsSearchEnabled(isEnabled);
    setPlaceholder(searchSegmentD ? searchSegmentD?.placeholder : 'Search');
  }, [searchSegment]);


  const onSearch =  (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value){
      setSearchTerm(value);
    } else {
      setSearchTerm(undefined);
    }
  };

  return {
    searchTerm,
    isSearchEnabled,
    searchSegment,
    placeholder,
    onSearch,
    setSearchEnabled,
  };
};

export default useSearch;
