import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  useCreateDistrictMutation,
  useDistrictEditDataQuery, useUpdateDistrictActiveFlagMutation, useUpdateDistrictMutation,
} from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';

/* NOTE: Reference : https://react-hook-form.com/v6/api#useForm */
type CreateDistrictFormData = {
  name: string,
  district_id: string,
  state_id: string,
  score_scheme_id: string,
};

interface RouteProp {
  id: string
}

const useCreateDistrict = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
  } = useForm<CreateDistrictFormData>();

  const [createDistrict, { loading: createLoading }] = useCreateDistrictMutation();
  const [updateDistrict, { loading: updateLoading }] = useUpdateDistrictMutation();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setChecked(event.target.checked);
  };
  const { id } = useParams<RouteProp>();

  const { data: districtDetails, loading: districtDetailsLoading } = useDistrictEditDataQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id!,
    },
    skip: !id,
  });

  const [updateDistrictActiveFlag, { loading: updateFlagLoading }] = useUpdateDistrictActiveFlagMutation();

  useEffect(() => {
    if (id && districtDetails) {
      setValue('district_id', districtDetails.districtDetails.district_id.trim());
      setValue('name', districtDetails.districtDetails.name.trim());
      setValue('state_id', districtDetails.districtDetails.state_id);
      setValue('score_scheme_id', districtDetails.districtDetails.score_scheme_id);
    }
  }, [reset, districtDetails, id, register, setValue]);

  const handleResponse = (districtID: String, district: CreateDistrictFormData) => {
    const districtDetailsPath = `/districts/${districtID}`;
    const districtName = district?.name || districtDetails?.districtDetails.name;
    const successMessage = id
      ? `${districtName} district was updated!`
      : `${districtName} district was added!`;

    if (checked) {
      reset({
        name: '',
        district_id: '',
        state_id: district.state_id,
        score_scheme_id: district?.score_scheme_id,
      });
    } else {
      history.push(districtDetailsPath);
    }
    if (districtDetails) {
      openSnackbar({
        message: successMessage,
        customIcon: DistrictsIcon,
        actionButtonText: 'View',
        onActionButtonClick: () => {
          history.push(`/districts/${districtDetails?.districtDetails?.id}`);
        },
      }, NotifierType.Success);
    } else {
      openSnackbar({
        message: successMessage,
        customIcon: DistrictsIcon,
        actionButtonText: checked ? 'View' : undefined,
        onActionButtonClick: () => {
          history.push(districtDetailsPath);
        },
      }, NotifierType.Success);
    }
  };

  const create = async (district: CreateDistrictFormData) => {
    try {
      const response = await createDistrict({
        variables: {
          input: {
            name: district?.name || districtDetails?.districtDetails?.name as string,
            district_id: district?.district_id || districtDetails?.districtDetails?.district_id as string,
            state_id: district?.state_id,
            score_scheme_id: district?.score_scheme_id,
          },
        },
      });

      const districtID = response?.data?.createDistrict?.trim() || '';
      handleResponse(districtID, district);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const updateDistrictStatus = async (districtID: string, isDisabled: boolean) => {
    try {
      await updateDistrictActiveFlag({
        variables: {
          id: districtID,
          isDisabled: !!isDisabled,
        },
      });
      openSnackbar({ message: `District has been successfully  ${isDisabled ? 'disabled' : 'enabled'}` }, NotifierType.Success);
      history.push(`/districts/${districtID}`);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const update = async (district: CreateDistrictFormData) => {
    try {
      const districtID = districtDetails?.districtDetails.id || '';
      await updateDistrict({
        variables: {
          id: districtID,
          input: {
            name: district?.name || districtDetails?.districtDetails?.name as string,
            district_id: district?.district_id || districtDetails?.districtDetails?.district_id as string,
            state_id: district?.state_id,
            score_scheme_id: district?.score_scheme_id,
          },
        },
      });

      handleResponse(districtID, district);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    loading: createLoading || updateLoading || districtDetailsLoading,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    createDistrict: create,
    updateDistrict: update,
    checked,
    handleChange,
    setValue,
    districtDetails: districtDetails?.districtDetails,
    updateDistrictStatus,
    updateFlagLoading,
  };
};

export default useCreateDistrict;
