import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NotifierType } from '../../../../variables/types';
import {
  File as APIFIle,
  useAdditionalFeedbackMutation,
  useDeleteFeedbackAudioMutation,
} from '../../../../generated/graphql';
import { useUploader } from '../../../../utils/multipart-uploader';
import { getToken } from '../../../../utils/auth';
import getErrorMessage from '../../../../utils/getErrorMessage';
import { openSnackbar } from '../../../../components/Notifier';
import {
  SUBMISSION_ALREADY_REVIEWED_ERROR,
  SUBMISSION_ALREADY_REVIEWED_MESSAGE,
} from '../../../../variables/constant';

interface Props {
  id: string,
  textFeedback: string,
  audioFeedback: Pick<APIFIle, 'id' | 'url' | 'file_type'> | null;
}

const useAdditionalFeedback = ({ id, textFeedback, audioFeedback }: Props) => {
  const history = useHistory();
  const [audioData, setAudioData] = useState<Pick<APIFIle, 'url' | 'file_type'> | null>(audioFeedback);
  const [notes, updateNotes] = useState<string>(textFeedback);
  const [currentFile, setCurrentFile] = useState<File | null>();
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [savingRecording, setSavingRecording] = useState(false);
  const [updateFeedback, { loading: updateFeedbackLoading }] = useAdditionalFeedbackMutation();
  const [deleteFeedbackAudio, { loading: deleteFeedbackLoading }] = useDeleteFeedbackAudioMutation();
  const loading = updateFeedbackLoading || deleteFeedbackLoading;

  useEffect(() => {
    updateNotes(textFeedback);
  }, [textFeedback]);

  useEffect(() => {
    setAudioData(audioFeedback);
  }, [audioFeedback]);

  const handleStartRecording = (state: boolean) => {
    setIsRecording(state);
    setAudioData(null);
    setCurrentFile(null);
  };

  const onRecord = (file: File) => {
    const data: Pick<APIFIle, 'url' | 'file_type'> = {
      url: URL.createObjectURL(file),
      file_type: file.type,
    };
    setAudioData(data);
    setCurrentFile(file);
  };

  const { uploadAsync, isLoading } = useUploader({
    //@ts-ignore
    uploadUrl: `${import.meta.env.REACT_APP_API_URL}/file/upload`,
    accessToken: getToken()?.idToken?.jwtToken,
  });

  const updateFeedbackRecording = async () => {
    setSavingRecording(true);
    try {
      let result: any;
      if (currentFile && audioData?.url !== audioFeedback?.url) {
        result = await uploadAsync(currentFile);
        setAudioData({
          file_type: result.type,
          url: result.url,
        });
      }
      const updatedFeedback = await updateFeedback({
        variables: {
          input: {
            submission_id: id,
            additional_feedback: notes,
            ...(
              result?.url ? {
                url: result.url,
                file_type: result.type,
                file_name: result.key,
              } : {}
            ),
          },
        },
      });
      setSavingRecording(false);
      if (!updatedFeedback?.data?.updateAdditionalFeedBack?.id) {
        openSnackbar({
          message: 'Failed to update feedback.',
        }, NotifierType.Error);
      }
    } catch (err) {
      if (getErrorMessage(err) === SUBMISSION_ALREADY_REVIEWED_ERROR) {
        openSnackbar({
          message: SUBMISSION_ALREADY_REVIEWED_MESSAGE,
        }, NotifierType.Error);
        setSavingRecording(false);
        history.push('/');
      } else {
        setSavingRecording(false);
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
  };

  const deleteFeedbackRecording = async () => {
    setSavingRecording(true);
    try {
      const response = await deleteFeedbackAudio({
        variables: {
          id: audioFeedback?.id ?? '',
        },
      });
      if (!response.data?.deleteFeedbackAudio) {
        openSnackbar({
          message: 'Failed to update feedback',
        }, NotifierType.Error);
      } else {
        openSnackbar({
          message: 'Feedback added successfully.',
        }, NotifierType.Success);
      }
    } catch (err) {
      if (getErrorMessage(err) === SUBMISSION_ALREADY_REVIEWED_ERROR) {
        openSnackbar({
          message: SUBMISSION_ALREADY_REVIEWED_MESSAGE,
        }, NotifierType.Error);
      } else {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
    setSavingRecording(false);
  };

  const onSave = async () => {
    // Since delete action on teacher feedback audio is not persisted immediately
    // and is actually relayed to backend at the time of saving
    if (audioFeedback?.id && (audioFeedback?.url !== audioData?.url)) {
      await deleteFeedbackRecording();
    }

    await updateFeedbackRecording();
  };

  const onDelete = async () => {
    setAudioData(null);
    setCurrentFile(null);
  };

  return {
    audioData,
    setAudioData,
    loading: loading || isLoading || savingRecording,
    onRecord,
    onSave,
    isRecording,
    setIsRecording,
    handleStartRecording,
    isPlaying,
    setIsPlaying,
    notes,
    updateNotes,
    onDelete,
  };
};

export default useAdditionalFeedback;
