import React from 'react';
import StyledCard from '../StyledCard';
import { Box, Skeleton, styled, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import AppTooltip from '../AppTooltip';
import NoResults from '../NoResults';
import EmptyResults from '../Icons/EmptyResults';
import { AdminDashboards } from '../../variables/constant';

interface Props {
  loading?: boolean;
  assignedTasks?: number,
  studentsCount?: number,
  tasksAssigned?: number,
  tasksCompleted?: number,
  title?: string,
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  enableTooltip?: boolean, // to enable tooltip for this component.
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
}

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  height: '50%',
  flexBasis: 'auto',
  boxSizing: 'border-box',
  flexDirection: 'column',
  border: `1px solid ${palette.customRed.progressPrimary}`,
});

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '19px',
  height: '30px',
  color: palette.customBlack.textBlack,
  textAlign: 'center',
});

const styles = {
  textContainer: {
    fontSize: '31px',
    fontWeight: 800,
    lineHeight: '42px',
    color: palette.customBlack.fontBlack,
    paddingTop: '16px',
  },
};

const TasksAssignedAndCompleted = ({ loading, assignedTasks, tasksCompleted, tasksAssigned, studentsCount, title, enableTooltip, alignTitle, tooltipTitle }:Props) =>{
  const hasData = [assignedTasks, tasksCompleted, tasksAssigned, studentsCount].reduce((previousValue, currentValue) => (previousValue! + currentValue!), 0);
  return (
    <StyledCard
      title={title}
      titleAlignment={alignTitle ? 'left' : 'center'}
      toolTipComponent={<AppTooltip toolTipText={tooltipTitle}/>}
      isToolTip={enableTooltip}
      fullHeight
    >
      {(loading && !hasData) && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
          <StyledBox sx={{ borderTop: 0, borderLeft: 0, p: 6 }}>
            <StyledTypography>Number of Tasks Assigned</StyledTypography>
            <Skeleton variant='text' sx={{ ...styles.textContainer }} width={80} />
          </StyledBox>
          <StyledBox sx={{ borderTop: 0, borderRight: 0, p: 6 }}>
            <StyledTypography>Number of Students</StyledTypography>
            <Skeleton variant='text' sx={{ ...styles.textContainer }} width={80} />
          </StyledBox>
          <StyledBox sx={{ borderBottom: 0, borderLeft: 0, p: 6 }}>
            <StyledTypography>Avg. Tasks Assigned per Teacher</StyledTypography>
            <Skeleton variant='text' sx={{ ...styles.textContainer }} width={80} />
          </StyledBox>
          <StyledBox sx={{ borderBottom: 0, borderRight: 0, p: 6 }}>
            <StyledTypography>Tasks Completed per Student</StyledTypography>
            <Skeleton variant='text' sx={{ ...styles.textContainer }} width={80} />
          </StyledBox>
        </Box>
      )}
      {(!loading && !!hasData) && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
          <StyledBox sx={{ borderTop: 0, borderLeft: 0, p: 6 }}>
            <StyledTypography>Number of Tasks Assigned</StyledTypography>
            <Typography component='p' sx={{ ...styles.textContainer }}>{assignedTasks}</Typography>
          </StyledBox>
          <StyledBox sx={{ borderTop: 0, borderRight: 0, p: 6 }}>
            <StyledTypography>Number of Students</StyledTypography>
            <Typography component='p' sx={{ ...styles.textContainer }}>{studentsCount}</Typography>
          </StyledBox>
          <StyledBox sx={{ borderBottom: 0, borderLeft: 0, p: 6 }}>
            <StyledTypography>Avg. Tasks Assigned per Teacher</StyledTypography>
            <Typography component='p' sx={{ ...styles.textContainer, color: palette.customGreen.fontGreen }}>{tasksAssigned}</Typography>
          </StyledBox>
          <StyledBox sx={{ borderBottom: 0, borderRight: 0, p: 6 }}>
            <StyledTypography>Tasks Completed per Student</StyledTypography>
            <Typography component='p' sx={{ ...styles.textContainer, color: palette.customGreen.fontGreen }}>{tasksCompleted}</Typography>
          </StyledBox>
        </Box>
      )}
      {(!loading && !hasData) && (
        <NoResults
          description={AdminDashboards.performance.TasksAssignedNCompletedWidget.insufficientData.description}
          svgComponent={<EmptyResults fillColor={palette.customBackground.disabled} />}
        />
      )}
    </StyledCard>
  );
};

export default TasksAssignedAndCompleted;
