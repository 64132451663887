import { SectionCategory, useAdminDashboardSectionFilterListQuery  } from '../../generated/graphql';

interface Props {
  stateId?: string,
  districtId?: string,
  schoolId?: string | string[],
  teacherId?: string | string[],
  classTypes?: SectionCategory[],
}

const classVariables = ({ stateId, districtId, schoolId, teacherId, classTypes }: Props) => {
  let variables = {};
  if (stateId) {
    variables = { ...variables, stateID: stateId as string };
  }
  if (districtId) {
    variables = { ...variables, districtID: districtId as string };
  }
  if (schoolId) {
    variables = {
      ...variables,
      schoolID: typeof (schoolId) === 'string' ? [schoolId] : schoolId,
    };
  }
  if (teacherId) {
    variables = {
      ...variables,
      teacherId: typeof (teacherId) === 'string' ? [teacherId] : teacherId
    };
  }
  if (classTypes) {
    variables = { ...variables, classTypes };
  }
  return variables;
};

const useAdminDashboardSectionFilter = ({ stateId, districtId, schoolId, teacherId, classTypes }: Props) => {
  const { data, loading } = useAdminDashboardSectionFilterListQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(classVariables({
        stateId, districtId, schoolId, teacherId, classTypes,
      })),
    },
  });

  return {
    sections: data?.adminDashboardSectionFilterList ?? [],
    loading,
  };
};

export default useAdminDashboardSectionFilter;
