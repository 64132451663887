import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { CSVLink } from 'react-csv';
import { Controller } from 'react-hook-form';
import StateFilter from '../../components/StateFilter';
import Loader from '../../components/Loader/loader';
import useDemo from './demo-hook';
import DistrictsIcon from '../../components/Icons/DistrictsIcon';
import List from './List';
import DemoAccountType from './constants';
import ScoreSchemeRadioGroup from '../../components/ScoreSchemeRadioGroup';
import useScoreSchemeRadioGroup from '../../components/ScoreSchemeRadioGroup/score-scheme-radio-group-hook';
import { palette  } from '../../theme/palette';


const styles = {
  saveButton: {
    width: 200,
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 800,
    marginTop: '32px',
  },
  inputInput: {
    '& .MuiInputBase-input': {
      background: 'none',
      border: 0,
    },
  },
  titleInputError: {
    color: palette.customRed.main,
    fontSize: 16,
    fontWeight: 600,
  },
  districtIcon: {
    height: '30px',
    width: '30px',
    marginRight: '16px',
  },
  titleText: {
    marginBottom: '8px',
  },
  districtTitle: {
    marginTop: '16px',
  },
}

const Demo = () => {
  const {
    loading,
    errors,
    handleSubmit,
    control,
    register,
    createDemoData,
    getValues,
    demoData,
    csvData,
    setValue,
  } = useDemo();

  const hasDemoData = Boolean(demoData);
  const setScoreSchemeValue = (value: string) => setValue('score_scheme_id', value);
  const { scoreSchemesDetailsLoading, scoreSchemeOptions } = useScoreSchemeRadioGroup({
    shouldDefault: true,
    setValue: setScoreSchemeValue,
    value: getValues('score_scheme_id'),
  });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
      >
        <Box width="100%" display="grid" gridTemplateColumns="7fr 3fr">
          <Box width="100%">
            <Box>
              <Box
                display="flex"
                justifyContent="flex-start"
              >
                <DistrictsIcon
                  pathfill={palette.customBlue.primaryBlue}
                  sx={{ ...styles.districtIcon }}
                />
                <Typography variant="pageTitle" sx={{ ...styles.titleText }}>Create Demo Accounts</Typography>
              </Box>
            </Box>
            <Box width="100%" display="grid" gridTemplateColumns="2fr 3fr">
              <form
                autoComplete="off"
                onSubmit={handleSubmit(createDemoData)}
                noValidate
              >
                <Grid
                  xs={8}
                  lg={5}
                >
                  <Controller
                    name="state_id"
                    render={({ onChange }) => (
                      <StateFilter
                        value={getValues('state_id') as string}
                        onChange={onChange}
                        placeholder="Select a state"
                        idSelectOnly
                        disabled={hasDemoData}
                      />
                    )}
                    rules={{ required: 'Please select a state.' }}
                    control={control}
                    defaultValue={null}
                  />
                  <Typography variant='sFormHelper'>
                    {errors.state_id && errors.state_id.message}
                  </Typography>
                  <Box sx={{ ...styles.districtTitle }} >
                    <Typography variant='sFormTitle' >District Name</Typography>
                    <TextField
                      id="district-name"
                      aria-describedby="district-name"
                      name="name"
                      sx={{ ...styles.inputInput }}
                      error={!!errors.name}
                      fullWidth
                      helperText={errors.name && 'Please enter the district name.'}
                      disabled={hasDemoData}
                      inputRef={register({
                        required: true,
                        validate: (value) => !!value.trim(),
                      })}
                    />
                  </Box>
                  <Controller
                    name="score_scheme_id"
                    render={({ onChange, value }) => (
                      <ScoreSchemeRadioGroup value={value} onChange={(onChange)} scoreSchemeOptions={scoreSchemeOptions} disabled={hasDemoData} />
                    )}
                    rules={{ required: 'Please select score scheme.' }}
                    control={control}
                    defaultValue={null}
                  />
                </Grid>
                <Box
                  display="grid"
                  justifyContent="flex-start"
                >
                  <Button
                    sx={{ ...styles.saveButton }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={hasDemoData}
                  >
                    Create
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
          {hasDemoData && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {/* Moving CSVLink out of button component to resolve the issue. 'After switching from material-ui@4 to mui@5 CSVLink stopped working because it is not forwarding ref to the original element.', it will be working with below work around */}
              <CSVLink
                style={{ textDecoration: 'none'  }}
                data={csvData}
                filename='Demo Accounts.csv'
                >
                <Button
                  sx={{ ...styles.saveButton }}
                  variant="contained"
                  color="primary"
                >
                  Download
                </Button>
              </CSVLink>
            </Box>
          )}
        </Box>
        {hasDemoData && (
          <Box>
            <List data={demoData?.teachers} type={DemoAccountType.TEACHER} />
            <List data={demoData?.students} type={DemoAccountType.STUDENT} />
          </Box>
        )}
      </Box>
      <Loader open={loading || scoreSchemesDetailsLoading} />
    </>
  );
};

export default React.memo(Demo);
