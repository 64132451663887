import React from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { Cancel } from '@mui/icons-material';
import { DistrictSyncSort, PaginationProps } from '../../../variables/types';
import { OrderBy } from '../../../generated/graphql';
import  { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import { PAGE_SIZE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import TablePagination from '../../../components/TablePagination';
import SuccessIcon from '../../../components/Icons/SuccessIcon';
import HumanDeskFrame from '../../../components/Icons/HumanDeskFrame';
import { getMountainTimeZone } from '../../../utils/timezone';
import {
  boldFontWeight,
  StyledTableCell,
  StyledTableDataColumn,
  StyledTableSort,
  tableWithNoDataStyles,
} from '../../../components/Common/Common.styled';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: DistrictSyncSort,
  order: OrderBy,
  syncJobList: any[] | undefined | null,
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: DistrictSyncSort) => void) | Function,
  handleViewButton: ((id: string) => void) | Function,
}

interface StatusIconProps{
  status: string | undefined,
  noMargin?: boolean
}

const statusValue = {
  Success: 'Success',
  Failed: 'Failed',
  InProgress: 'In Progress',
  SuccessWithWarning: 'Success With Warning(s)',
};

export const StatusIcon = ({ status, noMargin = true }: StatusIconProps) => {
  const leftIcon = noMargin ? 0 : 1;
  const leftStatus = noMargin ? 1 : 0.5;

  if (status === 'Success' || status === 'InProgress' || status === 'SuccessWithWarning') {
    return (
      <Box display="flex">
        <Box ml={leftIcon}><SuccessIcon /></Box>
        <Box ml={leftStatus}>
          <Typography sx={{ ...((!noMargin) && boldFontWeight.boldFont) }}>{statusValue[status]}</Typography>
        </Box>
      </Box>
    );
  }
  if (status === 'Failed') {
    return (
      <Box display="flex">
        <Box ml={leftIcon}><Cancel color="error" /></Box>
        <Box ml={leftStatus}>
          <Typography sx={{ ...((!noMargin) && boldFontWeight.boldFont) }}>{status}</Typography>
        </Box>
      </Box>
    );
  }
  return <Typography>No status</Typography>;
};

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount = 0,
  sort,
  order,
  handlePageChange,
  syncJobList,
  handleSort,
  handleViewButton,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';
  return (
    <Box
      display="grid"
      width="100%"
      mt={3}
    >
      <Box>
        <StyledContainerRoot>
          <Table
            stickyHeader
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: '25%' }}>
                  <StyledTableCell sx={{ width: '15%', textAlign: 'left' }}>
                    Initiated by
                  </StyledTableCell>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '15%' }}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictSyncSort.CreatedAt)}
                    active={sort === DistrictSyncSort.CreatedAt}
                    IconComponent={ArrowDownIcon}
                  >
                    Started at
                  </StyledTableSort>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '15%', textAlign: 'left' }}>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(DistrictSyncSort.RunDuration)}
                    active={sort === DistrictSyncSort.RunDuration}
                    IconComponent={ArrowDownIcon}
                  >
                    Duration
                  </StyledTableSort>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '15%', textAlign: 'left' }}>
                  Status
                </StyledTableCell>
                <StyledTableCell sx={{ width: '15%', textAlign: 'left' }}>
                  Districts
                </StyledTableCell>
                <StyledTableCell sx={{ width: '15%' }}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && syncJobList?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography variant='tableTitleData'>
                      {
                        row?.owner_type === 'System'
                          ? row?.owner_type : `${row?.admin?.user?.first_name} ${row?.admin?.user?.last_name}`
                      }
                    </Typography>
                  </TableCell>
                  <StyledTableDataColumn>
                    {getMountainTimeZone(row?.created_at, '')}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {
                      row?.status === 'InProgress' ? '-' : (row?.run_duration > 60
                        ? `${Math.floor(row?.run_duration / 60)}m ${row?.run_duration % 60}s`
                        : `${row?.run_duration}s`)
                    }
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    <StatusIcon status={row?.status} />
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    <NumberFormat
                      displayType="text"
                      value={row?.district_ids?.length ?? '-'}
                      thousandSeparator
                    />
                  </StyledTableDataColumn>
                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleViewButton(row?.id)}
                    >
                      VIEW
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {isTableEmpty && !loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="left"
                  >
                    <NoResults
                      label="No Sync Jobs to display"
                      description="There has yet to be a district synced."
                      svgComponent={<HumanDeskFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      { totalCount > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
    </Box>
  );
};
export default List;
