import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Teacher from './Teacher';
import TeachersTable from './TeachersTable';
import Layout from '../../components/Layout';

const Teachers = () => (
  <Layout>
    <Switch>
      <Route
        path="/teachers"
        component={TeachersTable}
        exact
      />
      <Route
        path="/teachers/:id"
        component={Teacher}
        exact
      />
    </Switch>
  </Layout>
);

export default React.memo(Teachers);
