import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { palette  } from '../../../theme/palette';

interface Props {
  label: string | React.ReactNode,
  Icon: any,
  link?: string
}

const styles ={
  icon: {
    '&.MuiSvgIcon-root': {
      height: '16px',
      width: '16px',
      marginRight: '8px',
      fill: palette.customBlack.lightBlack,
      '& path': {
        fill: palette.customBlack.lightBlack,
      },
    },
  },
  box: {
    marginBottom: '12px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
}

const ResourceDetail = ({ label, Icon, link }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ ...styles.box }}
    >
      <Icon sx={{ ...styles.icon }} />
      {link
        ? (
          <Typography
            variant='redirectionLinks'
            component={Link}
            to={link}
          >
            {label}
          </Typography>
        )
        : <Typography>{label}</Typography>}
    </Box>
  );
};

export default React.memo(ResourceDetail);
