import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  SectionFilterFragmentFragment,
  SubmissionStatus,
  TeacherOfStudentFilterFragmentFragment,
  useGetAllAssignmentsForStudentQuery,
  useGetTeachersAssociatedWithSectionsQuery,
} from '../../../generated/graphql';

const useBackPackForStudents = () => {
  const [selectedTeacher, setSelectedTeacher] = useQueryParam('teacher', StringParam);
  const [selectedSection, setSelectedSection] = useQueryParam('section', StringParam);
  const [sectionIds, setSectionIds] = useState<(string)[]>([]);

  const onTeacherChange = (teacher: string | TeacherOfStudentFilterFragmentFragment | null) => {
    setSelectedTeacher(teacher as string);
  };

  const onSectionChange = (section: string | SectionFilterFragmentFragment | null) => {
    setSelectedSection(section as string);
  };

  const handleReset = () => {
    setSelectedSection('');
    setSelectedTeacher('');
  };

  const history = useHistory();
  const { data, loading } = useGetAllAssignmentsForStudentQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        include_not_started: true,
        status: [
          SubmissionStatus.Started,
          SubmissionStatus.AnnotationCompleted,
          SubmissionStatus.SpeakingCompleted,
          SubmissionStatus.Submitted,
          SubmissionStatus.Reviewed
        ]
      }
    }
  });
  const assignments = data?.getAllAssignmentsAndBenchmarksForStudent.assignments ?? [];
  const benchmarks = data?.getAllAssignmentsAndBenchmarksForStudent.benchmarks ?? [];
  const handleRedirection = (submissionId: string) => async () => {
    if (submissionId) history.push(`/backpack/${submissionId}/view`);
  };

  useEffect(() => {
    getSectionIds()
  }, [assignments]);

  const getSectionIds = () => {
    const sectionIds = assignments.map(assignment => assignment.section_id ?? '')
    setSectionIds(sectionIds!)
  }

  const {data: sectionTeachers, loading: teacherLoading} = useGetTeachersAssociatedWithSectionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
       sectionIDs: sectionIds ?? [],
    },
    skip: !sectionIds.length
  })

  const assignmentTeachers = (id?: string) => {
    const teachers = sectionTeachers?.teachersOfSection?.find(section => section?.id == id)?.teachers
    teachers?.sort((t1, t2) => Number(!t1?.primary_teacher) - Number(!t2?.primary_teacher));
    return teachers?.map(teacher => {
      return teacher?.primary_teacher ? ` ${teacher?.first_name} ${teacher?.last_name}*` : ` ${teacher?.first_name} ${teacher?.last_name}`
    })
  }

  return {
    loading: loading || teacherLoading,
    assignments,
    benchmarks,
    handleRedirection,
    selectedTeacher,
    setSelectedTeacher,
    onTeacherChange,
    selectedSection,
    setSelectedSection,
    onSectionChange,
    handleReset,
    assignmentTeachers,
  };
};

export default useBackPackForStudents;
