import { useMySchoolQuery, useSchoolsFilterQuery } from '../../generated/graphql';
import useRole from '../../hooks/useRole';

interface Props {
  stateIds?: string[] | undefined
  districts?: string[] | undefined
  grades?: string[] | undefined
}

const schoolVariables = ({ stateIds, districts, grades }: Props) => {
  const stateIdFilters = stateIds?.filter(state => !(['', null].includes(state)));
  const districtIdFilters = districts?.filter(district => !(['', null].includes(district)));
  let variables = {};
  if (stateIdFilters && stateIdFilters.length) {
    variables = {
      ...variables,
      stateIDs: typeof (stateIds) === 'string' ? [stateIds] as string[] : stateIds,
    };
  }
  if (districtIdFilters && districtIdFilters.length) {
    variables = {
      ...variables,
      districtIDs: typeof (districts) === 'string' ? [districts] as string[] : districts,
    };
  }
  if (grades && grades.length) {
    variables = {
      ...variables,
      gradeIDs: typeof (grades) === 'string' ? [grades] : grades,
    };
  }
  return variables;
};

const useSchoolsFilter = ({ stateIds, districts, grades }: Props) => {
  const { isSchoolAdmin } = useRole();
  const { data, loading } = useSchoolsFilterQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...(schoolVariables({ stateIds, districts, grades })),
    },
  });
  const { data: schoolDetails, loading: schoolDetailsLoading } = useMySchoolQuery({
    fetchPolicy: 'network-only',
    skip: !isSchoolAdmin,
  });

  return {
    schools: !isSchoolAdmin ? data?.schoolsFilterList ?? [] : [schoolDetails?.mySchool],
    loading: loading || schoolDetailsLoading,
    schoolsOnly: data?.schoolsFilterList?.map(school => ({ name: school?.name, id: school?.id, district_id: school?.district_id, district_state_id: school?.district_state_id })),
  };
};

export default useSchoolsFilter;
