import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useTeacher from './teacher-hook';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import StyledBoxWrapper from '../../../components/StyledCard/StyledCard.styled';
import ResourceDetailHeader from '../../Districts/District/resource-detail-header';
import ResourceDetail from '../../Districts/District/resource-detail';
import SchoolsIcon from '../../../components/Icons/SchoolsIcon';
import TeachersIcon from '../../../components/Icons/TeachersIcon';
import MessageIcon from '../../../components/Icons/MessageIcon';
import { SchoolInfo } from '../../../generated/graphql';
import ClassesIcon from '../../../components/Icons/ClassesIcon';
import { StudentsIcon } from '../../../components/Icons';
import { MY_SCHOOL, PROVIDER_TYPE } from '../../../variables/constant';
import { Entity, ImitatationUserType } from '../../../variables/types';
import AddNewEntity from '../../../components/AddNewEntity';
import DistrictsIcon from '../../../components/Icons/DistrictsIcon';

const Teacher = () => {
  const {
    teacher,
    loading,
    editTeacherPermission,
    isSchoolAdmin,
    handleImitationLogin,
    imitateUserPermission,
  } = useTeacher({});

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <ResourceDetailHeader
        title={`${teacher?.first_name ? teacher?.first_name : ''} ${teacher?.last_name ? teacher.last_name : ''}`}
        addNewEntity={editTeacherPermission ? (
          <AddNewEntity
            entity={Entity.Teacher}
            districtId={teacher?.district_id ?? undefined}
            stateId={teacher?.state_id ?? undefined}
            schoolId={teacher?.schools?.[0]?.id ?? undefined}
            teacherId={teacher?.id ?? undefined}/>
        ) : undefined }
        primaryBtnRedirectionLink={teacher?.source !== PROVIDER_TYPE.FLASHLIGHT || !editTeacherPermission ? undefined : `/admin/create/teacher/${teacher?.id}`}
        PrimaryBtnIcon={teacher?.source !== PROVIDER_TYPE.FLASHLIGHT ? undefined : PencilIcon}
        primaryBtnLabel={teacher?.source !== PROVIDER_TYPE.FLASHLIGHT ? undefined : 'Edit'}
        secondaryBtnLabel={imitateUserPermission ? 'Log In As' : undefined}
        secondaryBtnOnClick={() => handleImitationLogin(
          teacher?.user_id!, ImitatationUserType.Teacher, teacher?.id!,
        )}
        SecondaryBtnIcon={StudentsIcon}
      />
      <Box my={2}>
        <StyledBoxWrapper width="30%">
          {teacher && teacher?.schools?.length > 0 && (
            <ResourceDetail
              label={(teacher?.schools && teacher?.schools?.length > 0 ? (
                <Box display="flex">
                  <Typography sx={{ marginRight: '4px' }}>{teacher?.job_title}</Typography>
                  {teacher?.job_title && teacher?.schools?.length > 0 ? <Typography sx={{ mr: '4px' }}>{' at '}</Typography> : ''}
                  <Box>
                    <Typography
                      key={teacher?.schools[0]?.id}
                      variant='redirectionLinks'
                      sx={{ mr: '4px' }}
                      component={Link}
                      to={`/districts/${teacher?.district_id}`}
                    >
                      {teacher?.district ?? ''}
                    </Typography>
                  </Box>
                </Box>
              ) : ''
              )}
              Icon={DistrictsIcon}
            />
          )}
          <ResourceDetail label={`Teacher ID: ${teacher?.teacher_id || 'No teacher id available'}`} Icon={TeachersIcon} />
          <ResourceDetail label={teacher?.email || 'No email available'} Icon={MessageIcon} />
          {teacher && teacher?.schools?.length > 0 && (
            <ResourceDetail
              label={
                <Box display="flex">
                  <Box>
                    {teacher?.schools?.map((school: SchoolInfo | undefined | null, index: number) => (
                      <Typography
                        key={school?.id}
                        variant='redirectionLinks'
                        sx={{ mr: '4px' }}
                        component={Link}
                        to={`/schools/${isSchoolAdmin ? MY_SCHOOL : school?.id}`}
                      >
                        {school?.name ?? ''}
                        {index !== teacher?.schools?.length - 1 ? ', ' : ''}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              }
              Icon={SchoolsIcon}
            />
          )}
          <ResourceDetail
            label="View Classes"
            link={`/classes?teacher=${teacher?.id ?? ''}`}
            Icon={ClassesIcon}
          />
        </StyledBoxWrapper>
      </Box>
      <Loader open={loading} />
    </Box>
  );
};

export default React.memo(Teacher);
