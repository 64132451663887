import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledTableContainer from '../../Assignments/AssignmentsTable/List.styled';
import TablePagination from '../../../components/TablePagination';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { BenchmarkSubmissionListNode, BenchmarkSubmissionListSortType, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import ImageCardAssignment from '../../../components/ImageCardAssignment';
import Loader from '../../../components/Loader/loader';
import {
  PAGE_SIZE,
  READABLE_SHORT_DATE_OPTIONS,
} from '../../../variables/constant';
import { StylesCss } from '../../Assignments/Assignments.styled';
import { toReadableFormat } from '../../../utils/dateFormat';
import { StyledTableCells } from '../../Admin/admin-tables-styled';
import { PaginationProps, SubmissionStatus } from '../../../variables/types';
import { StyledTableSort } from '../../../components/Common/Common.styled';
import { StyledViewButton } from '../../../components/PTTile/PTTtile.styled';
import BenchmarkStatus from '../../../components/BenchmarkStatus';
import MarkCompleteIcon from '../../../components/Icons/MarkCompleteIcon';

export const getNoDataLabels = () => ({
  label: 'No Benchmarks Submitted Yet',
  description: 'There has yet to be a benchmark submitted at this time',
});

interface Props {
  pagination: PaginationProps,
  totalCount: number,
  order: OrderBy,
  sort: string,
  onSortChange: (sortBy: BenchmarkSubmissionListSortType, orderBy: OrderBy) => void,
  handleRedirection: Function,
  handlePagination: (value: React.SetStateAction<number>) => void,
  gradeBenchmarks?: BenchmarkSubmissionListNode[],
  submissionQueueData?: string[],
  loading: boolean,
}

const GradesTable = ({
  pagination,
  totalCount,
  order,
  sort,
  onSortChange, handlePagination,
  handleRedirection,
  gradeBenchmarks,
  submissionQueueData,
  loading,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCss(theme);
  const isTableEmpty = !loading && gradeBenchmarks?.length === 0;

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: React.SetStateAction<number>) => handlePagination(value);

  const handleSort = (column: BenchmarkSubmissionListSortType) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const { label, description } = getNoDataLabels();
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const sortColumn = (title:string, sortEnum: BenchmarkSubmissionListSortType) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box>
        <StyledTableContainer>
          <Table
            stickyHeader
            aria-label="Admin Benchmarks Grades List"
            sx={{ ...((loading || isTableEmpty) && styles.tableWithNoData), ...(loading && { maxHeight: '60px' }) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCells/>
                <StyledTableCells sx={styles.width25}>
                  { sortColumn('Student', BenchmarkSubmissionListSortType.StudentName) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  { sortColumn('Benchmark', BenchmarkSubmissionListSortType.BenchmarkTitle) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width15}>
                  { sortColumn('Submitted', BenchmarkSubmissionListSortType.SubmittedAt) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  { sortColumn('District', BenchmarkSubmissionListSortType.DistrictName) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width10}>
                  { sortColumn('Grade', BenchmarkSubmissionListSortType.GradeTitle) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  Status
                </StyledTableCells>
                <StyledTableCells />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && gradeBenchmarks?.map((row: any) => (
                <TableRow key={row?.id}>
                  {/*Image*/}
                  <TableCell>
                    <Box>
                      <ImageCardAssignment
                        imageSrc={row.benchmark_image_url || ''}
                        thumbnailImageSrc={row?.benchmark_thumbnail_image_url || row?.benchmark_image_url || ''}
                        key={row?.id}
                      />
                    </Box>
                  </TableCell>

                  {/*Student Name*/}
                  <TableCell
                    sx={styles.assignmentNameCell}
                  >
                    {row?.user_first_name} {row?.user_last_name}
                  </TableCell>

                  {/*Assignment Title*/}
                  <TableCell
                    sx={styles.assignmentNameCell}
                  >
                    {row.benchmark_title}
                  </TableCell>

                  {/*Date*/}
                  <TableCell
                    sx={{ ...styles.dateRangeCell, ...styles.uppercase }}
                  >
                    {`${toReadableFormat(row?.submitted_at as string, READABLE_SHORT_DATE_OPTIONS)}`}
                  </TableCell>

                  {/*District*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {row.student_district_title}
                  </TableCell>

                  {/*Grade*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {row.student_grade_title}
                  </TableCell>

                  {/*Status*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    <BenchmarkStatus iconPresent abbreviation={<MarkCompleteIcon />} tileStatus={row?.status}/>
                  </TableCell>

                  {/*View*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    <StyledViewButton
                      variant="outlined"
                      onClick={handleRedirection(
                        submissionQueueData ?? [row?.id],
                        row?.id,
                      )}
                    >
                      {row?.status === SubmissionStatus.Reviewed ? 'View' : 'Grade'}
                    </StyledViewButton>
                  </TableCell>

                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align="center"
                  >
                    <NoResults
                      label={label}
                      description={description}
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              <Loader open={loading} />
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      {totalCount! > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount! / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
    </Box>
  );
};

export default GradesTable;
