import React from 'react';
import { MenuItem } from '@mui/material';
import { Maybe } from '../../generated/graphql';
import useBenchmarkTypes from './benchmarkTypes-hook';
import { StyledSelect } from './Select.styled';
import Loader from '../Loader/loader';

interface Props {
  error?: boolean;
  className?: string;
  value?: string | undefined;
  onChange?: (data: Maybe<string> | undefined) => void;
  disabled?: boolean;
  placeholder?: string | undefined;
}

const BenchmarkTypes = ({
  value = '',
  onChange,
  disabled = false,
  placeholder = 'Benchmark Type',
}: Props) => {
  const { benchmarkTypes, loading } = useBenchmarkTypes();

  const onSelectChange = (event: any) => {
    onChange?.(event.target.value);
  };

  return (
    <>
      <Loader open={loading} />
      <StyledSelect
        id={'benchmark-type'}
        value={value ?? ''}
        label={placeholder}
        onChange={onSelectChange}
        disabled={disabled || loading}
      >
        {
          !loading && benchmarkTypes?.length && benchmarkTypes?.map(
            (benchmarkType) => (
              <MenuItem value={benchmarkType?.key} key={benchmarkType?.key}>{benchmarkType?.value}</MenuItem>
            ))
        }
      </StyledSelect>
    </>
  );
};

export default BenchmarkTypes;
