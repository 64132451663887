/* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LightningBolt = (props: any) => (
  <SvgIcon
    fontSize="medium"
    width="24"
    height="17"
    viewBox="0 0 24 20"
    {...props}
  >
    <path xmlns="http://www.w3.org/2000/svg" d="M8.30064 0.0463346C8.71692 0.177555 9 0.563602 9 1.00007V6.00007L13 6.00007C13.3729 6.00007 13.7148 6.20753 13.887 6.53826C14.0592 6.86899 14.0331 7.26806 13.8192 7.57353L6.81924 17.5735C6.56894 17.9311 6.11564 18.085 5.69936 17.9538C5.28309 17.8226 5 17.4365 5 17.0001L5 12.0001H1C0.627127 12.0001 0.285222 11.7926 0.113024 11.4619C-0.0591735 11.1312 -0.0330591 10.7321 0.180771 10.4266L7.18077 0.42661C7.43107 0.0690398 7.88436 -0.0848856 8.30064 0.0463346Z" />
  </SvgIcon>
);

export default React.memo(LightningBolt);
