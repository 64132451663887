import { useState } from 'react';
import { uploadWithProgress, abortUpload } from './uploader';

const useUploader = ({
  uploadUrl,
  accessToken,
  chunkSize = 5500000, // ~5.25MB
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [done, setDone] = useState(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [uploadData, setUploadData] = useState<any>(null);

  const onUploadProgress = (progressData: any) => {
    const { progress: currentProgress, finished, ...fileData } = progressData;
    if (finished || currentProgress === 100) {
      onUploadComplete(fileData);
      return;
    }
    if (Number.isInteger(currentProgress) && currentProgress !== 100) {
      setProgress(currentProgress);
    }
  };

  const onUploadComplete = (fileData: any) => {
    setIsLoading(false);
    setData(fileData);
    setProgress(100);
    setDone(true);
  };

  const abort = async () => {
    if (uploadData) {
      // setIsLoading(false);
      const { key, uploadId } = uploadData;
      try {
        await abortUpload({
          key,
          uploadId,
          accessToken,
          uploadUrl,
        });
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    }
  };

  const upload = async (file: any) => {
    try {
      setIsLoading(true);
      setProgress(0);
      const req = await uploadWithProgress({
        uploadUrl,
        file,
        accessToken,
        chunkSize,
        handleUpload: onUploadProgress,
      });
      setUploadData(req);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line no-async-promise-executor
  const uploadAsync = async (file: File) => new Promise<UploadResponseType>(async (resolve, reject) => {
    try {
      setIsLoading(true);
      setProgress(0);
      const req = await uploadWithProgress({
        uploadUrl,
        file,
        accessToken,
        chunkSize,
        handleUpload: (progressData: any) => {
          onUploadProgress(progressData);
          if (progressData.finished) {
            resolve(progressData);
          }
        },
      });
      setUploadData(req);
    } catch (err) {
      setError(err);
      setIsLoading(false);
      reject(err);
    }
  });

  return { uploadAsync, upload, abort, isLoading, progress, data, error, done };
};

export interface UploadResponseType {
  key: string,
  bucket: string,
  url: string,
  fileType: string,
}

export default useUploader;
