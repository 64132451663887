// eslint-disable-next-line import/prefer-default-export
import { isIOS } from 'react-device-detect';
import { palette } from '../theme/palette';
import { FormStep, GradedFilter } from './types';

export const PROD_ENVIRONMENT_LABEL = 'prod';
export const TURING_ENVIRONMENT_LABEL = 'turing';
// @ts-ignore
export const CURRENT_ENVIRONMENT = `${import.meta.env.REACT_APP_ENV}`;

export const AUTH_USER_TOKEN_KEY = 'AUTH_USER_TOKEN_KEY';
export const AUTH_KEY = 'auth';
export const FULL_DATE_FORMAT = 'MM/dd/yyyy';
export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const PAGE_SIZE = 20;
export const TAG_WIDTH = 210;
export const TAG_HEIGHT = 30;
// Change POPUP_WIDTH & POPUP_HEIGHT based on device.
export const POPUP_WIDTH = isIOS ? 250 : 350;
export const POPUP_HEIGHT = isIOS ? 150 : 250;
export const PREVIEW_GRADIENT_HEIGHT = 100;
export const STUDENT_RECORDING_DELAY_TIME = 7;
export const ASSIGNMENT_PROMPT_DELAY_TIME = 3;
export const READABLE_DATE_FORMAT = 'EEE, LLL dd';
export const READABLE_SINGLE_DATE_FORMAT = 'LLL dd';
export const CLICK_AND_LABEL_TEXT_LENGTH = 100;
export const DEBOUNCE_THRESHOLD = 2000;
export const STUDENT_AUDIO_FILE_LIMIT = 3;
export const GOOGLE_VOICE = 'Google US English';
export const SAMANTHA_VOICE = 'Samantha';
export const PUBLIC = 'public';
export const ACTIVE_GOAL_TOOLTIP_TYPE = 'goal';
export const IMAGE_HEIGHT = 1080;
export const IMAGE_WIDTH = 1920;
export const READABLE_DATE_OPTIONS = {
  weekday: 'short',
  month: 'short',
  day: '2-digit',
  timeZone: 'UTC',
};
export const READABLE_SHORT_DATE_OPTIONS = {
  month: 'short',
  day: '2-digit',
  timeZone: 'UTC',
};
export const DELETED_ASSIGNMENT_MESSAGE = 'The assignment has been deleted. Please connect with your teacher.';
export const SUBMISSION_ALREADY_REVIEWED_MESSAGE = 'The submission has been already reviewed.';
export const USER_GROUP_MAPPING_MESSAGE = 'User does not have access to this group.';
export const ASSIGNMENT_DOES_NOT_EXIST_MESSAGE = 'The assignment might be deleted or moved to another section.';
export const RECORD_NOT_FOUND_ERROR = 'record not found';
export const SUBMISSION_DOES_NOT_EXIST_ERROR = 'submission does not exist';
export const SUBMISSION_ALREADY_REVIEWED_ERROR = 'submission already reviewed';
export const USER_GROUP_MAPPING_ERROR = 'user section mapping does not exist';
export const ASSIGNMENT_DOES_NOT_EXIST_ERROR = 'assignment does not exist';
export const BENCHMARK_DOES_NOT_EXIST_ERROR = 'benchmark does not exist';
export const BENCHMARK_DOES_NOT_EXIST_MESSAGE = 'The benchmark might be deleted or moved to another section.';
export const INVALID_TOKEN_ERROR = 'invalid token';
export const CUSTOM_TOOLTIP_HEIGHT = 100;
export const CUSTOM_TOOLTIP_WIDTH = 350;
export const IMAGE_ERROR = 'Invalid Image(s)';
export const IMAGE_RES_ERROR = 'Please upload an image of size 1920*1080';
export const THUMBNAIL_MAX_FILE_SIZE_KB = 512;
export const THUMBNAIL_MAX_FILE_SIZE_ERROR = 'Please upload a thumbnail image upto 512 KB';
export const THUMBNAIL_RES = '1280*720';
export const THUMBNAIL_RES_ERROR = 'Please upload an image of size 1280*720';
export const FILE_REQUIRED_ERROR = 'Please upload an image';
export const UPLOADING_IMAGE_FAILED = 'Upload Failed, Try Again.';
export const STATIC_RESOURCE_IMG_UPLOAD_DEL_TEXT = 'Teachers and students will not be able to see or use this image once its deleted.';
export const CONFIRM_PASSWORD_HELPER_TEXT = 'Password and confirm password do not match';
export const ASSIGNMENT_TITLE_ERROR = 'Please enter the assignment title';
export const SCORES = {
  limited: {
    skillLevel: 1,
    skillLabel: 'Limited',
    skillDescription: 'Limited/no response in English, may contain student’s first language',
    skillLinkText: 'student',
    color: palette.customRed.buttonFontRed,
  },
  emerging: {
    skillLevel: 2,
    skillLabel: 'Emerging',
    skillDescription: 'Emerging English with significant errors',
    skillLinkText: 'student',
    color: palette.customYellow.main,
  },
  expanding: {
    skillLevel: 3,
    skillLabel: 'Expanding',
    skillDescription: 'Responses are more precise and contain moderate errors',
    skillLinkText: 'student',
    color: palette.fontColors.buttonFontGreen,
  },
  bridging: {
    skillLevel: 4,
    skillLabel: 'Bridging',
    skillDescription: 'Correct and relevant responses with minimal errors',
    skillLinkText: 'student',
    color: palette.customBlue.aquaBlue,
  },
  advanced: {
    skillLevel: 5,
    skillLabel: 'Advanced',
    skillDescription: 'Meets or exceeds grade-level ELA/ELD and CCSS/CCR standards',
    skillLinkText: 'student',
    color: palette.customBlue.cornflowerBlue,
  },
};

export const SCORE_TYPE_TEXT = {
  limited: 'Limited',
  emerging: 'Emerging',
  expanding: 'Expanding',
  bridging: 'Bridging',
  advanced: 'Advanced',
};

export const SKILL_TYPE_TEXT = {
  speaking: 'Speaking',
  writing: 'Writing',
};

export const SPEAKING_SKILL_TYPE_TEXT = {
  grammar: 'Grammar',
  vocabulary: 'Vocabulary',
  pronunciation: 'Pronunciation',
  interpretation: 'Interpretation',
  fluency: 'Fluency',
};

export const WRITING_SKILL_TYPE_TEXT = {
  vocab_and_grammar: 'Vocabulary & Grammar',
  points_and_reasons: 'Points & Reasons',
  description_and_explanation: 'Description & Explanation',
};

export const GOALS = [
  'vocab_and_grammar',
  'points_and_reasons',
  'description_and_explanation',
];

export const ALL_SKILLS = 'all';

export const DEFAULT_GOAL_OPTION = 'select-a-goal';
export const CUSTOM_GOAL_CATEGORY = 'Custom';
export const CUSTOM_GOAL_OPTION = 'custom-goal-option';

export const GOAL_BUTTON_LABELS = {
  edit: 'EDIT',
  create: 'CREATE',
};
export const READABLE_MONTH_OPTIONS = {
  month: 'short',
  day: '2-digit',
  timeZone: 'UTC',
};
export const NUMBER_OF_SCORES = 5;

export const PROVIDER_TYPE = {
  CLEVER: 'clever',
  FLASHLIGHT: 'flashlight',
  ROSTERSTREAM: 'rosterStream',
};

export const EMAIL_REGEX = /^[a-zA-Z0-9]+[\w-+\.]*@([\w-]+(?:[.-][\w-]+)*\.[\w-]{2,64})$/i;
export const ALPHABETICAL_REGEX = /^[A-Z]/i;
export const NUMERICAL_REGEX = /^[0-9]/i;
export const NON_SPACE_REGEX = /^\S*$/;
export const GAIN_VALUE = isIOS ? 2 : 1;
export const MY_DISTRICT = 'my-district';
export const MY_SCHOOL = 'my-school';

export const GRADES_ALL_GRADES = 'All Grades';
export const ANCHOR_GRADE_PK_K = 'PK-K';
export const ANCHOR_GRADE_1_2 = '1-2';
export const ANCHOR_GRADE_3_5 = '3-5';
export const ANCHOR_GRADE_6_8 = '6-8';
export const DEMO_ENV = 'demo';
export const ANCHOR_GRADE_9_12 = '9-12';

export const isIpad = window.matchMedia('(max-width: 1024px)').matches;
export const SPEAKING_AUDIO_ALERT_MESSAGE = 'Good job! You can listen to your recording here.';
// @ts-ignore
export const SHARED_BUCKET_URL = `https://${import.meta.env.REACT_APP_SHARED_RESOURCE_BUCKET_NAME}.s3-accelerate.amazonaws.com`;

export const DEFAULT_SCHEME = 'default_scheme';
export const SCHEME_1 = 'scheme_1';
export const SKILL_LINK_TEXT = 'student';
export const SEE_GOALS = 'See Goals';
export const GOAL_SCORES = {
  all: {
    skill_label: 'All',
    skillLinkText: 'See Goals',
    color: palette.customBlue.primaryBlue,
    skill_key: 'all',
  },
};
export const DeleteSubmittedAssignment = 'Some students have already finished this assignment. If you save these changes, their submissions will be lost.';
export const DeleteNonSubmittedAssignment = 'You want to delete this assignment. Assignment and its details will be lost.';
export const AssignmentDeleteButtonDisableTooltip = 'Assignment cannot be deleted as it has scored submissions that share goals with submissions from other assignments.';

export const DeleteSubmittedBenchmark = 'Some students have already finished this benchmark. If you delete the assignment, their submissions will be lost.';
export const DeleteNonSubmittedBenchmark = 'You want to delete this benchmark. Benchmark and its details will be lost.';

export const CELEBRATION_ACTION = {
  DONE: 'DONE_FOR_NOW',
  CONTINUE: 'CONTINUE_GRADING',
};

export const GRADES_FOR_CELEBRATION = 5;

export const CELEBRATION_DATA = {
  imageMessageData: [
    {
      imageUrl: `${SHARED_BUCKET_URL}/images/grading-celebration/egypt.jpg`,
      imageTitle: 'Egypt',
      primaryMessage: 'Another achievement under your belt!',
      secondaryMessage: "You're making a difference for your students.",
    },
    {
      imageUrl: `${SHARED_BUCKET_URL}/images/grading-celebration/hero.jpg`,
      imageTitle: 'Hero',
      primaryMessage: 'Another achievement under your belt!',
      secondaryMessage: 'The world needs more heroes like you.',
    },
    {
      imageUrl: `${SHARED_BUCKET_URL}/images/grading-celebration/puppies.jpg`,
      imageTitle: 'Puppies',
      primaryMessage: 'You deserve all the puppies',
      secondaryMessage: 'Keep up the amazing work!',
    },
    {
      imageUrl: `${SHARED_BUCKET_URL}/images/grading-celebration/superhero.jpg`,
      imageTitle: 'Superhero',
      primaryMessage: 'Another achievement under your belt!',
      secondaryMessage: 'The world needs more heroes like you.',
    },
    {
      imageUrl: `${SHARED_BUCKET_URL}/images/grading-celebration/unicorn.jpg`,
      imageTitle: 'Unicorn',
      primaryMessage: "You're one of a kind",
      secondaryMessage: 'Keep up the amazing work!',
    },
  ],
  primaryButtonText: 'Next PT',
  secondaryButtonText: "I'm done for now",
  secondaryMessageForGoals: 'Set goals to fill in all the chips and light the green check mark. Goals support students by personalizing their learning.',
};

export const QUEUE_END_INFO = {
  imageUrl: `${SHARED_BUCKET_URL}/images/grading-celebration/beach.jpg`,
  imageTitle: 'Beach',
  primaryButtonText: 'Done',
  primaryMessage: 'You finished the grading queue!',
  secondaryMessage: "Sit back and relax. You're done!",
};

export const ErrInvalidQueueNavigation = 'Invalid navigation';
export const ErrQueueNotCreated = 'No active queue found, new queue can be started from the teacher dashboard';

export const BREAKPOINTS = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const SPEAKING_DEFAULT_PROMPT_TEXT = 'Describe the picture. Tell as much as you can. You can record 3 times.';
export const WRITING_DEFAULT_PROMPT_TEXT = 'Describe the picture. Write as much as you can.';

export const CreatePtSteps: FormStep[] = [
  {
    step: 1,
    name: 'details',
    label: 'details',
  },
  {
    step: 2,
    name: 'select-image',
    label: 'select image',
  },
  {
    step: 3,
    name: 'instructions',
    label: 'instructions',
  },
];

export const AdminDashboards = {
  common: {
    insufficientData: {
      'title': 'Insufficient Data',
      'description': 'Check back when assignments have been scored.',
    },
  },
  districtWideComparisions: {
    schools: {
      name: 'schools',
      tooltipText: '',
      insufficientData: {
        'title': 'We Need More Data.',
        'description': '',
      },
    },
    grades: {
      name: 'grades',
      tooltipText: '',
      insufficientData: {
        'title': 'We Need More Data.',
        'description': '',
      },
    },
  },
  performance: {
    overallPerformance: {
      name: 'overallPerformance',
      tooltipText: 'These are the students’s average scores on their Performance Tasks over time.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
    studentCompletionRatesWidget: {
      name: 'studentCompletionRatesPerformance',
      tooltipText: 'This is the percentage of all assignments that have been completed.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
    PerformanceSliderWidget: {
      name: 'PerformanceSliderWidget',
      tooltipText: 'This is how many students fall into each performance category.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
    TasksAssignedNCompletedWidget: {
      name: 'PerformanceTasksAssignedNCompleted',
      tooltipText: 'These are the assignments teachers have assigned compared to the number of students.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
  },
  learningGoals: {
    GoalsAndScoresByIndicatorWidget: {
      name: 'GoalsAndScoresByIndicatorWidget',
      tooltipText: 'This is the number of goals and average scores per indicator.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
    GoalAchievementWidget: {
      name: 'GoalAchievementWidget',
      tooltipText: 'This is student goal performance over time.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
    CompletedAssignmentsGoalsWidget: {
      name: 'CompletedAssignmentsGoalsWidget',
      tooltipText: 'This shows the number of active students and active goals each month.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
    GoalPerformanceWidget: {
      name: 'GoalPerformanceWidget',
      tooltipText: 'This is the number of goals and average student performance of each indicator.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
    GoalsByIndicatorWidget: {
      name: 'GoalsByIndicatorWidget',
      tooltipText: 'This is how many active learning goals are focused on each indicator.',
      insufficientData: {
        'title': 'Insufficient Data',
        'description': 'Check back when assignments have been scored.',
      },
    },
  },
};

export const GradeOptions = [
  { label: 'PK-K', value: 'PK-K' },
  { label: '1-2', value: '1-2' },
  { label: '3-5', value: '3-5' },
  { label: '6-8', value: '6-8' },
  { label: '9-12', value: '9-12' },
];

export const CreateBenchmarkSteps = CreatePtSteps.concat({
  step: 4,
  name: 'summary',
  label: 'summary',
});


export const SubmissionTypes = {
  Submitted: 'Submitted',
  NotStarted: 'Not Started',
  InProgress: 'In Progress',
};

export const BenchmarkSubmissionTypes = {
  Submitted: 'Submitted',
  Reviewed: 'Reviewed',
};

export const SubmissionOptions = [
  {
    label: 'Graded',
    value: GradedFilter.Graded,
  },
  {
    label: 'Not Yet Graded',
    value: GradedFilter.NotGraded,
  },
];

export const BENCHMARK_COUNT_LIMIT = 5000;

