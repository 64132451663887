import React from 'react';
import { Box, Typography, IconButton, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import List from './List';
import { StyledFlexColBoxContainer } from '../../../../components/Common/Common.styled';
import { DistrictWideComparisonsStylesCSS } from '../DistrictWideComparisons.styled';

import useGradeSpecificDetails from './grade-specific-details-hook';
import { DistrictWideGradeDetail, DistrictWideGradeSpecificDetailsSortType } from '../../../../generated/graphql';

interface Props {
  districtId?: string;
  schoolId?: string;
  grade: DistrictWideGradeDetail,
  toggleDrawer: (event: React.MouseEvent) => void,
}

const GradeSpecificDetails = ({ districtId, schoolId, grade, toggleDrawer }: Props) => {
  const theme = useTheme();
  const styles = DistrictWideComparisonsStylesCSS(theme);

  const gradeId = grade?.grade_id;
  const gradeName = grade?.grade_title;

  const {
    loading,
    queryInput, handleSort,
    pagination, handlePageChange,
    districtWideGradeSpecificDataCount, districtWideGradeSpecificData: teachers, isTableEmpty,
  } = useGradeSpecificDetails({ districtId: districtId, schoolId: schoolId, gradeId: gradeId });

  return (
    <StyledFlexColBoxContainer role="presentation">
      <Box sx={styles.drawerHeader}>
        <Grid container>
          <Grid item xs={11}>
            <Box>
              <Box>
                <Typography
                  variant='font22'
                  sx={{ color: theme.palette.customWhite.main }}
                >
                  {gradeName}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='font16'
                >
                  {`${districtWideGradeSpecificDataCount} teacher`}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="close grade specific drawer" component="button" size="large" edge="end"
              sx={{ ...styles.drawerHeaderIcon }}
              onClick={toggleDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <List
          isTableEmpty={isTableEmpty}
          loading={loading!}
          totalCount={districtWideGradeSpecificDataCount}
          pagination={pagination}
          limit={queryInput.limit!}
          sort={queryInput.sort! as DistrictWideGradeSpecificDetailsSortType}
          order={queryInput.orderBy!}
          teachers={teachers}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
        />
      </Box>
    </StyledFlexColBoxContainer>
  );
};

export default GradeSpecificDetails;
