/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette  } from '../../theme/palette';

const ArrowDownIcon = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    {...props}
  >
    <path
      d="M13.0782 8.50088H1.9219C0.918777 8.50088 0.417215 7.28682 1.12503 6.579L6.70315 1.00088C7.14378 0.560254 7.85628 0.560254 8.29221 1.00088L13.8703 6.579C14.5828 7.28682 14.0813 8.50088 13.0782 8.50088Z"
      fill={palette.fontColors.fontBlack}
    />
  </SvgIcon>
);

export default ArrowDownIcon;
