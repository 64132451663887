import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import {
  StudentSort, StudentTabs,
} from '../../../variables/types';
import {
  StudentsFragFragment,
  OrderBy,
  TeacherByUserIdFragmentFragment,
  Section,
} from '../../../generated/graphql';
import NoResults from '../../../components/NoResults';
import QuestionIcon from '../../../components/Icons/QuestionIcon';
import DogFrame from '../../../components/Icons/DogFrame';
import { StyledTableSorted } from '../../../components/Common/Common.styled';
import Loader from '../../../components/Loader/loader';
import PencilIcon from '../../../components/Icons/PencilIcon';
import { palette } from '../../../theme/palette';
import { PROVIDER_TYPE } from '../../../variables/constant';

interface Props {
  stickyHeader?: boolean,
  loading?: boolean,
  students?: StudentsFragFragment[],
  onSortChange: (sortBy: StudentSort, orderBy: OrderBy) => void,
  sort: StudentSort,
  order: OrderBy,
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleClick: (id: string) => void,
  numSelectedStudents: number,
  rowCount: number,
  isSelected: (id: string) => boolean,
  closeDialog: (type?: string, id?: string) => void,
  teacher?: TeacherByUserIdFragmentFragment,
  isTeacherPrimary: (sections?: Section[]) => boolean | undefined
}

const StylesCSS = (theme: Theme) => ({
  studentNameCell: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  iconSortLabel: {
    icon: {
      marginLeft: theme.spacing(1.25),
    },
  },
  noData: {
    padding: theme.spacing(2),
  },
  tableHeadContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
  },
  questionIconStyles: {
    '&.MuiSvgIcon-root': {
      fontSize: 13,
    },
  },
  tooltipContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  tooltipTitle: {
    marginBottom: theme.spacing(1.25),
  },
  tooltipContentListContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  tooltipContentOrder: {
    color: theme.palette.fontColors.fontBlack,
    fontSize: '16px',
    fontWeight: 800,
    lineHeight: '22px',
    letterSpacing: '0.1px',
    marginRight: theme.spacing(1),
  },
  tooltipContent: {
    color: theme.palette.customBlack.lightBlack,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
  tooltip: {
    maxWidth: 'none',
  },
  tableWithNoData: {
    height: '100%',
    '& tbody tr:hover': {
      backgroundColor: theme.palette.transparent.main,
      cursor: 'initial',
    },
  },
});

const ColumnInfoTooltip = (
  { tooltipTitle }: { tooltipTitle: NonNullable<React.ReactNode> },
) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  return (
    <Box sx={styles.questionContainer}>
      <Tooltip
        title={tooltipTitle}
        sx={styles.tooltip}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={5000}
      >
        <Box sx={styles.questionContainer}>
          <QuestionIcon sx={styles.questionIconStyles}/>
        </Box>
      </Tooltip>
    </Box>
  );
};

const TooltipContent = (
  { definition }: { definition: string },
) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  return (
    <Box sx={styles.tooltipContentContainer}>
      <Box sx={styles.tooltipContentListContainer}>
        <Typography sx={styles.tooltipContent}>
          {definition}
        </Typography>
      </Box>
    </Box>
  );
};

const TrimmedTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.customBlack.lightBlack,
  fontSize: 16,
  fontWeight: 800,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '175px',
}));



const StudentTable = ({
  loading = false,
  stickyHeader = true,
  students,
  onSortChange,
  onSelectAllClick,
  handleClick,
  isSelected,
  numSelectedStudents,
  rowCount,
  sort,
  order,
  closeDialog,
  teacher,
  isTeacherPrimary,
}: Props) => {
  const theme = useTheme();
  const styles = StylesCSS(theme);
  const history = useHistory();
  const isTableEmpty = !loading && students?.length === 0;
  const navigateTo = (id: string) => () => {
    history.push(`/students/${id}/view/${StudentTabs.Tasks}`);
  };
  const handleSort = (column: StudentSort) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const studentsWithPrimary = () => students?.filter((std) => isTeacherPrimary(std.section)) ?? [];
  const orderBy = order === OrderBy.Desc ? 'asc' : 'desc';
  const sourceCheck = teacher?.source === PROVIDER_TYPE.FLASHLIGHT;

  return (
    <Table
      /*
        *  Due to 'stickyHeader' prop, backgroundColor on TableHead or TableRow
        *  was not working. So added global styles under theme.ts file for
        *  MuiTableCell & MuiTableHead
        */
      stickyHeader={stickyHeader}
      aria-label="student directory table"
      sx={loading || isTableEmpty ? styles.tableWithNoData : {}}
    >
      <TableHead>
        <TableRow>
          {sourceCheck && <TableCell>
            <Checkbox
              color="primary"
              indeterminate={numSelectedStudents > 0 && numSelectedStudents < rowCount}
              checked={rowCount > 0 && numSelectedStudents === rowCount}
              onChange={onSelectAllClick}
              disabled={sourceCheck && !studentsWithPrimary().length}
            />
          </TableCell>}
          <TableCell>
            <StyledTableSorted
              direction={orderBy}
              onClick={handleSort(StudentSort.FirstName)}
              active={sort === StudentSort.FirstName}
              IconComponent={ArrowDownIcon}
            >
              Student Name
            </StyledTableSorted>
          </TableCell>
          <TableCell>
            Student ID
          </TableCell>
          <TableCell>
            Grade
          </TableCell>
          <TableCell>
            <Box sx={styles.tableHeadContentContainer}>
              Language Class
              <ColumnInfoTooltip
                tooltipTitle={(
                  <TooltipContent
                    definition="Identification of potential English Language Learners"
                  />
                )}
              />
            </Box>
          </TableCell>
          <TableCell>
            Language Level
          </TableCell>
          <TableCell>
            Heritage Language
          </TableCell>
          <TableCell>
            Class
          </TableCell>
          <TableCell>
            School
          </TableCell>
          {sourceCheck && <TableCell>
            Edit
          </TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading && students?.map((row) => {
          const isItemSelected = isSelected(row.id);
          const isPrimary = isTeacherPrimary(row?.section);
          const isDisabled = sourceCheck && !isPrimary;
          return (
            <TableRow
              hover
              key={row.id}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              {sourceCheck && <TableCell>
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  onClick={() => handleClick(row.id)}
                  disabled={isDisabled}
                />
              </TableCell>}
              <TableCell
                onClick={navigateTo(row.id)}
                sx={{ color: theme.palette.primary.main, fontSize: 18 }}
              >
                {`${row?.user?.first_name} ${row?.user?.last_name}`}
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.customBlack.lightBlack,
                  fontSize: 16,
                }}
              >
                {row?.student_number}
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.customBlack.lightBlack,
                  fontSize: 16,
                }}
              >
                {row?.grade}
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.customBlack.lightBlack,
                  fontSize: 16,
                }}
              >
                {row?.language_class || '-'}
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.customBlack.lightBlack,
                  fontSize: 16,
                }}
              >
                {row?.language_level || '-'}
              </TableCell>
              <TableCell
                sx={{
                  color: theme.palette.customBlack.lightBlack,
                  fontSize: 16,
                }}
              >
                {row?.heritage_language || '-'}
              </TableCell>
              <TableCell>
                {
                  row?.section && row?.section.length === 1 && row?.section[0].name.length > 22 ?
                    <Tooltip title={<Typography component='p' variant='tableTitleData' sx={{ mb: '6px' }}>{row.section[0]?.name}</Typography>}>
                      <TrimmedTypography>{row?.section && row?.section[0].name}</TrimmedTypography>
                    </Tooltip> : <TrimmedTypography>{row?.section && row?.section?.length === 1 ? row?.section[0].name : 'Multiple'}</TrimmedTypography>
                }
              </TableCell>
              <TableCell>
                <TrimmedTypography>{row?.school?.name}</TrimmedTypography>
              </TableCell>
              {sourceCheck && <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => closeDialog('edit', row.id)}
                  disabled={isDisabled}
                >
                  <PencilIcon fillColor={isDisabled ? palette.customBackground.disabled : palette.customBlue.primaryBlue} />
                </Button>
              </TableCell>}
            </TableRow>
          );
        })}
        {isTableEmpty && (
          <TableRow sx={styles.noData}>
            <TableCell
              colSpan={8}
              align="center"
            >
              <NoResults
                label="Where did they go?"
                description="Students have yet to be added in the directory."
                svgComponent={<DogFrame />}
              />
            </TableCell>
          </TableRow>
        )}
        <Loader open={loading} />
      </TableBody>
    </Table>
  );
};
export default StudentTable;
