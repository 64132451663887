import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { AdminListSort, PaginationProps } from '../../../variables/types';
import TablePagination from '../../../components/TablePagination';
import { PAGE_SIZE, PROVIDER_TYPE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { AdminListFragmentFragment, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import SuccessIcon from '../../../components/Icons/SuccessIcon';
import { StyledTableCell, StyledTableDataCell, StyledTableDataColumn } from '../../Admin/admin-tables-styled';
import { StyledTableSort, tableWithNoDataStyles } from '../../../components/Common/Common.styled';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: AdminListSort,
  order: OrderBy,
  admins?: AdminListFragmentFragment[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: AdminListSort) => void) | Function,
  isSchoolAdmin: boolean,
  isDistrictAdmin: boolean,
  type: string,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  admins,
  handleSort,
  isSchoolAdmin,
  type,
  isDistrictAdmin,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box>
        <StyledContainerRoot>
          <Table
            stickyHeader
            aria-label="admin table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableDataCell sx={{ textAlign: 'left' }}>
                  Admin
                </StyledTableDataCell>
                <StyledTableDataCell>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(AdminListSort.JobTitle)}
                    active={sort === AdminListSort.JobTitle}
                    IconComponent={ArrowDownIcon}
                  >
                    Job Title
                  </StyledTableSort>
                </StyledTableDataCell>
                <StyledTableDataCell>
                  <StyledTableSort
                    direction={orderByIcon}
                    onClick={handleSort(AdminListSort.AdminId)}
                    active={sort === AdminListSort.AdminId}
                    IconComponent={ArrowDownIcon}
                  >
                    Admin ID
                  </StyledTableSort>
                </StyledTableDataCell>
                <StyledTableCell>
                  District
                </StyledTableCell>
                {isSchoolAdmin && (
                  <StyledTableCell>
                    School
                  </StyledTableCell>
                )}
                <StyledTableDataCell>
                  Clever
                </StyledTableDataCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && admins?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography
                      component={Link}
                      variant='tableTitleData'
                      to={`/admins/${type}/${row.id}`}
                    >
                      {`${row.first_name} ${row.last_name}`}
                    </Typography>
                  </TableCell>
                  <StyledTableDataColumn>
                    {row.job_title || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row.admin_id || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row.district || '-'}
                  </StyledTableDataColumn>
                  {isSchoolAdmin && (
                    <StyledTableDataColumn>
                      {row.school || '-'}
                    </StyledTableDataColumn>
                  )}
                  <StyledTableDataColumn>
                    {row?.districtData?.source !== PROVIDER_TYPE.FLASHLIGHT ? <SuccessIcon /> : '-'}
                  </StyledTableDataColumn>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={isSchoolAdmin ? 6 : 5}
                    align="center"
                  >
                    <NoResults
                      label={`No ${isSchoolAdmin ? 'School' : ''}${isDistrictAdmin ? 'District' : ''} Admin`}
                      description={`There has yet to be a ${isSchoolAdmin ? 'school' : ''}${isDistrictAdmin ? 'district' : ''} admin created.`}
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={isSchoolAdmin ? 6 : 5}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      {totalCount > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
    </Box>
  );
};
export default React.memo(List);
