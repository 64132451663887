import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { LocalizationProvider }  from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PrivateRoute from '../core/privateRoute';
import Login from './Login/DistrictLogin';
import Students from './Students';
import Assignments from './Assignments';
import SubmitTask from './SubmitTask';
import ScoreTask from './ScoreTask';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import useUserInfo from '../utils/useUserInfo';
import { refreshAuthentication } from '../core/apolloClient';
import Backdrop from '../components/Backdrop';
import useFeature from '../hooks/useFeature';
import Credentials from './Login/Credentials';
import Districts from './Districts';
import Settings from './Settings';
import Teachers from './Teachers';
import Schools from './Schools';
import Classes from './Classes';
import CreateResource from './CreateResource';
import Admins from './Admins';
import AdminStudents from './AdminStudents';
import { useImitationProvider } from '../core/imitationContext';
import TeacherToolBox from './TeacherToolBox';
import StudentBackPack from './StudentBackPack';
import StudentsProgress from './StudentBackPack/StudentProgress';
import Scores from './StudentBackPack/StudentProgress/Scores';
import Analytics from './Analytics';
import Groups from './Groups';
import Dashboard from './Dashboard';
import { SearchContext } from '../core/searchContext';
import useSearch from '../hooks/useSearch';
import DistrictSync from './DistrictSync';
import AdminAnalytics from './AdminAnalytics';
import useRole from '../hooks/useRole';
import AdminBenchmarks from './AdminBenchmarks';
import TeacherBenchmarks from './TeacherBenchmarks';
import ScoreTaskBenchmark from './ScoreTaskBenchmark';

const AppRouter = () => {
  const [isLoadingUserInfo] = useUserInfo();
  const { getImitationUserId } = useImitationProvider();
  const [isLoading, setIsLoading] = useState(true);
  const { isEnabled: isPasswordFlowEnable } = useFeature('PASSWORD_FLOW');
  const { isSchoolAdmin, isDistrictAdmin } = useRole();
  const searchState = useSearch();

  const checkAuthentication = useCallback(async () => {
    await refreshAuthentication(getImitationUserId());
    setIsLoading(false);
  }, [getImitationUserId]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  if (isLoading || isLoadingUserInfo) {
    return <Backdrop open />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route path={['/login']} exact component={Login} />
            <Route
              path={isPasswordFlowEnable ? ['/login/:districtId/:username/:password', '/login/:districtId'] : '/login/:districtId'}
              exact
              component={Credentials}
            />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            {isPasswordFlowEnable && <Route path="/forgot-password" component={ForgotPassword} />}
            <SearchContext.Provider value={searchState}>
              {/* TODO: Refactor route definitions within the app be swithcing to incremental url patternss in child components */}
              <PrivateRoute path="/">
                <Route path="/" exact>
                  {(!isSchoolAdmin || !isDistrictAdmin) && <Redirect to="/grade" /> }
                  {(isSchoolAdmin || isDistrictAdmin) && <Redirect to="/admin/analytics" /> }
                </Route>
                <Route path={['/analytics/:section', '/analytics']} component={Analytics} />
                <Route path="/grade" component={Dashboard} />
                <Route path="/students" component={Students} />
                <Route path="/assignments" component={Assignments} />
                {isPasswordFlowEnable && <Route path="/reset-password" component={ResetPassword} />}
                <Route path="/districts" component={Districts} />
                <Route path="/teachers" component={Teachers} />
                <Route path="/schools" component={Schools} />
                <Route path="/classes" component={Classes} />
                <Route path="/admin-students" component={AdminStudents} />
                <Route path="/settings" component={Settings} />
                <Route path="/admins" component={Admins} />
                <Route path="/admin/create" component={CreateResource} />
                <Route path="/admin/analytics" component={AdminAnalytics} />
                <Route path="/tasks/:id/submit" component={SubmitTask} />
                <Route path="/tasks/:id/score/:section" component={ScoreTask} />
                <Route path="/toolbox" component={TeacherToolBox} />
                <Route path="/backpack" component={StudentBackPack} />
                <Route path="/progress/score" component={Scores} />
                <Route path="/progress" exact component={StudentsProgress} />
                <Route path="/groups" component={Groups} />
                <Route path="/syncDistricts" component={DistrictSync} />
                <Route path="/benchmarks" component={AdminBenchmarks} />
                <Route path="/teacher/benchmarks" component={TeacherBenchmarks} />
                <Route path={[
                  '/tasks/:id/benchmark/score',
                  '/tasks/:id/benchmark/score/:section',
                ]} component={ScoreTaskBenchmark} />
              </PrivateRoute>
            </SearchContext.Provider>
          </Switch>
        </QueryParamProvider>
      </Router>
    </LocalizationProvider>
  );
};

export default AppRouter;
