import React from 'react';
import TasksAssignedAndCompleted from '../../../../components/TasksAssignedAndCompleted';
import { AdminDashboards } from '../../../../variables/constant';
import useTasksAssignedAndCompleted from './tasks-assigned-and-completed-widget-hook';

const TasksAssignedAndCompletedWidget = () => {

  const {
    tasksAssignedAndCompletedLoading, tasksAssignedAndCompletedData, title,
  } = useTasksAssignedAndCompleted();

  return (
    <>
      <TasksAssignedAndCompleted
        loading={tasksAssignedAndCompletedLoading}
        assignedTasks={tasksAssignedAndCompletedData?.count_of_tasks_assigned!}
        tasksAssigned={tasksAssignedAndCompletedData?.average_tasks_assigned_per_teacher!}
        studentsCount={tasksAssignedAndCompletedData?.count_of_students!}
        tasksCompleted={tasksAssignedAndCompletedData?.tasks_completed_per_student!}
        title={title}
        alignTitle
        enableTooltip
        tooltipTitle={AdminDashboards.performance.TasksAssignedNCompletedWidget.tooltipText}
      />
    </>
  );
};

export default TasksAssignedAndCompletedWidget;
