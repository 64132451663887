import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  TableCell,
  CircularProgress, Typography
} from '@mui/material';
import {
  StyledTableCellFlyover,
  StyledTableContainerFlyover, tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import useSubmissionsNotStartedInfinite from '../../../TeacherDashboard/submissions-not-started-infinite-hook';
import { SubmissionStatus } from '../../../../generated/graphql';
import NoResults from "../../../../components/NoResults";
import WavingLadyFrame from "../../../../components/Icons/WavingLadyFrame";
import {SubmissionSort} from "../../../../variables/types";

interface Props {
  assigmentId: string
}

const NotStartedList = (props: Props) => {
  const {
    page,
    totalCount,
    submissions,
    loading,
    handlePagination,
  } = useSubmissionsNotStartedInfinite({
    assigmentId: props.assigmentId,
    sort: SubmissionSort.Name,
    statusList: [
      SubmissionStatus.NotStarted,
    ]
  });

  const isTableEmpty = !loading && submissions?.length === 0;

  return (
      <Box>
        <StyledTableContainerFlyover>
          <InfiniteScroll
            height={'calc(100vh - 83px)'}
            next={() => handlePagination(page + 1)}
            hasMore={totalCount > submissions?.length}
            dataLength={totalCount}
            loader={null}
          >
          <Table
            stickyHeader
            aria-label="students submissions table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableBody>
              {submissions && submissions?.map((row) => (
                <TableRow key={row.student_id}>
                  <StyledTableCellFlyover>
                    <Typography variant='tableTitleData'>
                      {row?.user_first_name!} {row?.user_last_name!}
                    </Typography>
                  </StyledTableCellFlyover>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="No Students"
                      description="No students at this step yet"
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </InfiniteScroll>
        </StyledTableContainerFlyover>
      </Box>
  );
};

export default NotStartedList;
