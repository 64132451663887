import { Box } from '@mui/material';
import React from 'react';
import { StyledMicIcon, StyledMicIcon2 } from '../Player/Player.styled';
import PauseIcon from '../Icons/PauseIcon';
import PlayIcon from '../Icons/PlayIcon';

interface Props {
  disabled?:boolean,
  onClick: () => Promise<void>,
  playing?:boolean,
  ptSubmissionPlayer?:boolean,
}

const AudioControls = ({ disabled, onClick, playing, ptSubmissionPlayer }: Props) => (
  <Box>
    {
      !ptSubmissionPlayer ? (
      <StyledMicIcon
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={onClick}
      >
        {playing ? (<PauseIcon />) : <PlayIcon />}
      </StyledMicIcon>) : (<StyledMicIcon2
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={onClick}
      >
        {playing ? (<PauseIcon fontSize="small" />) : <PlayIcon fontSize="small" />}
      </StyledMicIcon2>)
    }
  </Box>
);

export default AudioControls;
