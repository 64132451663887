import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryParam } from 'use-query-params';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';
import ClassesIcon from '../../../components/Icons/ClassesIcon';
import {
  DistrictFilterFragmentFragment,
  Maybe,
  SchoolFilterFragmentFragment,
  SchoolPredefinedGradeMappings,
  useClassFormMutation,
  useSchoolPredefinedGradeMappingsQuery,
  useSectionDetailQuery,
} from '../../../generated/graphql';
import sortGradeMapping from '../../../utils/sortGradeMapping';

type ClassFormData = {
  id: string
  state_id: string
  district_id: string
  school_id: string
  teacher_id: string
  secondary_teacher_ids: (string | undefined)[] | any,
  name: string
  section_number: string
  period: number
  grade:string
  term_start: Date
  term_end: Date
};

interface RouteProp {
  id: string
}

const useClassForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    reset,
    setValue,
    clearErrors,
  } = useForm<ClassFormData>();
  const [districtParam] = useQueryParam('districtId');
  const [stateParam] = useQueryParam('stateId');
  const [schoolParam] = useQueryParam('schoolId');
  const [teacherParam] = useQueryParam('teacherId');
  const [secondaryTeacherParam] = useQueryParam('secondaryTeacherId');

  const startAtDatePicker = watch('term_start', new Date());

  const [classForm, { loading }] = useClassFormMutation();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setChecked(event.target.checked);
  };
  const onStateChange = () => {
    setValue('district_id', null);
    setValue('school_id', null);
    setValue('teacher_id', null);
    setValue('secondary_teacher_ids', []);
  };
  const onDistrictChange = (district: DistrictFilterFragmentFragment | null) => {
    if (district?.state_id) {
      setValue('state_id', district?.state_id);
      clearErrors('state_id');
    }

    setValue('school_id', null);
    setValue('teacher_id', null);
    setValue('secondary_teacher_ids', []);
  };
  const onSchoolChange = (schoolData?: SchoolFilterFragmentFragment | null) => {
    if (schoolData?.district_id) {
      setValue('district_id', schoolData.district_id);
      clearErrors('district_id');
    }
    if (schoolData?.district_state_id) {
      setValue('state_id', schoolData.district_state_id);
      clearErrors('state_id');
    }
    setValue('teacher_id', null);
    setValue('secondary_teacher_ids', []);
  };

  const { id } = useParams<RouteProp>();

  if (id) {
    register('id', { required: true });
  }

  const { data: classDetails, loading: classDetailsLoading } = useSectionDetailQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id!,
    },
    skip: !id,
  });

  useEffect(() => {
    if (classDetails) {
      reset({
        id: classDetails?.sectionDetails.id,
        name: classDetails?.sectionDetails.name,
        section_number: classDetails?.sectionDetails.section_number || undefined,
        school_id: classDetails?.sectionDetails.school_id,
        district_id: classDetails?.sectionDetails.district_id,
        state_id: classDetails?.sectionDetails.districtData?.state_id || undefined,
        teacher_id: classDetails?.sectionDetails.teachers[0]?.id,
        period: classDetails?.sectionDetails.period || undefined,
        grade: classDetails?.sectionDetails.section_school_predefined_grade_mappings?.length
          ? classDetails?.sectionDetails?.section_school_predefined_grade_mappings[
            classDetails?.sectionDetails.section_school_predefined_grade_mappings?.length - 1
          ]?.school_predefined_grade?.predefined_grades?.id
          : undefined,
        secondary_teacher_ids: classDetails.sectionDetails.teachers?.filter(
          (teacherValue:any) => !teacherValue?.primary_teacher,
        ).map((teacherValue) => teacherValue?.id),
      });
    }
  }, [id, classDetails]);

  useEffect(() => {
    if (stateParam) {
      setValue('state_id', stateParam);
    }
  }, [setValue, stateParam]);

  useEffect(() => {
    if (teacherParam) {
      setValue('teacher_id', teacherParam);
    }
  }, [setValue, teacherParam]);

  useEffect(() => {
    if (secondaryTeacherParam) {
      setValue('secondary_teacher_ids', secondaryTeacherParam);
    } else {
      setValue('secondary_teacher_ids', []);
    }
  }, [secondaryTeacherParam, setValue]);

  useEffect(() => {
    if (districtParam) {
      setValue('district_id', districtParam);
    }
  }, [setValue, districtParam]);

  useEffect(() => {
    if (schoolParam) {
      setValue('school_id', schoolParam);
    }
  }, [setValue, schoolParam]);

  const create = async (section: ClassFormData) => {
    const className = section.name ?? classDetails?.sectionDetails.name;
    const successMessage = `${className} class was ${section.id ? 'updated' : 'added'}!`;
    try {
      const response = await classForm({
        variables: {
          input: {
            id: section.id,
            district_id: section.district_id,
            school_id: section.school_id,
            teacher_id: section.teacher_id,
            grade: [section.grade],
            period: section.period ? section.period : undefined,
            name: className?.trim(),
            section_number: section.section_number ? section.section_number.trim() : classDetails?.sectionDetails.section_number,
            secondary_teacher_ids: section.secondary_teacher_ids,
          },
        },
      });
      const classDetailsPath = `/classes/${response?.data?.upsertSection.id}`;
      if (checked) {
        reset({
          id: '',
          name: '',
          section_number: '',
          school_id: section.school_id,
          district_id: section.district_id,
          state_id: section.state_id,
          teacher_id: section.teacher_id,
          period: undefined,
          grade: '',
          secondary_teacher_ids: section.secondary_teacher_ids,
        }, {
          isSubmitted: false,
        });
      } else {
        history.push(classDetailsPath);
      }
      openSnackbar({
        message: successMessage,
        customIcon: ClassesIcon,
        actionButtonText: 'View',
        onActionButtonClick: () => { history.push(classDetailsPath); },
      }, NotifierType.Success);
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const selectedStateId = watch('state_id');
  const selectedDistrictId = watch('district_id');
  const selectedSchoolId = watch('school_id');
  const { data: schoolDetails, loading: schoolDetailsLoading } = useSchoolPredefinedGradeMappingsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: selectedSchoolId!,
    },
    skip: !selectedSchoolId,
  });

  const getPredefinedGradesForSchool = useCallback(() => sortGradeMapping([...(schoolDetails?.schoolDetails.school_predefined_grade_mappings || [])] as Maybe<SchoolPredefinedGradeMappings>[])?.map((schoolPredefinedGrade) => schoolPredefinedGrade?.predefined_grades), [schoolDetails]);

  return {
    loading: loading || schoolDetailsLoading || classDetailsLoading,
    source: classDetails?.sectionDetails.districtData?.source,
    classCategory: classDetails?.sectionDetails.category,
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    watch,
    createSchool: create,
    checked,
    handleChange,
    preDefinedGrades: getPredefinedGradesForSchool(),
    startAtDatePicker,
    onStateChange,
    onDistrictChange,
    onSchoolChange,
    selectedStateId,
    selectedDistrictId,
    selectedSchoolId,
  };
};

export default useClassForm;
