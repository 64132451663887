import React, { useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  ManageClassUserFragmentFragment,
} from '../../../generated/graphql';
import { palette  } from '../../../theme/palette';

interface Props {
  student: ManageClassUserFragmentFragment,
  onChange: (studentId: string) => void,
  isSelected: (studentId: string) => boolean,
  disabled?: boolean,
}

const styles = {
  tableCell: {
    paddingLeft: '24px',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: palette.fontColors.fontBlack,
  },
}

const ListRow = ({
  student,
  onChange,
  isSelected,
  disabled,
}: Props) => {
  const isSelectedStudent = useMemo(() => isSelected(student?.student?.id!), [isSelected, student]);

  return (
    <TableRow>
      <TableCell sx={{ ...styles.tableCell }}>
        <FormControlLabel
          sx={{ ...styles.label }}
          control={(
            <Checkbox
              color="primary"
              checked={isSelectedStudent}
              disabled={disabled}
              onChange={() => onChange(student?.student?.id!)}
            />
          )}
          label={`${student.first_name ?? ''} ${student.last_name ?? ''}`}
        />
      </TableCell>
    </TableRow>
  );
};
export default ListRow;
