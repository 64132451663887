import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  TableCell,
  CircularProgress,
  Typography, TableHead
} from '@mui/material';
import {
  StyledTableCellFlyover,
  StyledTableContainerFlyover, tableWithNoDataStyles,
} from '../../../../components/Common/Common.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import useBenchmarkGradesInfiniteList from '../../Grade/grades-list-infinite-hook';
import {SubmissionStatus, TeacherBenchmarkSubmissionListSortType} from '../../../../generated/graphql';
import NoResults from "../../../../components/NoResults";
import WavingLadyFrame from "../../../../components/Icons/WavingLadyFrame";
import {Link} from "react-router-dom";
import {BenchmarkSubmissionTypes} from "../../../../variables/constant";
import BenchmarkStatus from "../../../../components/BenchmarkStatus";
import MarkCompleteIcon from "../../../../components/Icons/MarkCompleteIcon";

interface Props {
  benchmarkId: string
}

const SubmittedList = (props: Props) => {
  const {
    page,
    totalCount,
    benchmarkSubmissionList,
    loading,
    handlePagination,
  } = useBenchmarkGradesInfiniteList({
    benchmarkId: props.benchmarkId,
    sort: TeacherBenchmarkSubmissionListSortType.StudentTitle,
    gradeStatuses: [
      SubmissionStatus.Submitted,
      SubmissionStatus.Reviewed,
    ]
  });

  const isTableEmpty = !loading && benchmarkSubmissionList?.length === 0;

  return (
      <Box>
        <StyledTableContainerFlyover>
          <InfiniteScroll
            height={'calc(100vh - 83px)'}
            next={() => handlePagination(page + 1)}
            hasMore={totalCount > benchmarkSubmissionList?.length}
            dataLength={totalCount}
            loader={null}
          >
          <Table
            stickyHeader
            aria-label="students submissions table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align='right'>
                  <Typography variant='secondaryLabel' fontWeight='bold'>
                    Assignment Graded
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {benchmarkSubmissionList && benchmarkSubmissionList?.map((student) => (
                <TableRow key={student.id}>
                  <StyledTableCellFlyover>
                    <Typography
                      component={Link}
                      variant='tableTitleData'
                      sx={{color: 'primary.main'}}
                      to={`/tasks/${student.id}/benchmark/score`}
                    >
                      {student?.user_first_name!} {student?.user_last_name!}
                    </Typography>
                  </StyledTableCellFlyover>
                  <StyledTableCellFlyover sx={{textAlign: 'right'}}>
                    {student.status == BenchmarkSubmissionTypes.Reviewed && (
                      <BenchmarkStatus iconPresent abbreviation={<MarkCompleteIcon />} tileStatus={BenchmarkSubmissionTypes.Reviewed}/>
                    )}
                  </StyledTableCellFlyover>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <NoResults
                      label="No Students"
                      description="No students at this step yet"
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </InfiniteScroll>
        </StyledTableContainerFlyover>
      </Box>
  );
};

export default SubmittedList;
