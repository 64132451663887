import { useEffect, useState } from 'react';
import {
  PerformanceWidgetInput,
  usePerformanceWidgetQuery,
} from '../../../../generated/graphql';
import { GraphSetData } from '../../../../variables/types';
import { AdminDashboards } from '../../../../variables/constant';
import { useDashboardContext } from '../../admin-analytics-hook';

export interface Props {
  alignTitle?: boolean, // to specify alignment of the component title, default is center.
  enableTooltip?: boolean, // to enable tooltip for this component.
  tooltipTitle?: string, // specifies the text used for Tooltip component.
  skillTypes?: string,
}

const usePerformanceInfo = () => {

  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    analyticsFilters: queryFilters,
    updateDashboardWidgetsDataStatus,
  } = useDashboardContext();

  const [currentSkill, setCurrentSkill] = useState(null);
  const [skillFetch, setSkillFetch] = useState();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasData, setHasData] = useState<boolean>(false);
  const [performanceSkillData, setPerformanceSkillData] = useState<GraphSetData[]>([]);
  const [barGraphData, setBarGraphData] = useState<Record<string, string | number > | undefined>();

  const handleTooltipClick = (data: any | null, skillScore: any) => {
    setCurrentSkill(data);
    setSkillFetch(skillScore);
  };

  const queryInput: PerformanceWidgetInput = {
    filters: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.school_id = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData?.id) queryInput.district_id = myDistrictData?.id;

  const { data: performanceWidgetDataResp, loading: performanceWidgetDataLoading } = usePerformanceWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { 'input': queryInput },
  });

  const getBarGraphData = (skillDataset: any) => skillDataset?.reduce(
    (prevValue: any, currentValue: any) => {
      const colorKey = `${currentValue?.skill_key}_key_color`;
      const skillKey = `${currentValue?.skill_key}_key`;
      const skillDescription = `${currentValue?.skill_key}_key_description`;
      const skillLevel = `${currentValue?.skill_key}_key_level`;
      const skillLabel = `${currentValue?.skill_key}_key_label`;
      const skillType = `${currentValue?.skill_key}_key_type`;
      const skillId = `${currentValue?.skill_key}_key_id`;
      const numberOfStudents = `${currentValue?.skill_key}_key_numberOfStudents`;
      const students = `${currentValue?.skill_key}_key_students`;
      return {
        ...prevValue,
        [skillKey]: currentValue?.total_count ?? 0,
        [colorKey]: currentValue?.color,
        [skillDescription]: currentValue?.skill_description,
        [skillLevel]: currentValue?.skill_level,
        [skillLabel]: currentValue?.skill_label,
        [skillType]: currentValue?.skillType ?? '',
        [skillId]: currentValue?.id,
        [numberOfStudents]: currentValue?.total_count,
        [students]: currentValue?.students ?? [],
      };
    },
    {
      name: 'Performance',
    },
  );

  // RePopulate Bar performanceSkillData on performanceWidgetDataResp Refresh
  useEffect(() => {
    if (!performanceWidgetDataLoading && !!performanceWidgetDataResp?.performanceWidget) {
      const newPerformanceSkillData: GraphSetData[] = [];
      performanceWidgetDataResp?.performanceWidget?.forEach((skillData: any) => newPerformanceSkillData.push({
        color: skillData?.color,
        skill_description: skillData?.skill_description,
        id: skillData?.id,
        numberOfStudents: skillData?.total_count,
        skill_label: skillData?.skill_label,
        skillType: '',
        students: [],
        skill_key: skillData?.skill_key,
        skill_level: skillData?.skill_level,
        total_count: totalCount,
      }));
      setPerformanceSkillData(newPerformanceSkillData);
      setBarGraphData(getBarGraphData(performanceWidgetDataResp?.performanceWidget));

      const performanceTotalCount = performanceWidgetDataResp?.performanceWidget?.map((skillData) => skillData?.total_count).reduce((total, num) => total + num, 0)!;
      setTotalCount(performanceTotalCount);

      updateDashboardWidgetsDataStatus?.({
        widget: AdminDashboards.performance.PerformanceSliderWidget.name,
        loading: performanceWidgetDataLoading,
        dataMissing: !performanceTotalCount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceWidgetDataLoading, performanceWidgetDataResp]);

  useEffect(() => {
    setHasData(totalCount > 0 && !!performanceSkillData && !!barGraphData);
  }, [totalCount, performanceSkillData, barGraphData]);

  return ({
    currentSkill, setCurrentSkill,
    skillFetch, setSkillFetch,
    totalCount, setTotalCount,
    hasData, setHasData,
    performanceSkillData, setPerformanceSkillData,
    barGraphData, setBarGraphData, getBarGraphData,
    handleTooltipClick,
    performanceWidgetDataLoading, performanceWidgetData: performanceWidgetDataResp?.performanceWidget,
    title: `${queryInput.filters?.submission_score_type} Performance`,
  });
};

export default usePerformanceInfo;