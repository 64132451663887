import React from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  SxProps,
} from '@mui/material';
import { toSingleReadableFormat } from '../../../utils/dateFormat';
import { StudentGoalListNode, StudentGoalListResult } from '../../../generated/graphql';
import BarStackGraphHorizontal from '../../../components/Charts/Graph/BarStackGraphHorizontal';
import GrammarIcon from '../../../components/Icons/GrammarIcon';
import { RubricTitles } from '../../../variables/types';
import InterpretationIcon from '../../../components/Icons/InterpretationIcon';
import FluencyIcon from '../../../components/Icons/FluencyIcon';
import PronunciationIcon from '../../../components/Icons/PronunciationIcon';
import VocabularyIcon from '../../../components/Icons/VocabularyIcon';
import PointsAndReason from '../../../components/Icons/PointsAndReason';
import VocabularyAndGrammarIcon from '../../../components/Icons/VocabularyAndGrammarIcon';
import DescriptionAndExplanation from '../../../components/Icons/DescriptionAndExplanation';
import { palette  } from '../../../theme/palette';

interface Props {
  data?: StudentGoalListResult;
  hideStatus?:boolean;
  getScores?: any;
}

const goalRowStyles = {
  tableCellStylesGoals: {
    padding: '12px 32px',
    width: '40%',
    textAlign: 'left',
  },
  tableCellStyles: {
    padding: '12px 32px',
    width: '20%',
    textAlign: 'center',
  },
  customBackground: {
    '&.MuiTableCell-root': {
      backgroundColor:palette.customBlue.primaryBlue,
      color: palette.customWhite.main,
    },
  },
  dateRangeCell: {
    color: palette.customBlack.lightBlack,
    fontSize: 16,
  },
};

const styles = {
  tableCellStylesGoals: {
    padding: '12px 32px',
    width: '40%',
    textAlign: 'left',
  },
  tableCellStyles: {
    padding: '12px 32px',
    width: '20%',
    textAlign: 'center',
  },
  tableCellStylesLeft: {
    textAlign: 'left',
  },
  dateRangeCell: {
    color: palette.customBlack.lightBlack,
    fontSize: 16,
  },
  goalIconText: {
    display: 'flex',
    alignItems: 'center',
  },
  goalText: {
    marginLeft: 16,
    textAlign: 'left',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  marking: {
    width: 40,
    minHeight: 40,
    alignItems: 'center',
    borderRadius: '12px',
    justifyContent: 'center',
    display: 'inline-flex',
    color: palette.customWhite.main,
  },
  noAttempt: {
    background: palette.fontColors.buttonFontRed,
    borderColor: palette.fontColors.buttonFontRed,
  },
  emerging: {
    background: palette.customYellow.main,
    borderColor:palette.customYellow.main,
    color: palette.fontColors.fontBlack,
  },
  progressing: {
    background: palette.fontColors.buttonFontGreen,
    borderColor: palette.fontColors.buttonFontGreen,
  },
  goalMet: {
    background: palette.customBlue.aquaBlue,
    borderColor: palette.customBlue.aquaBlue,
  },
}

export const getGoalIcon = (skillKey: string) => {
  switch (skillKey) {
    case RubricTitles.Grammar:
      return <GrammarIcon />;
    case RubricTitles.Interpretation:
      return <InterpretationIcon />;
    case RubricTitles.Fluency:
      return <FluencyIcon />;
    case RubricTitles.Pronunciation:
      return <PronunciationIcon />;
    case RubricTitles.Vocabulary:
      return <VocabularyIcon />;
    case RubricTitles.PointsAndReason:
      return <PointsAndReason />;
    case RubricTitles.VocabularyAndGrammar:
      return <VocabularyAndGrammarIcon />;
    case RubricTitles.DescriptionAndExplanation:
      return <DescriptionAndExplanation />;
    default:
      return null;
  }
};
const GoalRow = ({
  data,
  hideStatus = false,
  getScores,
}: Props) => {
  const keys = data ? (Object.keys(data) as Array<keyof StudentGoalListNode>).filter((key) => key !== '__typename') : [];
  if (!data) {
    return null;
  }

  return (
    <>
      {/* @ts-ignore */}
      {keys && keys.map((skill) => data?.[skill]?.map((skillGoal: StudentGoalListNode) => (
        <TableRow key={`${skill}_${skillGoal?.id}`}>
          <TableCell sx={{ ...goalRowStyles.dateRangeCell, ...styles.tableCellStyles, ...styles.tableCellStylesLeft, ...styles.capitalize } as SxProps}
          >
            {skill}
          </TableCell>
          <TableCell sx={{ ...goalRowStyles.tableCellStylesGoals, ...goalRowStyles.dateRangeCell } as SxProps}>
            <Typography  sx={{ ...styles.goalIconText }}>
              {getGoalIcon(skillGoal?.predefined_goal_rubric_title ?? '')}
              <Typography component="span" sx={{ marginLeft: '16px', textAlign: 'left' }}>
                {skillGoal?.predefined_goal_description ?? '-'}
              </Typography>
            </Typography>
          </TableCell>
          {!hideStatus && (
            <TableCell
              sx={{ ...goalRowStyles.tableCellStyles, ...goalRowStyles.dateRangeCell } as SxProps}
            >
              <Typography sx={{...styles.marking}}>
                <BarStackGraphHorizontal
                  score={getScores(skillGoal?.goal_status || '')}
                />
              </Typography>
            </TableCell>
          )}
          <TableCell
            sx={{ ...goalRowStyles.tableCellStyles, ...goalRowStyles.dateRangeCell } as SxProps}
          >
            {skillGoal?.assigned_date ? toSingleReadableFormat(skillGoal?.assigned_date) : '-'}
          </TableCell>
        </TableRow>
      )))}
    </>
  );
};
export default GoalRow;
