import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { useSendResetPasswordLinkMutation, useSetPasswordByTokenMutation } from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import getErrorMessage from '../../utils/getErrorMessage';
import { NotifierType } from '../../variables/types';
import { INVALID_TOKEN_ERROR, PUBLIC } from '../../variables/constant';

type ForgotPasswordForm = {
  email?: string
  newPassword: string
  confirmPassword: string
};

const useForgotPassword = () => {
  const { register, handleSubmit, errors, getValues } = useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [token] = useQueryParam('token');
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');
  const [showInvalidTokenMessage, setInvalidTokenShowMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseConfirmDownPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const [sendResetPasswordLink, { loading: sending }] = useSendResetPasswordLinkMutation();

  const [setResetPasswordByToken, { loading: reset }] = useSetPasswordByTokenMutation();

  const onPasswordChange = async (passwordForm: ForgotPasswordForm) => {
    if (!token) {
      handleGoBack();
      return;
    }
    setLoading(true);
    try {
      await setResetPasswordByToken({
        variables: {
          password: passwordForm.newPassword,
          token: token as string,
        },
        context: { clientName: PUBLIC },
      });
      setLoading(false);
      setStatus('Success!');
      setDescription('Your new password has been set.');
    } catch (err) {
      if (getErrorMessage(err) === INVALID_TOKEN_ERROR) {
        setStatus('Invalid Token');
        setDescription('Please resend password reset link');
        setInvalidTokenShowMessage(true);
      } else {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
      setLoading(false);
    }
  };

  const handleForgotPassword = async (passwordForm: ForgotPasswordForm) => {
    setLoading(true);
    try {
      await sendResetPasswordLink({
        variables: {
          email: passwordForm.email ?? '',
          host: window.location.protocol + "//" + window.location.host,
        },
        context: { clientName: PUBLIC },
      });

      setLoading(false);
      setStatus('Link Sent!');
      setDescription('If your email exists in our database, you will\n'
        + '              receive a password recovery email soon. Please\n'
        + '              make sure to check your inbox or spam folder.');
    } catch (err) {
      setLoading(false);
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  const handleGoBack = () => {
    setStatus('');
    setDescription('');
    setInvalidTokenShowMessage(false);
    history.push('/login');
  };

  const handleResetPasswordRedirect = () => {
    setStatus('');
    setDescription('');
    setInvalidTokenShowMessage(false);
    history.push('/forgot-password');
  };

  return {
    register,
    handleSubmit,
    onPasswordChange,
    errors,
    loading: loading || sending || reset,
    handleGoBack,
    handleForgotPassword,
    handleResetPasswordRedirect,
    token,
    getValues,
    status,
    description,
    showInvalidTokenMessage,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword,
    handleClickShowConfirmPassword,
    handleMouseConfirmDownPassword,
    showConfirmPassword,
  };
};

export default useForgotPassword;
