import gql from 'graphql-tag';

const query = gql`
  query Me {
    me {
      id,
      first_name,
      last_name,
      email,
      role,
      showHelpVideo,
      permissions {
        id
        permission
      }
      score_scheme {
        id
        title_key
        title
        scoreDetail {
          id
          title_key
          title
          score_value
          description
          color_code
        }
      }
    }
  }
`;

export default query;
