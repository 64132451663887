import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import useStudentsProgressScores from './student-progress-score-hooks';
import {
  SubmissionFilterFragment, SubmissionStatus,
} from '../../../../generated/graphql';
import Layout from '../../../../components/Layout';
import SubmissionFilter from '../../../../components/SubmissionsFilter';
import AppBreadcrumbs from '../../../../components/AppBreadcrumbs';
import { toReadableFormat } from '../../../../utils/dateFormat';
import Feedback from './feedback';
import Scores from './scores';
import zIndex from '../../../../theme/z-index';
import { palette } from '../../../../theme/palette';

const styles = {
  speakingTile: {
    flex: '2 1 auto',
    marginRight: 80,
  },
  writingTile: {
    flex: '1 2 auto',
    marginLeft: 80,
  },
  stickyHeader: {
    position: 'sticky',
    backgroundColor: palette.customWhite.main,
    width: '100%',
    marginTop: '-16px',
    top: 0,
    paddingTop: '16px',
    zIndex: zIndex.zIndex9,
  },
  spaceBetween: {
    placeContent: 'space-between',
  },
};

const SubmissionScores = () => {
  let breadcrumb = [{
    label: 'Progress',
    route: '/progress',
  }];
  const {
    loading,
    studentTasks,
    submission,
    setSubmission,
    maxScoreCount,
    scoreGraphData,
    donutData,
  } = useStudentsProgressScores();

  const onSubmissionChange = (data: string | SubmissionFilterFragment | null) => {
    if (data !== '') {
      setSubmission(data as string);
    }
  };
  const assignmentOrBenchmark = studentTasks?.assignment ? studentTasks?.assignment : studentTasks?.getBenchmark;
  if (assignmentOrBenchmark?.title) {
    breadcrumb = [{
      label: 'Progress',
      route: '/progress',
    },
    {
      label: assignmentOrBenchmark?.title ?? '',
      route: '',
    }];
  }

  const resourceURl = assignmentOrBenchmark?.resource?.file?.thumbnail_url ? assignmentOrBenchmark?.resource?.file?.thumbnail_url : assignmentOrBenchmark?.resource?.file?.url;
  return (
    <Layout>
      <Box sx={{ ...styles.stickyHeader as SxProps<Theme> }} >
        <Box mb={1} display="flex" sx={{ ...styles.spaceBetween }}>
          <Typography variant="pageTitle">
            <AppBreadcrumbs breadcrumb={breadcrumb} />
          </Typography>
          <Box width="20%">
            <SubmissionFilter
              onChange={onSubmissionChange}
              idSelectOnly
              placeholder="Select Assignment"
              value={submission}
              status={[SubmissionStatus.Reviewed]}
            />
          </Box>
        </Box>
        <Typography variant='sContentHeader' component='p'>{assignmentOrBenchmark?.title}</Typography>
        <Typography variant='sLabel' component='p'>
          {`Submitted ${studentTasks?.submitted_at ? toReadableFormat(studentTasks?.submitted_at) : ''}`}
        </Typography>
      </Box>
      <Feedback
        imageSrc={resourceURl}
        teacherFeedback={studentTasks?.getAdditionalFeedback?.feedback_notes || ''} />
      <Box
        mt={3}
        width="100%"
      >
        <Scores
          loading={loading}
          maxScoreCount={maxScoreCount}
          scoreGraphData={scoreGraphData}
          donutData={donutData}
          assignmentOrBenchmark={assignmentOrBenchmark}
        />
      </Box>
    </Layout>
  );
};

export default React.memo(SubmissionScores);
