import { useForm } from 'react-hook-form';
import { useState } from 'react';
import {
  CleverDistrict,
  useGetUninitialisedCleverDistrictsQuery,
  useUpdateCleverDistrictMutation,
} from '../../../generated/graphql';
import { openSnackbar } from '../../../components/Notifier';
import getErrorMessage from '../../../utils/getErrorMessage';
import { NotifierType } from '../../../variables/types';
import useScoreSchemeRadioGroup from '../../../components/ScoreSchemeRadioGroup/score-scheme-radio-group-hook';

type CleverDistrictData = {
  score_scheme_id: string
};

const useInitialiseDistrict = () => {
  const [schemeDialogOpen, setSchemeDialog] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState<CleverDistrict | null>(null);
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
  } = useForm<CleverDistrictData>();

  const { data: uninitialisedDistricts, loading } = useGetUninitialisedCleverDistrictsQuery({
    fetchPolicy: 'network-only',
  });

  const [updateDistrict, { loading: updateLoading }] = useUpdateCleverDistrictMutation();

  const setScoreSchemeValue = (value: string) => setValue('score_scheme_id', value);

  const { scoreSchemesDetailsLoading, scoreSchemeOptions } = useScoreSchemeRadioGroup({
    setValue: setScoreSchemeValue,
    value: getValues('score_scheme_id') ?? '',
  });

  const onDistrictChange = (ev:React.ChangeEvent<{}>, value:CleverDistrict | null) => { setSelectedDistrict(value); };

  const update = async (initialiseDistrict: CleverDistrict | null) => {
    try {
      await updateDistrict({
        variables: {
          input: {
            id: initialiseDistrict?.clever_id || '',
            score_scheme_id: getValues('score_scheme_id'),
            source: initialiseDistrict?.source || '',
          },
        },
      });
      openSnackbar({ message: 'New district added for sync' }, NotifierType.Success);
      window.location.reload();
    } catch (err) {
      openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
    }
  };

  return {
    loading: updateLoading || loading,
    districtList: uninitialisedDistricts?.getUnImportedCleverDistricts,
    updateDistrict: update,
    onDistrictChange,
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    schemeDialogOpen,
    setSchemeDialog,
    selectedDistrict,
    scoreSchemesDetailsLoading,
    scoreSchemeOptions,
  };
};

export default useInitialiseDistrict;
