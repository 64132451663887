import { useEffect, useState } from 'react';
import {
  AdminDashboardFilterInput, useCompletedAssignmentsLearningGoalsWidgetQuery,
} from '../../../../generated/graphql';
import { useDashboardContext } from '../../admin-analytics-hook';
import { AdminDashboards } from '../../../../variables/constant';

interface QueryInputProps {
  district_id?: string,
  school_id?: string,
  input?: AdminDashboardFilterInput,
}

const useCompletedAssignmentLearningGoals = () => {
  const {
    isDistrictAdmin, myDistrictData,
    isSchoolAdmin, mySchoolData,
    skipGraphQLRequest,
    updateDashboardWidgetsDataStatus,
    analyticsFilters: queryFilters,
    sessionStartDate, sessionEndDate,
  } = useDashboardContext();

  const [hasData, setData] = useState();

  const queryInput: QueryInputProps = {
    input: queryFilters,
  };
  if (isSchoolAdmin && mySchoolData?.id) queryInput.school_id = mySchoolData?.id;
  if (isDistrictAdmin && myDistrictData) queryInput.district_id = myDistrictData?.id;

  const { data, loading: assignmentLearningGoalsCompletedLoading } = useCompletedAssignmentsLearningGoalsWidgetQuery({
    fetchPolicy: 'network-only',
    skip: skipGraphQLRequest(),
    variables: { ...queryInput },
  });

  useEffect(() => {
    const studentCompletionRateDataMissing = data?.learningGoalsCompletedPTsMostRecentGoalsData.map((rateData) => rateData?.average_no_of_most_recent_goals_count as number)
      .reduce((total: any, num: any) => total + num, 0)!;
    setData(studentCompletionRateDataMissing);
    updateDashboardWidgetsDataStatus?.({
      widget: AdminDashboards.learningGoals.CompletedAssignmentsGoalsWidget.name,
      loading: assignmentLearningGoalsCompletedLoading,
      dataMissing: !assignmentLearningGoalsCompletedLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentLearningGoalsCompletedLoading, data]);

  return ({
    assignmentLearningGoalsCompletedLoading,
    completeData: data?.learningGoalsCompletedPTsMostRecentGoalsData,
    title: 'Completed Assignments with Learning Goals',
    sessionStartDate, sessionEndDate,
    hasData,
  });
};

export default useCompletedAssignmentLearningGoals;
