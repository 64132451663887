import React from 'react';
import { Box, Button, Checkbox, CheckboxProps, TextField, Typography } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import useSyncDistricts, { DistrictDetails } from './syncDistricts-hook';
import Chip from '@mui/material/Chip';
import { StyledAutocomplete } from './sync-district.styled';
import { palette } from '../../../theme/palette';

interface SelectProps {
  districts: DistrictDetails[] | undefined,
  loading: boolean,
  setSelectedDistricts: Function,
  selectedVal: DistrictDetails[] | undefined
}

interface Props {
  isSyncEnabled: boolean | undefined
}

const styles = {
  textField: {
    border: '0px',
    minHeight: '48px',
    height: 'auto',
    "& input.MuiInputBase-input.Mui-disabled": {
      background: `${palette.customWhite.inputDisabled} !important`
    }
  }
}

const MultiSelectDistrict = ({ districts = [], loading, setSelectedDistricts, selectedVal }:SelectProps) => {
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  return (
    <StyledAutocomplete
      multiple
      limitTags={1}
      disableCloseOnSelect
      options={districts || []}
      getOptionLabel={(option) => `${option?.name ?? ''}`}
      isOptionEqualToValue={(option: any, selected: any) => option?.id === selected?.id}
      disabled={loading}
      onChange={(_, districtList) => setSelectedDistricts(districtList)}
      value={selectedVal}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={<Typography sx={{ fontSize: '13px', fontWeight: 800 }} component='span'>{option?.name ?? ''}</Typography>}
            {...getTagProps({ index })}
          />
        ))}
      renderOption={(props, option, { selected }) => (
        <Box component="li" {...props}>
          <Checkbox
            color={'primary' as  CheckboxProps['color']}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            sx={{ marginRight: '16px' }}
          />
          {`${option?.name ?? ''}`}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ ...styles.textField }}
          placeholder={params.InputProps.startAdornment && districts?.length ? '' : 'Select district(s) to sync'}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: { height: 'auto', maxHeight: '500px' },
          }}
        />
      )}
    />
  );
};

const SyncDistrict = ({ isSyncEnabled }: Props) => {
  const { syncDistrictList, loading, runSyncForSelectedDistricts, selectedDistricts, selectDistricts } = useSyncDistricts();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
    >
      <Box mr={2}>
        <MultiSelectDistrict
          districts={syncDistrictList}
          loading={loading}
          setSelectedDistricts={selectDistricts}
          selectedVal={selectedDistricts}
        />
      </Box>
      <Button
        onClick={runSyncForSelectedDistricts}
        disabled={!selectedDistricts.length || isSyncEnabled || loading}
        color="primary"
        variant="contained"
        sx={{
          fontSize: 18,
          maxHeight: 55,
        }}
      >
        SYNC
      </Button>
    </Box>
  );
};

export default SyncDistrict;
