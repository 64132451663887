import React, { useEffect, useCallback } from 'react';
import {
  Box,
  TextField,
  FormControl,
  Button,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  LoginLink,
  LoginButton,
} from '../Login.styled';
import { openSnackbar } from '../../../components/Notifier';
import { NotifierType } from '../../../variables/types';
import Loader from '../../../components/Loader/loader';
import SuccessImage from '../../../components/Icons/SuccessImage';
import LoginWrapper from '../../../components/LoginWrapper';
import useFeature from '../../../hooks/useFeature';
import isEmpty from '../../../utils/isEmpty';
import useLogin from './login-hooks';
import { styled } from '@mui/material/styles';
import { palette  } from '../../../theme/palette';
import { PROVIDER_TYPE } from '../../../variables/constant';

export const inputStyles = {
  input: {
    height: '60px',
    '& .MuiInputBase-root.MuiOutlinedInput-root':{
      border: 0,
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      backgroundColor: palette.customWhite.main,
      border: `2px solid ${palette.customBackground.disabled} !important`,
      height: '40px',
      borderRadius: '12px',
    },
  },
  successImage: {
    position: 'absolute',
    height: '360px',
    width: '100%',
    transform: 'rotateY(180deg)',
    bottom: '-40px',
    left: '10px',
  },
};

export const StyledFormControl = styled(FormControl)({
  '&.MuiFormControl-root':{
    marginTop: 20,
  },
});

const Credentials = () => {
  const { isEnabled } = useFeature('CLEVER_BUTTON');
  const {
    username,
    password,
    prevEmail,
    prevPassword,
    newPasswordRequired,
    setNewPasswordRequired,
    cognitoUser,
    setCognitoUser,
    register,
    errors,
    watch,
    loading,
    setLoading,
    handleRedirection,
    handleSubmit,
    districtData,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword,
  } = useLogin();

  const onUserLogin = useCallback(async (data:Record<string, string>) => {
    setLoading(true);
    try {
      let user;
      if (!newPasswordRequired) {
        let cognitoUsername;
        if (data.username.includes('@')) {
          cognitoUsername = data.username.trim();
        } else {
          if (districtData == null) {
            handleRedirection();
            return;
          }
          let districtID = districtData.id;
          cognitoUsername = `${districtID}-${data.username.trim()}`;
        }
        user = await Auth.signIn({
          // Trimming username and password as it throws error in cognito
          username: cognitoUsername, // data.email.trim(),
          password: data.password,
        });
      } else {
        // Trimming username and password as it throws error in cognito
        await Auth.completeNewPassword(
          cognitoUser,
          data.newPassword,
        );
      }
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setNewPasswordRequired(true);
        setCognitoUser(user);
        setLoading(false);
      } else {
        window.location.href = '/';
      }
    } catch (err: any) {
      setLoading(false);
      if (username && password && err.code === 'NotAuthorizedException') {
        handleRedirection();
      } else {
        openSnackbar({ message: err.message }, NotifierType.Error);
      }
    }
  }, [cognitoUser, districtData, handleRedirection, newPasswordRequired, password, setCognitoUser, setLoading, setNewPasswordRequired, username]);

  useEffect(() => {
    if (username && password && (username !== prevEmail || password !== prevPassword)) {
      onUserLogin({ username, password });
    }
  }, [username, onUserLogin, password, prevEmail, prevPassword]);

  return (
    <LoginWrapper
      title={districtData?.name ?? ''}
      frameText="Not your school district?"
      text="Log in"
      image={(
        <SuccessImage
          sx={{ ...inputStyles.successImage }}
          imagePrimaryColor={palette.customYellow.buttonFontOrange}
        />
      )}
      onClick={handleRedirection}
    >
      <>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onUserLogin)}
          noValidate
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {!(username && password) && (
            <>
              <StyledFormControl>
                <TextField
                  fullWidth
                  type="email"
                  name="username"
                  sx={{ ...inputStyles.input }}
                  placeholder="Username"
                  inputRef={register({
                    required: { value: !(username && password), message: '*username is required' },
                  })}
                />
              </StyledFormControl>
              <StyledFormControl>
                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  sx={{ ...inputStyles.input }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ position: 'absolute', right: '0px' }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                  inputRef={register({
                    required: { value: !(username && password), message: '*Password is required' },
                  })}
                />
                {
                  errors.password && (
                    <Typography variant='sFormHelper'>
                      {errors?.password?.message}
                    </Typography>
                  )
                }
              </StyledFormControl>
            </>
          )}
          {newPasswordRequired && (
            <>
              <StyledFormControl>
                <TextField
                  fullWidth
                  type="password"
                  name="newPassword"
                  placeholder="New Password"
                  sx={{ ...inputStyles.input }}
                  inputRef={register({
                    required: { value: newPasswordRequired, message: '*New Password is required' },
                  })}
                />
                {
                  errors.newPassword && (
                    <Typography variant='sFormHelper'>
                      {errors?.newPassword?.message}
                    </Typography>
                  )
                }
              </StyledFormControl>
              <StyledFormControl>
                <TextField
                  fullWidth
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm New Password"
                  sx={{ ...inputStyles.input }}
                  inputRef={register({
                    validate: (value) => value === watch('newPassword') || 'The passwords do not match',
                    required: { value: newPasswordRequired, message: '*Confirm New Password is required' },
                  })}
                />
                {
                  errors.confirmPassword && (
                    <Typography variant='sFormHelper'>
                      {errors?.confirmPassword?.message}
                    </Typography>
                  )
                }
              </StyledFormControl>
            </>
          )}
          {!newPasswordRequired && (
            <Box
              width="100%"
              display="flex"
              mt={2}
            >
              <LoginLink to="/forgot-password" sx={{ mr: 3 }}>
                Forgot Password?
              </LoginLink>
            </Box>
          )}
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={5}
            mb={2}
          >
            <LoginButton
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ minWidth: '10vw !important' }}
            >
              {newPasswordRequired ? 'Update Password' : 'Login'}
            </LoginButton>
          </Box>
          {isEnabled && !isEmpty(districtData)
          && (
            <Button
              variant="outlined"
              color="primary"
            >
              Clever Login
            </Button>
          )}
        </form>
        <Loader open={loading} />
      </>
    </LoginWrapper>
  );
};

export default Credentials;
