import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import StyledMain from './Layout.styled';
import NavBar from '../NavBar';
import { useNavigationProvider } from '../../core/navigationContext';
import { NavigationType } from '../../variables/types';
import { useImitationProvider } from '../../core/imitationContext';
import { SearchContext } from '../../core/searchContext';

type Props = {
  children?: React.ReactNode;
  mainClass?: string;
};

const Layout = ({
  children,
  mainClass,
}: Props) => {
  const {
    getImitationUserId,
  } = useImitationProvider();
  const imitationUserId = getImitationUserId();
  const { getNavigationType } = useNavigationProvider();
  const NavType = getNavigationType();
  const NoNavigation = NavType === NavigationType.None;
  const { setSearchEnabled } = useContext(SearchContext);
  useEffect(() => {
    setSearchEnabled?.();
  });
  return (
    <>
      {!NoNavigation && (
        <NavBar imitationUserId={imitationUserId}/>
      )}
      <Box
        component="div"
        display="flex"
        width="100%"
        height={NoNavigation ? '100vh' : `calc(100vh - ${imitationUserId ? 130 : 75})`}
        sx={!NoNavigation ? (imitationUserId ? { mt: 15.75 } : { mt: 8.75 }) : { mt: 0 }}
      >
        <StyledMain component="main" className={mainClass}>
          <Box
            sx={{
              p: 2,
              height: '100%',
            }}
          >
            {children}
          </Box>
        </StyledMain>
      </Box>
    </>
  );
};

export default Layout;
