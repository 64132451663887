import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Loader from '../../../components/Loader/loader';
import useManageGroup from './manage-group-hook';
import List from '../../Classes/ManageClass/List';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import WarningWindow from '../../../components/WarningWindow';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import DebounceSearchBar from '../../../components/DebounceSearchBar';
import { StyledDeleteButton } from '../Groups.styled';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';

const ManageGroup = () => {
  const theme = useTheme();
  const {
    loading,
    groupData,
    availableTotalCount,
    pagination,
    handlePageChange,
    availableStudents,
    onChangeAvailable,
    isAvailableSelected,
    selectAllAvailable,
    handleSelectAllAvailable,
    selectedAvailableStudents,
    selectedAllocatedStudents,
    allocatedTotalCount,
    paginationAssignedStudents,
    handleAssignedStudentsPageChange,
    allocatedStudents,
    onChangeAllocated,
    isAllocatedSelected,
    selectAllAllocated,
    handleSelectAllAllocated,
    handleAddSelected,
    handleRemoveSelected,
    showRemoveSelectedConfirmation,
    pageLoading,
    cancelRemoveSelected,
    confirmationMessage,
    handleRemoveSelectedConfirm,
    search,
    onSearch,
    deleteConfirmation,
    handleDelete,
    cancelDelete,
    handleDeleteConfirm,
  } = useManageGroup();

  const breadcrumb = [{
    label: 'Groups',
    route: '/groups',
  },
  {
    label: groupData?.name ?? '',
  }];

  return (
    <StyledFlexColBoxContainer>
      <Box>
        <Typography variant="pageTitle">
          Manage Group
        </Typography>
        <AppBreadcrumbs breadcrumb={breadcrumb} />
      </Box>
      <Box>
        <Typography variant='sLabel'>
          Select students to be added to or removed from the group.
        </Typography>
      </Box>
      <Box
        mt={2}
        width="30%"
      >
        <DebounceSearchBar
          handleChange={onSearch}
          searchTerm={search}
          mt={0}
          mb={0}
        />
      </Box>
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns="2fr 1fr 2fr"
        sx={{
          gridGap: 48,
        }}
      >
        <Box>
          <List
            isTableEmpty={availableTotalCount === 0}
            pagination={pagination}
            loading={loading}
            totalCount={availableTotalCount ?? 0}
            handlePageChange={handlePageChange}
            students={availableStudents}
            title={groupData?.parent_section_name ?? ''}
            onChange={onChangeAvailable}
            isSelected={isAvailableSelected}
            isSelectAll={selectAllAvailable}
            handleSelectAll={handleSelectAllAvailable}
          />
        </Box>
        <Box
          display="flex"
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="100%"
            display="grid"
            gridTemplateColumns="1fr"
            sx={{
              gridGap: 12,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIos />}
              onClick={handleAddSelected}
              disabled={selectedAvailableStudents?.length === 0}
            >
              Add Selected
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIos />}
              onClick={handleRemoveSelected}
              disabled={selectedAllocatedStudents?.length === 0}
            >
              Remove Selected
            </Button>
            <StyledDeleteButton
              color="primary"
              onClick={handleDelete}
            >
              <DeleteIcon/>
              {' '}
              <Typography variant="sFormHelper" marginLeft={1}>Delete Group</Typography>
            </StyledDeleteButton>
          </Box>
        </Box>
        <Box>
          <List
            isTableEmpty={allocatedTotalCount === 0}
            pagination={paginationAssignedStudents}
            loading={loading}
            totalCount={allocatedTotalCount ?? 0}
            handlePageChange={handleAssignedStudentsPageChange}
            students={allocatedStudents}
            title={groupData?.name ?? ''}
            onChange={onChangeAllocated}
            isSelected={isAllocatedSelected}
            isSelectAll={selectAllAllocated}
            handleSelectAll={handleSelectAllAllocated}
          />
        </Box>
      </Box>
      <WarningWindow
        openDialog={(showRemoveSelectedConfirmation || deleteConfirmation) && !pageLoading}
        closeDialog={() => {
          if (deleteConfirmation) {
            cancelDelete();
          } else {
            cancelRemoveSelected();
          }
        }}
        header="Are you sure?"
        subheader={confirmationMessage}
        actions={[{
          title: 'CANCEL',
          event: deleteConfirmation ? cancelDelete : cancelRemoveSelected,
          classname: 'keepButton',
        }, {
          title: 'PROCEED',
          event: deleteConfirmation ? handleDeleteConfirm : handleRemoveSelectedConfirm,
          classname: 'deleteButton',
        }]}
      />
      <Loader open={pageLoading} />
    </StyledFlexColBoxContainer>
  );
};

export default React.memo(ManageGroup);
