import get from 'lodash.get';

export enum Direction {
  Up = 1,
  Down,
}

export interface Sort {
  name: string;
  direction: Direction
}

export const sortData = (sortBy: Sort | undefined) => (a: any, b: any) => {
  if (sortBy) {
    if (sortBy.direction === Direction.Up) {
      if (get(a, sortBy.name) === null) {
        return 1;
      }
      if (get(b, sortBy.name) === null) {
        return -1;
      }
      if (get(a, sortBy.name) > get(b, sortBy.name)) {
        return 1;
      }
      if (get(a, sortBy.name) < get(b, sortBy.name)) {
        return -1;
      }
      return 0;
    }
    if (sortBy.direction === Direction.Down) {
      if (get(a, sortBy.name) === null) {
        return -1;
      }
      if (get(b, sortBy.name) === null) {
        return 1;
      }
      if (get(a, sortBy.name) > get(b, sortBy.name)) {
        return -1;
      }
      if (get(a, sortBy.name) < get(b, sortBy.name)) {
        return 1;
      }
      return 0;
    }
  }
  return 0;
};
