import React from 'react';
import { Box, Button, Checkbox, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import useClassForm from './student-form-hook';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Loader from '../../../components/Loader/loader';
import StudentIcon from '../../../components/Icons/StudentsIcon';
import { errorMessages, labels } from './constants';
import { NON_SPACE_REGEX, PROVIDER_TYPE } from '../../../variables/constant';
import { HERITAGE_LANGUAGE_OTHER_VALUE } from '../../Teachers/TeacherForm/constants';
import { SectionCategory } from '../../../generated/graphql';
import {
  StyledBox,
  StyledDistrictFilter,
  StyledMenuItem,
  StyledSchoolFilter,
  StyledSectionsSelectMultiple,
  StyledSelectGrade,
  StyledStatesFilter,
} from './student-form-styled';
import { StyledSaveButton } from '../../Districts/CreateDistrict/create-district.styled';
import { formStyles } from '../../Admin/admin-tables-styled';
import { StyledFlexBox } from '../../../components/Common/Common.styled';
import { useTheme } from '@mui/material/styles';
import { CreateAssignmentStylesCss } from '../../Assignments/CreateAssignment/CreateAssignment.styled';

const studentFormStyles = {
  flexBoxes:{
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
  },
  customMargin30:{
    marginTop: '30px',
  },
  saveButton:{
    width: '200px',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 800,
    marginTop: '32px',
  },
};
/**
 * StudentForm: student form view to add/edit a student
 * @returns StudentForm
 */
const StudentForm = () => {
  const {
    loading,
    errors,
    handleSubmit,
    control,
    register,
    createStudent,
    checked,
    handleChange,
    getValues,
    onStateChange,
    onDistrictChange,
    onSchoolChange,
    selectedStateId,
    selectedDistrictId,
    selectedClassIds,
    selectedSchoolId,
    preDefinedGrades,
    heritageLanguageOptions,
    heritageLanguage,
    editPasswordToggle,
    editPassword,
    source,
    customSource,
  } = useClassForm();
  const theme = useTheme();
  const styles = CreateAssignmentStylesCss(theme);
  const studentID = getValues('id');
  const breadcrumb = [{
    label: 'Students',
    route: '/admin-students',
  }, {
    label: studentID ? 'Update' : 'Create Student',
  }];
  const readOnlyField = (studentID !== undefined) && (source === PROVIDER_TYPE.ROSTERSTREAM) && (customSource === PROVIDER_TYPE.ROSTERSTREAM);
  const studentReadOnlyField = (studentID !== undefined) && (source === PROVIDER_TYPE.ROSTERSTREAM);

  const formTitle = studentID ? 'Update a student' : 'Add a new student';

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 120px)"
      >
        <Box>
          <AppBreadcrumbs breadcrumb={breadcrumb} />
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems='baseline'
          >
            <StudentIcon
              pathFill={theme.palette.primary.main}
              sx={{
                height: '30px',
                width: '30px',
                mr: '16px',
              }}
            />
            <Typography variant="pageTitle" sx={{ mb: 1 }} >{formTitle}</Typography>
          </Box>
        </Box>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createStudent)}
          noValidate
        >
          <Grid
            xs={8}
            lg={5}
          >
            <Controller
              name="state_id"
              render={({ onChange, value }) => (
                <StyledStatesFilter
                  value={value}
                  onChange={(state) => {
                    onChange(state);
                    onStateChange();
                  }}
                  placeholder="Select a state"
                  idSelectOnly
                  readOnly={studentReadOnlyField}
                  sx={{ ...(studentReadOnlyField && styles.disabled) }}
                />
              )}
              rules={{ required: source === PROVIDER_TYPE.FLASHLIGHT && errorMessages.stateHelperText  }}
              control={control}
              defaultValue={null}
            />
            <Typography variant='sFormHelper' component='p'>
              {errors.state_id && errors.state_id.message}
            </Typography>
            <Box>
              <Controller
                name="district_id"
                render={({ onChange, value }) => (
                  <StyledDistrictFilter
                    value={value}
                    onChange={(districtID, district) => {
                      onChange(districtID);
                      onDistrictChange(district?.state_id);
                    }}
                    placeholder="Select a district"
                    idSelectOnly
                    readOnly={studentReadOnlyField}
                    stateIds={selectedStateId as string}
                    source={[PROVIDER_TYPE.FLASHLIGHT, PROVIDER_TYPE.CLEVER, PROVIDER_TYPE.ROSTERSTREAM]}
                    sx={{ ...(studentReadOnlyField && styles.disabled) }}
                  />
                )}
                rules={{ required: errorMessages.districtHelperText }}
                control={control}
                defaultValue={null}
              />
              <Typography variant='sFormHelper' component='p'>
                {errors.district_id && errors.district_id.message}
              </Typography>
            </Box>
            <Controller
              name="school_id"
              render={({ onChange, value }) => (
                <StyledSchoolFilter
                  value={value}
                  onChange={(schoolID, school) => {
                    onChange(schoolID);
                    onSchoolChange(school);
                  }}
                  placeholder="Select a school"
                  idSelectOnly
                  stateId={selectedStateId as string}
                  districtId={selectedDistrictId as string}
                  readOnly={readOnlyField}
                  sx={{ ...(readOnlyField && styles.disabled) }}
                />
              )}
              rules={{ required: errorMessages.schoolHelperText }}
              control={control}
              defaultValue={null}
            />
            <Typography variant='sFormHelper' component='p'>
              {errors.school_id && errors.school_id.message}
            </Typography>
            <Controller
              name="section_ids"
              render={({ onChange }) => (
                <StyledSectionsSelectMultiple
                  value={selectedClassIds}
                  onChange={onChange}
                  stateId={selectedStateId}
                  districtId={selectedDistrictId}
                  schoolId={selectedSchoolId}
                  readOnly={readOnlyField}
                  sx={{ ...(readOnlyField && styles.disabled) }}
                  classTypes={[SectionCategory.Class, SectionCategory.CustomClass]}
                />
              )}
              rules={
                {
                  required: errorMessages.classesHelperText,
                  validate: (value) => value?.length > 0 || errorMessages.classesHelperText,
                }
              }
              control={control}
              defaultValue={null}
            />
            <Typography variant='sFormHelper' component='p'>
              {errors.section_ids && errorMessages.classesHelperText}
            </Typography>
            <Typography variant='sFormTitle' component='p'>Grade</Typography>
            <Box>
              <Controller
                name="predefined_grade_id"
                render={(
                  { onChange, value }) => (
                  <StyledSelectGrade
                    onChange={onChange}
                    value={value}
                    preDefinedGrades={preDefinedGrades}
                    isLoading={loading}
                    placeholder={'Select grade'}
                    idSelectOnly
                    readOnly={readOnlyField}
                    sx={{ ...(readOnlyField && styles.disabled) }}
                    noOptionPlaceholder={selectedSchoolId ? errorMessages.gradeSchoolHelperText : errorMessages.gradeMissingSchoolHelperText}
                  />
                )}
                rules={{ required: errorMessages.gradeHelperText }}
                control={control}
                defaultValue=""
              />
              <Typography variant='sFormHelper'>
                {errors.predefined_grade_id && errors.predefined_grade_id.message}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              flexGrow={1}
            >
              <Box sx={{ mr: 3 }}>
                <Typography variant='sFormTitle' component='p'> First Name </Typography>
                <TextField
                  id="first-name"
                  aria-describedby="first-name"
                  name="first_name"
                  error={!!errors.first_name}
                  InputProps={{ readOnly: readOnlyField }}
                  sx={{ ...formStyles.titleInput, width: '200px', ...(readOnlyField && styles.disabled) }}
                  helperText={errors.first_name && errorMessages.firstNameHelperText}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
              <Box sx={{ marginRight: '24px' }}>
                <Typography variant='sFormTitle' component='p'> Last Name </Typography>
                <TextField
                  id="last-name"
                  aria-describedby="last-name"
                  name="last_name"
                  error={!!errors.last_name}
                  InputProps={{ readOnly: readOnlyField }}
                  sx={{ ...formStyles.titleInput, width: '200px', ...(readOnlyField && styles.disabled) }}
                  helperText={errors.last_name && errorMessages.lastNameHelperText}
                  inputRef={register({
                    required: true,
                    validate: (value) => !!value.trim(),
                  })}
                />
              </Box>
            </Box>
            <StyledBox>
              <Typography variant='sFormTitle' component='p'>Student ID</Typography>
              <TextField
                id="student-id"
                aria-describedby="student-id"
                name="student_id"
                error={!!errors.student_id}
                InputProps={{ readOnly: readOnlyField }}
                sx={{ ...formStyles.textFieldAdmin, ...(readOnlyField && styles.disabled) }}
                helperText={!readOnlyField && errors.student_id && errorMessages.studentIDHelperText}
                inputRef={register({
                  required: true,
                  pattern: NON_SPACE_REGEX,
                  validate: (value) => !!value.trim() && value.trim().length >= 4,
                })}
              />
            </StyledBox>
            {
              !studentID && (
                <StyledFlexBox sx={{ ...(errors.student_id && studentFormStyles.customMargin30), mt: 0 }}>
                  <Typography variant='sFormTitle' component='p'>Password</Typography>
                  <TextField
                    id="password"
                    aria-describedby="password"
                    name="password"
                    sx={{ ...formStyles.textFieldAdmin }}
                    error={!!errors.password}
                    helperText={errors.password
                    && errorMessages.passwordHelperText}
                    inputRef={register({
                      required: true,
                      validate: (value) => !!value.trim()
                        && value.trim().length >= 6,
                    })}
                  />
                </StyledFlexBox>
              )
            }
            <StyledFlexBox sx={{ ...(errors.password && studentFormStyles.customMargin30), mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Email (Optional)</Typography>
              <TextField
                id="email"
                aria-describedby="email"
                name="email"
                InputProps={{ readOnly: readOnlyField }}
                sx={{ ...formStyles.textFieldAdmin, ...(readOnlyField && styles.disabled) }}
                inputRef={register({
                  required: false,
                })}
              />
            </StyledFlexBox>
            <StyledFlexBox sx={{ ...(errors.student_id && studentFormStyles.customMargin30), mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Language Level</Typography>
              <TextField
                id="language-level"
                aria-describedby="language-level"
                name="language_level"
                InputProps={{ readOnly: readOnlyField }}
                sx={{ ...formStyles.textFieldAdmin, ...(readOnlyField && styles.disabled) }}
                inputRef={register({
                  required: false,
                })}
              />
            </StyledFlexBox>
            <Box mt={2}>
              <Typography variant='sFormTitle' component='p'>
               Heritage Language (Optional)
              </Typography>
              <Controller
                name="heritage_language"
                render={(
                  { onChange, value },
                ) => (
                  <TextField
                    id="heritage-language"
                    select
                    aria-describedby="heritage-language"
                    name="heritage_language"
                    value={value || 'select'}
                    InputProps={{ readOnly: readOnlyField }}
                    onChange={(event) => {
                      onChange(event?.target?.value);
                    }}
                    sx={{
                      width: '300px',
                      '& .MuiInputBase-input': {
                        background: 'none',
                      },
                      '& .MuiSelect-icon': {
                        height: '1em',
                        width: '1em',
                        top: 'auto',
                      },
                      ...(readOnlyField && styles.disabled),
                    }}
                    defaultValue="select"
                  >
                    <MenuItem key="select" value="select" disabled>
                    Select a Language
                    </MenuItem>
                    {
                      heritageLanguageOptions.map((item) => (
                        [
                          <StyledMenuItem
                            key={item.key}
                            value={item.value}
                          >
                            {item.value}
                          </StyledMenuItem>,
                          item.divider ?  <Divider
                            variant="middle"
                            sx={{ background: theme.palette.customGrey.main }}
                          /> : null,
                        ]
                      ),
                      )
                    }
                  </TextField>
                )}
                rules={{ required: false }}
                control={control}
                defaultValue=""
              />
            </Box>
            {
              heritageLanguage === HERITAGE_LANGUAGE_OTHER_VALUE && (
                <Box mt={2}>
                  <Typography variant='sFormTitle' component='p'>
                    Other Language
                  </Typography>
                  <Controller
                    name="heritage_other_language"
                    render={(
                      { onChange, value },
                    ) => (
                      <TextField
                        id="heritage-other-language"
                        aria-describedby="other language"
                        name="heritage_other_language"
                        sx={{ ...formStyles.textFieldAdmin }}
                        error={!!errors.heritage_other_language}
                        helperText={errors.heritage_other_language
                        && errorMessages.otherHeritageLanguageHelperText}
                        inputRef={register({
                          required: true,
                          validate: (val) => !!val.trim(),
                        })}
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                      />
                    )}
                    control={control}
                  />
                </Box>
              )
            }
            <StyledFlexBox sx={{ ...(errors.student_id && studentFormStyles.customMargin30), mt: 0 }}>
              <Typography variant='sFormTitle' component='p'>Demographic Information</Typography>
              <TextField
                id="demographic-info"
                aria-describedby="demographic-info"
                name="demographic_info"
                sx={{ ...formStyles.textFieldAdmin, ...(readOnlyField && styles.disabled) }}
                inputRef={register({
                  required: false,
                })}
                InputProps={{ readOnly: readOnlyField }}
              />
            </StyledFlexBox>
            {
              studentID && editPassword && (
                <Box>
                  <Typography variant='sFormTitle' component='p'>Edit Password</Typography>
                  <Box display="flex">
                    <Box sx={{ marginRight: '24px' }}>
                      <TextField
                        id="password"
                        aria-describedby="password"
                        name="password"
                        sx={{ ...formStyles.textFieldAdmin }}
                        error={!!errors.password}
                        helperText={errors.password
                          && errorMessages.passwordHelperText}
                        inputRef={register({
                          required: true,
                          validate: (value) => !!value.trim()
                            && value.trim().length >= 6,
                        })}
                      />
                    </Box>
                    <Box>
                      <StyledSaveButton
                        sx={{ margin: 0 }}
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={editPasswordToggle}
                      >
                        Cancel
                      </StyledSaveButton>
                    </Box>
                  </Box>
                </Box>
              )
            }
          </Grid>
          {
            !studentID && (
              <Box display="flex" justifyContent="flex-start" sx={{ mt: 4, ml: -1.5 }}>
                <Checkbox
                  id="reopen-create-class-form"
                  aria-describedby="reopen-create-class-form"
                  name="reopen_create_checkbox"
                  checked={checked}
                  onChange={handleChange}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  inputRef={register({
                    required: false,
                  })}
                />
                <Typography variant='sLabel' sx={{ pt: 1 }}>
                  {labels.checkBoxLabel}
                </Typography>
              </Box>
            )
          }
          <Box
            display="grid"
            justifyContent="flex-start"
          >
            {
              studentID && !editPassword && (
                <Button
                  sx={{ ...studentFormStyles.saveButton }}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={editPasswordToggle}
                >
                  Edit Password
                </Button>
              )
            }
            <StyledSaveButton
              sx={{ ...studentFormStyles.saveButton }}
              variant="contained"
              color="primary"
              type="submit"
            >
              { studentID ? 'Update' : 'Add Student' }
            </StyledSaveButton>
          </Box>
        </form>
      </Box>
      <Loader open={loading} />
    </>
  );
};

export default React.memo(StudentForm);
