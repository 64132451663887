import React from 'react';
import {
  PieChart,
  Pie,
  Label,
  ResponsiveContainer,
  Text,
} from 'recharts';
import { Box, SxProps } from '@mui/material';
import { palette } from '../../../theme/palette';
import DoughnutGraphLoader from '../../Loader/DonutChartLoader';

interface Props {
  loading: boolean,
  boxHeight?: number | string,
  boxWidth?: number | string,
  chartHeight?: number,
  chartWidth?: number,
  startAngle?: number,
  endAngle?: number,
  label?: boolean
  pieChartValue?: number |  null,
}

const styles = {
  totalYtd: {
    width: '80.14px',
    height: '77.45px',
    fontWeight: 'bold',
    fontSize: '30px',
  },
  percentYtd: {
    width: '60.14px',
    height: '67.45px',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  totalYD: {
    width: '119.81px',
    height: '24.84px',
    fontWeight: 600,
    fontSize: '18px',
    color: palette.customBlue.darkestBlue,
    letterSpacing: '0.0757853px',
  },
  outlineStyles: {
    '.recharts-wrapper>svg>g': {
      outline: 'none !important',
    }
  },
};


const StudentCompletionDonutChart = ({
  loading,
  boxHeight = 300,
  boxWidth = 300,
  chartHeight = 300,
  chartWidth = 300,
  pieChartValue,
}: Props) => {

  const DonutLabel = ({ viewBox }: any) => {
    const { cx, cy } = viewBox;
    return (
      <g textAnchor="middle">
        <>
          <Text style={styles.totalYtd} textAnchor="middle" x={cx - 10} y={cy - 10} dy={8}>
            {pieChartValue?.toFixed(2)}
          </Text>
          <Text style={styles.percentYtd} textAnchor="middle" x={cx + 50} y={cy - 10} dy={8}>
            %
          </Text>
          <Text style={styles.totalYD} textAnchor="middle" x={cx} y={cy + 20} dy={8}>
            YTD
          </Text>
        </>
      </g>
    );
  };

  const dataToBeFilled = [
    { name: 'YTD', value: 100 - pieChartValue!, fill: palette.customBackground.emptyBarBackground },
    { name: 'YTD', value: pieChartValue, fill: palette.customBlue.primaryBlue },
  ];

  return (
    <Box width={boxWidth} height={boxHeight} sx={styles.outlineStyles} >
      {!loading && (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={chartWidth} height={chartHeight}>
            <Pie
              data={dataToBeFilled}
              dataKey="value"
              cx={200}
              cy={200}
              startAngle={-270}
              innerRadius={70}
              outerRadius={90}
              fill="inherit"
              style={{ outline: 'none' }}
            >
              <Label content={<DonutLabel />} /> 
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
      {loading && (
        <Box sx={{ align: 'center', pl: 10, pt: 8 }  as SxProps}>
          <DoughnutGraphLoader />
        </Box>
      )}
    </Box>
  );
};

export default StudentCompletionDonutChart;
