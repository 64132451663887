import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClassComponent from './Class';
import ClassesTable from './ClassesTable';
import ManageClass from './ManageClass';
import Layout from '../../components/Layout';

const Classes = () => (
  <Layout>
    <Switch>
      <Route
        path="/classes"
        component={ClassesTable}
        exact
      />
      <Route
        path="/classes/:id"
        component={ClassComponent}
        exact
      />
      <Route
        path="/classes/:id/manage"
        component={ManageClass}
        exact
      />
    </Switch>
  </Layout>
);

export default React.memo(Classes);
