import { Box, Button } from '@mui/material';
import React from 'react';
import { StyledDateTypography, StyledTypography, StyledBodyTypography, StyledBox } from './DashboardFeedbackTiles.styled';
import AudioPlayer from '../Player';
import { toReadableFormat } from '../../utils/dateFormat';
import { isIpad } from '../../variables/constant';
import { StyledImage } from '../../pages/StudentDashboard/StudentDashboard.styled';
import { palette  } from '../../theme/palette';

interface Props {
  imageSrc?: string,
  teacherFeedback?: string | null,
  assignmentName?: string,
  assignmentDate?: string | null,
  audioFileID?: string,
  submissionId?: string,
  progressRedirect:(submissionId: string) => void
}

const tileStyles = {
  tile: {
    borderRadius: '0 0 12px 12px',
    backgroundColor: palette.customWhite.main,
    border: `2px solid ${palette.customBackground.disabled}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 9,
    height: '305px',
    padding: '24px',
    marginBottom: '48px',
    borderTop: 'none',
  },
  imageBox: {
    width: isIpad ? '150px ' : '300px',
    height: isIpad ? '120px' : '160px',
  },
};

const TileItem = ({ imageSrc, teacherFeedback, assignmentName, assignmentDate, audioFileID, submissionId, progressRedirect }: Props) => {
  const formattedSubmittedDate = assignmentDate ? toReadableFormat(assignmentDate) : '';
  return (
    <Box sx={{  height: '365px' }}>
      <Box sx={{ ...tileStyles.tile }}>
        <Box
          display="grid"
          width="100%"
          gridTemplateColumns="1fr 5fr"
          sx={{
            gridGap: 24,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box sx={{ ...tileStyles.imageBox }}>
              <StyledImage
                src={imageSrc}
                alt="task"
                sx={{  borderRadius: '12px' }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => progressRedirect(submissionId || '')}
              sx={{ mt: '20px' }}
            >
              SEE HOW I DID
            </Button>
          </Box>
          <Box>
            <StyledTypography>
              {assignmentName}
            </StyledTypography>
            <StyledDateTypography>
              Submitted at
              {' '}
              {formattedSubmittedDate}
            </StyledDateTypography>
            <StyledBox>
              {teacherFeedback && (
                <StyledBodyTypography>
                  {teacherFeedback}
                </StyledBodyTypography>
              )}
            </StyledBox>
            {audioFileID
              && (
                <Box
                  mt={1.25}
                  display="grid"
                  gridTemplateColumns={isIpad ? '4fr fr' : '4fr 1fr'}
                >
                  <AudioPlayer
                    customStyle
                    showLabel
                    label="A message from your teacher"
                    url={audioFileID}
                    backpackPlayer
                  />
                </Box>
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TileItem;
