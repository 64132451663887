/* eslint-disable react/prop-types,react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import { Grid, Button, Menu, MenuItem } from '@mui/material';
import useDropdownButton from './dropdown-button-hook';

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 12,
      marginTop: theme.spacing(1),
      minWidth: 200,
      '& .MuiMenuItem-root': {
          color: theme.palette.primary.main,
          fontSize: 20,
          fontWeight: 800,
          '&:active': {
              backgroundColor: theme.palette.transparent.main,
          }
       }, 
      }
  }));
  
export const StyledMenuItem = styled(MenuItem)(() => ({
    '&.MuiButtonBase-root.MuiMenuItem-root': {
      border: 0,
    },
}));

const buttonStyles = {
    button: {
      minWidth: "20vh", 
      fontWeight: 800
    }
  };

export interface Action {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  label: string,
  actionItems: Array<Action>;
  handleClick: (action: Action) => void;
  variant?: 'contained'
  | 'outlined'
  | 'text';
  color?: undefined
  | 'inherit'
  | 'primary'
  | 'secondary';
}

const DropdownButton = ({ label, actionItems, handleClick, color, variant }: Props) => {
  const { open, anchorEl, onToggle, handleClose  } = useDropdownButton();

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <Button
          variant={variant || "outlined"}
          color={color || "primary"}
          onClick={onToggle}
          sx={{ ...buttonStyles.button, py: 1.75 }}
          endIcon={<ChevronDownIcon sx={{ pt: '6px'}} />}
        >
          {label}
        </Button>
        <StyledMenu
          id="add-new-menu-list"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          autoFocus={false}
        >
        {actionItems.map((option, index) => (
            <StyledMenuItem 
              key={option?.value} 
              onClick={() => handleClick(option)}
            >
              {option?.label}
            </StyledMenuItem>
        ))}
        </StyledMenu>
      </Grid>
    </Grid>
  );
};

export default DropdownButton;
