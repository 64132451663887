import React from 'react';
import SchoolFilterWithMultiSelect from '../../../../components/SchoolFilterWithMultiSelect';
import DistrictMultiSelectFilter from '../../../../components/DistrictMultiSelectFilter';
import { Button, Grid } from '@mui/material';
import { StyledFilterButton } from '../../../AdminAnalytics/AnalyticsFilters/AnalyticsFilters.styled';
import SyncIcon from '@mui/icons-material/Sync';
import GradeStatusFilter from '../../../../components/GradeStatusFilter';
import BenchmarkMultiTypeSelectFilter from '../../../../components/BenchmarkMultiTypeSelectFilter';
import MultiStateSelectFilter from '../../../../components/StateFilter/MultiSelectStateFilter';
import { SubmissionStatus } from '../../../../generated/graphql';

export const SubmissionOptions  = [
  {
    label: 'Graded',
    value: SubmissionStatus.Reviewed,
  },
  {
    label: 'Not Yet Graded',
    value: SubmissionStatus.Submitted,
  },
];

const GradeFilters = ({
  benchmarkTypes, setBenchmarkTypes,
  selectedStatuses, setSelectedStatuses,
  selectedStates, setSelectedStates,
  selectedDistricts, setSelectedDistricts,
  selectedSchools, setSelectedSchools,
  handleApplyFilters, handleClearFilters,
}: any) =>{

  const handleStateChange = (values:any[] | undefined) => {
    setSelectedStates(values);
    setSelectedDistricts(undefined);
    setSelectedSchools(undefined);
  };

  const handleDistrictChange = (values:any[] | undefined) => {
    setSelectedDistricts(values);
    setSelectedSchools(undefined);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <MultiStateSelectFilter
            onChange={handleStateChange}
            value={selectedStates}
            limitTags={1}
            textInputPlaceholder='All States'/>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <DistrictMultiSelectFilter
            stateIds={selectedStates as [string] | undefined}
            onChange={handleDistrictChange}
            value={selectedDistricts}
            limitTags={1}
            textInputPlaceholder='All Districts'/>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <SchoolFilterWithMultiSelect
            stateIds={selectedStates}
            districts={selectedDistricts}
            onChange={(values:any[]) => setSelectedSchools(values)}
            value={selectedSchools}
            limitTags={1}
            textInputPlaceholder="All Schools"/>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <GradeStatusFilter
            onChange={(value: any) => setSelectedStatuses([value])}
            status={selectedStatuses ? selectedStatuses[0] : undefined}
            options={SubmissionOptions}
            textInputPlaceholder='All Submissions'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2.5}>
          <BenchmarkMultiTypeSelectFilter
            onChange={(values:any) => setBenchmarkTypes(values)}
            value={benchmarkTypes as (string | undefined)[]}
            limitTags={1}
            textInputPlaceholder='All Benchmarks'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item>
          <StyledFilterButton
            variant="contained"
            color='inherit'
            startIcon={<SyncIcon color="primary" fontSize="medium" />}
            onClick={handleClearFilters}
          >
            Clear all filters
          </StyledFilterButton>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
            disabled={!(!!benchmarkTypes.length || selectedStates || selectedDistricts || selectedStatuses || selectedSchools)}
          >
            Apply filters
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export  default GradeFilters;
