import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledContainerRoot } from '../../Assignments/AssignmentsTable/List.styled';
import { AdminStudentsSort, PaginationProps } from '../../../variables/types';
import TablePagination from '../../../components/TablePagination';
import { PAGE_SIZE, PROVIDER_TYPE } from '../../../variables/constant';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import {
  OrderBy,
  StudentDetailsFragmentFragment,
} from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import SuccessIcon from '../../../components/Icons/SuccessIcon';
import {
  StyledTableCell,
  StyledTableDataCell,
  StyledTableDataCellWidth10,
  StyledTableDataColumn,
} from '../../Admin/admin-tables-styled';
import {
  StyledTableSort,
  tableWithNoDataStyles,
} from '../../../components/Common/Common.styled';

interface Props {
  isTableEmpty: boolean;
  pagination: PaginationProps,
  loading: boolean,
  totalCount: number,
  sort: AdminStudentsSort,
  order: OrderBy,
  students?: StudentDetailsFragmentFragment[],
  handlePageChange: (_: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>) => void | undefined,
  handleSort: ((column: AdminStudentsSort) => void) | Function,
}

const List = ({
  isTableEmpty,
  pagination,
  loading,
  totalCount,
  sort,
  order,
  handlePageChange,
  students,
  handleSort,
}: Props) => {
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const sortColumn = (title: string, sortType: AdminStudentsSort) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortType)}
      active={sort === sortType}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
    >
      <Box>
        <StyledContainerRoot>
          <Table
            stickyHeader
            aria-label="students assignments table"
            sx={{ ...((loading || isTableEmpty) && tableWithNoDataStyles.noData) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableDataCell sx={{ textAlign: 'left' }}>
                  { sortColumn('Student', AdminStudentsSort.FirstName) }
                </StyledTableDataCell>
                <StyledTableDataCell>
                  { sortColumn('Student ID', AdminStudentsSort.StudentID) }
                </StyledTableDataCell>
                <StyledTableDataCellWidth10>
                  Active PT
                </StyledTableDataCellWidth10>
                <StyledTableDataCell>
                  { sortColumn('Grades', AdminStudentsSort.Grade) }
                </StyledTableDataCell>
                <StyledTableCell sx={{ width: '25%' }}>
                  { sortColumn('School', AdminStudentsSort.School) }
                </StyledTableCell>
                <StyledTableDataCell>
                  { sortColumn('District', AdminStudentsSort.District) }
                </StyledTableDataCell>
                <StyledTableDataCellWidth10>
                  Clever
                </StyledTableDataCellWidth10>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && students?.map((row: StudentDetailsFragmentFragment) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography
                      component={Link}
                      variant='tableTitleData'
                      to={`/admin-students/${row.id}`}
                    >
                      {`${row?.first_name ? row?.first_name : ''} ${row?.last_name ? row?.last_name : ''}`}
                    </Typography>
                  </TableCell>
                  <StyledTableDataColumn>
                    {row?.student_id}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.is_active_submission ? <SuccessIcon /> : '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.grade || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn sx={{ fontWeight: 800 }}>
                    {row?.school_name || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.district_name || '-'}
                  </StyledTableDataColumn>
                  <StyledTableDataColumn>
                    {row?.source !== PROVIDER_TYPE.FLASHLIGHT ? <SuccessIcon /> : ''}
                  </StyledTableDataColumn>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <NoResults
                      label="No Students"
                      description="There has yet to be a student created."
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                  >
                    <CircularProgress size={28} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainerRoot>
      </Box>
      {totalCount > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
    </Box>
  );
};
export default List;
