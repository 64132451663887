import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AssignmentsTable from './AssignmentsTable';
import CreateAssignment from './CreateAssignment';
import { AssignmentTabs } from '../../variables/types';

const Assignments = () => (
  <>
    <Switch>
      <Route
        path="/assignments/create"
        component={CreateAssignment}
        exact
      />
      <Route
        path="/assignments/create/:step"
        component={CreateAssignment}
        exact
      />
      <Route
        path="/assignments/:id/edit"
        component={CreateAssignment}
        exact
      />
      <Route
        path="/assignments/:id/edit/:step"
        component={CreateAssignment}
        exact
      />
      <Route
        path="/assignments/:section"
        component={AssignmentsTable}
        exact
      />
      <Redirect
        from="/assignments"
        to={`/assignments/${AssignmentTabs.Current}`}
      />
    </Switch>
  </>
);

export default React.memo(Assignments);
