import { useEffect, useRef } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { PAGE_SIZE } from '../../../variables/constant';
import { OrderBy, useAssignmentsQuery } from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { AssignmentTabs, AssignmentSort } from '../../../variables/types';
import { toUTC } from '../../../utils/dateFormat';
import usePrevious from '../../../utils/usePrevious';

interface Params {
  section?: string;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const getDateFilter = (section: string = AssignmentTabs.Current, dateString: string) => {
  let start = null;
  let close = null;
  if (section === AssignmentTabs.Past) {
    close = {
      lt: dateString,
    };
  } else if (section === AssignmentTabs.Upcoming) {
    start = {
      gt: dateString,
    };
  } else {
    close = {
      gte: dateString,
    };
    start = {
      lte: dateString,
    };
  }
  return {
    closeAt: close,
    startAt: start,
  };
};

const useAssignments = ({ section }: Params) => {
  const pagination = usePagination();
  const now = useRef(toUTC(new Date()));
  const prevSection = usePrevious(section);
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, AssignmentSort.CloseAt));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const { startAt, closeAt } = getDateFilter(section, now.current);
  const { data, loading } = useAssignmentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      startAt,
      closeAt,
      page: pagination.page,
      sort,
      orderBy: order as OrderBy,
    },
  });

  const onSortChange = (sortBy: AssignmentSort, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  useEffect(() => {
    if (prevSection && section !== prevSection) {
      pagination.setPage(1);
    }
  }, [pagination, prevSection, section]);

  const assignments = data?.getAllAssignmentsForTeacher.nodes ?? [];
  const totalCount = data?.getAllAssignmentsForTeacher.totalCount ?? 0;

  return {
    loading,
    totalCount,
    assignments,
    pagination,
    onSortChange,
    sort,
    order: order as OrderBy,
  };
};

export default useAssignments;
