import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../components/Layout';
import DistrictSyncTable from './DistrictSyncTable';

const DistrictSync = () => (
  <Layout>
    <Route
      path="/syncDistricts"
      component={DistrictSyncTable}
      exact
    />
  </Layout>
);

export default DistrictSync;
