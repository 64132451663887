import { styled } from '@mui/material/styles';
import SliderNavIcon from '../../components/Icons/SliderNavIcon';
import { Box, Button } from '@mui/material';
import zIndex from '../../theme/z-index';

export const StyledNavIcon = styled(SliderNavIcon)(({ theme }) => ({
  color: theme.palette.fontColors.fontBlack,
  position: 'absolute',
  left: '45%',
  transform: 'rotate(90deg)',
  bottom: '30%',
}));

export const StyledLeftArrow = styled(Box)(({ theme }) => ({
  width: 210,
  height: 70,
  background: `linear-gradient(180deg,
      ${theme.palette.gradients.scrollMenuGradient} 0%, ${theme.palette.fontColors.fontBlack} 100%)`,
  position: 'absolute',
  transform: 'rotate(-270deg)',
  zIndex: zIndex.zIndex999,
  top: 70,
  left: -70,
  cursor: 'pointer',
}));

export const StyledScrollGradient = styled(Box)(({ theme }) => ({
  width: 210,
  height: 70,
  background: `linear-gradient(180deg,
      ${theme.palette.gradients.scrollMenuGradient} 0%, ${theme.palette.fontColors.fontBlack} 100%)`,
  position: 'absolute',
  transform: 'rotate(-90deg)',
  zIndex: zIndex.zIndex999,
  right: -72,
  top: 70,
  cursor: 'pointer',
}));

export const ImageBox = styled(Box)(({ theme }) => ({
  width:'calc(100vw - 330px)',
  height:600,
  paddingLeft: theme.spacing(3.75),
  paddingTop: theme.spacing(2.5),
  border:`2px solid ${theme.palette.customRed.progressPrimary}`,
  borderRadius:'12px',
  overflow:'scroll',
  background: theme.palette.customWhite.main,
}));

export const AddButton = styled(Button)(({ theme }) => ({
  width: 220,
  fontSize: 16,
  lineHeight: '18px',
  fontWeight: 800,
  marginTop: theme.spacing(4),
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  width: 160,
  fontSize: 15,
  lineHeight: '18px',
  fontWeight: 800,
  marginTop: theme.spacing(4),
}));

export const DeleteButton = styled(Button)(({ theme }) => ({
  width: 165,
  fontSize: 15,
  lineHeight: '18px',
  fontWeight: 800,
  color: theme.palette.customRed.buttonFontRed,
  border: `2px solid ${theme.palette.customRed.darkestRed}`,
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.customPink.main,
  '&::after': {
    borderColor: theme.palette.customPink.main,
  },
}));
