import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BenchmarkSubmissionTypes } from '../../variables/constant';

interface Props {
  tileStatus: string;
  abbreviation: string | React.ReactNode;
  iconPresent?: boolean;
  align?: 'left' | 'right' | undefined
}

const BenchmarkStatus = ({ tileStatus, abbreviation, iconPresent, align }: Props) => {
  const theme = useTheme();
  return (
    <Box display="inline-flex" sx={{textAlign: align ? align : 'left'}}>
      <Tooltip
        title={tileStatus === BenchmarkSubmissionTypes.Reviewed ? BenchmarkSubmissionTypes.Reviewed : BenchmarkSubmissionTypes.Submitted }
        sx={{ maxWidth: 'none', fontWeight: 'bold' }}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={5000}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '12px',
            minWidth: '50px',
            minHeight: '40px',
            marginRight: theme.spacing(1.5),
            ...(BenchmarkSubmissionTypes.Reviewed === tileStatus && {
              backgroundColor: theme.palette.customGreen.submitted,
              color: theme.palette.customWhite.main,
              '&.MuiSvgIcon-root': {
                fill: theme.palette.customWhite.main,
              },
            }),
            ...(BenchmarkSubmissionTypes.Submitted === tileStatus && {
              backgroundColor: theme.palette.customRed.notSubmitted,
              color: theme.palette.customRed.darkFont,
              '&.MuiSvgIcon-root': {
                fill: theme.palette.customWhite.main,
              },
            }),
          }}
        >
          <Typography sx={{
            fontSize: 16,
            fontWeight: 800,
            ...(iconPresent === true && {
              marginTop: '6px',
            }),
          }}
          >
            {abbreviation}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default BenchmarkStatus;
