import { Button, styled, TableCell } from '@mui/material';
import { StyledTableContainer } from '../Students/Student/Student.styled';
import zIndex from '../../theme/z-index';
import StateFilter from '../../components/StateFilter';
import DistrictFilter from '../../components/DistrictFilter';
import SchoolFilter from '../../components/SchoolFilter';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  width: '25%',
  textAlign: 'center',
  '&.MuiTableCell-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.customWhite.main,
  },
}));

export const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  textAlign: 'center',
  '&.MuiTableCell-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.customWhite.main,
  },
}));

export const StyledTableDataColumn = styled(TableCell)(() => ({
  fontSize: '16px',
  fontWeight: 'normal',
  '&.MuiTableCell-root': {
    textAlign: 'center',
  },
}));

export const StyledTableCellOverFlowContent = styled(TableCell)(() => ({
  width: 100,
  maxWidth: 100,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledTableDataCell = styled(StyledTableCell)(() => ({
  width: '15%',
}));

export const StyledTableDataCellWidth10 = styled(StyledTableDataCell)(() => ({
  width: '10%',
}));

export const StyledTableDataContainer = styled(StyledTableContainer)(({ theme }) => ({
  '&.MuiTableContainer-root': {
    marginTop: theme.spacing(1.5),
    height: 'auto',
    zIndex: zIndex.zIndex3,
  },
}));

export const StyledTableDataCellRoot = styled(StyledTableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    color: theme.palette.customBlack.lightBlack,
    fontSize: 16,
    backgroundColor: theme.palette.customWhite.main,
  },
}));

export const StyledStatesFilter = styled(StateFilter)(({ theme }) => ({
  width: 240,
  marginTop: theme.spacing(3),
}));

export const StyledDistrictFilter = styled(DistrictFilter)(({ theme }) => ({
  width: 240,
  marginTop: theme.spacing(3),
}));

export const StyledSchoolFilter = styled(SchoolFilter)(({ theme }) => ({
  width: 240,
  marginTop: theme.spacing(3),
}));


export const StyledTableButton = styled(Button)(({ theme }) => ({
  fontSize: 18,
  lineHeight: '24px',
  fontWeight: 800,
  backgroundColor: theme.palette.customWhite.main,
  border: `2px solid ${theme.palette.customGrey.adminButtonBorder}`,
  boxSizing: 'border-box',
  marginRight: theme.spacing(2),
}));

export const formStyles = {
  titleInput: {
    '& .MuiInputBase-input': {
      background: 'none',
    },
  },
  title: {
    '& .MuiSelect-icon': {
      height: '1em',
      width: '1em',
      top: 'auto',
    },
  },
  textFieldAdmin: {
    width: 298,
    '& .MuiInputBase-input': {
      background: 'none',
    },
  },
};


export const StyledTableCells = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.customWhite.main,
  },
}));
