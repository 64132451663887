import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation, Link } from 'react-router-dom';
import { Box, Button, Typography, useTheme } from '@mui/material';

interface BreadcrumbItem {
  label: string;
  route?:string;
}

interface Breadcrumb {
  breadcrumb: BreadcrumbItem[];
}

const styles = {
  root: {
    '& > * + *': {
      marginTop: '16px',
    },
    marginBottom: '8px',
  },
};

const AppBreadcrumbs = ({ breadcrumb }: Breadcrumb) => {
  const location = useLocation();
  const theme = useTheme();
  let currentRoutes = [];
  currentRoutes = location.pathname !== '/' ? location.pathname.split('/') : [];
  if (currentRoutes.length > 0) {
    currentRoutes.shift();
    return (
      <Box sx={{ ...styles.root }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumb.map((item, index) => (item?.route ? (
            // eslint-disable-next-line react/no-array-index-key
            <Button component={Link} to={item.route} key={index} sx={{
              borderWidth: 0,
              borderRadius: 0,
              fontWeight: 800,
              fontSize: 18,
              color: theme.palette.primary.main,
              textTransform: 'uppercase',
              lineHeight: '24.55px',
              letterSpacing: 1,
              textDecoration: 'none',
              '&:first-of-type': {
                paddingLeft: 0,
              },
              '&:hover': {
                textDecoration: 'none',
                cursor: 'pointer',
                backgroundColor: 'transparent',
              },
              '&:active': {
                borderWidth: 0,
                borderRadius: 0,
              },
              '&::after': {
                display: 'none',
              },
            }}>
              {item.label}
            </Button>
            // eslint-disable-next-line react/no-array-index-key
          ) : <Typography key={index} variant='breadcrumbs'>{item.label}</Typography>))}
        </Breadcrumbs>
      </Box>
    );
  } return <></>;
};

export default AppBreadcrumbs;
