import React from 'react';
import {
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledTableDataColumn } from '../../../../Admin/admin-tables-styled';
import { PerformanceStylesCSS } from '../../Performance.styled';
import {
  PerformanceWidgetSlideOutLineItem,
} from '../../../../../generated/graphql';

interface Props {
  rowIndex?: number,
  student?: PerformanceWidgetSlideOutLineItem,
}

const ListRow = ({
  rowIndex,
  student,
}: Props) => {
  const theme = useTheme();
  const styles = PerformanceStylesCSS(theme);
  const section = student?.sections[0];
  const primaryTeacher = section?.teachers.filter((teacher) => teacher?.primary_teacher);
  return (
    <TableRow key={rowIndex} sx={{
      ...styles.tableRowHover, ...styles.tableRowBorder,
    }}>
      <TableCell sx={{ ...styles.width20 }}>
        <Typography
          variant='secondaryLabel'
        >
          {student?.first_name} {student?.last_name}
        </Typography>
      </TableCell>
      <StyledTableDataColumn sx={styles.width5}>
        <Typography
          variant='secondaryLabel'
        >
          {student?.grade}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width25}>
        <Typography
          variant='secondaryLabel'
        >
          {student?.school_name}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width20}>
        <Typography
          component="span"
        >
          {(student?.sections && student?.sections.length > 1) ? 'Multiple' : (
            primaryTeacher ? `${primaryTeacher[0]?.first_name} ${primaryTeacher[0]?.last_name}` : ''
          )}
        </Typography>
      </StyledTableDataColumn>
      <StyledTableDataColumn sx={styles.width30}>
        <Typography
          component="span"
        >
          {(student?.sections && student?.sections.length > 1) ? 'Multiple' : section?.name}
        </Typography>
      </StyledTableDataColumn>
    </TableRow>
  );
};

export default ListRow;
