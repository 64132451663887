import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from '../../../components/Layout';
import useBackPackViewForStudents from './student-backpack-view-hook';
import Loader from '../../../components/Loader/loader';
import FeedbackTile from '../../../components/FeedbackTile';
import CheckIcon from '../../../components/Icons/CheckIcon';
import AppleIcon from '../../../components/Icons/AppleIcon';
import SubmissionTile from '../../../components/SubmissionTile';
import { FixedHeader } from './studentbackpackview.styled';
import { toReadableFormat } from '../../../utils/dateFormat';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';

const StudentBackPackView = () => {
  const {
    submission,
    loading,
    showPreview,
    togglePreview,
    setIsPlaying,
    selectedPlayerId,
    setSelectedPlayerId,
  } = useBackPackViewForStudents();

  const assignmentOrBenchmark = submission?.assignment ?? submission?.getBenchmark

  const breadcrumb = [{
    label: 'BACKPACK',
    route: '/backpack',
  }, {
    label: assignmentOrBenchmark?.title ?? '',
  }];
  const submittedAt = submission?.submitted_at;
  return (
    <Layout>
      <Loader open={loading} />
      <FixedHeader>
        <AppBreadcrumbs breadcrumb={breadcrumb} />
        <Box>
          <Typography variant='pageTitle' sx={{ textTransform:'none', pt:'24px' }}>{assignmentOrBenchmark?.title}</Typography>
          <Typography component='p' variant='sLabel'sx={{ pb:'16px' }} >
            Submitted
            {' '}
            {submittedAt ? toReadableFormat(submittedAt) : ''}
          </Typography>
        </Box>
      </FixedHeader>
      {!loading && (
        <Box
          display="flex"
          sx={{
            gridGap: 30,
          }}
        >
          <Box width="580px">
            <SubmissionTile
              submission={submission}
              title="My Submission"
              togglePreview={togglePreview}
              show={showPreview}
              Icon={CheckIcon}
              setIsPlaying={setIsPlaying}
              selectedPlayerId={selectedPlayerId}
              setSelectedPlayerId={setSelectedPlayerId}
            />
          </Box>
          <Box width="520px">
            <FeedbackTile
              submission={submission}
              title="Teacher Feedback"
              Icon={AppleIcon}
              setIsPlaying={setIsPlaying}
            />
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default React.memo(StudentBackPackView);
