import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { match, useHistory } from 'react-router-dom';
import { palette } from '../../theme/palette';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?:  string;
    '--tree-view-chip-bg-color'?:  string;
  }
}

type TreeItems = {
  indexId: string,
  label: string,
  linkToRedirect: string,
  routeMatches: match<{}> | null,
  icon: React.ElementType<SvgIconProps>,
  counterChips?: React.Component | React.ReactElement
};

interface Props{
  mainCategoryItem: TreeItems
  subCategoryItems: TreeItems[]
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  chipBgColor?: string;
  labelIcon: React.ElementType<SvgIconProps> | undefined;
  labelInfo?: string;
  labelText: string;
  counterChip?: React.Component | React.ReactElement
};

const TreeViewNavigation = ({ mainCategoryItem, subCategoryItems }:Props) => {
  const history = useHistory();
  const navigateTo = (path: string) => () => {
    history.push(path);
  };

  const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
      width: '100%',
      display: 'flex',
      padding: 10,
      paddingLeft: 0,
      border: '2px solid transparent',
      borderRadius: 12,
      fontSize: '18px',
      lineHeight: '25px',
      fontWeight: 600,
      textTransform: 'capitalize',
      backgroundColor: mainCategoryItem.routeMatches ? `var(--tree-view-bg-color, ${palette.customBlue.primaryBlue})` : palette.customWhite.main,
      color: mainCategoryItem.routeMatches ?  palette.customWhite.main : palette.customBlack.lightBlack,
      '& .MuiTypography-root':{
        color: mainCategoryItem.routeMatches ? palette.customWhite.main : palette.customBlack.lightBlack,
      },
      '&:hover': {
        backgroundColor:mainCategoryItem.routeMatches ? `var(--tree-view-bg-color, ${palette.customBlue.primaryBlue})` : palette.customWhite.main,
        color: mainCategoryItem.routeMatches ?  palette.customWhite.main : palette.customBlack.lightBlack,
      },
      '&.Mui-selected:hover': {
        backgroundColor: palette.customBlue.primaryBlue,
        '& .MuiTypography-root':{
          color: mainCategoryItem.routeMatches ? palette.customWhite.main : palette.customBlack.lightBlack,
        },
      },
      '&.Mui-focused:hover': {
        color: palette.customWhite.main,
      },
      '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
        backgroundColor: palette.customBlue.primaryBlue,
        '& .MuiTypography-root':{
          color: palette.customWhite.main,
        },
      },
      [`& .${treeItemClasses.label}`]: {
        padding: 0,
      },
      [`& .${treeItemClasses.iconContainer}`]: {
        display: 'none',
      },
    },
    [`& .${treeItemClasses.group}`]: {
      margin: '5px 0',
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
        backgroundColor: 'var(--tree-view-bg-color)',
        '& .MuiTypography-root': {
          color: 'var(--tree-view-color)',
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
          backgroundColor: 'var(--tree-view-bg-color)',
          '& .MuiTypography-root': {
            color: 'var(--tree-view-color)',
          },
        },
        [`& .${treeItemClasses.label}`]: {
          fontWeight: 600,
          fontSize:'18px',
          color: palette.customBlack.fontBlack,
          '& .MuiSvgIcon-root':{
            fontSize: '12px',
          },
          '& .MuiTypography-root':{
            marginLeft: '8px',
          },
          '& .MuiChip-root': {
            backgroundColor: 'var(--tree-view-chip-bg-color)',
          },
        },
      },
    },
  }));

  const StyledTreeItem = (props: StyledTreeItemProps) => {
    const {
      labelIcon: LabelIcon,
      labelText,
      bgColor,
      color,
      chipBgColor,
      counterChip,
      ...other
    } = props;
    return (
      <StyledTreeItemRoot
        label={
          <Box sx={{ display: 'flex', justifyContent:'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', p:0 }}>
              <Box component={LabelIcon} sx={{ mr: 1, ml: 0.5 }} />
              <Typography sx={{ fontSize:'18px', fontWeight:600 }}>
                {labelText}
              </Typography>
            </Box>
            <Box>{counterChip}</Box>
          </Box>
        }
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
          '--tree-view-chip-bg-color': chipBgColor,
        }}
        {...other}
      />
    );
  };

  return (
    <TreeView
      sx={{ width: '100%' }}
      onNodeToggle={navigateTo(mainCategoryItem.linkToRedirect)}
      expanded={mainCategoryItem.routeMatches ? ['1'] : undefined}
    >
      <StyledTreeItem
        nodeId={mainCategoryItem.indexId}
        labelText={mainCategoryItem.label}
        labelIcon={mainCategoryItem.icon}
        counterChip={mainCategoryItem.counterChips}
      >
        {subCategoryItems?.map((item)=>{
          return (
            <Box key={item?.label}>
              <StyledTreeItem
                nodeId={mainCategoryItem.indexId}
                labelText={item.label}
                labelIcon={ item.routeMatches ? item.icon : undefined}
                onClick={navigateTo(item.linkToRedirect)}
                counterChip={item.counterChips}
                color={item.routeMatches ? palette.customBlue.primaryBlue : palette.customBlack.fontBlack}
                bgColor={item.routeMatches ? palette.customGrey.dragBorder : palette.customWhite.main}
                chipBgColor={item.routeMatches ? palette.customWhite.main : palette.customBackground.dragBackground}
              />
            </Box>
          );
        })}
      </StyledTreeItem>
    </TreeView>
  );
};

export default TreeViewNavigation;
