import { useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  useGetGoalsQuery,
  useStudentDetailQuery,
  useGetStudentScoreSummaryQuery,
  GoalType,
  useGetAllPtStudentSubmissionsForTeacherQuery,
} from '../../../generated/graphql';
import { palette  } from '../../../theme/palette';
import { RubricScoreDetail, ScoreScheme } from '../../../variables/types';
import { useAuthProvider } from '../../../core/authContext';

interface RouteProp {
  id: string
}

const useStudentsSubmission = () => {
  const { id } = useParams<RouteProp>();
  const [assignment, setAssignment] = useQueryParam('assignment', StringParam);
  const [selectedSection, setSelectedSection] = useQueryParam('section', StringParam);

  const { data: scoreData, loading: scoreLoading } = useGetStudentScoreSummaryQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      ...(selectedSection ? { sectionIDs: [selectedSection] } : {}),
      ...(assignment ? { assignmentIDs: [assignment] } : {}),
      studentID: id,
    },
  });

  const { data, loading } = useGetAllPtStudentSubmissionsForTeacherQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...(selectedSection ? { section_id: selectedSection } : {}),
      ...(assignment ? { assignment_id: assignment } : {}),
      student_id: id!,
    },
  });

  const { data: studentDetail, loading: studentLoading } = useStudentDetailQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: id!,
    },
  });

  const { data: goalsDetail, loading: goalsLoading } = useGetGoalsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      goalType: GoalType.Active,
      studentID: id,
      ...(selectedSection ? { sectionID: selectedSection } : {}),
      ...(assignment ? { assignmentID: assignment } : {}),
    },
  });

  const { data: previousGoals, loading: previousLoading } = useGetGoalsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      goalType: GoalType.Attached,
      studentID: id,
      ...(selectedSection ? { sectionID: selectedSection } : {}),
      ...(assignment ? { assignmentID: assignment } : {}),
    },
  });

  const studentDetails = studentDetail?.student;
  const assignments = data?.getAllAssignmentSubmissionsForTeacher?.assignments ?? [];

  const { getUser } = useAuthProvider();
  const user = getUser();
  const scoreScheme = user?.score_scheme?.scoreDetail?.map((sscheme: any) => ({
    'score_value' : sscheme.score_value,
    'color_code' : sscheme.color_code,
  }));

  const maxScoreCount = scoreData?.studentScoreSummary.max_score_count || 5;

  const scoreGraphData = scoreData?.studentScoreSummary?.rubric_groups.map((scoreSubmission) => {
    const totalScore = scoreSubmission.score_line_items.reduce((acc, score: RubricScoreDetail) => acc + score.count, 0);
    const averageScore = parseFloat((totalScore / (scoreSubmission.score_line_items.length || 1)).toFixed(1));
    return {
      submissionType: scoreSubmission.submission_type,
      average: averageScore,
      barData: scoreSubmission.score_line_items.map((score:RubricScoreDetail) => ({
        skillLevel: score.count,
        skillLabel: score.score_detail_title,
        skillIcon: score.rubric_icon_base_64,
        skillDescription: score.score_detail_description,
        skillLinkText: 'student',
        color: score.score_detail_color_code,
        scoreLabel: score.rubric_title,
        scoreKey: scoreSubmission.submission_type,
      })),
      donutGraphData: [{
        color: scoreData.studentScoreSummary?.score_details?.find((score:ScoreScheme) => score.score_value === Math.floor(averageScore))?.color_code,
        average: averageScore,
      },
      {
        color: palette.customBackground.graphBackgroundColor,
        average: maxScoreCount - averageScore,
      }],
    };
  }).sort((score1, score2) => (score1.submissionType > score2.submissionType ? 1 : -1));

  return {
    id,
    loading: loading || studentLoading || goalsLoading || scoreLoading || previousLoading,
    assignments,
    studentDetails,
    goalsDetail,
    previousGoals,
    assignment,
    setAssignment,
    selectedSection,
    setSelectedSection,
    maxScoreCount,
    scoreGraphData,
    scoreScheme: scoreScheme ?? [],
  };
};

export default useStudentsSubmission;
