import { BENCHMARK_DOES_NOT_EXIST_ERROR, BENCHMARK_DOES_NOT_EXIST_MESSAGE, PAGE_SIZE } from '../../../variables/constant';
import { BenchmarkSuperAdminDtoSortType, OrderBy, useBenchmarksListQuery, useDeleteBenchmarkMutation } from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { useRef } from 'react';
import { toUTC } from '../../../utils/dateFormat';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { NotifierType } from '../../../variables/types';
import getErrorMessage from '../../../utils/getErrorMessage';
import { openSnackbar } from '../../../components/Notifier';

const useBenchmarksList = ( ) => {
  const pagination = usePagination();
  const now = useRef(toUTC(new Date()));
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, BenchmarkSuperAdminDtoSortType.StartDate));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [deleteBenchmark, { loading: deleteLoading }] = useDeleteBenchmarkMutation()
  const { data, loading, refetch } = useBenchmarksListQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: PAGE_SIZE,
      page: pagination.page,
      sort: sort as BenchmarkSuperAdminDtoSortType,
      orderBy: order as OrderBy,
    },
  });

  const onSortChange = (sortBy: BenchmarkSuperAdminDtoSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
  };

  const onDeleteBenchmarkHandle = async (id: string) => {
    try {
      const response = await deleteBenchmark({
        variables: {
          id: id,
        },
      });
      openSnackbar({
        message: 'Benchmark deleted successfully',
      }, NotifierType.Success);
      if (response.data?.deleteBenchmark) refetch();
    } catch (err) {
      if (getErrorMessage(err) === BENCHMARK_DOES_NOT_EXIST_ERROR) {
        openSnackbar({
          message: BENCHMARK_DOES_NOT_EXIST_MESSAGE,
        }, NotifierType.Error);
      } else {
        openSnackbar({ message: getErrorMessage(err) }, NotifierType.Error);
      }
    }
  };

  const benchmarks = data?.getAllBenchmarksForSuperAdmin?.nodes;
  const totalCount = data?.getAllBenchmarksForSuperAdmin?.totalCount;

  return {
    loading: loading || deleteLoading,
    benchmarks,
    pagination,
    totalCount,
    onSortChange,
    onDeleteBenchmarkHandle,
    sort,
    order: order as OrderBy,
  };
};

export default useBenchmarksList;
