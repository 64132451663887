import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { InputAdornment, Typography } from '@mui/material';
import { palette } from '../../theme/palette';
import { svgStyles } from '../Common/Common.styled';
import BenchmarkTypeIcon from '../Icons/BenchmarkTypeIcon';
import useBenchmarkTypes from '../BenchmarkTypes/benchmarkTypes-hook';
import { KeyValueType } from '../../generated/graphql';


interface BenchmarkTypesMultiSelectFilterProps {
  onChange: (values:any[]) => void;
  value: (string | undefined)[]
  limitTags?: number;
  textInputPlaceholder?: string;
  placeholder?: string;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BenchmarkMultiTypeSelectFilter = ({
  onChange,
  limitTags = 3,
  textInputPlaceholder,
  placeholder,
  value,
}: BenchmarkTypesMultiSelectFilterProps) => {

  const { benchmarkTypes, loading } = useBenchmarkTypes();

  const handleTypeChange = (_: React.ChangeEvent<{}>, newValues: any[]) => {
    onChange(newValues?.map((option: KeyValueType) => option?.key));
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disabled={loading}
      options={benchmarkTypes}
      limitTags={limitTags}
      getOptionLabel={(option: any) => option?.value}
      value={benchmarkTypes?.filter((benchType) => value?.includes(benchType?.key))}
      onChange={handleTypeChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <FormControlLabel
            control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />}
            label={option?.value}
          />
        </li>
      )}
      renderTags={(benchmarkValues, getTagProps) =>
        benchmarkValues.map((option, index) => (
          <Chip label={option?.value} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <>
          {placeholder && <Typography variant='sFormTitle' component='p'>{placeholder}</Typography>}
          <TextField
            {...params}
            placeholder={params.InputProps.startAdornment ? undefined : (!textInputPlaceholder ? 'Select Benchmarks' : textInputPlaceholder)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: 'auto', maxHeight: '500px', fontSize: '16px', background: palette.customWhite.main },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <BenchmarkTypeIcon pathFill={palette.customBlue.primaryBlue} sx={{ ...svgStyles.icon }}/>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default BenchmarkMultiTypeSelectFilter;
